import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'twbooking-contact-us',
  templateUrl: './unit-enquiry-dialog.component.html',
  styleUrls: ['./unit-enquiry-dialog.component.scss'],
})
export class UnitEnquiryDialogComponent implements OnInit {
  @ViewChild('inquiryComponent') inquiryComponent: any;
  warningMessage: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UnitEnquiryDialogComponent>,
    private breakpointObserver: BreakpointObserver
  ) {}
  ngOnInit(): void {}

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  handleSubmit() {
    let resp: { result: any; warningMessage: string } =
      this.inquiryComponent.handleSubmit(
        this.inquiryComponent.form.value,
        this.inquiryComponent.form.valid
      );
    if (resp.warningMessage) {
      this.warningMessage = resp.warningMessage;
    }
    if (resp.result) {
      this.dialogRef.close(resp.result);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
