<mat-card
  appearance="outlined"
  class="flex-container-column unit-summary-card brand-white-background new-brand-color"
>
  <div class="ghost-image" mat-card-image></div>

  <div class="summary-info-container brand-white-background">
    <div class="lines">
      <h1 class="summary-title brand-offwhite-color full-width"></h1>
      <h1 class="amenity brand-accent-background half-width"></h1>
      <p class="half-width brand-offwhite-color brand-offwhite-border"></p>
      <p class="full-width brand-offwhite-color brand-offwhite-border"></p>
    </div>
  </div>
</mat-card>
