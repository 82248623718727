import { LodgingQuote } from './lodging-quote.model';

export interface MMRate {
  Id: string; // rate calculator id
  TotalCost__c: number;
  Rent__c: number;
  TotalRent__c: number;
  TotalHousekeeping__c: number;
  PetDeposit__c: number;
  Discounted_Rent__c?: number;
  Discount__c?: number;
  RefundableDeposit__c?: number;
  Subtotal: number;
  GrossSales: number;
  DamageProtectionFee__c?: number;
  VRDPDamagePolicy__c?: number; // The amount of Damage Protection Fee that guest accepted to pay. DamageProtectionFee__c if user paid; 0 if user opted it out
  DamageProtectionDeposit__c?: number; // The amount of Damage Protection Deposit that guest accepted to pay. It is 3000 or 5000 depending on the DamageProtectionFee__c if guest not paid DamageProtectionFee__c, or 0 if guest paid DamageProtectionFee__c
  CleaningService__c: number;
  GuestServicePackage__c: number;
  PetFee__c: number;
  TAX__c: number;
  Insurance__c: number;
  TravelInsurance__c?: number; // The amount of insurance that guest accepted to pay. 0 if checkbox is false, Insurance__c if checkbox is true
  arrival?: Date;
  departure?: Date;
  numAdults?: number;
  numChildren?: number;
  numInfants?: number;
  numPets?: number;
}

export const DamageProtectionDeposit: { fee: number; deposit: number }[] = [
  { fee: 59, deposit: 3000 },
  { fee: 129, deposit: 5000 },
];

export interface QuoteData {
  arrival?: string; // YYYY-MM-DD
  departure?: string;
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
  quoteMM?: MMRate; // For inntopia units
  quoteInntopia?: InntopiaRates;
  errorMessage?: string;
  lodgingQuote?: LodgingQuote;
}

export interface InntopiaSupplier {
  HotelCode__c: string;
  Commission__c?: string;
  RateType__c?: string;
  InntopiaFees__r?: InntopiaFee[];
}

export interface InntopiaFee {
  Name?: string;
  FeeType__c?: string;
  Fee__c?: string;
  FeeTax__c?: string;
}

export interface InntopiaRates {
  rateType?: boolean; // NET = true or RAC = false
  rateCode?: string;
  rent?: number;
  baseRent?: number; // populated if there is promo. This is the original (NET/RAC) rent before promo
  baseDisplayRent?: number; // populated if there is promo. This is the original (NET/RAC) display rent before promo
  DisplayRent: number;
  DisplayFees: number;
  DisplayTaxes: number;
  savingsAmount?: number;
  fees?: number;
  optionalFees?: number;
  taxes?: number;
  minStay?: number;
  capacity?: number; // Occupancy, Sleep__c for unit
  error?: string;
  deposit?: number;
  DailyHousekeepingFee__c?: number;
  CheckOutCleanFee__c?: number;
  arrival?: string;
  departure?: string;
  numAdults?: number;
  numChildren?: number;
  numInfants?: number;
  numPets?: number;
  insurance?: number;
  TravelInsurance__c: number; // The amount of insurance that guest accepted to pay. 0 if checkbox is false, Insurance__c if checkbox is true
}
