export const newbrand = 'vacationsbylaurie.rentals';

export const mainsite = 'thetravelwhisperer.com';

export const mm = 'mountainmanagement.com';

export const staging = 'travelwhisperer-booking-stage.web.app';

export const stagingwtlbl = 'travelwhisperer-booking-stage.firebaseapp.com';

export const localhost = 'localhost';

export const amanda = 'amandastravels.rentals';

export const smartflyer = 'smartflyer.rentals';

export const jettsettravel = 'jettsettravel.rentals';

export const packhappytravel = 'packhappytravel.rentals';

export const neutral = 'neutral';

export const firstteetravel = 'travelwhisperer-booking-stage.firebaseapp'; //'travelwhisperer-booking-stage.firebaseapp.com';
