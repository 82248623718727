// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  development: false,
  sentryEnv: 'staging',
  version: '2.10.4',
  firebaseConfig: {
    apiKey: 'AIzaSyDpJYZCnNGi_T2hj3ecucIZc1kUkf2HZSo',
    authDomain: 'travelwhisperer-booking-stage.firebaseapp.com',
    databaseURL: 'https://travelwhisperer-booking-stage.firebaseio.com',
    projectId: 'travelwhisperer-booking-stage',
    storageBucket: 'travelwhisperer-booking-stage.appspot.com',
    messagingSenderId: 'MESSAGING_SENDER_ID_STAGE',
    appId: '1:479760156912:web:363eb42aa0aa599c',
  },
  recaptchaEnterpriseSiteKey: '6Lerm1MpAAAAANyz-db87Mu6Glmz2b81DwXlqjgp',
  cyberSourceUrl: 'https://secureacceptance.cybersource.com/pay',
  sentry: 'https://49734b9d4cbb437c9cb10f4679fbaa06@o216257.ingest.sentry.io/1356639',
  ttw: 'https://booking.thetravelwhisperer.com/',
  mm: 'https://booking.mountainmanagement.com/',
  laurie: 'https://vacationsbylaurie.rentals/',
  amandastravel: 'https://amandastravels.rentals',
  smartflyer: 'https://smartflyer.rentals',
  jettsettravel: 'https://jettsettravel.rentals',
  packhappytravel: 'https://packhappytravel.rentals',
  stagingwebapp: 'https://travelwhisperer-booking-stage.web.app/',
  stagingfirebaseapp: 'https://travelwhisperer-booking-stage.firebaseapp.com/',
  dynamicLinkDomain: 'booking-staging.thetravelwhisperer.com',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
