<mat-select
  #mySelect
  panelClass="guests-select"
  disableOptionCentering
  (closed)="numberofGuestChanged()"
  placeholder="{{ guests }}"
>
  <mat-select-trigger>
    {{ guests }}
  </mat-select-trigger>
  <mat-option disabled>
    <div class="guest-column">
      <span class="guest-title small-text new-brand-color">ADULTS</span>
      <span class="guest-description">Ages 13 or above</span>
    </div>
    <span class="guest-class">
      <button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('-', 'adults')"
      >
        <span>&minus;</span></button
      >&nbsp;&nbsp;<span class="new-brand-color">{{ adults }}</span
      >&nbsp;&nbsp;<button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('+', 'adults')"
      >
        <span>&#43;</span>
      </button>
    </span>
  </mat-option>
  <mat-option disabled>
    <div class="guest-column">
      <span class="guest-title small-text new-brand-color">CHILDREN</span>
      <span class="guest-description">Ages 2-12</span>
    </div>
    <span class="guest-class">
      <button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('-', 'children')"
      >
        <span>&minus;</span></button
      >&nbsp;&nbsp;<span class="new-brand-color">{{ children }}</span
      >&nbsp;&nbsp;<button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('+', 'children')"
      >
        <span>&#43;</span>
      </button>
    </span>
  </mat-option>

  <mat-option disabled *ngIf="petFriendly">
    <div class="guest-column">
      <span class="guest-title small-text">PETS</span>
      <span class="guest-description">&nbsp;</span>
    </div>
    <span class="guest-class">
      <button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('-', 'pets')"
      >
        <span>&minus;</span></button
      >&nbsp;&nbsp;{{ pets }}&nbsp;&nbsp;<button
        ttwNoDoubleTapZoom
        class="plus-minus-button"
        (click)="changeGuests('+', 'pets')"
      >
        <span>&#43;</span>
      </button>
    </span>
  </mat-option>
  <div class="guests-button-container">
    <button
      class="ttw-transparent-button"
      mat-button
      (click)="guestsPanelClose()"
    >
      Done
    </button>
  </div>
</mat-select>
