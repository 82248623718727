import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PathNotFoundComponent } from './core/path-not-found/path-not-found.component';
import { UnitDetailsComponent } from './units/unit-details/unit-details.component';
import { ComplexDetailsComponent } from './units/complex-details/complex-details.component';
import { UnitSearchListingComponent } from './units/unit-search-listing/unit-search-listing.component';
import { UnitEnquiryThankYouComponent } from './units/unit-booking/unit-enquiry-thank-you/unit-enquiry-thank-you.component';
import { BookingReservationThankYouComponent } from './units/unit-booking/booking-reservation-thankyou/booking-reservation-thankyou.component';
import { CheckoutStep1Component } from './shared/checkout-step1/checkout-step1.component';
import { MainPageComponent } from './units/main-page/main-page.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { TTWGuardService as TTWGuard } from './services/mm-guard.service';
import { PortalGuardService as PortalGuard } from './services/portal-guard.service';
import { BookingReservationThankyouFailComponent } from './units/unit-booking/booking-reservation-thankyou-fail/booking-reservation-thankyou-fail.component';
import { BookingReservationThankyouSuccessComponent } from './units/unit-booking/booking-reservation-thankyou-success/booking-reservation-thankyou-success.component';
import { FavoritesComponent } from './units/favorites/favorites.component';
import { BookingReservationThankyouCancelComponent } from './units/unit-booking/booking-reservation-thankyou-cancel/booking-reservation-thankyou-cancel.component';
import { ContactUsComponent } from './units/contact-us/contact-us.component';
import { SignUpComponent } from './core/sign-up/sign-up.component';
import { ComplexListingComponent } from './units/complex-listing/complex-listing.component';
import { MapSearchPageComponent } from './units/map-search-page/map-search-page.component';

const routes: Routes = [
  { path: '', component: MainPageComponent, canActivate: [TTWGuard] },
  { path: 'units', component: UnitSearchListingComponent },
  { path: 'search-results', component: UnitSearchListingComponent },
  { path: 'complexes', component: ComplexListingComponent },
  { path: 'villages/:Destination', component: MainPageComponent },
  { path: 'unit-details/:unitSlug__c', component: UnitDetailsComponent },
  { path: 'complex-details/:unitSlug__c', component: ComplexDetailsComponent },
  { path: 'Checkout', component: CheckoutStep1Component },
  {
    path: 'favorites/:favoritesId',
    component: FavoritesComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'favorites',
    component: FavoritesComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'guest',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [PortalGuard],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'map-search',
    component: MapSearchPageComponent,
  },
  {
    path: 'booking-enquiry-thank-you',
    component: UnitEnquiryThankYouComponent,
  },
  {
    path: 'booking-reservation-thankyou',
    component: BookingReservationThankYouComponent,
  },
  {
    path: 'booking-reservation-thankyou-fail',
    component: BookingReservationThankyouFailComponent,
  },
  {
    path: 'booking-reservation-thankyou-success',
    component: BookingReservationThankyouSuccessComponent,
  },
  {
    path: 'booking-reservation-thankyou-cancel',
    component: BookingReservationThankyouCancelComponent,
  },
  { path: 'sign-up', component: SignUpComponent },
  { path: '**', pathMatch: 'full', component: PathNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
