<twbooking-chat *ngIf="!isMobile" mode="button"></twbooking-chat>
<div class="unit-details-page" *ngIf="unit" [hidden]="!unit">
  <div class="breadcrumb-bar" *ngIf="isMobile" class="top-icons-space-between">
    <button
      class="back-button"
      mat-button
      aria-label="Back button"
      (click)="back()"
    >
      <mat-icon class="brand-dark-color">keyboard_arrow_left</mat-icon>
      <span class="back-text">Back to homes listing</span>
    </button>
    <button
      mat-icon-button
      *ngIf="!nb && !favoriteUnit"
      class="favorite-icon"
      (click)="saveFavorite()"
      matTooltip="Please like the units that you are interested in to help our personal travel designers find the best options for you"
    >
      <mat-icon class="brand-dark-color">favorite_border</mat-icon>
    </button>

    <button
      mat-icon-button
      *ngIf="!nb && favoriteUnit"
      class="favorite-icon"
      (click)="removeFavorite()"
    >
      <mat-icon class="red-color">favorite</mat-icon>
    </button>
  </div>
  <div *ngIf="!loading && unitExist">
    <span *ngIf="unit.ImagesProcessed">
      <twbooking-unit-gallery [unit]="unit"></twbooking-unit-gallery>
    </span>
    <div class="unit-details-content-section">
      <div *ngIf="unit" class="unit-details-content-layout">
        <twbooking-unit-tabs [unit]="unit" class="left"></twbooking-unit-tabs>
      </div>
    </div>
    <twbooking-unit-related [unit]="unit"></twbooking-unit-related>
  </div>
</div>

<div class="loading-container-page" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!unitExist && !loading" class="unit-not-found">
  <h1 class="mat-h1">Oops!</h1>

  <h2>We can't seem to find the home you're looking for.</h2>
  <p>Here are available homes instead:</p>
  <p>
    <a
      [routerLink]="['/units']"
      routerLinkActive="router-link-active"
      class="show-more-link"
      >View Homes</a
    >
  </p>
</div>
<twbooking-footer></twbooking-footer>
