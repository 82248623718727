import { MMRate, InntopiaRates } from './rate.models';
import { FeaturedAmenity } from './unit.model';
export interface ComplexSummary {
  externalId: string;
  MarketingHeadline__c: string;
  Name: string;
  Destination: string;
  isInntopiaUnit__c: boolean;
  inntopiaSupplierId__c: number;
  DisplayRate__c: string;
  UnitImageName: string;
  URL__c: string;
  SeasonThumbnail: string;
  SeasonCaption?: string;
  Caption__c: string;
  Display__c: string;
  PropertyManager__c: string;
  UnitType__c: string;
  PriorityListing__c: number;
  UnitSlug__c: string;
  ReviewCount: number;
  ReviewRating: number;
  Pets_Allowed__c: boolean;
  additionalimages: ThumbnailImages[];
  Summary_Description__c: string;
  promounits?: number;
  featuredamenities: FeaturedAmenity[];
  unitsSummary: string;
}

export interface ThumbnailImages {
  URL__c: string;
  Caption__c: string;
}

export const locationCategories = [
  {
    Name: 'General',
    Icon: 'M2,12L12,3L22,12H19V20H5V12H2M12,18L12.72,17.34C15.3,15 17,13.46 17,11.57C17,10.03 15.79,8.82 14.25,8.82C13.38,8.82 12.55,9.23 12,9.87C11.45,9.23 10.62,8.82 9.75,8.82C8.21,8.82 7,10.03 7,11.57C7,13.46 8.7,15 11.28,17.34L12,18Z',
  },
  {
    Name: 'Amenity',
    Icon: 'M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z',
  },
  {
    Name: 'Attractions',
    Icon: 'M10,21V18H3L8,13H5L10,8H7L12,3L17,8H14L19,13H16L21,18H14V21H10Z',
  },
  {
    Name: 'Convenience',
    Icon: 'M5.5,21C4.72,21 4.04,20.55 3.71,19.9V19.9L1.1,10.44L1,10A1,1 0 0,1 2,9H6.58L11.18,2.43C11.36,2.17 11.66,2 12,2C12.34,2 12.65,2.17 12.83,2.44L17.42,9H22A1,1 0 0,1 23,10L22.96,10.29L20.29,19.9C19.96,20.55 19.28,21 18.5,21H5.5M12,4.74L9,9H15L12,4.74M12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13Z',
  },
  {
    Name: 'Entertainment',
    Icon: 'M16,9V7H12V12.5C11.58,12.19 11.07,12 10.5,12A2.5,2.5 0 0,0 8,14.5A2.5,2.5 0 0,0 10.5,17A2.5,2.5 0 0,0 13,14.5V9H16M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z',
  },
  {
    Name: 'Kitchen',
    Icon: 'M12.5,3C7.81,3 4,5.69 4,9V9C4,10.19 4.5,11.34 5.44,12.33C4.53,13.5 4,14.96 4,16.5C4,17.64 4,18.83 4,20C4,21.11 4.89,22 6,22H19C20.11,22 21,21.11 21,20C21,18.85 21,17.61 21,16.5C21,15.28 20.66,14.07 20,13L22,11L19,8L16.9,10.1C15.58,9.38 14.05,9 12.5,9C10.65,9 8.95,9.53 7.55,10.41C7.19,9.97 7,9.5 7,9C7,7.21 9.46,5.75 12.5,5.75V5.75C13.93,5.75 15.3,6.08 16.33,6.67L18.35,4.65C16.77,3.59 14.68,3 12.5,3M12.5,11C12.84,11 13.17,11.04 13.5,11.09C10.39,11.57 8,14.25 8,17.5V20H6V17.5A6.5,6.5 0 0,1 12.5,11Z',
  },
  {
    Name: 'Leisure Activities',
    Icon: 'M19.5,15.5A0.5,0.5 0 0,1 19,16A0.5,0.5 0 0,1 18.5,15.5V8.5C18.5,6.57 16.43,5 14.5,5H6A4,4 0 0,0 2,9V19H6V15H11V19H15V14.5A0.5,0.5 0 0,1 15.5,14A0.5,0.5 0 0,1 16,14.5V16A3,3 0 0,0 19,19A3,3 0 0,0 22,16V14H19.5V15.5Z',
  },
  {
    Name: 'Local Services / Businesses',
    Icon: 'M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z',
  },
  // { Name: 'Location Type', Icon: 'local_activities' },//FIXME no icon
  {
    Name: 'Outdoor',
    Icon: 'M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z',
  },
  {
    Name: 'Pool / Spa',
    Icon: 'M7,4A2,2 0 0,1 9,6A2,2 0 0,1 7,8A2,2 0 0,1 5,6A2,2 0 0,1 7,4M11.15,12H22V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12H5V11.25C5,10 6,9 7.25,9H7.28C7.62,9 7.95,9.09 8.24,9.23C8.5,9.35 8.74,9.5 8.93,9.73L10.33,11.28C10.56,11.54 10.84,11.78 11.15,12M7,20V14H5V20H7M11,20V14H9V20H11M15,20V14H13V20H15M19,20V14H17V20H19M18.65,5.86C19.68,6.86 20.16,8.21 19.95,9.57L19.89,10H18L18.09,9.41C18.24,8.62 18,7.83 17.42,7.21L17.35,7.15C16.32,6.14 15.85,4.79 16.05,3.43L16.11,3H18L17.91,3.59C17.76,4.38 18,5.17 18.58,5.79L18.65,5.86M14.65,5.86C15.68,6.86 16.16,8.21 15.95,9.57L15.89,10H14L14.09,9.41C14.24,8.62 14,7.83 13.42,7.21L13.35,7.15C12.32,6.14 11.85,4.79 12.05,3.43L12.11,3H14L13.91,3.59C13.76,4.38 14,5.17 14.58,5.79L14.65,5.86Z',
  },
  {
    Name: 'Restrictions',
    Icon: 'M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z',
  },
  {
    Name: 'Sports and Adventure Activities',
    Icon: 'M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7',
  },
  {
    Name: 'Suitability',
    Icon: 'M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z',
  },
];

export interface Complex {
  externalId: string;
  MarketingHeadline__c: string;
  Name: string;
  DisplayRate__c: string;
  Active__c: boolean;
  AddressLine1__c: string;
  AddressLine2__c: string;
  CatsAllowed__c: boolean;
  IsDeleted: boolean;
  Dogs_Allowed__c: boolean;
  Driving_Directions__c: string;
  gridHero__c: boolean;
  IncludeInSearch__c: boolean;
  Long_Description__c: string;
  Pets_Allowed__c: boolean;
  Postal_Code__c: string;
  Property_Collection__c: string;
  Village: string;
  Destination: string;
  Smoking_Allowed__c: boolean;
  UnitCode__c: string;
  UnitType__c: string;
  WholesaleLink__c: string;
  PriorityListing__c?: boolean;
  UnitSlug__c: string;
  DisplayRates__c: boolean;
  Amenities__r: any[];
  FeaturedAmenities__r: FeaturedAmenity[];
  UnitTaxesFees__r: UnitTaxFee[];
  Unit_Rates__r: UnitRates[];
  BookedDates: BookedDates[];
  Images__r: UnitImage[];
  ImagesProcessed?: UnitImage[];
  Reviews__r: ReviewRecord[];
  Units__r: UnitShort[];
  Restaurant: Venue[];
  Spa: Venue[];
  locationAmenities: LocationAmenity[];
}

export interface LocationAmenity {
  Name: string;
  Category: string;
}

export interface Venue {
  Name: string;
  URL__c: string;
  Caption__c: string;
  Description__c: string;
}

export interface UnitShort {
  externalId: string;
  MarketingHeadline__c: string;
  Name: string;
  Bedrooms__c: number;
  Bathrooms__c: number;
  Sleeps__c: number;
  UnitSlug__c: string;
  URL__c: string;
  Caption__c: string;
  DisplayRates__c: boolean;
  UnitType__c: string;
  Property_Collection__c: string;
  isInntopiaUnit__c: boolean;
  Pets_Allowed__c: boolean;
  inntopiaSupplierId__c: string;
  inntopiaProductId__c: string;
  Destination: string;
  Total?: number | Error;
  Rent?: number | Error;
  showDetails?: boolean;
  rateDetails?: MMRate;
  inntopiaRate?: InntopiaRates;
  errorMessage: string;
  minStay: number;
  isInntopiaUnit: boolean;
  promotioncount?: number;
}

export interface UnitImage {
  Name: string;
  Caption__c?: string;
  URL__c: string;
  Display__c?: string;
  DisplayOrder__c?: number;
  Season__c: string;
}
export interface BookedDates {
  startDate: Date;
  endDate: Date;
}

export interface UnitRates {
  NightlyRate__c: number;
  NightlyWeekNightRate__c: number;
  Deposit__c: number;
  MinNights__c: number;
  PromoDiscount__c: number;
  PromoNoNights__c: number;
}

export interface UnitTaxFee {
  Name: string;
  Amount__c: string;
  Type__c: string;
  FeeOrTax__c: string;
  Mandatory__c: boolean;
}

export interface UnitsInformation {
  AdditionalInformation__c: string;
  AfterHoursAndEmergencies__c: string;
  AirConditioning__c: string;
  Alarm__c: string;
  CheckOutTime__c: string;
  Concierge__c: string;
  CreatedById: string;
  CreatedDate: string;
  DailARideNumber__c: string;
  IsDeleted: boolean;
  GarageCode__c: string;
  GarbageCollection__c: string;
  HotTub__c: string;
  DialARideInformation__c: string;
  LastModifiedById: string;
  LastModifiedDate: string;
  LastReferencedDate: string;
  LastViewedDate: string;
  Medical__c: string;
  Number__c: string;
  Parking__c: string;
  Password__c: string;
  PublicSafety__c: string;
  Id: string;
  Housekeeping__c: string;
  SkiStorage__c: string;
  SystemModstamp: string;
  Television__c: string;
  Unit__c: string;
  Name: string;
  WiFiNetwork__c: string;
  WindowsAndDoors__c: string;
}

export interface ReviewRecord {
  externalId: string;
  Cleanliness__c: string;
  CreatedById: string;
  Description__c: string;
  Guest__c: string;
  Heading__c: string;
  Location__c: string;
  Manager_s_Response__c: string;
  Name: string;
  OwnerId: string;
  Property_Condition__c: string;
  Rating__c: string;
  Recommend_to_Other__c: string;
  Review_Received_On__c: string;
  Services_From_PM__c: string;
  Unit__c: string;
  Review_Received_Date?: string;
  fullReview?: boolean;
}
