<section class="ks89-gallery">
  <mat-grid-list
    *ngIf="isMobile && galleryImages && galleryImages.length > 1"
    cols="1"
    rowHeight="3:2"
    class="my-grid"
  >
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <div class="main-image-container">
        <div
          class="left-arrow"
          (click)="showPrev()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_left</mat-icon>
        </div>

        <img
          width="100%"
          height="100%"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
          src="{{
            galleryImages[index].modal.img.includes('imgix')
              ? galleryImages[index].modal.img
                  .toString()
                  .substring(
                    0,
                    galleryImages[index].modal.img.toString().indexOf('?')
                  ) + this.mobilePostfix
              : galleryImages[index].modal.img
          }}"
          (click)="onShow(101, index)"
          alt="{{ galleryImages[index].modal.description }}"
          (error)="onImgError($event, image.URL__c)"
        />

        <div
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
          class="projected-mobile"
        >
          <h1 class="mat-h1">{{ unit?.MarketingHeadline__c }}</h1>
        </div>

        <div
          class="right-arrow"
          (click)="showNext()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_right</mat-icon>
        </div>

        <div
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
          class="preview-dots"
        >
          @for (dot of dotsPreviewArray; track i; let i = $index) {
          <span>
            <span
              [class.inactive-dot]="i !== index"
              [class.active-dot]="i === index"
            >
              &#9679;
            </span>
          </span>
          }
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list
    *ngIf="!isMobile && galleryImages && galleryImages.length > 3"
    cols="4"
    rowHeight="3:2"
    class="my-grid"
    gutterSize="3px"
  >
    <mat-grid-tile [colspan]="3" [rowspan]="3">
      <div class="main-image-container">
        <div
          class="left-arrow"
          (click)="showPrev()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_left</mat-icon>
        </div>

        @for (image of galleryImages; track image.id; let i = $index){
        <ng-container>
          <img
            *ngIf="i === index"
            width="100%"
            height="100%"
            (swipeleft)="showNext($event)"
            (swiperight)="showPrev($event)"
            src="{{
              image.modal.img.includes('imgix')
                ? image.modal.img
                    .toString()
                    .substring(0, image.modal.img.toString().indexOf('?')) +
                  this.imgixPostfix
                : image.modal.img
            }}"
            alt="{{ galleryImages[index].modal.description }}"
            @carouselAnimation
            (click)="onShow(101, index)"
            class="clickable-image"
          />
        </ng-container>
        }
        <ks-plain-gallery
          class="gallery"
          [id]="101"
          [images]="galleryImages"
          [currentImageConfig]="customFullDescriptionHidden"
          [dotsConfig]="dotsConfig"
          [config]="{ visible: false }"
          [slideConfig]="{
            sidePreviews: { show: false },
            infinite: true
          }"
          (clickImage)="onShow(101, $event)"
          [config]="libConfigPlainGallery"
          [plainGalleryConfig]="{ visible: false }"
          [carouselConfig]="carouselConfig"
        ></ks-plain-gallery>
        <div class="projected">
          <h1 class="mat-h1">{{ unit?.MarketingHeadline__c }}</h1>
        </div>

        <div
          class="right-arrow"
          (click)="showNext()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_right</mat-icon>
        </div>

        <div class="preview-dots">
          @for (dot of dotsPreviewArray; track i; let i = $index) {
          <span>
            <span
              [class.inactive-dot]="i !== index"
              [class.active-dot]="i === index"
            >
              &#9679;
            </span>
          </span>
          }
        </div>
      </div>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <img
        class="thumbnail-image"
        width="100%"
        height="100%"
        src="{{
          galleryImages[
            index + 1 < carouselMax ? index + 1 : index + 1 - carouselMax
          ].plain.img
        }}"
        (click)="increaseThumb(index + 1)"
        alt="{{
          galleryImages[
            index + 1 < carouselMax ? index + 1 : index + 1 - carouselMax
          ].modal.description
        }}"
      />
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <img
        class="thumbnail-image"
        width="100%"
        height="100%"
        src="{{
          galleryImages[
            index + 2 < carouselMax ? index + 2 : index + 2 - carouselMax
          ].plain.img
        }}"
        alt="{{
          galleryImages[
            index + 2 < carouselMax ? index + 2 : index + 2 - carouselMax
          ].modal.description
        }}"
        (click)="increaseThumb(index + 2)"
      />
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <img
        class="thumbnail-image"
        width="100%"
        height="100%"
        src="{{
          galleryImages[
            index + 3 < carouselMax ? index + 3 : index + 3 - carouselMax
          ].plain.img
        }}"
        alt="{{
          galleryImages[
            index + 3 < carouselMax ? index + 3 : index + 3 - carouselMax
          ].modal.description
        }}"
        (click)="increaseThumb(index + 3)"
      />
    </mat-grid-tile>
  </mat-grid-list>
</section>
