import {
  state,
  style,
  animate,
  transition,
  query,
  stagger
} from '@angular/animations';

export function fadeIn(selector = ':enter', duration = '1000ms ease-out') {
  return [
    transition('* => *', [
      query(
        selector,
        [
          style({ opacity: 0 }),
          stagger('60ms', [
            animate(
              duration,
              style({
                opacity: 1
              })
            )
          ])
        ],
        { optional: true }
      )
    ])
  ];
}

export function fadeOut(selector = ':leave', duration = '1000ms ease-out') {
  return [
    transition('* => *', [
      query(
        selector,
        [
          style({ opacity: 1 }),
          stagger('30ms', [
            animate(
              duration,
              style({
                opacity: 0
              })
            )
          ])
        ],
        { optional: true }
      )
    ])
  ];
}
