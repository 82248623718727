import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { localhost, mainsite, mm, staging } from '../store/models/hostings';

@Injectable({
  providedIn: 'root'
})
export class PortalGuardService  {
  constructor(public router: Router) {}
  canActivate(): boolean {
    if (
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost) ||
      window.location.hostname.includes(mm)
    ) {
      return true;
    } else {
      this.router.navigate(['/units']);
      return false;
    }
  }
}
