import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FirebaseApp, getApp } from '@angular/fire/app';
import {
  Functions,
  getFunctions,
  httpsCallable,
} from '@angular/fire/functions';
import { BehaviorSubject, from } from 'rxjs';
import { rewrites } from './rewrites';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private headers: HttpHeaders = new HttpHeaders();
  public token: string;
  public local = false; // TODO: refactor. If set local = true then it can be tested locally with proxy to staging firebase api
  app: FirebaseApp;
  functions: Functions;
  rewrites = rewrites;

  constructor(private http: HttpClient) {
    this.app = getApp();
    this.functions = getFunctions(this.app);
  }

  /* Post functions uses standard headers by default.
   * Use apiService.post(url, body, null,null, true ) - to sent post request without headers
   */

  public getUrl(url: string) {
    if (this.local) {
      return '/katya' + url;
    } else {
      return url;
    }
  }

  public httpCall(
    apiurl: string,
    body: any,
    customErrorHandler: Function | null = null
  ) {
    let functionName = this.getNameByApi(apiurl);
    if (!functionName) {
      console.error('httpCall: functionName not found for apiurl: ' + apiurl);
      return null;
    }
    const httpCallFn = httpsCallable(this.functions, functionName);
    return from(httpCallFn(body)).pipe(
      map((result) => {
        const res: any = result.data;
        return res;
      }),
      catchError((err) => {
        throw this.errorHandlerWrapper(
          customErrorHandler,
          err,
          apiurl,
          body.sessionId ? body.sessionId : null
        );
      })
    );
  }

  getNameByApi(apiurl: string) {
    const rewrite = rewrites.find((r) => r.source === apiurl);
    return rewrite ? rewrite.function : undefined;
  }

  // TODO: Develop nice error handler service
  private async errorHandlerWrapper(
    customErrorHandler: Function | null,
    err: HttpErrorResponse,
    endpoint: string = '',
    sessionId: string = null
  ) {
    let saved = await this.saveApiException(err, endpoint, sessionId);
    console.error('errorHandlerWrapper on ' + endpoint, err);

    if (customErrorHandler) {
      customErrorHandler(err);
    } else {
      console.error('errorHandlerWrapper on ' + endpoint, err);
      let errorString = 'API error: ' + JSON.stringify(err);
      if (err.error && err.error.message) {
        errorString += 'message: ' + err.error.message;
      }
      if (err.message) {
        errorString += 'message: ' + err.message;
      }
      throw new Error(errorString);
    }
    return err;
  }

  async saveApiException(err: any, apiurl: string = '', sessionId) {
    let gclid = await this.getClientId();
    console.log('gclid', gclid);
    console.log('httpCall error', err.message ? err.message : err);
    console.log('url', window.location.href);
    console.log('appversion', environment.version);
    console.log('sessionId', sessionId);
    if (apiurl.indexOf('saveException') === -1) {
      this.httpCall('/api/saveException', {
        exceptionData: {
          message: err.message ? err.message : err,
          endpoint: apiurl,
          url: window.location.href,
          appversion: environment.version,
          gclid: gclid,
          sessionId: sessionId,
        },
      }).subscribe((saved: any) => {
        console.log('exception saved');
      });
    } else {
      console.log('error in saveException ');
    }
  }

  getClientId() {
    return new Promise((resolve, reject) => {
      gtag('get', 'G-JR581MM61N', 'client_id', (clientId) => {
        if (clientId) {
          resolve(clientId);
        } else {
          reject('Failed to get client_id');
        }
      });
    });
  }
}
