<div *ngIf="showMap">
  <google-map
    [options]="mapOptions"
    *ngIf="latNumber && lngNumber"
    [center]="{ lat: latNumber, lng: lngNumber }"
    [zoom]="zoom"
    [width]="width"
    [height]="height"
  >
    <map-advanced-marker
      [content]="customPin.element"
      [position]="{ lat: latNumber, lng: lngNumber }"
      #mapMarker="mapAdvancedMarker"
      (mapClick)="openInfoWindowHTML(mapMarker)"
    ></map-advanced-marker>
    <map-info-window #infoWindow class="map-info-window"> </map-info-window>
  </google-map>
</div>
