import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { DialogReplica, UnitBasics } from 'src/app/store/models/chat.models';
import { ChatService } from '../../../services/chat.service';
import { EventService } from 'src/app/services/event.service';
import { locationsUnified } from 'src/app/store/models/search-options';
import { SearchParamsService } from 'src/app/services/search-params.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Subscription } from 'rxjs';
import { Unit } from 'src/app/store/models/unit.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  addDoc,
  collection,
  CollectionReference,
  collectionSnapshots,
  doc,
  docData,
  Firestore,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';

export interface MessagesCollections {
  COLLECTION_KEY: string;
  UUID_KEY: string;
  MESSAGE_COLLECTION_NAME: string;
}

const businessHours = [
  {
    //weekday
    start: moment.tz('09:00:00', 'HH:mm:ss', 'America/Denver').format(),
    end: moment.tz('17:00:00', 'HH:mm:ss', 'America/Denver').format(),
  },
  {
    //day off
    start: moment.tz('10:00:00', 'HH:mm:ss', 'America/Denver').format(),
    end: moment.tz('17:00:00', 'HH:mm:ss', 'America/Denver').format(),
  },
];

@Component({
  selector: 'twbooking-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
})
export class ChatDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer') private myScrollContainer: ElementRef;
  ptdInDialog: boolean;
  timeoutReached: boolean = false;
  constructor(
    private readonly firestore: Firestore,
    private chatService: ChatService,
    private apiService: ApiService,
    private unitService: UnitsService,
    private router: Router,
    private eventService: EventService,
    private searchService: SearchParamsService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<ChatDialogComponent>
  ) {
    this.ptdInDialog = false;
  }

  collectionObject: MessagesCollections = {
    COLLECTION_KEY: 'user',
    UUID_KEY: null,
    MESSAGE_COLLECTION_NAME: 'chat',
  };
  userText = new UntypedFormControl();
  dialogHistory: DialogReplica[] = [];
  sessionId: string = null;

  supportTyping = false;

  sub: Subscription = null;
  changeNameSub: Subscription = null;
  previousReplica = null;

  uuid = null;
  messagesCollection: CollectionReference;
  chatSessionIdSub: Subscription = null;
  ptdSub: Subscription = null;
  troubles: number = 0;

  segment = 0;

  volume: 'On' | 'Off' | null;

  FirstName__c = null;
  loadingHistory = false;

  ngOnInit(): void {
    this.uuid = this.authService.getUserId();
    this.volume = this.chatService.getVolume();

    const userRef = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    this.collectionObject.UUID_KEY = this.uuid;
    const collectionDoc = doc(userRef, this.collectionObject.UUID_KEY);
    this.messagesCollection = collection(
      collectionDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );

    this.dialogHistory = this.chatService.get();

    if (!this.ptdInDialog) {
      this.setWaitTimeout();
    }
    this.chatSessionIdSub = this.chatService.chatSessionId.subscribe(
      (chatId) => {
        if (chatId && chatId !== 'REQUESTED') {
          this.sessionId = chatId;
          if (this.ptdSub) {
            this.ptdSub.unsubscribe();
          }
          if (this.changeNameSub) {
            this.changeNameSub.unsubscribe();
          }
          this.setChatStatusAsRead(this.sessionId);
          this.ptdSub = this.fetchPTDAnswer().subscribe(
            (newMessages: { id: string; message: DialogReplica }[]) => {
              newMessages.forEach(
                (newM: { id: string; message: DialogReplica }) => {
                  let index = this.dialogHistory.findIndex(
                    (elem) => newM.id === elem.firebaseId
                  );
                  if (index === undefined || index < 0) {
                    let newMessage = newM.message;
                    this.ptdInDialog = true;
                    this.chatService.ptdEnteredChat(true);
                    let ptdReplica: DialogReplica = {
                      firebaseId: newM.id,
                      link: newMessage.link ? newMessage.link : null,
                      showUnitCard: newMessage.showUnitCard
                        ? newMessage.showUnitCard
                        : null,
                      linkDescription: newMessage.linkDescription
                        ? newMessage.linkDescription
                        : null,
                      externalLink: newMessage.externalLink
                        ? newMessage.externalLink
                        : null,
                      replica: newMessage.replica ? newMessage.replica : null,
                      author: 'ptd',
                      authorName: newMessage.authorName,
                      authorPhoto: newMessage.authorPhoto
                        ? newMessage.authorPhoto
                        : null, //avatarURL
                      created: newMessage.created,
                    };

                    this.dialogHistory.unshift(ptdReplica);
                    this.scrollToBottom();
                    this.chatService.update(this.dialogHistory);
                    ptdReplica.chatSessionId = this.sessionId;
                    ptdReplica.status = 'received';
                    this.updateStatusAsReceived(newM.id, ptdReplica);
                    this.eventService.triggerChatEvent(ptdReplica);
                  }
                }
              );
            }
          );
          const userCollection = collection(this.firestore, 'user');
          const uuidDoc = doc(userCollection, this.uuid);
          const chatCollection = collection(uuidDoc, 'chat');
          const existingMetaRef = doc(chatCollection, this.sessionId);
          this.changeNameSub = docData(existingMetaRef).subscribe(
            (existingMeta: any) => {
              if (existingMeta.userName) {
                this.authService.updateUserData({
                  firstName: existingMeta.userName,
                });
              }
            }
          );
        }
      }
    );

    this.ptdInDialog = this.chatService.getPtdStatus();
    this.troubles = this.chatService.getTroubles();
    if (!this.sessionId) {
      this.conversationStart();
    } else {
      this.loadingHistory = true;
      this.loadConversationHistory();
    }

    let user = this.userDataService.get();
    if (user && user.segment) {
      this.segment = user.segment;
    }
  }

  setWaitTimeout() {
    setTimeout(() => {
      if (!this.ptdInDialog) {
        this.timeoutReached = true;
        this.addTimeoutReplica();
      }
    }, 60000); // 60000 milliseconds = 1 minute.. 5 sec for test
  }

  async loadConversationHistory() {
    const userCollection = collection(this.firestore, 'user');
    const uuidDoc = doc(userCollection, this.uuid);
    const chatCollection = collection(uuidDoc, 'chat');
    const chatSessionDoc = doc(chatCollection, this.sessionId);
    const messagesCollection = collection(chatSessionDoc, 'messages');
    const messagesQuery = query(
      messagesCollection,
      where('chatSessionId', '==', this.sessionId),
      orderBy('created', 'desc')
    );
    this.dialogHistory = [];
    getDocs(messagesQuery).then((res) => {
      let isFirstPtdReplica = true;

      res.forEach((element) => {
        const id = element.id;
        let message = element.data() as DialogReplica;
        message.firebaseId = id;
        let index = this.dialogHistory.findIndex(
          (elem) => id === elem.firebaseId
        );

        if (isFirstPtdReplica && message.author === 'ptd') {
          isFirstPtdReplica = false;
          console.log('last message in history', message.created);
          console.log('now it is', Date.now());
          if (!this.ptdInDialog) {
            const firestoreTimestamp: any = message.created;

            // Step 1: Convert Firestore Timestamp to milliseconds
            const timestampMillis =
              firestoreTimestamp.seconds * 1000 +
              Math.floor(firestoreTimestamp.nanoseconds / 1000000);

            // Step 2: Get current time in milliseconds
            const currentTimeMillis = Date.now();

            // Step 3: Calculate the difference in milliseconds
            const differenceMillis = currentTimeMillis - timestampMillis;

            // Step 4: Convert the difference from milliseconds to minutes
            const differenceMinutes = differenceMillis / (1000 * 60);

            console.log(`Difference in minutes: ${differenceMinutes}`);
            if (differenceMinutes < 15) {
              this.ptdInDialog = true;
              console.log(
                'recent ptd answer was 15 min ago, considering ptd is in dialogue'
              );
            }
          }
        }

        if (message.replica !== this.timeoutApology) {
          //do not show apology if dialogue is loaded in new session
          if (index === undefined || index < 0) {
            let ptdReplica: DialogReplica = {
              firebaseId: id,
              link: message.link ? message.link : null,
              showUnitCard: message.showUnitCard ? message.showUnitCard : null,
              linkDescription: message.linkDescription
                ? message.linkDescription
                : null,
              externalLink: message.externalLink ? message.externalLink : null,
              replica: message.replica ? message.replica : null,
              author: message.author,
              authorName: message.authorName,
              authorPhoto: message.authorPhoto ? message.authorPhoto : null, //avatarURL
              created: message.created,
            };
            this.dialogHistory.push(message);
          }
        }
      });
      this.chatService.update(this.dialogHistory);
    });
    let botReplica: DialogReplica = {
      chatSessionId: this.sessionId,
      replica: `User has joined the chat.`,
      author: 'system',
    };

    this.dialogHistory.unshift(botReplica);

    this.chatService.setTroubles(1);
    this.saveMessageToFirestore(botReplica);
    this.scrollToBottom();
    this.chatService.update(this.dialogHistory);
  }

  generateUniqueString(): string {
    const date = new Date();
    const timestamp = date.getTime(); // Get current timestamp in milliseconds
    const milliseconds = date.getMilliseconds(); // Get milliseconds portion of the timestamp
    const uniqueString = `${timestamp}${milliseconds}`; // Concatenate timestamp and milliseconds

    return uniqueString;
  }

  conversationStart() {
    this.sessionId = this.generateUniqueString();
    this.chatService.updateSessionId(this.sessionId);
    let user = this.userDataService.get();
    if (user && user.firstName) {
      this.FirstName__c = user.firstName;
    }
    let botReplica: DialogReplica = {
      chatSessionId: this.sessionId,
      replica: this.FirstName__c
        ? `Hello, ${this.FirstName__c}.
      Please hold on while we connect you to a Personal Travel Designer.`
        : `Hello!
      Please hold on while we connect you to a Personal Travel Designer.`,
      author: 'bot',
    };

    this.dialogHistory.unshift(botReplica);

    this.chatService.setTroubles(1);
    this.saveMessageToFirestore(botReplica);
    this.scrollToBottom();
    this.chatService.update(this.dialogHistory);
  }

  timeoutApology = `Sorry, our agents are currently busy assisting another guest. Please leave your name, number, and email, and they'll get back to you within 30 minutes.`;

  addTimeoutReplica() {
    let botReplica: DialogReplica = {
      chatSessionId: this.sessionId,
      replica: this.timeoutApology,
      author: 'bot',
    };

    this.dialogHistory.unshift(botReplica);

    this.chatService.setTroubles(1);
    this.saveMessageToFirestore(botReplica);
    this.scrollToBottom();
    this.chatService.update(this.dialogHistory);
  }

  setChatStatusAsRead(chatSessionId: string) {
    const userCollection = collection(this.firestore, 'user');
    const uuidDoc = doc(userCollection, this.uuid);
    const chatCollection = collection(uuidDoc, 'chat');
    const chatSessionDoc = doc(chatCollection, chatSessionId);
    setDoc(chatSessionDoc, {
      status: 'read',
      lastUpdated: Date.now(),
      chatSessionId: chatSessionId,
      userName: this.FirstName__c,
    }).then(() => {
      console.log('updated');
    });
  }

  ngOnDestroy() {
    if (this.chatSessionIdSub) {
      this.chatSessionIdSub.unsubscribe();
    }
    if (this.ptdSub) {
      this.ptdSub.unsubscribe();
    }
    if (this.changeNameSub) {
      this.changeNameSub.unsubscribe();
    }
  }

  submitUserText(startReplica?: string) {
    if (
      startReplica &&
      this.previousReplica &&
      this.previousReplica === startReplica
    ) {
      console.error('Possible looping detected');
      return;
    } else {
      this.previousReplica = startReplica;
    }

    let replica: DialogReplica = {
      replica: startReplica ? startReplica : this.userText.value,
      author: 'user',
    };
    if (!startReplica) {
      this.dialogHistory.unshift(replica);
      this.scrollToBottom();
      replica.chatSessionId = this.sessionId;
      this.eventService.triggerChatEvent(replica);
      this.saveMessageToFirestore(replica);
      this.chatService.update(this.dialogHistory);
      this.userText.setValue('');
    } else {
      if (this.sessionId && this.sessionId !== 'REQUESTED') {
        replica.chatSessionId = this.sessionId;
        this.eventService.triggerChatEvent(replica);
        this.saveMessageToFirestore(replica);
      }
    }
  }

  fetchPTDAnswer(): Observable<any> {
    const userRef = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    const keysDoc = doc(userRef, this.collectionObject.UUID_KEY);
    const messColl = collection(
      keysDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );
    const sessionDoc = doc(messColl, this.sessionId);
    const messagesCollection = collection(sessionDoc, 'messages');

    let ptdOnly = query(
      messagesCollection,
      where('author', '==', 'ptd'),
      where('chatSessionId', '==', this.sessionId),
      where('status', '==', 'new'),
      orderBy('created', 'asc')
    );

    return collectionSnapshots(ptdOnly).pipe(
      map((res) => {
        return res.map((element) => {
          const id = element.id;
          let message = element.data() as DialogReplica;

          return { id: id, message: message };
        });
      })
    );
  }

  saveMessageToFirestore(message: DialogReplica) {
    message.created = new Date();
    message.status = 'received';

    const sessionDoc = doc(this.messagesCollection, message.chatSessionId);
    const userRef = setDoc(sessionDoc, {
      status: 'new',
      lastUpdated: message.created,
      chatSessionId: message.chatSessionId,
      userName: this.FirstName__c ? this.FirstName__c : null,
    }).then(() => {
      console.log('chat  updated');
    });

    /* save message to user/uid/chat/messages/conversation */

    const keysCollection = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    const uuidDoc = doc(keysCollection, this.collectionObject.UUID_KEY);
    const messColl = collection(
      uuidDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );
    const chatDoc = doc(messColl, message.chatSessionId);
    const messagesCollection = collection(chatDoc, 'messages');

    addDoc(messagesCollection, message).catch((error) => {
      console.error(
        'user uid ',
        this.uuid,
        ' message ',
        message,
        ' is not saved to firestore because of error: ',
        error
      );
    });

    /* update conversation metadata */
    let user = this.userDataService.get();
    if (user && user.segment) {
      this.segment = user.segment;
    }

    const chatsCollection = collection(this.firestore, 'chats');
    const sessionDoc2 = doc(chatsCollection, message.chatSessionId);
    const meta = setDoc(sessionDoc2, {
      chatSessionId: message.chatSessionId,
      status: 'new',
      lastUpdate: message.created,
      user: this.uuid,
      userName: this.FirstName__c ? this.FirstName__c : null,
      lastMessage: message.replica,
      segment: this.segment,
      troubles: this.troubles,
      agent: this.ptdInDialog ? 'ptd' : 'bot',
    })
      .then(() => {
        console.log('chat message saved');
      })
      .catch((error) => {
        console.error(
          'the chat metadata for  ',
          message.chatSessionId,
          ' message ',
          message,
          ' is not saved to firestore because of error: ',
          error
        );
      });
    /* update troubles file if troubles >0 */
    this.troubles = this.chatService.getTroubles();
    if (
      this.troubles &&
      this.troubles > 0 &&
      this.uuid !== 'aBsaUl30Jpa8Ie5SbHVAb3JYdcj1' //do not trigger notifications for Katya
    ) {
      const chatCollection = collection(this.firestore, 'chats');

      const troubledDoc = doc(chatCollection, 'troubles');
      const troubles = setDoc(troubledDoc, {
        lastEvent: Date.now(),
      })
        .then(() => {
          console.log('troubled doc updated');
        })
        .catch((error) => {
          console.error(
            'update the troubles with lastEvent ',
            Date.now(),
            ' is not saved to firestore because of error: ',
            error
          );
        });

      this.chatService.setTroubles(0);
    }
  }

  updateStatusAsReceived(id: string, message: DialogReplica) {
    const chatSessionDoc = doc(this.messagesCollection, message.chatSessionId);
    const messagesColl = collection(chatSessionDoc, 'messages');
    const idDoc = doc(messagesColl, id);
    const userRef = setDoc(idDoc, message)
      .then(() => {
        console.log('message received');
      })
      .catch((error) => {
        console.error(
          'update status message for message ',
          id,
          ' failed with error: ',
          error
        );
      });
  }

  JSON_SIMPLE_VALUE_KINDS = new Set([
    'numberValue',
    'stringValue',
    'boolValue',
  ]);

  /* Parse google bot payload */
  valueProtoToJson(proto) {
    if (!proto || !proto.kind) {
      return null;
    }

    if (this.JSON_SIMPLE_VALUE_KINDS.has(proto.kind)) {
      return proto[proto.kind];
    } else if (proto.kind === 'nullValue') {
      return null;
    } else if (proto.kind === 'listValue') {
      if (!proto.listValue || !proto.listValue.values) {
        console.warn('Invalid JSON list value proto: ', JSON.stringify(proto));
      }
      return proto.listValue.values.map(this.valueProtoToJson);
    } else if (proto.kind === 'structValue') {
      return this.structProtoToJson(proto.structValue);
    } else {
      console.warn('Unsupported JSON value proto kind: ', proto.kind);
      return null;
    }
  }

  structProtoToJson(proto): any {
    if (!proto || !proto.fields) {
      return {};
    }
    const json = {};
    for (const k in proto.fields) {
      json[k] = this.valueProtoToJson(proto.fields[k]);
    }
    return json;
  }

  volumeOff() {
    this.volume = 'Off';
    this.chatService.setVolume(this.volume);
  }
  volumeOn() {
    this.volume = 'On';
    this.chatService.setVolume(this.volume);
  }

  scrollToBottom = () => {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.widgetHeight;
    } catch (err) {}
  };

  minimize() {
    let replica: DialogReplica = {
      chatSessionId: this.sessionId,
      replica: 'User has minimized the chat.',
      author: 'system',
    };
    this.saveMessageToFirestore(replica);
    this.dialogRef.close();
  }

  close() {
    let replica: DialogReplica = {
      chatSessionId: this.sessionId,
      replica: 'User has closed the chat.',
      author: 'system',
    };
    this.saveMessageToFirestore(replica);

    this.dialogRef.close();
  }
}
