import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Unit, ReviewRecord } from '../../store/models/unit.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Complex } from 'src/app/store/models/complex.model';

export interface IResponsiveColumnsMap {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
}

export interface ReviewsSummary {
  Rating__c: number;
  Cleanliness__c: number;
  Location__c: number;
  Property_Condition__c: number;
  Services_From_PM__c: number;
}

@Component({
  selector: 'twbooking-unit-reviews',
  templateUrl: './unit-reviews.component.html',
  styleUrls: ['./unit-reviews.component.scss'],
})
export class UnitReviewsComponent implements OnChanges, OnDestroy {
  @Input() unit: Unit | Complex;
  @Output() rating = new EventEmitter();
  sub: any;
  public reviewsList: ReviewRecord[];
  public reviewsTotal: number;
  public rowHeightResponsive = '6:2';
  public columnNum = 2;
  public showAll = false;

  public reviewsSummary: ReviewsSummary = {
    Rating__c: 0,
    Cleanliness__c: 0,
    Location__c: 0,
    Property_Condition__c: 0,
    Services_From_PM__c: 0,
  };

  public ratingArr = [0, 1, 2, 3, 4]; // auxiliary array for showing star icons.

  constructor(
    private breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe
  ) {
    this.columnNum = 2;
    this.rowHeightResponsive = '6:2';
    this.sub = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.columnNum = 2;
          this.rowHeightResponsive = '6:2';
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.columnNum = 2;
          this.rowHeightResponsive = '6:2';
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.columnNum = 4;
          this.rowHeightResponsive = '5:1';
        } else if (result.breakpoints[Breakpoints.Large]) {
          this.columnNum = 4;
          this.rowHeightResponsive = '6:1';
        } else if (result.breakpoints[Breakpoints.XLarge]) {
          this.columnNum = 4;
          this.rowHeightResponsive = '6:1';
        }
      });
  }

  ngOnChanges(changes) {
    if (changes.unit) {
      this.unit = changes.unit.currentValue;
      if (
        this.unit &&
        this.unit.Reviews__r &&
        this.unit.Reviews__r.length > 0
      ) {
        this.reviewsList = Array.from(this.unit.Reviews__r);

        this.reviewsTotal = this.unit.Reviews__r.length;
        this.PrepareReviewsView();
      }
    }
  }

  PrepareReviewsView() {
    /* Prepare reviews: compute summary, parse name and data*/
    let totalCleanliness = 0;
    let totalLocation = 0;
    let totalProperty_Condition = 0;
    let totalServices_From_PM__c = 0;
    this.reviewsList.forEach((value, i) => {
      /* Compute total for required parameters for summary info */
      value.fullReview = false;
      if (value.Rating__c) {
        this.reviewsSummary.Rating__c += parseInt(value.Rating__c, 10);
      }
      if (value.Cleanliness__c) {
        this.reviewsSummary.Cleanliness__c += parseInt(
          value.Cleanliness__c,
          10
        );
        totalCleanliness++;
      }
      if (value.Location__c) {
        this.reviewsSummary.Location__c += parseInt(value.Location__c, 10);
        totalLocation++;
      }
      if (value.Property_Condition__c) {
        this.reviewsSummary.Property_Condition__c += parseInt(
          value.Property_Condition__c,
          10
        );
        totalProperty_Condition++;
      }
      if (value.Services_From_PM__c) {
        this.reviewsSummary.Services_From_PM__c += parseInt(
          value.Services_From_PM__c,
          10
        );
        totalServices_From_PM__c++;
      }

      /* Filter name and data for required format */

      if (value.Review_Received_On__c) {
        value.Review_Received_Date = this.datePipe.transform(
          new Date(value.Review_Received_On__c),
          'MMM, y'
        );
      }
    });

    /* Calculate average ratings for summary reviews */
    this.reviewsSummary.Rating__c =
      this.reviewsSummary.Rating__c / this.reviewsTotal;

    this.rating.emit(this.reviewsSummary.Rating__c);

    if (totalCleanliness > 0) {
      this.reviewsSummary.Cleanliness__c =
        this.reviewsSummary.Cleanliness__c / totalCleanliness;
    } else {
      this.reviewsSummary.Cleanliness__c = null;
    }
    if (totalLocation > 0) {
      this.reviewsSummary.Location__c =
        this.reviewsSummary.Location__c / totalLocation;
    } else {
      this.reviewsSummary.Location__c = null;
    }
    if (totalProperty_Condition > 0) {
      this.reviewsSummary.Property_Condition__c =
        this.reviewsSummary.Property_Condition__c / totalProperty_Condition;
    } else {
      this.reviewsSummary.Property_Condition__c = null;
    }
    if (totalServices_From_PM__c > 0) {
      this.reviewsSummary.Services_From_PM__c =
        this.reviewsSummary.Services_From_PM__c / totalServices_From_PM__c;
    } else {
      this.reviewsSummary.Services_From_PM__c = null;
    }

    /* Sort list by date */
    this.reviewsList.sort((a, b) => {
      return new Date(a.Review_Received_On__c) <
        new Date(b.Review_Received_On__c)
        ? 1
        : new Date(a.Review_Received_On__c) > new Date(b.Review_Received_On__c)
        ? -1
        : 0;
    });
  }

  showStarsIcon(index: number, ReviewRating: string) {
    if (parseFloat(ReviewRating) >= index + 0.9) {
      return 'star';
    } else if (parseFloat(ReviewRating) >= index + 0.3) {
      return 'star_half';
    } else {
      return 'star_border';
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
