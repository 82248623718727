import {
  Component,
  Input,
  OnChanges,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { UnitImage, maxImageWidth } from '../../store/models/unit.model';
import {
  GridLayout,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  DescriptionStrategy,
  CurrentImageConfig,
  ModalGalleryService,
  PlainLibConfig,
  ModalGalleryRef,
  ModalGalleryConfig,
  ButtonsStrategy,
} from '@ks89/angular-modal-gallery';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MyImage } from '../unit-gallery/unit-gallery.component';

@Component({
  selector: 'twbooking-unit-bottom-gallery',
  templateUrl: './unit-bottom-gallery.component.html',
  styleUrls: ['./unit-bottom-gallery.component.scss'],
})
export class UnitBottomGalleryComponent implements OnChanges, OnDestroy {
  @Input()
  unitImages: UnitImage[];
  @Input() showMore: boolean;
  subMedia: Subscription;

  public innerWidth: any;
  galleryPreview: Image[];
  galleryTotal: Image[];
  isMobile = false;
  picturesInRow = 1;
  imageWidth = 200;
  imageHeight = 140;
  showAll = false;
  customFullDescriptionHidden: CurrentImageConfig = {
    description: { strategy: DescriptionStrategy.ALWAYS_HIDDEN },
    navigateOnClick: true,
    downloadable: false,
    invertSwipe: true,
  };

  libConfigPlainGallery: PlainLibConfig = {
    plainGalleryConfig: {
      strategy: PlainGalleryStrategy.GRID,
      layout: new GridLayout(
        { width: this.imageWidth + 'px', height: this.imageHeight + 'px' },
        { length: this.picturesInRow, wrap: true }
      ),
    },
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private modalGalleryService: ModalGalleryService
  ) {
    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }
  /*
  openModalViaService(event?: any) {
    if (this.galleryTotal.length > this.galleryPreview.length) {
      this.showAll = true;
      this.galleryService.openGallery(14, event.result ? event.result : 1);
    }
  }*/

  onShow(id: number, index: number, images: Image[] = this.galleryTotal): void {
    if (this.galleryTotal.length > this.galleryPreview.length) {
      this.showAll = true;
    }
    let previewConfigDesktop = {
      visible: true,
      number: 3,
      arrows: true,
      clickable: true,
    };
    let previewConfigMobile = {
      visible: false,
      number: 0,
      arrows: false,
      clickable: true,
    };

    let slideConfigMobile = {
      infinite: true,
      playConfig: { autoPlay: false, interval: 5000, pauseOnHover: true },
      sidePreviews: {
        show: false,
        size: { width: '0px', height: '0px' },
      },
    };

    let slideConfigDesktop = {
      infinite: true,
      playConfig: { autoPlay: false, interval: 5000, pauseOnHover: true },
      sidePreviews: {
        show: true,
        size: { width: '100px', height: 'auto' },
      },
    };

    let modalConfig: ModalGalleryConfig = {
      id: id,
      images: images,
      currentImage: images[index],
      libConfig: {
        previewConfig: this.isMobile
          ? previewConfigMobile
          : previewConfigDesktop,
        dotsConfig: images.length > 10 ? { visible: false } : { visible: true },
        slideConfig: this.isMobile ? slideConfigMobile : slideConfigDesktop,
        currentImageConfig: this.customFullDescriptionHidden,
        buttonsConfig: this.isMobile
          ? { visible: true, strategy: ButtonsStrategy.DEFAULT }
          : { visible: false, strategy: ButtonsStrategy.DEFAULT },
      },
    };
    const dialogRef: ModalGalleryRef = this.modalGalleryService.open(
      modalConfig
    ) as ModalGalleryRef;
  }

  ngOnChanges(changes): void {
    if (changes.showMore) {
      this.showAll = changes.showMore.currentValue;
    }
    if (changes.unitImages) {
      this.unitImages = changes.unitImages.currentValue;
      this.galleryTotal = [];
      const screenWidth = window.innerWidth <= 1366 ? window.innerWidth : 1366;
      const largePrefix =
        '?auto=enhance&w=' +
        ((window.innerWidth * 8) / 10 <= maxImageWidth
          ? ((window.innerWidth * 8) / 10).toString()
          : maxImageWidth.toString());
      const previewPosfix =
        '?auto=enhance&w=' +
        this.imageWidth +
        '&h=' +
        this.imageHeight +
        '&fit=scale';

      const heroes = this.unitImages.filter(
        (img) => img.Display__c === 'Hero'
      ).length;
      const standardTakenToHero = heroes >= 4 ? 0 : 4 - heroes;

      let i = -1;
      if (heroes > 10) {
        this.galleryTotal = this.unitImages
          .filter((a) => a.Display__c === 'Hero')
          .map((img, index) => {
            if (index >= 10) {
              i++;
              return new Image(
                i,
                {
                  img: img.URL__c.includes('imgix')
                    ? img.URL__c + largePrefix
                    : img.URL__c,
                  description: img.Caption__c ? img.Caption__c : img.Name,
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                },
                {
                  img: img.preview ? img.preview : img.URL__c,
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                }
              );
            }
          })
          .filter((x) => !!x);
      }

      this.galleryTotal = this.galleryTotal.concat(
        this.unitImages
          .filter((a) => a.Display__c === 'Standard')
          .filter(
            (img) =>
              !this.galleryTotal.some(
                (gImg: MyImage) => gImg.modal.img === img.URL__c
              )
          )
          .map((img, index) => {
            if (index >= standardTakenToHero) {
              i++;
              return new Image(
                i,
                {
                  img: img.URL__c.includes('imgix')
                    ? img.URL__c + largePrefix
                    : img.URL__c,
                  description: img.Caption__c ? img.Caption__c : img.Name,
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                },
                {
                  img: img.preview ? img.preview : img.URL__c,
                  description: img.Caption__c ? img.Caption__c : img.Name,
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                }
              );
            }
          })
          .filter((x) => !!x)
      );
      this.galleryResponse();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.galleryResponse();
  }

  galleryResponse() {
    this.innerWidth = window.innerWidth;
    this.imageWidth = 200;
    this.imageHeight = 140;
    if (this.innerWidth > 1366) {
      this.innerWidth = 1366;
    } // max-width limit
    if (this.innerWidth >= 600) {
      /* We should left space for widget on the right, if screen is wider then 600 */
      this.picturesInRow = Math.floor(
        (this.innerWidth - 350) / this.imageWidth
      );
    } else {
      if (this.innerWidth <= 600) {
        this.imageWidth = 110;
        this.imageHeight = 80;
      }
      /* space for paddings */
      this.picturesInRow = Math.floor((this.innerWidth - 40) / this.imageWidth);
      console.log('this.picturesInRow = ', this.picturesInRow);
    }
    if (this.galleryTotal && this.galleryTotal.length > this.picturesInRow) {
      let tmp = [...this.galleryTotal];
      this.galleryPreview = tmp.slice(0, this.picturesInRow);
    } else {
      this.galleryPreview = this.galleryTotal;
    }

    this.libConfigPlainGallery = {
      plainGalleryConfig: {
        strategy: PlainGalleryStrategy.GRID,
        layout: new GridLayout(
          { width: this.imageWidth + 'px', height: this.imageHeight + 'px' },
          { length: this.picturesInRow, wrap: true }
        ),
      },
    };
  }

  ngOnDestroy(): void {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }
}
