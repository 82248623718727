<div class="modal-headline dialogue-headline">
  <h1 mat-dialog-title>Share favorite units</h1>
  <div class="modal-headline-close">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div></div>
</div>
<div
  mat-dialog-content
  *ngIf="mode === 'share'"
  class="enquiry-dialog-container"
>
  <div class="share-options-container">
    <button mat-button class="brand-button same-width" (click)="getLink()">
      Get shareable link
    </button>

    <a
      mat-button
      class="brand-button same-width"
      (click)="shareLink()"
      href="mailto:?subject=Please check out these units on The Travel Whisperer&body=Please check out these units on The Travel Whisperer: {{
        link
      }}"
    >
      Email</a
    >
  </div>

  <div *ngIf="shareInProgress">
    <p>Sharing is in progress</p>
    <mat-progress-bar class="ttw-progress" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <div *ngIf="sharedstatus">Shared successfully</div>

  <div class="legal-notice" *ngIf="needtoshare">
    Please note, once you share this link, anyone with this link can view your
    favorites.
  </div>
  <div class="legal-notice" *ngIf="!needtoshare">
    Please note, anyone with this link can view recommended units.
  </div>
</div>
<div
  mat-dialog-content
  *ngIf="mode === 'link'"
  class="enquiry-dialog-container"
>
  <div class="shareable-link-container">
    <div>
      <p>{{ link }}</p>
    </div>
    <button
      mat-button
      class="brand-button full-width"
      [cdkCopyToClipboard]="link"
    >
      Copy to clipboard
    </button>
  </div>
  <div *ngIf="shareInProgress">
    <p>Sharing is in progress</p>
    <mat-progress-bar class="ttw-progress" mode="indeterminate">
    </mat-progress-bar>
  </div>
  <div *ngIf="sharedstatus">Shared successfully</div>
  <div class="legal-notice" *ngIf="needtoshare">
    Please note, once you share this link, anyone with this link can view your
    favorites.
  </div>
  <div class="legal-notice" *ngIf="!needtoshare">
    Please note, anyone with this link can view recommended units.
  </div>
</div>
