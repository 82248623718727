import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  CdkDragEnd,
  CdkDragMove,
  CdkDragRelease,
  DragRef,
  Point,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { min } from 'moment';

@Component({
  selector: 'twbooking-bottom-sheet',
  templateUrl: './ttw-bottom-sheet.component.html',
  styleUrl: './ttw-bottom-sheet.component.scss',
})
export class TtwBottomSheetComponent implements OnInit, AfterViewInit {
  @ViewChild('bottomSheet') bottomSheet: ElementRef;
  @ViewChild('bottomContent') bottomContent: ElementRef;
  @Output() sheetSizeChanged = new EventEmitter<string>();

  dragPosition = { x: 0, y: 0 };
  elementHeight = 150;

  direction: 'expand' | 'reduce' = 'expand';

  previousPosition: 'below' | 'above' = 'below';

  ngOnInit(): void {
    console.log('TtwBottomSheetComponent ngOnInit');
  }

  computeDragRenderPos(pos, dragRef) {
    let minY = 150;
    let maxY = window.innerHeight - 50;
    let half = window.innerHeight / 2;

    let newY = pos.y < minY ? minY : pos.y > maxY ? maxY : pos.y;
    this.dragPosition = { x: pos.x, y: newY };

    return { x: pos.x, y: newY };
  }

  ngAfterViewInit() {
    const minHeight = 150; // Minimum height of the bottom sheet
    this.elementHeight = minHeight;
    this.bottomSheet.nativeElement.style.height = `${minHeight}px`;
    this.bottomContent.nativeElement.style.height = `${minHeight}px`;
    this.previousPosition = 'below';
  }

  onDragEnded(event: CdkDragEnd) {
    console.log(' onDragEnded this.direction = ', this.direction);
    if (this.direction === 'expand') {
      let top = window.innerHeight - 180;
      this.bottomContent.nativeElement.style.height = `${top}px`;
      // this.bottomSheet.nativeElement.style.height = `${top}px`;
      this.dragPosition = {
        x: this.dragPosition.x,
        y: -top + 180,
      };
      this.elementHeight = top;
      this.direction = 'reduce';
    } else {
      this.bottomContent.nativeElement.style.height = `100px`;
      // this.bottomSheet.nativeElement.style.height = `100px`;
      this.dragPosition = {
        x: this.dragPosition.x,
        y: 50,
      };

      this.elementHeight = 100;
      this.direction = 'expand';
    }

    /*  let distanceFromBottom = -yPosition + 100;

    // Let's check, if bottom sheet increased more then half or decreased less then half, then pan map center 
    let viewportHeight = window.innerHeight;
    let half = viewportHeight / 2 - 200;
    if (distanceFromBottom > half && this.previousPosition === 'below') {
      console.log('sheet it increased more then half');
      this.previousPosition = 'above';
      this.sheetSizeChanged.emit('above');
    } else if (distanceFromBottom < half && this.previousPosition === 'above') {
      console.log('sheet it decreased less then half');
      this.previousPosition = 'below';
      this.sheetSizeChanged.emit('below');
    }

    this.elementHeight = distanceFromBottom;
    this.bottomContent.nativeElement.style.height = `${distanceFromBottom}px`;*/
  }

  onDragMoved(event: CdkDragMove) {
    let yPosition = event.source.getFreeDragPosition().y;

    let distanceFromBottom = -yPosition + 100;
    this.elementHeight = distanceFromBottom;
    this.bottomContent.nativeElement.style.height = `${distanceFromBottom}px`;
    /*
    console.log(' onDragMoved this.elementHeight = ', this.elementHeight);
    if (this.elementHeight <= 150) {
      console.log('expandBottomSheet');
      let top = window.innerHeight - 180;
      this.bottomContent.nativeElement.style.height = `${top}px`;
      //   this.bottomSheet.nativeElement.style.height = `${top}px`;
      this.dragPosition = {
        x: this.dragPosition.x,
        y: -top + 180,
      };
      this.elementHeight = top;
    } else {
      const bottom = window.innerHeight - 150;
      console.log('bottom = ', bottom);
      console.log('reduceBottomSheet');

      // this.bottomSheet.nativeElement.style.height = `100px`;
      this.dragPosition = {
        x: this.dragPosition.x,
        y: 200,
      };
      this.bottomContent.nativeElement.style.height = `100px`;

      this.elementHeight = 100;
    }*/
  }
}
