import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import {
  localhost,
  mainsite,
  mm,
  staging,
} from 'src/app/store/models/hostings';

@Directive({
  selector: '[ttw-tooltip]',
})
export class TooltipDirective {
  @Input() public tooltipHtml: TemplateRef<any>;
  @Input() public tooltipHideDelay: number = 0;
  @Input() public tooltipOnHover: boolean = true;
  @Input() public clickable: string = null;
  @Input() target: HTMLElement;
  @Input() smallTooltip: string;
  
  clickCounter = 0;
  nb = true;

  hideTimeout = 0;
  private componentRef: ComponentRef<TooltipComponent> = null;
  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.tooltipOnHover && this.tooltipOnHover === true) {
      this.initializeTooltip();
    }
  }

  setPosition() {
    if (!this.target) {
      this.componentRef.instance.leftArrow = -1;

      return;
    }

    const rect = this.target.getBoundingClientRect();

    this.componentRef.instance.leftArrow = rect.left + 10;
    this.componentRef.instance.rightArrow = rect.right;
  }

  public openTooltip() {
    this.initializeTooltip();
    if (this.tooltipHideDelay && this.tooltipHideDelay > 0) {
      this.hideTimeout = window.setTimeout(
        this.destroy.bind(this),
        this.tooltipHideDelay
      );
    }
  }

  private initializeTooltip() {
    if (!this.nb) {
      if (this.componentRef === null) {
        this.clickCounter = 0;
        this.componentRef =
          this.viewContainerRef.createComponent(TooltipComponent);
        this.setPosition();
        this.setTooltipComponentProperties();
        this.componentRef.instance.smallTooltip  =this.smallTooltip;
      }
      this.componentRef.instance.close.subscribe(() => {
        this.closeTooltip();
      });
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltipHtml = this.tooltipHtml;
      const { left, right, bottom } =
        this.elementRef.nativeElement.getBoundingClientRect();
     // this.componentRef.instance.left = 0;
     // this.componentRef.instance.right = 0;

       this.componentRef.instance.right = right;
     this.componentRef.instance.left = (right - left) / 2 + left;
     if (this.componentRef.instance.left<170){
      this.componentRef.instance.left =170;
     }
      this.componentRef.instance.top = bottom;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event: TouchEvent): void {
    if (this.tooltipOnHover && this.tooltipOnHover === true) {
      $event.preventDefault();
      this.initializeTooltip();
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScroll($event: TouchEvent): void {
    this.destroy();
  }

  @HostListener('document:touchstart')
  onTouch(): void {
    if (this.componentRef !== null && this.clickable !== 'yes') {
      if (this.clickCounter > 0) {
        this.destroy();
      }
      this.clickCounter++;
    }
  }

  @HostListener('document:click')
  onClick(): void {
    if (this.componentRef !== null && this.clickable !== 'yes') {
      if (this.clickCounter > 0) {
        this.destroy();
      }
      this.clickCounter++;
    }
  }

  public closeTooltip() {
    this.destroy();
  }
  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
