<div *ngIf="fullDesktop">
  <form [formGroup]="fastRatesWidget">
    <div class="desktop-widget">
      <h3 class="new-brand-color">DATES:</h3>
      <div class="two-in-row">
        <mat-form-field class="widget-selector-half">
          <!-- If inntopia unit, we need two separate fields for arrival and departure -->

          <input
            matInput
            readonly
            [matDatepicker]="arrivalPicker"
            placeholder="Arrival"
            [matDatepickerFilter]="arrivalFilterFunction"
            formControlName="arrivalForRates"
            (dateChange)="arrivalChanged($event)"
            (click)="arrivalPicker.open()"
          />
          <mat-datepicker
            #arrivalPicker
            [touchUi]="isMobile || isNarrow"
            color="primary"
          ></mat-datepicker>
        </mat-form-field>
        <div class="middle">
          <p>&nbsp;&nbsp;&#8212;&nbsp;&nbsp;</p>
        </div>
        <mat-form-field class="widget-selector-half">
          <input
            matInput
            readonly
            placeholder="Departure"
            [matDatepicker]="departurePicker"
            [matDatepickerFilter]="departureFilterFunction"
            formControlName="departureForRates"
            (dateChange)="departureChanged($event)"
            (click)="departurePicker.open()"
          />
          <mat-datepicker
            #departurePicker
            [startAt]="departureDate ? departureDate : departureStart"
            color="primary"
            [dateClass]="dateClass"
            [touchUi]="isMobile || isNarrow"
          ></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="two-in-row">
    <div class="widget-selector-half">
      <h3 class="new-brand-color">ADULTS:</h3>
    </div>
    <div class="middle"></div>
    <div class="widget-selector-half" style="padding-left: 16px">
      <h3 class="new-brand-color">CHILDREN:</h3>
    </div>
  </div>

  <div class="two-in-row">
    <mat-form-field class="widget-selector-half">
      <mat-select
        [(value)]="numAdults"
        (selectionChange)="numberofGuestChanged($event)"
      >
        @for (g of guests; track g;) {
        <mat-option [value]="g" class="no-checkbox-select">
          {{ g }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="middle"></div>

    <mat-form-field class="widget-selector-half">
      <mat-select
        [(value)]="numChildren"
        (selectionChange)="numberofGuestChanged($event)"
      >
        @for (g of guests_children; track g){
        <mat-option [value]="g" class="no-checkbox-select">
          {{ g }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="unit.Dogs_Allowed__c || unit.Pets_Allowed__c">
    <h3 class="new-brand-color">PETS:</h3>
  </div>
  <div *ngIf="unit.Dogs_Allowed__c || unit.Pets_Allowed__c">
    <mat-form-field class="widget-selector">
      <mat-select
        [(value)]="numPets"
        (selectionChange)="numberofPetsChanged($event)"
      >
        @for (p of petOptions; track p){
        <mat-option [value]="p" class="no-checkbox-select">
          {{ p }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <p
    class="lexend-font"
    *ngIf="errorMessage"
    style="font-style: italic"
    [innerHTML]="this.errorMessage"
  ></p>

  <p class="lexend-font" *ngIf="!arrivalDate || !departureDate">
    Please select dates to get a quote.
  </p>

  <mat-grid-list
    cols="3"
    rowHeight="40px"
    class="price-data"
    *ngIf="!errorMessage"
  >
    <div *ngIf="!rateLoaded && !errorMessage && arrivalDate && departureDate">
      <mat-grid-tile colspan="2" class="left-column"> Total: </mat-grid-tile>
      <mat-grid-tile class="right-column">
        <mat-spinner [diameter]="30"></mat-spinner>
      </mat-grid-tile>
    </div>
    <div *ngIf="rateLoaded && !errorMessage && TotalCost > 0 && Rent > 0">
      <mat-grid-tile colspan="2" class="left-column"> Total: </mat-grid-tile>
      <mat-grid-tile class="right-column">
        ${{ TotalCost | number : "1.2-2" }}
      </mat-grid-tile>
    </div>
    <div
      *ngIf="
        !showdetails && rateLoaded && !errorMessage && TotalCost > 0 && Rent > 0
      "
    >
      <mat-grid-tile colspan="3" class="right-column">
        <a
          class="show-more-nav"
          style="text-decoration: underline"
          (click)="showdetails = true"
          >View details</a
        >
      </mat-grid-tile>
    </div>
    <div
      *ngIf="
        showdetails && rateLoaded && !errorMessage && TotalCost > 0 && Rent > 0
      "
    >
      <mat-grid-tile colspan="3" class="right-column">
        <a
          class="show-more-nav"
          style="text-decoration: underline"
          (click)="showdetails = false"
          >Hide details</a
        >
      </mat-grid-tile>
      <div *ngIf="!unit.isInntopiaUnit__c">
        <mat-grid-tile colspan="2" class="left-column">
          <span
            [class.striked-out]="
              rateDetails.Discount__c &&
              rateDetails.Discount__c > 0 &&
              rateDetails.Discounted_Rent__c &&
              rateDetails.Discounted_Rent__c > 0
            "
          >
            Rent:
          </span>
        </mat-grid-tile>
        <mat-grid-tile class="right-column">
          <span
            [class.striked-out]="
              rateDetails &&
              rateDetails.Discount__c &&
              rateDetails.Discount__c > 0 &&
              rateDetails.Discounted_Rent__c &&
              rateDetails.Discounted_Rent__c > 0
            "
          >
            {{ rateDetails.TotalRent__c | currency }}
          </span>
        </mat-grid-tile>
        <div
          *ngIf="
            rateDetails &&
            rateDetails.Discount__c &&
            rateDetails.Discount__c > 0 &&
            rateDetails.Discounted_Rent__c &&
            rateDetails.Discounted_Rent__c > 0
          "
        >
          <mat-grid-tile colspan="2" class="left-column">
            Discounted rent:
          </mat-grid-tile>
          <mat-grid-tile class="right-column">
            {{ rateDetails.Discounted_Rent__c | currency }}
          </mat-grid-tile>
        </div>
        <div
          *ngIf="
            rateDetails.RefundableDeposit__c &&
            rateDetails.RefundableDeposit__c > 0
          "
        >
          <mat-grid-tile colspan="2" class="left-column">
            Refundable Security Deposit:
          </mat-grid-tile>
          <mat-grid-tile class="right-column">
            {{ rateDetails.RefundableDeposit__c | currency }}
          </mat-grid-tile>
        </div>
        <mat-grid-tile colspan="2" class="left-column">
          Taxes and Fees:
        </mat-grid-tile>
        <mat-grid-tile class="right-column">
          {{
            rateDetails.DamageProtectionFee__c +
              rateDetails.CleaningService__c +
              rateDetails.GuestServicePackage__c +
              rateDetails.PetFee__c +
              rateDetails.TAX__c | currency
          }}
        </mat-grid-tile>
      </div>
      <div *ngIf="unit.isInntopiaUnit__c">
        <div *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0">
          <mat-grid-tile colspan="2" class="left-column">
            <span class="striked-out"> Rent: </span>
          </mat-grid-tile>
          <mat-grid-tile class="right-column">
            <span class="striked-out">
              {{ inntopiaRate.baseDisplayRent | currency }}
            </span>
          </mat-grid-tile>
        </div>
        <mat-grid-tile colspan="2" class="left-column">
          <span *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
            >Discounted Rent:
          </span>
          <span *ngIf="inntopiaRate && inntopiaRate.savingsAmount === 0"
            >Rent:</span
          >
        </mat-grid-tile>
        <mat-grid-tile class="right-column">
          {{ inntopiaRate.DisplayRent | currency }}
        </mat-grid-tile>
        <mat-grid-tile colspan="2" class="left-column"> Taxes: </mat-grid-tile>
        <mat-grid-tile class="right-column">
          {{ inntopiaRate.DisplayTaxes | currency }}
        </mat-grid-tile>
        <mat-grid-tile colspan="2" class="left-column"> Fees: </mat-grid-tile>
        <mat-grid-tile class="right-column">
          {{ inntopiaRate.DisplayFees | currency }}
        </mat-grid-tile>
        <div *ngIf="inntopiaRate.optionalFees > 0">
          <mat-grid-tile colspan="2" class="left-column">
            Optional fees:
          </mat-grid-tile>
          <mat-grid-tile class="right-column">
            {{ inntopiaRate.optionalFees | currency }}
          </mat-grid-tile>
        </div>
      </div>
    </div>
  </mat-grid-list>
  <div
    *ngIf="!this.errorMessage && inntopiaRate && inntopiaRate.savingsAmount > 0"
    style="font-weight: bold"
  >
    Book now to secure savings of {{ inntopiaRate.savingsAmount | currency }} on
    this reservation
  </div>
  <div
    *ngIf="
      !this.errorMessage &&
      rateDetails &&
      rateDetails.Discount__c &&
      rateDetails.Discount__c > 0 &&
      rateDetails.Discounted_Rent__c &&
      rateDetails.Discounted_Rent__c > 0
    "
    style="font-weight: bold"
  >
    Book now to secure savings of {{ rateDetails.Discount__c | currency }} on
    this reservation
  </div>
  <div *ngIf="taxesWarning != null">
    <p>{{ taxesWarning }}</p>
  </div>
  <mat-divider></mat-divider>
</div>

<!----------------Mobile widget------------------->
<div *ngIf="!fullDesktop" class="mobile-widget">
  <!--------Mobile: no rates -->
  <div *ngIf="unit.DisplayRates__c !== true && !nb" class="no-rates-widget">
    <button
      mat-button
      class="brand-button book-now-button full-size"
      (click)="enquiryNow()"
    >
      <span>Inquire now</span>
    </button>
    <twbooking-chat mode="icon"></twbooking-chat>
  </div>

  <!--------Mobile: reduced---------->
  <div
    class="mobile-reduced-widget brand-light-background"
    *ngIf="reducedMobileWidget && unit.DisplayRates__c === true"
  >
    <div
      class="mobile-info"
      (click)="reducedMobileWidget = !reducedMobileWidget; showdetails = true"
    >
      <div
        class="total-cost standard-text"
        *ngIf="TotalCost && TotalCost > 0 && !errorMessage"
      >
        <span>{{ TotalCost | currency }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div
        class="total-cost standard-text"
        *ngIf="
          !errorMessage &&
          (!arrivalDate ||
            !departureDate ||
            (!loading && (!TotalCost || TotalCost <= 0)))
        "
      >
        Price depends on date
      </div>
      <div
        *ngIf="errorMessage"
        class="warning small-text"
        [innerHTML]="this.errorMessage"
      ></div>
      <div
        class="total-cost standard-text"
        *ngIf="arrivalDate && departureDate && !rateLoaded && !errorMessage"
        class="lines"
        [@fadeOut]="TotalCost"
      >
        <p class="some-width brand-light-background"></p>
      </div>

      <div class="reduced-dates new-brand-color">
        {{
          arrivalDate && departureDate
            ? arrivalDate.format("DD MMM") +
              " - " +
              departureDate.format(
                "DD MMM
        YYYY"
              )
            : "Tap to get a quote"
        }}
      </div>
    </div>

    <div *ngIf="!nb" class="buttons-group">
      <button
        *ngIf="unit.DisplayRates__c"
        class="book-button new-brand-background"
        (click)="bookNow()"
      >
        <span>Book now</span>
      </button>
      <button
        *ngIf="!id || !status || status === 'Off'"
        class="inquiry-button-alone brand-light-background new-brand-color"
        (click)="enquiryNow()"
      >
        Inquire now
      </button>
      <div class="inquiry-chat" *ngIf="id && status && status === 'On'">
        <button
          class="inquiry-button brand-light-background new-brand-color"
          (click)="enquiryNow()"
        >
          Inquire</button
        ><span>/</span>
        <twbooking-chat mode="text"></twbooking-chat>
      </div>
    </div>
  </div>

  <!--------Mobile: expanded---------->
  <div
    *ngIf="!reducedMobileWidget && unit.DisplayRates__c === true"
    class="mobile-expanded-widget brand-light-background"
  >
    <form [formGroup]="fastRatesWidget">
      <div>
        <div class="date-inputs">
          <div class="widget-selector-half input-field">
            <!-- If inntopia unit, we need two separate fields for arrival and departure -->

            <input
              matInput
              readonly
              [matDatepicker]="arrivalPicker"
              placeholder="Arrival"
              [matDatepickerFilter]="arrivalFilterFunction"
              formControlName="arrivalForRates"
              (dateChange)="arrivalChanged($event)"
              (click)="arrivalPicker.open()"
            />
            <mat-datepicker
              #arrivalPicker
              [touchUi]="true"
              color="primary"
            ></mat-datepicker>
          </div>
          <div class="middle">&nbsp;&nbsp;&#8212;&nbsp;&nbsp;</div>
          <div class="widget-selector-half input-field">
            <input
              matInput
              readonly
              placeholder="Departure"
              [matDatepicker]="departurePicker"
              [matDatepickerFilter]="departureFilterFunction"
              formControlName="departureForRates"
              (dateChange)="departureChanged($event)"
              (click)="departurePicker.open()"
            />
            <mat-datepicker
              #departurePicker
              [startAt]="departureStart"
              color="primary"
              [dateClass]="dateClass"
              [touchUi]="true"
            ></mat-datepicker>
          </div>
        </div>
      </div>
    </form>

    <div
      *ngIf="myStay < minStay && !errorMessage"
      class="space-between warning small-text"
    >
      Please note that minimum stay allowed for booking is
      {{ minStay }} nights.
    </div>
    <div
      *ngIf="rangeNotAllowed && rateLoaded"
      class="space-between warning small-text"
    >
      Please note that choosen range is not allowed for booking.
    </div>
    <div
      *ngIf="errorMessage && !loading"
      class="warning small-text"
      [innerHTML]="this.errorMessage"
    ></div>
    <!-- && myStay >= minStay && !errorMessage-->
    <div *ngIf="arrivalDate && departureDate && loading" class="space-between">
      <span class="align-left lines total-cost" [@fadeOut]="TotalCost">
        <p class="some-width brand-light-background"></p>
      </span>
      <span class="align-right lines total-cost" [@fadeOut]="TotalCost">
        <p class="some-width brand-light-background"></p>
      </span>
    </div>
    <div
      class="space-between"
      *ngIf="
        arrivalDate &&
        departureDate &&
        rateLoaded &&
        !errorMessage &&
        myStay >= minStay
      "
    >
      <span class="align-left" *ngIf="myStay >= minStay && !errorMessage"
        >Total for {{ myStay }} nights</span
      >
      <span
        class="align-right"
        *ngIf="
          TotalCost &&
          TotalCost > 0 &&
          Rent &&
          Rent > 0 &&
          rateLoaded &&
          myStay >= minStay
        "
      >
        ${{ TotalCost | number : "1.2-2" }}
      </span>
    </div>

    <!-- Rate Details -->

    <div
      *ngIf="
        !unit.isInntopiaUnit__c &&
        rateLoaded &&
        !errorMessage &&
        TotalCost &&
        TotalCost > 0
      "
      class="rate-details"
    >
      <div class="space-between">
        <span
          [class.striked-out]="
            rateDetails.Discount__c &&
            rateDetails.Discount__c > 0 &&
            rateDetails.Discounted_Rent__c &&
            rateDetails.Discounted_Rent__c > 0
          "
        >
          Rent:
        </span>
        <span
          [class.striked-out]="
            rateDetails &&
            rateDetails.Discount__c &&
            rateDetails.Discount__c > 0 &&
            rateDetails.Discounted_Rent__c &&
            rateDetails.Discounted_Rent__c > 0
          "
        >
          {{ rateDetails.TotalRent__c | currency }}
        </span>
      </div>

      <div
        class="space-between"
        *ngIf="
          rateDetails &&
          rateDetails.Discount__c &&
          rateDetails.Discount__c > 0 &&
          rateDetails.Discounted_Rent__c &&
          rateDetails.Discounted_Rent__c > 0
        "
      >
        <span> Discounted rent: </span>
        <span>
          {{ rateDetails.Discounted_Rent__c | currency }}
        </span>
      </div>
      <div
        class="space-between"
        *ngIf="
          rateDetails.RefundableDeposit__c &&
          rateDetails.RefundableDeposit__c > 0
        "
      >
        <span> Refundable Security Deposit: </span>
        <span>
          {{ rateDetails.RefundableDeposit__c | currency }}
        </span>
      </div>
      <div class="space-between">
        <span colspan="2" class="left-column"> Taxes and Fees: </span>
        <span class="right-column">
          {{
            rateDetails.DamageProtectionFee__c +
              rateDetails.CleaningService__c +
              rateDetails.GuestServicePackage__c +
              rateDetails.PetFee__c +
              rateDetails.TAX__c | currency
          }}
        </span>
      </div>
    </div>
    <div
      *ngIf="
        unit.isInntopiaUnit__c &&
        rateLoaded &&
        !errorMessage &&
        TotalCost &&
        TotalCost > 0
      "
      class="rate-details"
    >
      <div
        *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
        class="space-between"
      >
        <span>
          <span class="striked-out"> Rent: </span>
        </span>
        <span class="striked-out">
          {{ inntopiaRate.baseDisplayRent | currency }}
        </span>
      </div>
      <div class="space-between">
        <span>
          <span *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
            >Discounted Rent:
          </span>
          <span *ngIf="inntopiaRate && inntopiaRate.savingsAmount === 0"
            >Rent:</span
          >
        </span>
        <span>
          {{ inntopiaRate.DisplayRent | currency }}
        </span>
      </div>
      <div class="space-between">
        <span> Taxes: </span>
        <span>
          {{ inntopiaRate.DisplayTaxes | currency }}
        </span>
      </div>
      <div class="space-between">
        <span> Fees: </span>
        <span>
          {{ inntopiaRate.DisplayFees | currency }}
        </span>
      </div>
      <div *ngIf="inntopiaRate.optionalFees > 0" class="space-between">
        <span> Optional fees: </span>
        <span>
          {{ inntopiaRate.optionalFees | currency }}
        </span>
      </div>
    </div>
    <div *ngIf="taxesWarning != null" class="space-between warning small-text">
      {{ taxesWarning }}.
    </div>
    <div class="hide-details">
      <a
        *ngIf="showdetails && rateLoaded"
        class="show-more-mobile new-brand-color"
        (click)="showdetails = false; reducedMobileWidget = true"
        >Hide details</a
      >
    </div>

    <div
      class="outside-buttons-group"
      (click)="showdetails = false; reducedMobileWidget = true"
    >
      <div *ngIf="!nb" class="buttons-group">
        <button
          *ngIf="unit.DisplayRates__c"
          class="book-button new-brand-background"
          (click)="bookNow()"
        >
          <span>Book now</span>
        </button>
        <button
          *ngIf="!id || !status || status === 'Off'"
          class="inquiry-button-alone brand-light-background new-brand-color"
          (click)="enquiryNow()"
        >
          Inquire now
        </button>
        <div class="inquiry-chat" *ngIf="id && status && status === 'On'">
          <button
            class="inquiry-button brand-light-background brand-show-more-color"
            (click)="enquiryNow()"
          >
            Inquire</button
          ><span>/</span>
          <twbooking-chat mode="text"></twbooking-chat>
        </div>
      </div>
    </div>
  </div>
  <!--END------Mobile: expanded---------->
</div>
