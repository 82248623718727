import {
  Component,
  Output,
  OnInit,
  TemplateRef,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'twbooking-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  public tooltipHtml: TemplateRef<any>;
  smallTooltip='no';

  left: number = 0;
  leftArrow: number = 0;
  rightArrow: number = 0;
  top: number = 0;
  right: number = 0;
  innerWidth: number = window.innerWidth;

  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.close.emit(true);
  }
}
