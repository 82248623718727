<button
  class="close-button new-brand-color"
  mat-icon-button
  (click)="sidenav.close()"
  label="Close"
  *ngIf="formMode === 'sidebar'"
>
  <mat-icon disabledInteractive="true">close</mat-icon>
</button>

<div *ngIf="logInMode === 'signUp' || logInMode === 'logIn'">
  <h3
    *ngIf="logInMode === 'signUp'"
    mat-dialog-title
    class="new-brand-color no-top-margin"
  >
    <span>Sign Up</span>
  </h3>
  <h3
    *ngIf="logInMode === 'logIn'"
    mat-dialog-title
    class="new-brand-color no-top-margin"
  >
    <span>Log In</span>
  </h3>
  <div mat-dialog-content class="unit-search-container">
    <p class="new-brand-color lexend-20">
      @if(logInMode === 'signUp'){Sign up} @if(logInMode === 'logIn'){Log in}
      for access to exclusive deals and promotions.
    </p>
    <div *ngIf="logInMode === 'signUp'">
      <p>
        Already have an account?
        <a class="show-more-nav" (click)="changeForm('logIn')">Log in</a>
      </p>
    </div>

    <div *ngIf="logInMode === 'logIn'">
      <p>
        Don’t have an account?
        <a class="show-more-nav" (click)="changeForm('signUp')">Sign up</a>
      </p>
    </div>

    <form
      class=""
      *ngIf="logInMode === 'signUp'"
      [formGroup]="registrationForm"
      (ngSubmit)="handleSubmit(registrationForm.value, registrationForm.valid)"
      novalidate
    >
      <mat-form-field class="unit-search-form-field">
        <input
          matInput
          required
          type="email"
          placeholder="Email"
          formControlName="emailField"
          name="emailField"
          autocomplete="on"
        />
        <div
          *ngIf="submitted && registrationForm.controls.emailField.errors"
          class="alert"
        >
          <div *ngIf="registrationForm.controls.emailField.errors.required">
            Email is required
          </div>
          <div *ngIf="!registrationForm.controls.emailField.errors.required">
            Invalid email address
          </div>
        </div>
      </mat-form-field>

      <mat-form-field class="unit-search-form-field">
        <input
          matInput
          required
          type="password"
          placeholder="Password"
          formControlName="passwordField"
          name="password"
          autocomplete="off"
        />
        <div
          *ngIf="submitted && registrationForm.controls.passwordField.errors"
          class="alert"
        >
          <div *ngIf="registrationForm.controls.passwordField.errors.required">
            Password is required
          </div>
          <div *ngIf="registrationForm.controls.passwordField.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </mat-form-field>
      <mat-form-field class="unit-search-form-field">
        <input
          matInput
          required
          type="password"
          placeholder="Confirm password"
          formControlName="passwordConfirmField"
          name="passwordConfirm"
          autocomplete="off"
        />
        <div
          *ngIf="
            submitted && registrationForm.controls.passwordConfirmField.errors
          "
        >
          <div
            *ngIf="
              registrationForm.controls.passwordConfirmField.errors.mustMatch
            "
            class="alert"
          >
            Passwords must match
          </div>
        </div>
      </mat-form-field>
      <!--<p>We'll email you registration confirmation.</p>-->
      <button
        mat-button
        class="brand-button action-button"
        type="submit"
        [disabled]="
          !registrationForm.value.emailField ||
          !registrationForm.value.passwordField ||
          !registrationForm.value.passwordConfirmField
        "
        (click)="
          signUpwithEmail(registrationForm.value, registrationForm.valid)
        "
      >
        Continue
      </button>
    </form>
    <!-- registration form -->

    <form
      class=""
      *ngIf="logInMode === 'logIn'"
      [formGroup]="loginForm"
      (ngSubmit)="handleSubmit(loginForm.value, loginForm.valid)"
      novalidate
    >
      <mat-form-field class="unit-search-form-field">
        <input
          matInput
          required
          type="email"
          placeholder="Email"
          formControlName="emailField"
          name="emailField"
          autocomplete="on"
        />
        <div
          *ngIf="submitted && loginForm.controls.emailField.errors"
          class="alert"
        >
          <div *ngIf="loginForm.controls.emailField.errors.required">
            Email is required
          </div>
          <div *ngIf="!loginForm.controls.emailField.errors.required">
            Invalid email address
          </div>
        </div>
      </mat-form-field>

      <mat-form-field class="unit-search-form-field">
        <input
          matInput
          required
          type="password"
          placeholder="Password"
          formControlName="passwordField"
          name="password"
          autocomplete="on"
          (input)="wrongPassword = false"
        />
        <div
          *ngIf="submitted && loginForm.controls.passwordField.errors"
          class="alert"
        >
          <div *ngIf="loginForm.controls.passwordField.errors.required">
            Password is required
          </div>
          <div *ngIf="loginForm.controls.passwordField.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
        <div *ngIf="wrongPassword" class="alert">The password is invalid.</div>
      </mat-form-field>

      <button
        mat-button
        class="brand-button action-button"
        type="submit"
        [disabled]="
          !loginForm.value.emailField || !loginForm.value.passwordField
        "
        (click)="logInwithEmail(loginForm.value, loginForm.valid)"
      >
        Log in
      </button>
      <br />
      <br />
      <a class="show-more-nav" (click)="changeForm('passwordReset')"
        >Forgot password?</a
      >
    </form>

    <div *ngIf="loading">
      <br />
      <mat-progress-bar class="ttw-progress" mode="indeterminate">
      </mat-progress-bar>
    </div>

    <div class="divider-with-text">
      <div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>
<!--*ngIf="logInMode === 'signUp' || 'logIn'-->

<div *ngIf="logInMode === 'emailCode'">
  <h3 mat-dialog-title>{{ linkTitle }}</h3>
  <br />
  <div class="brand-accent-color success-done">
    <mat-icon>done</mat-icon>
    &nbsp; &nbsp; &nbsp;
    <h3>{{ linkRequestResult }}</h3>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />
  <p>
    {{ linkComment }}
  </p>
</div>

<div *ngIf="logInMode === 'passwordReset'">
  <h3 mat-dialog-title>Reset password</h3>
  <br />
  <form
    class=""
    [formGroup]="resetForm"
    (ngSubmit)="handleSubmit(resetForm.value, resetForm.valid)"
    novalidate
  >
    <mat-form-field class="unit-search-form-field">
      <input
        matInput
        required
        type="email"
        placeholder="Email"
        formControlName="emailField"
        name="emailField"
        autocomplete="on"
      />
      <div
        *ngIf="submitted && resetForm.controls.emailField.errors"
        class="alert"
      >
        <div *ngIf="resetForm.controls.emailField.errors.required">
          Email is required
        </div>
        <div *ngIf="!resetForm.controls.emailField.errors.required">
          Invalid email address
        </div>
      </div>
    </mat-form-field>
    <button
      mat-button
      class="brand-button action-button"
      type="submit"
      [disabled]="!resetForm.value.emailField"
      (click)="resetPassword(resetForm.value, resetForm.valid)"
    >
      Send reset link
    </button>
    <br />
    <mat-divider></mat-divider>
    <br />
    <p>
      <a class="show-more-nav align-row" (click)="changeForm('logIn')">
        <mat-icon>arrow_back</mat-icon> <span>Back to log in</span>
      </a>
    </p>
  </form>
</div>

<div *ngIf="logInMode === 'confirmReset'">
  <h3 mat-dialog-title>Password reset</h3>
  <br />
  <div class="success-done">
    <mat-icon>done</mat-icon>
    &nbsp; &nbsp; &nbsp;
    <h3>
      A link to reset your password has been sent to
      {{ resetForm.value.emailField }}
    </h3>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />
  <p>
    Please click on the link that has just been sent to your email account to
    reset your password.
  </p>
</div>

<div *ngIf="logInMode === 'logInFailed'">
  <h3 mat-dialog-title>{{ title }}</h3>
  <br />
  <div [innerHTML]="message | safeHtml"></div>
  <br />
  <mat-divider></mat-divider>
  <br />
  <p *ngIf="this.title !== 'Sign up failed'">
    <a class="show-more-nav align-row" (click)="changeForm('signUp')">
      <mat-icon>arrow_back</mat-icon> <span>Back to sign up</span>
    </a>
  </p>
  <p *ngIf="this.title === 'Sign in failed'">
    <a class="show-more-nav align-row" (click)="changeForm('logIn')">
      <mat-icon>arrow_back</mat-icon> <span>Back to sign in</span>
    </a>
  </p>
  <p *ngIf="this.title === 'Sign In Link is not submitted'">
    <a class="show-more-nav align-row" (click)="changeForm('logIn')">
      <mat-icon>arrow_back</mat-icon> <span>Back to sign in</span>
    </a>
  </p>
</div>
