import { RelatedStrategy } from './unit-related.component';
import { Unit } from '../../store/models/unit.model';
import { SearchParamsService } from '../../services/search-params.service';

export class CombinedStratery implements RelatedStrategy {
  unit: Unit;
  constructor(unitInput: Unit, private searchQueryInitial: any) {
    this.unit = unitInput;
  }

  public getSearchQuery(): any {
    /*Identify key information from unit (Destination, Village, Number of bedrooms, Property collection) to add as input */

    const searchQuery = {
      ...this.searchQueryInitial,
      City: this.unit.Destination__Name.split(', ')[0],
      State: this.unit.Destination__Name.split(', ')[1],
      Village: this.unit.Village__Name,
      Bedrooms: Math.floor(parseFloat(this.unit.Bedrooms__c)),
      PropertyCollection: this.unit.Property_Collection__c
    };
    return searchQuery;
  }
}
