import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { AuthenticatedApiService } from './authenticated-api.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private static scriptLoadingPromise: Promise<void>;
  private scriptLoadedSubject = new BehaviorSubject<boolean>(false);
  public scriptLoaded$ = this.scriptLoadedSubject.asObservable();
  public static isScriptLoaded = false;
  constructor(private apiService: AuthenticatedApiService) {}

  public async loadScript(): Promise<void> {
    if (GoogleMapsLoaderService.isScriptLoaded) {
      return Promise.resolve();
    }

    let apiKey = null;
    const key = await this.fetchMapsInput();
    if (key && key.key) {
      apiKey = key.key;
    }

    if (!GoogleMapsLoaderService.scriptLoadingPromise && apiKey) {
      GoogleMapsLoaderService.scriptLoadingPromise = new Promise<void>(
        (resolve, reject) => {
          const loader = new Loader({
            apiKey: apiKey,
            version: 'weekly', // or specify a version number, e.g., "3.40"
            libraries: ['marker', 'maps'], // add libraries as needed
          });

          loader
            .load()
            .then(() => {
              GoogleMapsLoaderService.isScriptLoaded = true;
              this.scriptLoadedSubject.next(true);
              resolve();
            })
            .catch((err) => {
              console.error('Error loading Google Maps script:', err);
              reject(err);
            });
        }
      );
    }

    return GoogleMapsLoaderService.scriptLoadingPromise;
  }

  private async fetchMapsInput(): Promise<{ key: string }> {
    try {
      const response = await firstValueFrom(this.loadmapsinput());
      return { key: response.key }; // Assuming the response contains the apiKey property
    } catch (error) {
      console.error('Failed to fetch API key:', error);
      throw error;
    }
  }

  loadmapsinput(): Observable<any> {
    return this.apiService.httpCall('/api/fetchMapsInput', {}).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
