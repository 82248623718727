export interface Country {
  value: string; //ISO 2 letter code
  viewValue: string;
}
export function findCountryCode(countryExp: string): string {
  let abbr = null;
  let index = this.countryExpanded.findIndex(
    (country) => country.viewValue === countryExp
  );
  if (index >= 0) {
    abbr = this.countryExpanded[index].value;
  }
  return abbr;
}
export const countryOptions = [
  { value: 'US', viewValue: 'United States' },
  { value: 'AR', viewValue: 'Argentina' },
  { value: 'AU', viewValue: 'Australia' },
  { value: 'BR', viewValue: 'Brazil' },
  { value: 'CA', viewValue: 'Canada' },
  { value: 'CN', viewValue: 'China' },
  { value: 'FR', viewValue: 'France' },
  { value: 'DE', viewValue: 'Germany' },
  { value: 'IN', viewValue: 'India' },
  { value: 'ID', viewValue: 'Indonesia' },
  { value: 'IT', viewValue: 'Italy' },
  { value: 'JP', viewValue: 'Japan' },
  { value: 'MX', viewValue: 'Mexico' },
  { value: 'RU', viewValue: 'Russia Federation' },
  { value: 'SA', viewValue: 'Saudi Arabia' },
  { value: 'KR', viewValue: 'South Korea' },
  { value: 'TR', viewValue: 'Turkey' },
  { value: 'GB', viewValue: 'United Kingdom' },
  { value: 'OTHER', viewValue: 'Other' },
];

export const countryExpanded = [
  { value: 'AF', viewValue: 'Afghanistan' },
  { value: 'AX', viewValue: 'Aland Islands' },
  { value: 'AL', viewValue: 'Albania' },
  { value: 'DZ', viewValue: 'Algeria' },
  { value: 'AS', viewValue: 'American Samoa' },
  { value: 'AD', viewValue: 'Andorra' },
  { value: 'AO', viewValue: 'Angola' },
  { value: 'AI', viewValue: 'Anguilla' },
  { value: 'AQ', viewValue: 'Antarctica' },
  { value: 'AG', viewValue: 'Antigua and Barbuda' },
  { value: 'AR', viewValue: 'Argentina' },
  { value: 'AM', viewValue: 'Armenia' },
  { value: 'AW', viewValue: 'Aruba' },
  { value: 'AU', viewValue: 'Australia' },
  { value: 'AT', viewValue: 'Austria' },
  { value: 'AZ', viewValue: 'Azerbaijan' },
  { value: 'BS', viewValue: 'Bahamas' },
  { value: 'BH', viewValue: 'Bahrain' },
  { value: 'BD', viewValue: 'Bangladesh' },
  { value: 'BB', viewValue: 'Barbados' },
  { value: 'BY', viewValue: 'Belarus' },
  { value: 'BE', viewValue: 'Belgium' },
  { value: 'BZ', viewValue: 'Belize' },
  { value: 'BJ', viewValue: 'Benin' },
  { value: 'BM', viewValue: 'Bermuda' },
  { value: 'BT', viewValue: 'Bhutan' },
  { value: 'BO', viewValue: 'Bolivia' },
  { value: 'BQ', viewValue: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', viewValue: 'Bosnia and Herzegovina' },
  { value: 'BW', viewValue: 'Botswana' },
  { value: 'BV', viewValue: 'Bouvet Island' },
  { value: 'BR', viewValue: 'Brazil' },
  { value: 'IO', viewValue: 'British Indian Ocean Territory' },
  { value: 'BN', viewValue: 'Brunei Darussalam' },
  { value: 'BG', viewValue: 'Bulgaria' },
  { value: 'BF', viewValue: 'Burkina Faso' },
  { value: 'BI', viewValue: 'Burundi' },
  { value: 'KH', viewValue: 'Cambodia' },
  { value: 'CM', viewValue: 'Cameroon' },
  { value: 'CA', viewValue: 'Canada' },
  { value: 'CV', viewValue: 'Cape Verde' },
  { value: 'KY', viewValue: 'Cayman Islands' },
  { value: 'CF', viewValue: 'Central African Republic' },
  { value: 'TD', viewValue: 'Chad' },
  { value: 'CL', viewValue: 'Chile' },
  { value: 'CN', viewValue: 'China' },
  { value: 'CX', viewValue: 'Christmas Island' },
  { value: 'CC', viewValue: 'Cocos (Keeling) Islands' },
  { value: 'CO', viewValue: 'Colombia' },
  { value: 'KM', viewValue: 'Comoros' },
  { value: 'CG', viewValue: 'Congo' },
  { value: 'CD', viewValue: 'Congo, The Democratic Republic of ' },
  { value: 'CK', viewValue: 'Cook Islands' },
  { value: 'CR', viewValue: 'Costa Rica' },
  { value: 'CI', viewValue: "Cote d'Ivoire" },
  { value: 'HR', viewValue: 'Croatia' },
  { value: 'CU', viewValue: 'Cuba' },
  { value: 'CW', viewValue: 'Curaçao' },
  { value: 'CY', viewValue: 'Cyprus' },
  { value: 'CZ', viewValue: 'Czechia' },
  { value: 'DK', viewValue: 'Denmark' },
  { value: 'DJ', viewValue: 'Djibouti' },
  { value: 'DM', viewValue: 'Dominica' },
  { value: 'DO', viewValue: 'Dominican Republic' },
  { value: 'EC', viewValue: 'Ecuador' },
  { value: 'EG', viewValue: 'Egypt' },
  { value: 'SV', viewValue: 'El Salvador' },
  { value: 'GQ', viewValue: 'Equatorial Guinea' },
  { value: 'ER', viewValue: 'Eritrea' },
  { value: 'EE', viewValue: 'Estonia' },
  { value: 'ET', viewValue: 'Ethiopia' },
  { value: 'FK', viewValue: 'Falkland Islands (Malvinas)' },
  { value: 'FO', viewValue: 'Faroe Islands' },
  { value: 'FJ', viewValue: 'Fiji' },
  { value: 'FI', viewValue: 'Finland' },
  { value: 'FR', viewValue: 'France' },
  { value: 'GF', viewValue: 'French Guiana' },
  { value: 'PF', viewValue: 'French Polynesia' },
  { value: 'TF', viewValue: 'French Southern Territories' },
  { value: 'GA', viewValue: 'Gabon' },
  { value: 'GM', viewValue: 'Gambia' },
  { value: 'GE', viewValue: 'Georgia' },
  { value: 'DE', viewValue: 'Germany' },
  { value: 'GH', viewValue: 'Ghana' },
  { value: 'GI', viewValue: 'Gibraltar' },
  { value: 'GR', viewValue: 'Greece' },
  { value: 'GL', viewValue: 'Greenland' },
  { value: 'GD', viewValue: 'Grenada' },
  { value: 'GP', viewValue: 'Guadeloupe' },
  { value: 'GU', viewValue: 'Guam' },
  { value: 'GT', viewValue: 'Guatemala' },
  { value: 'GG', viewValue: 'Guernsey' },
  { value: 'GN', viewValue: 'Guinea' },
  { value: 'GW', viewValue: 'Guinea-Bissau' },
  { value: 'GY', viewValue: 'Guyana' },
  { value: 'HT', viewValue: 'Haiti' },
  { value: 'HM', viewValue: 'Heard and Mc Donald Islands' },
  { value: 'VA', viewValue: 'Holy See (Vatican City State)' },
  { value: 'HN', viewValue: 'Honduras' },
  { value: 'HK', viewValue: 'Hong Kong' },
  { value: 'HU', viewValue: 'Hungary' },
  { value: 'IS', viewValue: 'Iceland' },
  { value: 'IN', viewValue: 'India' },
  { value: 'ID', viewValue: 'Indonesia' },
  { value: 'IR', viewValue: 'Iran, Islamic Republic of' },
  { value: 'IQ', viewValue: 'Iraq' },
  { value: 'IE', viewValue: 'Ireland' },
  { value: 'IM', viewValue: 'Isle of Man' },
  { value: 'IL', viewValue: 'Israel' },
  { value: 'IT', viewValue: 'Italy' },
  { value: 'JM', viewValue: 'Jamaica' },
  { value: 'JP', viewValue: 'Japan' },
  { value: 'JE', viewValue: 'Jersey' },
  { value: 'JO', viewValue: 'Jordan' },
  { value: 'KZ', viewValue: 'Kazakstan' },
  { value: 'KE', viewValue: 'Kenya' },
  { value: 'KI', viewValue: 'Kiribati' },
  { value: 'KP', viewValue: "Korea, Democratic People's Republic of" },
  { value: 'KR', viewValue: 'Korea, Republic of' },
  { value: 'XK', viewValue: 'Kosovo (temporary code)' },
  { value: 'KW', viewValue: 'Kuwait' },
  { value: 'KG', viewValue: 'Kyrgyzstan' },
  { value: 'LA', viewValue: "Lao, People's Democratic Republic" },
  { value: 'LV', viewValue: 'Latvia' },
  { value: 'LB', viewValue: 'Lebanon' },
  { value: 'LS', viewValue: 'Lesotho' },
  { value: 'LR', viewValue: 'Liberia' },
  { value: 'LY', viewValue: 'Libyan Arab Jamahiriya' },
  { value: 'LI', viewValue: 'Liechtenstein' },
  { value: 'LT', viewValue: 'Lithuania' },
  { value: 'LU', viewValue: 'Luxembourg' },
  { value: 'MO', viewValue: 'Macao' },
  { value: 'MK', viewValue: 'Macedonia, The Former Yugoslav Republic Of' },
  { value: 'MG', viewValue: 'Madagascar' },
  { value: 'MW', viewValue: 'Malawi' },
  { value: 'MY', viewValue: 'Malaysia' },
  { value: 'MV', viewValue: 'Maldives' },
  { value: 'ML', viewValue: 'Mali' },
  { value: 'MT', viewValue: 'Malta' },
  { value: 'MH', viewValue: 'Marshall Islands' },
  { value: 'MQ', viewValue: 'Martinique' },
  { value: 'MR', viewValue: 'Mauritania' },
  { value: 'MU', viewValue: 'Mauritius' },
  { value: 'YT', viewValue: 'Mayotte' },
  { value: 'MX', viewValue: 'Mexico' },
  { value: 'FM', viewValue: 'Micronesia, Federated States of' },
  { value: 'MD', viewValue: 'Moldova, Republic of' },
  { value: 'MC', viewValue: 'Monaco' },
  { value: 'MN', viewValue: 'Mongolia' },
  { value: 'ME', viewValue: 'Montenegro' },
  { value: 'MS', viewValue: 'Montserrat' },
  { value: 'MA', viewValue: 'Morocco' },
  { value: 'MZ', viewValue: 'Mozambique' },
  { value: 'MM', viewValue: 'Myanmar' },
  { value: 'NA', viewValue: 'Namibia' },
  { value: 'NR', viewValue: 'Nauru' },
  { value: 'NP', viewValue: 'Nepal' },
  { value: 'NL', viewValue: 'Netherlands' },
  { value: 'AN', viewValue: 'Netherlands Antilles' },
  { value: 'NC', viewValue: 'New Caledonia' },
  { value: 'NZ', viewValue: 'New Zealand' },
  { value: 'NI', viewValue: 'Nicaragua' },
  { value: 'NE', viewValue: 'Niger' },
  { value: 'NG', viewValue: 'Nigeria' },
  { value: 'NU', viewValue: 'Niue' },
  { value: 'NF', viewValue: 'Norfolk Island' },
  { value: 'MP', viewValue: 'Northern Mariana Islands' },
  { value: 'NO', viewValue: 'Norway' },
  { value: 'OM', viewValue: 'Oman' },
  { value: 'PK', viewValue: 'Pakistan' },
  { value: 'PW', viewValue: 'Palau' },
  { value: 'PS', viewValue: 'Palestinian Territory, Occupied' },
  { value: 'PA', viewValue: 'Panama' },
  { value: 'PG', viewValue: 'Papua New Guinea' },
  { value: 'PY', viewValue: 'Paraguay' },
  { value: 'PE', viewValue: 'Peru' },
  { value: 'PH', viewValue: 'Philippines' },
  { value: 'PN', viewValue: 'Pitcairn' },
  { value: 'PL', viewValue: 'Poland' },
  { value: 'PT', viewValue: 'Portugal' },
  { value: 'PR', viewValue: 'Puerto Rico' },
  { value: 'QA', viewValue: 'Qatar' },
  { value: 'RS', viewValue: 'Republic of Serbia' },
  { value: 'RE', viewValue: 'Reunion' },
  { value: 'RO', viewValue: 'Romania' },
  { value: 'RU', viewValue: 'Russia Federation' },
  { value: 'RW', viewValue: 'Rwanda' },
  { value: 'BL', viewValue: 'Saint Barthélemy' },
  { value: 'SH', viewValue: 'Saint Helena' },
  { value: 'KN', viewValue: 'Saint Kitts & Nevis' },
  { value: 'LC', viewValue: 'Saint Lucia' },
  { value: 'MF', viewValue: 'Saint Martin' },
  { value: 'PM', viewValue: 'Saint Pierre and Miquelon' },
  { value: 'VC', viewValue: 'Saint Vincent and the Grenadines' },
  { value: 'WS', viewValue: 'Samoa' },
  { value: 'SM', viewValue: 'San Marino' },
  { value: 'ST', viewValue: 'Sao Tome and Principe' },
  { value: 'SA', viewValue: 'Saudi Arabia' },
  { value: 'SN', viewValue: 'Senegal' },
  { value: 'CS', viewValue: 'Serbia and Montenegro' },
  { value: 'SC', viewValue: 'Seychelles' },
  { value: 'SL', viewValue: 'Sierra Leone' },
  { value: 'SG', viewValue: 'Singapore' },
  { value: 'SX', viewValue: 'Sint Maarten' },
  { value: 'SK', viewValue: 'Slovakia' },
  { value: 'SI', viewValue: 'Slovenia' },
  { value: 'SB', viewValue: 'Solomon Islands' },
  { value: 'SO', viewValue: 'Somalia' },
  { value: 'ZA', viewValue: 'South Africa' },
  { value: 'GS', viewValue: 'South Georgia & The South Sandwich Islands' },
  { value: 'SS', viewValue: 'South Sudan' },
  { value: 'ES', viewValue: 'Spain' },
  { value: 'LK', viewValue: 'Sri Lanka' },
  { value: 'SD', viewValue: 'Sudan' },
  { value: 'SR', viewValue: 'Suriname' },
  { value: 'SJ', viewValue: 'Svalbard and Jan Mayen' },
  { value: 'SZ', viewValue: 'Swaziland' },
  { value: 'SE', viewValue: 'Sweden' },
  { value: 'CH', viewValue: 'Switzerland' },
  { value: 'SY', viewValue: 'Syrian Arab Republic' },
  { value: 'TW', viewValue: 'Taiwan, Province of China' },
  { value: 'TJ', viewValue: 'Tajikistan' },
  { value: 'TZ', viewValue: 'Tanzania, United Republic of' },
  { value: 'TH', viewValue: 'Thailand' },
  { value: 'TL', viewValue: 'Timor-Leste' },
  { value: 'TG', viewValue: 'Togo' },
  { value: 'TK', viewValue: 'Tokelau' },
  { value: 'TO', viewValue: 'Tonga' },
  { value: 'TT', viewValue: 'Trinidad and Tobago' },
  { value: 'TN', viewValue: 'Tunisia' },
  { value: 'TR', viewValue: 'Turkey' },
  { value: 'XT', viewValue: 'Turkish Rep N Cyprus (temporary code)' },
  { value: 'TM', viewValue: 'Turkmenistan' },
  { value: 'TC', viewValue: 'Turks and Caicos Islands' },
  { value: 'TV', viewValue: 'Tuvalu' },
  { value: 'UG', viewValue: 'Uganda' },
  { value: 'UA', viewValue: 'Ukraine' },
  { value: 'AE', viewValue: 'United Arab Emirates' },
  { value: 'GB', viewValue: 'United Kingdom' },
  { value: 'US', viewValue: 'United States' },
  { value: 'UM', viewValue: 'United States Minor Outlying Islands' },
  { value: 'UY', viewValue: 'Uruguay' },
  { value: 'UZ', viewValue: 'Uzbekistan' },
  { value: 'VU', viewValue: 'Vanuatu' },
  { value: 'VE', viewValue: 'Venezuela' },
  { value: 'VN', viewValue: 'Vietnam' },
  { value: 'VG', viewValue: 'Virgin Islands, British' },
  { value: 'VI', viewValue: 'Virgin Islands, U.S.' },
  { value: 'WF', viewValue: 'Wallis and Futuna' },
  { value: 'EH', viewValue: 'Western Sahara' },
  { value: 'YE', viewValue: 'Yemen' },
  { value: 'ZM', viewValue: 'Zambia' },
  { value: 'ZW', viewValue: 'Zimbabwe' },
];
