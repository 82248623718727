<div class="related-properties-container">
  <div class="header-separator-center">
    <h1 class="title-heading">You may also like</h1>
    <div class="separator"></div>
  </div>
  <div class="grid-container" *ngIf="unitDisplay | async as unitDisplayList">
    <mat-grid-list
      class="ttw-grid"
      [cols]="columnNum"
      rowHeight="38:55"
      gutterSize="4%"
    >
      @for (unitMy of unitDisplayList; track identifyUnit(i, unitMy); let i =
      $index) {
      <mat-grid-tile class="unit-summary-card-shadow">
        <twbooking-unit-summary
          [unit]="unitMy"
          [searchQuery]="searchQuery"
          [columnNum]="columnNum"
        >
        </twbooking-unit-summary>
      </mat-grid-tile>
      }
    </mat-grid-list>
  </div>
  <div class="related-text">
    <a class="show-more-nav" (click)="search()"
      ><span>More places to stay</span>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>
  </div>
</div>
