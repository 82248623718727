<div class="unit-details-tab">
  <div
    class="mat-subtitle-1 header-detail"
    *ngIf="
      (this.unit && amenityList.length > 0) ||
      (this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r.length > 0)
    "
  >
    <div *ngIf="featuredAmenities && featuredAmenities.length > 0">
      <div class="header-separator-left">
        <h2 class="title-heading">Amenities</h2>
        <div class="separator"></div>
      </div>

      <div
        class="featured-icons-container brand-light-background"
        [class.padding-desktop]="!isMobile"
        [class.padding-mobile]="isMobile"
      >
        @for (amenity of featuredAmenities; track $index) {
        <div
          [class.featured-amenity-icon]="!isMobile"
          [class.featured-amenity-icon-mobile]="isMobile"
          (click)="showAmenity(amenity.sliderIndex)"
        >
          <mat-icon
            fontSet="TTW"
            fontIcon="{{ iconByAmenity[amenity.AmenityType__c] }}"
            class="amenity-icon brand-accent-color tw-icon icon-clickable-always"
          ></mat-icon>
          <span class="amenity-name new-brand-color">
            <span
              *ngIf="!isMobile || amenity.AmenityType__c !== 'Air Conditioning'"
            >
              {{ amenity.AmenityType__c }}
            </span>
            <span
              *ngIf="isMobile && amenity.AmenityType__c === 'Air Conditioning'"
            >
              AC
            </span>
          </span>
        </div>
        }
      </div>

      <div
        [class.complex-features-desktop]="!isMobile"
        [class.complex-features-mobile]="isMobile"
        class="brand-offwhite-background"
      >
        <div class="amenity-carousel">
          <span #showAmenityAnchor></span>
          <div class="aspect-ratio-container">
            <div class="full-width amenities-carousel-container">
              <div
                *ngIf="images.length > 1"
                class="left-arrow"
                (click)="showPrev()"
                (swipeleft)="onSwipeLeft($event)"
                (swiperight)="onSwipeRight($event)"
              >
                <mat-icon class="slider-arrow">keyboard_arrow_left</mat-icon>
              </div>

              @for (image of images; track image.URL__c; let i = $index) {
              <ng-container>
                <img
                  class="full-animation"
                  *ngIf="i === currentImage"
                  (swipeleft)="onSwipeLeft($event)"
                  (swiperight)="onSwipeRight($event)"
                  mat-card-image
                  [class.mat-card-image-summary]="summaryCard"
                  [class.mat-card-image-details]="!summaryCard"
                  [src]="image.URL__c"
                  [alt]="image.Caption__c ? image.Caption__c : image.Name"
                  @carouselAnimation
                />
                <div
                  *ngIf="i === currentImage"
                  (swipeleft)="onSwipeLeft($event)"
                  (swiperight)="onSwipeRight($event)"
                  class="projected-mobile ttw-projected ttw-projected-paddings"
                >
                  <h1 class="mat-h1">
                    {{ this.featuredAmenities[amenityIndex].AmenityType__c }}
                  </h1>
                </div>
                <div
                  *ngIf="
                    i === currentImage &&
                    showDescription &&
                    (this.featuredAmenities[amenityIndex].Long_Description__c ||
                      images[currentImage].representative) &&
                    !isMobile
                  "
                  (swipeleft)="onSwipeLeft($event)"
                  (swiperight)="onSwipeRight($event)"
                  class="projected-mobile-bottom ttw-projected ttw-projected-paddings"
                >
                  <span class="mat-subtitle-1">
                    {{ this.featuredAmenities[amenityIndex].Description__c }}
                  </span>
                </div>
              </ng-container>
              }

              <div
                *ngIf="images.length > 1"
                class="right-arrow"
                (click)="showNext()"
                (swipeleft)="onSwipeLeft($event)"
                (swiperight)="onSwipeRight($event)"
              >
                <mat-icon class="slider-arrow">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
          @if(isMobile && images[currentImage].representative){
          <span class="representative-image-mobile">
            ***Representative image only***
          </span>
          }
        </div>
        @if(isMobile) {
        <div class="amenity-description">
          {{ this.featuredAmenities[amenityIndex].Description__c }}
        </div>
        }
        <div class="amenity-description">
          <div
            *ngIf="this.featuredAmenities[amenityIndex].Long_Description__c"
            class="inner-html"
            [innerHTML]="
              this.featuredAmenities[amenityIndex].Long_Description__c
                | safeHtml
            "
          ></div>

          <div
            class="representative-image"
            *ngIf="
              !isMobile &&
              !this.featuredAmenities[amenityIndex].Long_Description__c &&
              !images[currentImage].representative
            "
          >
            ***{{ this.featuredAmenities[amenityIndex].Description__c }}***
          </div>

          <span
            class="representative-image"
            *ngIf="!isMobile && images[currentImage].representative"
          >
            ***Representative image only***
          </span>
          <!--description-->
        </div>

        <!--complex-features-->
      </div>

      <div class="classic-amenities">
        @for (category of amenityList; track category.text; let i = $index) {
        <div class="category-container">
          <div *ngIf="showAll || (showAmenities && i <= 4)">
            <div *ngIf="category?.values?.length">
              <h2 class="mat-h2">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <svg:path
                    class="brand-light-accent"
                    [attr.d]="category.img"
                  />
                </svg>
                <span class="category-text"
                  >&nbsp;&nbsp;{{ category.text }}</span
                >
              </h2>
              <mat-chip-listbox>
                @for (amenity of category.values; track amenity) {
                <mat-chip-option>
                  <span class="chip-text">{{ amenity }}</span>
                </mat-chip-option>
                }
              </mat-chip-listbox>
              <br />
            </div>
          </div>
        </div>
        }
      </div>

      <a
        *ngIf="!showAll && amenityList.length > 0"
        class="show-more-nav"
        (click)="showAll = !showAll"
        ><span>Show all amenities</span
        ><span class="hover-arrows">
          <mat-icon class="right-arrow-classic">keyboard_arrow_right</mat-icon>
          <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
        </span>
      </a>
      <div *ngIf="showAll">
        <a class="show-more-nav" (click)="showAll = !showAll"
          ><span>Hide amenities</span>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
      </div>

      <br />
    </div>
  </div>
</div>
