import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    limit: number = 40,
    completeWords: boolean = false,
    ellipsis: string = '...'
  ): string {
    if (!value || limit <= 0) {
      return '';
    }

    if (value.length <= limit) {
      return value;
    }

    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
      // Ensure limit is not -1 if the first word is longer than the limit
      limit = limit <= 0 ? (limit = value.indexOf(' ')) : limit;
    }

    return value.length > limit ? value.substring(0, limit) + ellipsis : value;
  }
}
