<div *ngIf="smallTooltip!=='yes'">
    <div class="left-arrow" [style.left]="leftArrow + 'px'" [style.top]="top + 'px'"></div>
    <div class="tooltip"[style.top]="top + 'px'">


        <ng-container [ngTemplateOutlet]="tooltipHtml">
        </ng-container>

        <span class="close-button" *ngIf="smallTooltip !== 'yes'" (click)="onClose()">

            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10"
                viewBox="0 0 64 64" class="svg-close">
                <defs>
                    <clipPath id="clip-Ttw-close-icon">
                        <rect width="64" height="64" />
                    </clipPath>
                </defs>
                <g id="Ttw-close-icon" clip-path="url(#clip-Ttw-close-icon)">
                    <rect width="64" height="64" fill="#103753" />
                    <line id="Line_5" data-name="Line 5" x2="53" y2="53" transform="translate(5.5 5.5)" fill="#fff"
                        stroke="#fff" stroke-linecap="round" stroke-width="10" />
                    <line id="Line_6" data-name="Line 6" y1="53" x2="53" transform="translate(5.5 5.5)" fill="#fff"
                        stroke="#fff" stroke-linecap="round" stroke-width="10" />
                </g>
            </svg>
        </span>
    </div>
</div>

<div *ngIf="smallTooltip && smallTooltip=='yes'" class="old-tooltip-styles">
    <div class="tooltip " [style.left]="left + 'px'" [style.top]="top + 'px'"
    [class.conditional-translate]="left>170 && right+160<=innerWidth" [class.full-translate]="right+160>innerWidth"
    [class.left-arrow]="left<=160">
        <ng-container [ngTemplateOutlet]="tooltipHtml">
        </ng-container>
    </div>
</div>