<div *ngIf="unitList && unitList.length && columnNum" class="units-container">
  <span *ngIf="name">
    <h2>{{ name }}</h2>
    <br />
  </span>

  <mat-grid-list
    class="ttw-grid"
    [cols]="columnNum"
    rowHeight="38:55"
    gutterSize="4%"
  >
    @for ( unit of unitList | slice : 0 : offset; track identifyUnit($index,
    unit)) {
    <mat-grid-tile class="unit-summary-card-shadow">
      <twbooking-unit-summary
        [unit]="unit"
        [searchQuery]="null"
        [columnNum]="columnNum"
      >
      </twbooking-unit-summary>
    </mat-grid-tile>
    }
  </mat-grid-list>
</div>
