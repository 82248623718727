import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'twbooking-booking-reservation-thankyou-cancel',
  templateUrl: './booking-reservation-thankyou-cancel.component.html',
  styleUrls: [
    '../unit-enquiry-thank-you/unit-enquiry-thank-you.component.scss',
  ],
})
export class BookingReservationThankyouCancelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
