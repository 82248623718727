import { Component, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {
  localhost,
  mainsite,
  mm,
  staging,
} from 'src/app/store/models/hostings';
import {
  collection,
  collectionSnapshots,
  doc,
  Firestore,
  query,
  setDoc,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/api.service';
import { LeadSubmitParamsGA } from 'src/app/store/models/events-ga.model';
import * as moment from 'moment';
import { UserDataService } from 'src/app/services/user-data.service';
import { user } from '@angular/fire/auth';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'twbooking-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: string;

  userIdSub: Subscription;
  chatIdSub: Subscription = null;
  ptdInitiatedChatSub: Subscription = null;
  statusSub: Subscription;
  volumeSub: Subscription;
  existingChatSub: Subscription = null;
  newMessagesSub: Subscription = null;
  submitLeadSub: Subscription = null;
  userVerifiedSub: Subscription = null;
  id: string = null;
  status = null;
  testMode = 'Off';
  chatId: string = null;
  newMessage = false;
  dialogStatus: 'opened' | 'closed' = 'closed';
  volume = null;
  nb = true;
  ptdImage = null;
  checkExistingChatsDone = false;

  collectionObject: any = {
    COLLECTION_KEY: 'user',
    UUID_KEY: null,
    MESSAGE_COLLECTION_NAME: 'chat',
  };

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public chatService: ChatService,
    private readonly firestore: Firestore,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private userDataService: UserDataService,
    private eventService: EventService
  ) {
    this.id = null;
  }

  ngOnInit(): void {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );
    this.newMessage = false;
    this.volumeSub = this.chatService.volume$.subscribe(
      (volume) => (this.volume = volume)
    );

    this.userIdSub = this.authService.userUID$.subscribe((id: string) => {
      this.id = id;
      if (this.ptdInitiatedChatSub) {
        this.ptdInitiatedChatSub.unsubscribe();
      }
      if (this.newMessagesSub) {
        this.newMessagesSub.unsubscribe();
      }

      if (this.id !== null) {
        this.collectionObject.UUID_KEY = this.id;
        this.ptdInitiatedChatSub = this.fetchNewChat(this.id).subscribe(
          (newMessage) => {
            if (
              newMessage &&
              newMessage.length &&
              newMessage[0].chatSessionId
            ) {
              this.chatId = newMessage[0].chatSessionId;
              this.chatService.chatSessionId.next(this.chatId);
            }
          }
        );
        this.checkExistingChatsDone = this.chatService.checkExistingChatsDone;
        this.fetchExistingChatwrapper();
      }
    });
    this.statusSub = this.chatService.botStatus$.subscribe((newStatus) => {
      this.status = newStatus;
      this.ptdImage = this.chatService.ptdImage$.getValue();
      if (this.status === 'On') {
        this.fetchExistingChatwrapper();
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params.chat && params.chat === 'test') {
        this.testMode = 'On';
      } else {
        this.testMode = 'Off';
      }
    });

    this.chatService.chatSessionId.subscribe((chatSessionId: string) => {
      this.chatId = chatSessionId;
      if (this.id && this.chatId && this.chatId !== 'REQUESTED') {
        if (this.newMessagesSub) {
          this.newMessagesSub.unsubscribe();
        }
        this.newMessagesSub = this.fetchPTDAnswer(
          this.chatId,
          this.id
        ).subscribe((newMessage) => {
          if (
            newMessage &&
            newMessage.length &&
            this.dialogStatus === 'closed'
          ) {
            this.newMessage = true;
            this.playSound();
          }
        });

        if (this.submitLeadSub) {
          this.submitLeadSub.unsubscribe();
        }

        this.submitLeadSub = this.fetchLeadSub(this.chatId, this.id).subscribe(
          (newLeads) => {
            if (
              newLeads &&
              newLeads.length &&
              newLeads[0].lead &&
              newLeads[0].id
            ) {
              console.log('new le non empty');
              let leadGA: LeadSubmitParamsGA = {
                StartDate: newLeads[0].lead.StartDate,
                EndDate: newLeads[0].lead.EndDate,
                Adults: newLeads[0].lead.Adults,
                Children: newLeads[0].lead.Children,
                Bedrooms: newLeads[0].lead.Bedrooms,
                UnitName: newLeads[0].lead.UnitName,
                Destination: newLeads[0].lead.Destination,
                value: newLeads[0].lead.value,
                currency: 'USD',
              };

              let userData = null;
              if (this.userDataService.userData) {
                userData = this.userDataService.get();
              }
              if (userData && userData.email && userData.phone) {
                this.eventService.sendLeadSubmitGA(leadGA, {
                  email: userData.email,
                  phone: userData.phone,
                });
              } else {
                this.eventService.sendLeadSubmitGA(leadGA, null);
              }

              newLeads[0].lead.status = 'submitted';

              this.leadEventSubmitted(
                this.chatId,
                this.id,
                newLeads[0].id,
                newLeads[0].lead
              );
            }
          }
        );
      }
    });
  }

  private fetchExistingChatwrapper(): void {
    if (this.id && this.status === 'On') {
      // Check if existing chats have not been checked yet
      if (!this.checkExistingChatsDone) {
        this.fetchExistingChat().subscribe({
          next: (result: any) => {
            this.chatService.checkExistingChatsDone = true;
            this.checkExistingChatsDone = true;

            if (result && result.chatId) {
              this.chatId = result.chatId;
              this.chatService.chatSessionId.next(this.chatId);
            }
          },
          error: (error) => {
            this.chatService.checkExistingChatsDone = true;
            this.checkExistingChatsDone = true;
            console.error('Error while fetching existing chat:', error);
          },
          complete: () => {
            this.chatService.checkExistingChatsDone = true;
            this.checkExistingChatsDone = true;
          },
        });
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.mode) {
      this.mode = changes.mode.currentValue;
    }
  }

  openChatDialog(): void {
    const guestName: string = null;
    this.newMessage = false;
    this.dialogStatus = 'opened';

    const dialogRef = this.dialog.open(ChatDialogComponent, {
      data: {},
      panelClass: 'chat-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogStatus = 'closed';
    });
  }

  fetchNewChat(userId): Observable<any> {
    const userCollection = collection(this.firestore, 'user');
    const userDoc = doc(userCollection, userId);
    const chatCollection = collection(userDoc, 'chat');
    let ptdStarted = query(
      chatCollection,
      where('status', '==', 'startedbyPTD')
    );

    return collectionSnapshots(ptdStarted).pipe(
      map((res: any) => {
        return res.map((element) => {
          let message = element.data();
          return message;
        });
      })
    );
  }

  fetchLeadSub(chatSessionId, userId): Observable<any> {
    const yesterdayStart = moment().subtract(1, 'days').startOf('day').toDate();
    const yesterdayTimestamp = Timestamp.fromDate(yesterdayStart);

    const userRef = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    const userDoc = doc(userRef, userId);
    const collectionM = collection(
      userDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );
    const chatSessionDoc = doc(collectionM, chatSessionId);
    const leadEventCollection = collection(chatSessionDoc, 'leadEvent');
    let ptdOnly = query(
      leadEventCollection,
      where('author', '==', 'ptd'),
      where('chatSessionId', '==', chatSessionId),
      where('status', '==', 'new'),
      where('created', '>', yesterdayTimestamp)
    );

    return collectionSnapshots(ptdOnly).pipe(
      map((res: any) => {
        return res.map((element) => {
          let lead = element.data();
          const id = element.id;
          return { lead: lead, id: id };
        });
      })
    );
  }

  leadEventSubmitted(
    chatSessionId: string,
    userId: string,
    leadEventId: string,
    leadEvent
  ) {
    const collectionRef = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    const userDoc = doc(collectionRef, userId);
    const collectionM = collection(
      userDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );
    const chatSessionDoc = doc(collectionM, chatSessionId);
    const leadEventCollection = collection(chatSessionDoc, 'leadEvent');
    const idDoc = doc(leadEventCollection, leadEventId);
    const userRef = setDoc(idDoc, leadEvent)
      .then(() => {
        console.log('successfully tagged le');
      })
      .catch((error) => {
        console.error(
          'leadEventSubmitted status update for leadEventId: ',
          leadEventId,
          ' failed with error: ',
          error
        );
      });
  }

  fetchPTDAnswer(chatSessionId, userId): Observable<any> {
    const userRef = collection(
      this.firestore,
      this.collectionObject.COLLECTION_KEY
    );
    const userDoc = doc(userRef, userId);
    const collectionM = collection(
      userDoc,
      this.collectionObject.MESSAGE_COLLECTION_NAME
    );
    const chatSessionDoc = doc(collectionM, chatSessionId);
    const messagesCollection = collection(chatSessionDoc, 'messages');
    let ptdOnly = query(
      messagesCollection,
      where('author', 'in', ['ptd', 'bot']),
      where('chatSessionId', '==', chatSessionId),
      where('status', '==', 'new')
    );

    return collectionSnapshots(ptdOnly).pipe(
      map((res: any) => {
        return res.map((element) => {
          let message = element.data();
          return true;
        });
      })
    );
  }

  playSound() {
    if (this.volume === 'On') {
      const audio = new Audio(
        'https://ttw-unit-images.imgix.net/Other/chat-opens.mp3'
      );
      audio.play();
    }
  }

  fetchExistingChat(): Observable<any> {
    return new Observable((observer) => {
      this.apiService
        .httpCall('/api/checkExistingChats', {
          uuid: this.authService.getUserId(),
        })
        .pipe(
          catchError((error) => {
            // Catch and handle the error here instead of apiService.
            console.error(
              'Error occurred while we tried to fetch existing chat:',
              error
            );
            return of({ chatId: null });
          })
        )
        .subscribe({
          next: (result: any) => {
            if (result && result.existingChatId) {
              observer.next({ chatId: result.existingChatId });
            } else {
              observer.next({ chatId: null });
            }
          },
          error: (error) => {
            console.error('Unexpected error in fetchExistingChat:', error);
          },
          complete: () => {
            observer.complete();
          },
        });
    });
  }

  ngOnDestroy() {
    this.userIdSub.unsubscribe();
    if (this.chatIdSub) {
      this.chatIdSub.unsubscribe();
    }
    if (this.newMessagesSub) {
      this.newMessagesSub.unsubscribe();
    }
    this.statusSub.unsubscribe();
    this.volumeSub.unsubscribe();
    if (this.ptdInitiatedChatSub) {
      this.ptdInitiatedChatSub.unsubscribe();
    }
    if (this.existingChatSub) {
      this.existingChatSub.unsubscribe();
    }
    if (this.submitLeadSub) {
      this.submitLeadSub.unsubscribe();
    }
  }
}
