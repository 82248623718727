<div class="container">
  <button
    mat-button
    type="submit"
    class="brand-button enquiry-button secure-payment"
    [disabled]="!formValues.agreed"
    (click)="encryptData()"
  >
    Continue to secure Payment
  </button>
  <form
    ngNoForm
    id="payment_confirmation"
    action="{{ cyberSourceUrl }}"
    method="post"
  >
    @for (c of controls; track $index) {
    <div>
      <input
        type="hidden"
        id="{{ c.key }}"
        name="{{ c.key }}"
        value="{{ c.value }}"
      />
    </div>
    }
  </form>
</div>
