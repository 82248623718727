import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bedroomFilter',
})
export class BedroomFilterPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (!args) {
      return value;
    }
    let returnValue;
    let sleepsfilter;
    if (args[0]) {
      sleepsfilter = +args[0];
    } else {
      sleepsfilter = +args;
    }
    if (typeof sleepsfilter !== 'number') {
      return value;
    }
    const sleepsFilteredValue = sleepsfilter
      ? value.filter(unit => {
          return +unit.Sleeps__c >= sleepsfilter;
        })
      : value;

    if (args[1]) {
      const bedroomFilter = +args[1];
      if (typeof bedroomFilter !== 'number') {
        return sleepsFilteredValue;
      } else {
        returnValue = bedroomFilter
          ? sleepsFilteredValue.filter(unit => {
              return +unit.Bedrooms__c >= bedroomFilter;
            })
          : sleepsFilteredValue;
      }
    } else {
      return sleepsFilteredValue;
    }

    return returnValue;
  }
}
