import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LodgingQuote } from '../store/models/lodging-quote.model';
import { AuthService } from './auth.service';
import { AuthenticatedApiService } from './authenticated-api.service';
import { SearchParamsService } from './search-params.service';
import { UserDataService } from './user-data.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LodgingQuoteService {
  userVerifiedSub: Subscription;
  public lodgingQuotes$: BehaviorSubject<LodgingQuote[] | null | 'loading'>;
  public holdExists$: BehaviorSubject<boolean>;
  holdDates = new Map<string, { start; end }[]>();
  lodgingQuotesDates = new Map<string, { start; end }[]>();

  constructor(
    private authApiService: AuthenticatedApiService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private searchParamService: SearchParamsService
  ) {
    this.lodgingQuotes$ = new BehaviorSubject<
      LodgingQuote[] | null | 'loading'
    >('loading');
    this.holdExists$ = new BehaviorSubject<boolean>(false);
  }

  refreshQuotesList() {
    this.reset();
    this.fetchLodgingQuote().subscribe((result) => {});
  }
  reset() {
    this.lodgingQuotes$.next('loading');
    this.holdExists$.next(false);
  }

  setHold(externalId: string, personalHold: { start; end }) {
    let existingHold = this.holdDates.get(externalId);
    if (existingHold) {
      existingHold.push(personalHold);
      this.holdDates.set(externalId, existingHold);
    } else {
      this.holdDates.set(externalId, [personalHold]);
    }
  }

  addLodgingQuotes(
    externalId: string,
    lodgingQuoteArrivalDeparture: { start; end }
  ) {
    let existingLQ = this.lodgingQuotesDates.get(externalId);
    if (existingLQ) {
      existingLQ.push(lodgingQuoteArrivalDeparture);
      this.lodgingQuotesDates.set(externalId, existingLQ);
    } else {
      this.lodgingQuotesDates.set(externalId, [lodgingQuoteArrivalDeparture]);
    }
  }

  getLodgingQuotes(externalId): { start; end }[] | null {
    const lodgingQuotes = this.lodgingQuotesDates.get(externalId);
    if (!lodgingQuotes) {
      return null;
    } else {
      return lodgingQuotes;
    }
  }

  checkLodgingQuoteForDates(
    externalId,
    arrivalDate: moment.Moment,
    departureDate: moment.Moment
  ) {
    let exists = false;
    let lqDates = this.getLodgingQuotes(externalId);
    if (lqDates && lqDates.length) {
      lqDates.forEach((lq: { start; end }) => {
        if (
          lq.start == arrivalDate.format('YYYY-MM-DD') &&
          lq.end == departureDate.format('YYYY-MM-DD')
        ) {
          exists = true;
        }
      });
    }
    return exists;
  }

  getHold(externalId): { start; end }[] | undefined {
    const personalHold = this.holdDates.get(externalId);
    if (!personalHold) {
      return undefined;
    } else {
      return personalHold;
    }
  }

  triggerHold(newValue: boolean) {
    this.holdExists$.next(newValue);
  }

  resetLodgingQuote() {
    this.lodgingQuotes$.next([]);
  }

  fetchLodgingQuote(): Observable<any> {
    //FIXME: remove me when beta tests will be over
    return new Observable((observer) => {
      this.userVerifiedSub = this.authService.userVerified$.subscribe(
        async (verified) => {
          if (verified && verified.verified && verified.email) {
            this.authApiService
              .httpCallWithRefresh(
                '/api/fetchLodgingQuote',
                {
                  user: this.authService.getUserId(),
                  email: verified.email,
                }
              )
              .subscribe((result: any) => {
                if (result && result.lodgingQuotes) {
                  if (result.lodgingQuotes.length) {
                    let haveBookingWithHold = false;
                    result.lodgingQuotes.forEach(
                      (lodgingQuote: LodgingQuote) => {
                        //Lets organize the data to have faster access
                        //during booked dates fetch and quoting
                        this.addLodgingQuotes(lodgingQuote.UnitLookup__c, {
                          start: lodgingQuote.ArrivalDate__c,
                          end: lodgingQuote.DepartureDate__c,
                        });
                        //Special attention to dates hold
                        if (lodgingQuote.Hold_Dates__c) {
                          haveBookingWithHold = true;
                          this.setHold(lodgingQuote.UnitLookup__c, {
                            start: lodgingQuote.ArrivalDate__c,
                            end: lodgingQuote.DepartureDate__c,
                          });
                        }
                      }
                    );
                    if (haveBookingWithHold) {
                      this.triggerHold(true);
                    }
                  }
                  this.lodgingQuotes$.next(result.lodgingQuotes);
                  return observer.next({ lodgingQuote: result.lodgingQuotes });
                } else {
                  console.error('unable to fetch lodgingQuote ');
                  this.lodgingQuotes$.next([]);
                  return observer.next({
                    error: 'unable to fetch Lodging Quote  ',
                  });
                }
              });
          } 
        }
      );
    });
  }

  populateWebBookingInput(input: LodgingQuote): {
    bookingInput: any;
    editAllowed: boolean;
  } {
    let editAllowed = false;
    if (input.FirstName__c) {
      this.userDataService.add({ firstName: input.FirstName__c });
    }
    if (input.LastName__c) {
      this.userDataService.add({ lastName: input.LastName__c });
    }
    if (input.Phone__c) {
      this.userDataService.add({ phone: input.Phone__c });
    }
    if (input.Email__c) {
      this.userDataService.add({ email: input.Email__c });
    }

    let searchQuery = {};
    if (input.ArrivalDate__c && input.DepartureDate__c) {
      let arrival = moment.utc(input.ArrivalDate__c);
      let departure = moment.utc(input.DepartureDate__c);
      let myStay = moment.duration(departure.diff(arrival)).asDays();

      searchQuery = {
        StartDate: moment.utc(arrival, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        EndDate: moment.utc(departure, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        myStay: myStay,
      };
    }

    if (input.Adults__c) {
      searchQuery = {
        Adults: +input.Adults__c,
        ...searchQuery,
      };
    }

    if (input.Children__c) {
      searchQuery = {
        Children: +input.Children__c,
        ...searchQuery,
      };
    }

    if (input.Infants__c) {
      searchQuery = {
        Infants: +input.Infants__c,
        ...searchQuery,
      };
    }

    if (input.Pets__c) {
      searchQuery = {
        Pets: +input.Pets__c,
        ...searchQuery,
      };
    }

    this.searchParamService.update(searchQuery);

    let depositPolicies = null;
    if (
      input.FullPaymentDate__c ||
      input.InitialDeposit__c ||
      input.DepositPolicyDescription__c
    ) {
      depositPolicies = {
        FullPaymentDate__c: input.FullPaymentDate__c
          ? input.FullPaymentDate__c
          : null,
        InitialDeposit__c: input.InitialDeposit__c
          ? input.InitialDeposit__c
          : null,
        DepositPolicyDescription__c: input.DepositPolicyDescription__c
          ? input.DepositPolicyDescription__c
          : null,
      };
    }

    let quoteData: any = null;
    if (input.LodgingNightlyRate__c && input.LodgingTaxAmount__c) {
      editAllowed = false;
      quoteData = {
        arrival: moment
          .utc(input.ArrivalDate__c, 'YYYY-MM-DD')
          .format('YYYY-MM-DD'),
        departure: moment
          .utc(input.DepartureDate__c, 'YYYY-MM-DD')
          .format('YYYY-MM-DD'),
        adults: input.Adults__c,
        children: input.Children__c,
        infants: input.Infants__c,
        pets: input.Pets__c,

        lodgingQuote: {
          LodgingNightlyRate__c: input.LodgingNightlyRate__c,
          LodgingDamageProtectionFee__c: input.LodgingDamageProtectionFee__c,
          LodgingTaxAmount__c: input.LodgingTaxAmount__c,
          LodgingSecurityDeposit__c: input.LodgingSecurityDeposit__c
            ? input.LodgingSecurityDeposit__c
            : null,
          LodgingTravelInsurance__c: input.LodgingTravelInsurance__c
            ? input.LodgingTravelInsurance__c
            : null,
          LodgingGrossSales__c: input.LodgingGrossSales__c
            ? input.LodgingGrossSales__c
            : null,
          Hold_Dates__c: input.Hold_Dates__c,
          //TODO add fees, discounted rent, security deposit etc.
        },
      };
    } else {
      editAllowed = true;
      let filter = null;
      if (input.inntopiaRate) {
        quoteData = {
          arrival: moment.utc(input.ArrivalDate__c).format('YYYY-MM-DD'),
          departure: moment.utc(input.DepartureDate__c).format('YYYY-MM-DD'),
          adults: input.Adults__c,
          children: input.Children__c,
          infants: input.Infants__c,
          pets: input.Pets__c,
          quoteInntopia: input.inntopiaRate,
        };
      }

      if (input.rateDetails) {
        quoteData = {
          arrival: moment.utc(input.ArrivalDate__c).format('YYYY-MM-DD'),
          departure: moment.utc(input.DepartureDate__c).format('YYYY-MM-DD'),
          adults: input.Adults__c,
          children: input.Children__c,
          infants: input.Infants__c,
          pets: input.Pets__c,
          quoteMM: input.rateDetails,
        };
      }
    }
    let cancellationPolicy: string | null = null;

    if (input.CustomCancellationPolicy__c) {
      cancellationPolicy = input.CustomCancellationPolicy__c;
    } else if (input.CancellationPeriod__c) {
      cancellationPolicy = `
      If you cancel your reservation more than ${input.CancellationPeriod__c} days prior to your expected arrival date, or if you cancel within 
      ${input.CancellationPeriod__c} days and we are able to re-book the unit for the entire duration of the original reservation, 80% of the 
      total reservation amount will be refunded. The total amount of the payments received will be forfeited if you 
      cancel within ${input.CancellationPeriod__c} days of arrival and we are unable to re-book the unit or you will be reimbursed through CSA 
      travel insurance provided you purchased coverage and the reason of cancellation is one of the covered reasons 
      under the CSA policy. Major Holiday bookings are non-refundable without insurance being purchased and it must 
      be for a covered reason.`;
    }
    let bookingInput = {
      unit: {
        Name:
          input.UnitLookup__r && input.UnitLookup__r.Name
            ? input.UnitLookup__r.Name
            : input.Unit__c,
        externalId: input.UnitLookup__c,
        Id: input.UnitLookup__c,
        Sleeps__c:
          input.UnitLookup__r && input.UnitLookup__r.Sleeps__c
            ? input.UnitLookup__r.Sleeps__c
            : false,
        DisplayRates__c:
          input.UnitLookup__r && input.UnitLookup__r.DisplayRates__c
            ? input.UnitLookup__r.DisplayRates__c
            : false,
        isInntopiaUnit__c:
          input.UnitLookup__r && input.UnitLookup__r.isInntopiaUnit__c
            ? input.UnitLookup__r.isInntopiaUnit__c
            : false,
        UnitType__c:
          input.UnitLookup__r && input.UnitLookup__r.UnitType__c
            ? input.UnitLookup__r.UnitType__c
            : false,
        Property_Collection__c:
          input.UnitLookup__r && input.UnitLookup__r.Property_Collection__c
            ? input.UnitLookup__r.Property_Collection__c
            : false,
        UnitSlug__c:
          input.UnitLookup__r && input.UnitLookup__r.UnitSlug__c
            ? input.UnitLookup__r.UnitSlug__c
            : false,
        inntopiaSupplierId__c:
          input.UnitLookup__r && input.UnitLookup__r.inntopiaSupplierId__c
            ? input.UnitLookup__r.inntopiaSupplierId__c
            : false,
        inntopiaProductId__c:
          input.UnitLookup__r && input.UnitLookup__r.inntopiaProductId__c
            ? input.UnitLookup__r.inntopiaProductId__c
            : false,
        PropertyManager__c: input.PropertyManager__c,
        Destination: input.Destination__c ? input.Destination__c : null,
        Pets_Allowed__c:
          input.UnitLookup__r &&
          (input.UnitLookup__r.Pets_Allowed__c ||
            input.UnitLookup__r.CatsAllowed__c ||
            input.UnitLookup__r.Dogs_Allowed__c)
            ? true
            : false,
      },
      booking: {
        TTW_ID__c: input.TTW_ID__c,
        Name: input.Name,
      },
      depositPolicies: depositPolicies,
      filter: {
        quoteData: quoteData,
      },
      cancellationPolicy: cancellationPolicy,
    };

    return { bookingInput: bookingInput, editAllowed: editAllowed };
  }
}
