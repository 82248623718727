import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { EventService } from 'src/app/services/event.service';
import { LodgingQuoteService } from 'src/app/services/lodging-quote.service';

@Component({
  selector: 'twbooking-booking-reservation-thankyou-success',
  templateUrl: './booking-reservation-thankyou-success.component.html',
  styleUrls: [
    '../unit-enquiry-thank-you/unit-enquiry-thank-you.component.scss',
  ],
})
export class BookingReservationThankyouSuccessComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private lodgingQuoteService: LodgingQuoteService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    let referenceId = this.route.snapshot.queryParams.Id;
    if (referenceId) {
      this.eventService.sendBookingPaymentSuccessGA({
        transaction_id: referenceId,
      });
      this.apiService
        .httpCall('/api/updateWebBookingPaymentStatus', {
          referenceId: referenceId,
          status: 'success',
        })
        .subscribe((result: any) => {
          console.log('successful reservation');
          this.lodgingQuoteService.refreshQuotesList();
        });
    }
  }
}
