import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import {
  Image as KsImage,
  CurrentImageConfig,
  DescriptionStrategy,
  ModalGalleryService,
  ModalGalleryRef,
  DotsConfig,
  ModalGalleryConfig,
  ModalLibConfig,
  CarouselLibConfig,
  ButtonsStrategy,
} from '@ks89/angular-modal-gallery';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UnitImage } from '../../store/models/unit.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Unit, mobileSM, maxImageWidth } from '../../store/models/unit.model';
import { Complex } from 'src/app/store/models/complex.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { CommonLibConfig } from '@ks89/angular-modal-gallery/lib/model/lib-config.interface';

export class MyImage extends KsImage {
  URL__c: string | SafeResourceUrl;
}

@Component({
  selector: 'twbooking-unit-gallery',
  templateUrl: './unit-gallery.component.html',
  styleUrls: ['./unit-gallery.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UnitGalleryComponent implements OnChanges, OnDestroy {
  @Input() unit: Unit | Complex;
  unitImages;
  innerWidth = window.innerWidth < 1366 ? window.innerWidth : 1366;
  galleryImages: MyImage[] = [];

  isMobile = false;
  imgixPostfix = '';
  mobilePostfix = '';
  index = 0;
  sub: Subscription;
  carouselMax = 4;

  customFullDescriptionHidden: CurrentImageConfig = {
    description: { strategy: DescriptionStrategy.ALWAYS_VISIBLE },
    navigateOnClick: true,
    downloadable: false,
    invertSwipe: true,
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private modalGalleryService: ModalGalleryService
  ) {
    this.sub = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  ngOnChanges(changes): void {
    this.innerWidth = window.innerWidth < 1366 ? window.innerWidth : 1366;

    if (changes.unit) {
      this.unit = changes.unit.currentValue;
      this.unit.gridHero__c = true;
      this.unitImages = [];
      this.galleryImages = [];
      this.unitImages = this.unit.ImagesProcessed;

      const width =
        window.innerWidth < mobileSM
          ? this.innerWidth
          : (this.innerWidth * 3) / 4;

      const height = (width * 2) / 3;

      this.mobilePostfix =
        '?auto=enhance&w=' +
        window.innerWidth.toString() +
        '&h=' +
        ((2 / 3) * window.innerWidth).toString() +
        '&fit=scale';

      this.imgixPostfix =
        '?auto=enhance&w=' +
        width.toString() +
        '&h=' +
        height.toString() +
        '&fit=scale';

      const previewPosfix =
        '?auto=enhance&w=' +
        (width / 3).toString() +
        '&h=' +
        (height / 3).toString() +
        '&fit=scale';

      const largePrefix =
        '?auto=enhance&w=' +
        ((window.innerWidth * 8) / 10 <= maxImageWidth
          ? ((window.innerWidth * 8) / 10).toString()
          : maxImageWidth.toString());

      let imgCount = 0;
      this.galleryImages = this.unitImages
        .map((img, index) => {
          if (img.Display__c === 'Hero') {
            imgCount++;
            return new MyImage(
              +(imgCount - 1),
              {
                img: img.URL__c.includes('imgix')
                  ? img.URL__c + largePrefix
                  : img.URL__c,
                description: '',
                alt: img.Caption__c ? img.Caption__c : img.Name,
              },
              {
                img: img.preview ? img.preview : img.URL__c,
                description: '',
                alt: img.Caption__c ? img.Caption__c : img.Name,
              }
            );
          }
        })
        .filter((x) => !!x);

      this.galleryImages = this.galleryImages.concat(
        this.unitImages
          .filter((img) => img.Display__c === 'Standard') // Filter only 'Standard' images
          .filter(
            (img) =>
              !this.galleryImages.some(
                (gImg: MyImage) => gImg.modal.img === img.URL__c
              )
          ) // Check if URL is unique
          .map((img, index) => {
            if (img.Display__c === 'Standard') {
              imgCount++;
              return new MyImage(
                +(imgCount - 1),
                {
                  img: img.URL__c.includes('imgix')
                    ? img.URL__c +
                      '?auto=enhance&w=' +
                      ((window.innerWidth * 8) / 10).toString()
                    : img.URL__c,
                  description: '',
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                },
                {
                  img: img.preview ? img.preview : img.URL__c,
                  description: '',
                  alt: img.Caption__c ? img.Caption__c : img.Name,
                }
              );
            }
          })
          .filter((x) => !!x)
      );

      if (this.galleryImages.length === 0) {
        throw new Error('No Images');
      }

      if (this.galleryImages.length < 4) {
        while (this.galleryImages.length < 4) {
          this.galleryImages.push(this.galleryImages[0]);
        }
        throw new Error('Less then 4 Hero images');
      }

      this.carouselMax = this.galleryImages.length;

      console.log('galleryImages = ', this.galleryImages);

      this.preloadNext(3);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  preloadNext(currentImage) {
    let nextImage = currentImage + 1;
    if (nextImage >= this.galleryImages.length) {
      nextImage = 0;
    }
    new Image().src = this.galleryImages[nextImage].modal.img.toString();
    new Image().src = this.galleryImages[nextImage].plain.img.toString();
  }
  preloadPrev(currentImage) {
    let prevImage = currentImage--;
    if (prevImage < 0) {
      prevImage = this.galleryImages.length - 1;
    }
    new Image().src = this.galleryImages[prevImage].modal.img.toString();
    new Image().src = this.galleryImages[prevImage].plain.img.toString();
  }

  showNext(event?) {
    this.index = this.index + 1 < this.carouselMax ? this.index + 1 : 0;
    this.preloadNext(this.index + 3);
  }

  showPrev(event?) {
    this.index = this.index - 1 >= 0 ? this.index - 1 : this.carouselMax - 1;
    this.preloadPrev(this.index);
  }

  increaseThumb(i: number) {
    this.index = i < this.carouselMax ? i : i - this.carouselMax;
    this.preloadNext(this.index);
  }

  onShow(
    id: number,
    index: number,
    images: KsImage[] = this.galleryImages
  ): void {
    let previewConfigDesktop = {
      visible: true,
      number: 3,
      arrows: true,
      clickable: true,
    };
    let previewConfigMobile = {
      visible: false,
      number: 0,
      arrows: false,
      clickable: true,
    };

    let slideConfigMobile = {
      infinite: true,
      playConfig: { autoPlay: false, interval: 5000, pauseOnHover: true },
      sidePreviews: {
        show: false,
        size: { width: '0px', height: '0px' },
      },
    };

    let slideConfigDesktop = {
      infinite: true,
      playConfig: { autoPlay: false, interval: 5000, pauseOnHover: true },
      sidePreviews: {
        show: true,
        size: { width: '100px', height: 'auto' },
      },
    };

    let modalConfig: ModalGalleryConfig = {
      id: id,
      images: images,
      currentImage: images[index],
      libConfig: {
        previewConfig: this.isMobile
          ? previewConfigMobile
          : previewConfigDesktop,
        dotsConfig: { visible: false },
        slideConfig: this.isMobile ? slideConfigMobile : slideConfigDesktop,
        currentImageConfig: this.customFullDescriptionHidden,
        buttonsConfig: this.isMobile
          ? { visible: true, strategy: ButtonsStrategy.DEFAULT }
          : { visible: false, strategy: ButtonsStrategy.DEFAULT },
      },
    };
    const dialogRef: ModalGalleryRef = this.modalGalleryService.open(
      modalConfig
    ) as ModalGalleryRef;
  }

  onImgError(event, url: string) {
    console.error(
      'Unit Hero image load error',
      url,
      'unit/complex: ',
      this.unit.UnitSlug__c
    );
  }
}
