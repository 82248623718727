import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Unit } from '../../store/models/unit.model';
import { QuoteData } from '../../store/models/rate.models';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UnitsService } from '../../services/units.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingDialogComponent } from '../unit-booking/unit-booking-dialog/unit-rates.component';
import { Subscription } from 'rxjs';
import { UnitEnquiryDialogComponent } from '../unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
declare let ga: any;
import * as _ from 'lodash';

import {
  InntopiaPromotions,
  PromoCondition,
} from '../../store/models/inntopia-promotion';
import { localhost, mainsite, mm, staging } from '../../store/models/hostings';

import { ActivatedRoute, Router } from '@angular/router';
import { FavoritesService } from 'src/app/services/favorites.service';
import { EventService } from 'src/app/services/event.service';
import { UnitMapComponent } from 'src/app/shared/unit-map/unit-map.component';

@Component({
  selector: 'twbooking-unit-tabs',
  templateUrl: './unit-tabs.component.html',
  styleUrls: ['./unit-tabs.component.scss'],
})
export class UnitTabsComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() unit: Unit;
  @ViewChild('stickyMenu', { read: ElementRef }) tr: ElementRef;
  @ViewChild('bottomLine', { read: ElementRef }) bottomDivider: ElementRef;
  @ViewChild('topLine', { read: ElementRef }) topLine: ElementRef;

  @ViewChild('mapsContainer', { read: ElementRef }) parentContainer: ElementRef;
  @ViewChild(UnitMapComponent) mapComponent: UnitMapComponent;

  favoritesSub: Subscription;
  favoriteUnit = false;

  public ratingArr = [0, 1, 2, 3, 4]; // auxiliary array for showing star icons.
  // Text
  shortText = true;
  showMoreImages = false;

  moreThen200words = true;

  // rates widget
  hasRates: boolean;
  isMobile = false;
  sticky = 'TOP'; // TOP - sticky to TOP position; BOTTOM - sticky to BOTTOM position, STICKY - moves at viewport
  drawerPositionTop: any;
  bottomLine1: any;
  datesSet = false;

  reviewsRating = -1;
  reviewsTotal = 0;
  totalBeds: number;

  maxOccupancy = 100;

  subMedia: Subscription;

  quote: QuoteData;

  processedDeals: InntopiaPromotions[] = [];

  showMoreDeals = false;
  nb = true;
  view_item_event_submitted = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private unitService: UnitsService,
    public dialog: MatDialog,
    private router: Router,
    private favoritesService: FavoritesService,
    private route: ActivatedRoute,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );

    const longDescription = this.unit.Long_Description__c || '';
    const words = longDescription.split(' ');
    this.moreThen200words = words.length > 200;

    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });

    this.processedDeals = [];

    this.getPromotions();
    let StartDate = this.route.snapshot.queryParams.StartDate;
    let EndDate = this.route.snapshot.queryParams.EndDate;
    if (!StartDate || !EndDate || !this.unit.DisplayRates__c) {
      this.view_item_event_submitted = true;
      this.eventService.sendViewItemEventGA({
        UnitName: this.unit.Name,
        currency: 'USD',
        value: 0,
        items: [
          {
            item_id: this.unit.externalId,
            item_name: this.unit.Name,
          },
        ],
      });
    } // is we have StartDate and EndDate and DisplayRates__c = true - then we want to submit view_item event when quote is ready.
    // so view_item event will be submitted form keepQuote function
  }

  ngOnChanges(changes) {
    if (changes.unit) {
      this.unit = changes.unit.currentValue;
      this.unit.Long_Description__c = this.unit.Long_Description__c.replace(
        /<p><br><\/p>/g,
        ''
      );
      this.totalBeds =
        +this.unit.Queen_Beds__c +
        +this.unit.Twin_Beds__c +
        +this.unit.Full_Beds__c +
        +this.unit.King_Beds__c +
        +this.unit.Sleeper_Sofas__c +
        +this.unit.BunkBeds__c;

      this.hasRates = this.unit.DisplayRates__c === true;

      if (
        this.unit &&
        this.unit.Reviews__r &&
        this.unit.Reviews__r.length > 0
      ) {
        this.reviewsTotal = this.unit.Reviews__r.length;
      }
      if (this.unit) {
        this.maxOccupancy = this.unit.Sleeps__c;
      }
      this.showMoreDeals = false;
      this.reset();
      this.processedDeals = [];
      this.getPromotions();
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getRating(event) {
    this.reviewsRating = event;
  }

  showStarsIcon(index: number, ReviewRating: string) {
    if (parseFloat(ReviewRating) >= index + 0.9) {
      return 'star';
    } else if (parseFloat(ReviewRating) >= index + 0.3) {
      return 'star_half';
    } else {
      return 'star_border';
    }
  }

  /****************Date and rates**********************************/

  ngAfterViewInit() {
    // this.drawerPosition = this.menuElement.nativeElement.offsetTop;
    if (
      this.unit.Coordinates__Latitude__s &&
      this.unit.Coordinates__Longitude__s
    ) {
      this.mapComponent.setParentElement(this.parentContainer);
    }
    if (!this.isMobile && !(!this.hasRates && this.nb)) {
      this.drawerPositionTop =
        this.topLine.nativeElement.getBoundingClientRect().top;
    }
    this.favoriteUnit = this.favoritesService.isFavorite({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.favoritesSub = this.favoritesService.favorites$.subscribe(
      (newList) => {
        this.favoriteUnit = this.favoritesService.isFavorite({
          unit: this.unit.externalId,
          Name: this.unit.Name,
        });
      }
    );
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    if (!this.isMobile && !(!this.hasRates && this.nb)) {
      const movingTop = this.tr.nativeElement.getBoundingClientRect().top;
      const movingBottom = this.tr.nativeElement.getBoundingClientRect().bottom;
      this.bottomLine1 =
        this.bottomDivider.nativeElement.getBoundingClientRect().bottom +
        window.pageYOffset;
      this.drawerPositionTop =
        this.topLine.nativeElement.getBoundingClientRect().top +
        window.pageYOffset;
      const widgetHeight = movingBottom - movingTop;

      if (window.pageYOffset > this.drawerPositionTop) {
        this.sticky = 'STICKY';
      }
      if (window.pageYOffset < this.drawerPositionTop) {
        this.sticky = 'TOP';
      }
      if (window.pageYOffset + widgetHeight + 86 > this.bottomLine1) {
        this.sticky = 'BOTTOM';
      }
    }
  }

  dateToUTC(d: Date): Date {
    // to prevent date change at HttpRequest
    const tmpDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    return new Date(tmpDate);
  }

  reset() {
    this.quote = null;
  }

  enquiryNow() {
    /* Don't have additional check here. User can send enquiry even if data range is incorrect */
    let rent = 0;
    if (
      this.quote &&
      this.quote.quoteInntopia &&
      this.quote.quoteInntopia.rent
    ) {
      rent = this.quote.quoteInntopia.rent;
    } else if (this.quote && this.quote.quoteMM && this.quote.quoteMM.Rent__c) {
      rent = this.quote.quoteMM.TotalRent__c;
    }

    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          arrival: this.quote && this.quote.arrival ? this.quote.arrival : null,
          departure:
            this.quote && this.quote.departure ? this.quote.departure : null,
          adults: this.quote && this.quote.adults ? this.quote.adults : null,
          children:
            this.quote && this.quote.children ? this.quote.children : null,
          value: rent,
        },
      },
      autoFocus: false,
      panelClass: 'inquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }

  keepQuote(quote: QuoteData) {
    this.quote = quote;
    if (!this.view_item_event_submitted) {
      this.view_item_event_submitted = true;
      let value = 0;
      if (quote.quoteMM) {
        value = quote.quoteMM.Discounted_Rent__c
          ? quote.quoteMM.Discounted_Rent__c
          : quote.quoteMM.TotalRent__c;
      } else if (quote.quoteInntopia) {
        value = quote.quoteInntopia.rent;
      }

      this.eventService.sendViewItemEventGA({
        UnitName: this.unit.Name,
        currency: 'USD',
        value: value,
        items: [
          {
            item_id: this.unit.externalId,
            item_name: this.unit.Name,
          },
        ],
      });
    }
  }

  bookNow() {
    // Check input. Submit input data to booking widget only if it's correct
    // TODO make same quote for mobile version

    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          quoteData: this.quote,
        },
      },
      panelClass: 'unit-rates-container',
      maxWidth: this.isMobile ? '96vw' : 'auto',
      width: this.isMobile ? '96%' : 'auto',
    });
  }

  getPromotions() {
    if (this.processedDeals.length === 0) {
      this.unitService
        .getPromotions(
          this.unit.isInntopiaUnit__c
            ? this.unit.inntopiaSupplierId__c
            : '999999',
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : '1',
          this.unit.externalId
        )
        .subscribe((response: any) => {
          this.processedDeals = [];
          const availableDeals = response;
          let i = 0;
          while (availableDeals && i < availableDeals.length) {
            const promo: InntopiaPromotions = {
              Name: availableDeals[i].Name,
              PromotionCode__c: availableDeals[i].PromotionCode__c,
              Description__c: availableDeals[i].Description__c,
              ValidStart__c: availableDeals[i].ValidStart__c,
              ValidEnd__c: availableDeals[i].ValidEnd__c,
              BookByDate__c: availableDeals[i].BookByDate__c,
              Details: [],
            };
            const promoDetails: PromoCondition = {
              Discount__c: availableDeals[i].Discount__c,
              MinNights__c: availableDeals[i].MinNights__c,
              MaxNights__c: availableDeals[i].MaxNights__c,
            };
            promo.Details.push(promoDetails);
            while (
              i + 1 < availableDeals.length &&
              availableDeals[i + 1].Name === promo.Name &&
              availableDeals[i + 1].ValidStart__c === promo.ValidStart__c &&
              availableDeals[i + 1].ValidEnd__c === promo.ValidEnd__c
            ) {
              const promoDetailsMore: PromoCondition = {
                Discount__c: availableDeals[i + 1].Discount__c,
                MinNights__c: availableDeals[i + 1].MinNights__c,
                MaxNights__c: availableDeals[i + 1].MaxNights__c,
              };
              promo.Details.push(promoDetailsMore);
              i++;
            }
            this.processedDeals.push(promo);
            i++;
          }
        });
    }
  }

  saveFavorite() {
    console.log('saveFavorite');
    this.favoriteUnit = true;
    this.favoritesService.add({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
  }

  removeFavorite() {
    console.log('removeFavorite');
    this.favoriteUnit = false;
    this.favoritesService.remove({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
  }

  ngOnDestroy() {
    if (this.favoritesSub) this.favoritesSub.unsubscribe();
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }
}
