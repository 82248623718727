<div class="search-listing-page">
  <twbooking-top-actions></twbooking-top-actions>
  <div class="search-params-container">
    <mat-chip-listbox #searchParams>
      <mat-chip-option
        *ngIf="complexQuery.UnitName"
        [removable]="true"
        class="tw-snackbar-container"
        (removed)="remove('UnitName')"
      >
        {{ complexQuery.UnitName }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.PropertyType"
        [removable]="true"
        class="tw-snackbar-container"
        (removed)="remove('PropertyType')"
      >
        {{ complexQuery.PropertyType }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.PropertyCollection"
        [removable]="true"
        class="tw-snackbar-container"
        (removed)="remove('PropertyCollection')"
      >
        {{ complexQuery.PropertyCollection }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      <mat-chip-option
        *ngIf="complexQuery.Village"
        [removable]="true"
        class="tw-snackbar-container"
        (removed)="remove('Village')"
      >
        {{ complexQuery.Village }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.rateBottom || complexQuery.rateTop"
        [removable]="complexQuery.rateBottom || complexQuery.rateTop"
        class="tw-snackbar-container"
        (removed)="remove('Rates')"
      >
        <span *ngIf="!complexQuery.rateBottom">Cost per night</span>
        <span *ngIf="complexQuery.rateBottom">
          ${{ complexQuery.rateBottom
          }}<span *ngIf="complexQuery.rateTop >= 10000">+</span>
          <span *ngIf="complexQuery.rateTop <= 10000">
            &nbsp;&mdash;&nbsp;${{ complexQuery.rateTop }}</span
          >
          &nbsp;per night
        </span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.Bedrooms"
        [removable]="true"
        class="tw-snackbar-container"
        (removed)="remove('Bedrooms')"
      >
        {{ complexQuery.Bedrooms }}+ bedrooms
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.PetFriendly"
        [removable]="complexQuery.PetFriendly"
        class="tw-snackbar-container"
        (removed)="remove('PetFriendly')"
      >
        Pet Friendly
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      <mat-chip-option
        *ngIf="complexQuery.DisplayRates"
        [removable]="complexQuery.DisplayRates"
        class="tw-snackbar-container"
        (removed)="remove('DisplayRates')"
      >
        With Rates
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      @for (amenity of selectedAmenities; track amenity.key) {

      <mat-chip-option
        [removable]="amenity"
        class="tw-snackbar-container"
        (removed)="remove(amenity.key, amenity)"
      >
        {{ amenity.viewValue }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>

      }
    </mat-chip-listbox>
  </div>
  <div class="new-brand-background complex-container">
    <div *ngIf="!noResult" class="grid-container">
      <div class="complex-title-all">
        <div class="category-title white-color">
          <h2>Complexes</h2>
          <h5 class="white-color">(Hotels, resorts, lodges, etc)</h5>
        </div>
        <div class="complex-navigation">
          <div class="view-all-container">
            <a
              class="white-color"
              [routerLink]="['/search-results']"
              routerLinkActive="router-link-active"
              [queryParams]="searchQuery"
              >View all search results</a
            >
          </div>
        </div>
      </div>

      <div *ngIf="!complexDisplay" class="padding-top">
        <mat-grid-list
          class="ttw-grid"
          [cols]="columnNum"
          rowHeight="38:48"
          gutterSize="4%"
        >
          @for (item of [].constructor(columnNum); track $index;) {
          <mat-grid-tile class="unit-summary-card-shadow">
            <twbooking-summary-ghost
              [@fadeOut]="complexDisplay"
            ></twbooking-summary-ghost>
          </mat-grid-tile>
          }
        </mat-grid-list>
      </div>
      <div
        *ngIf="complexDisplay | async as complexDisplayList"
        class="padding-top"
      >
        <div
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="100"
          (scrolled)="onScroll($event)"
        >
          <mat-grid-list
            class="ttw-grid"
            [cols]="columnNum"
            rowHeight="38:48"
            gutterSize="4%"
          >
            @for (complex of complexDisplayList | slice : 0 : complexLimit;
            track identifyComplex($index, complex); let index = $index) {
            <mat-grid-tile class="unit-summary-card-shadow">
              <twbooking-complex-summary
                *ngIf="complex"
                [@fadeIn]="complexDisplay"
                [complex]="complex"
                [complexQuery]="complexQuery"
                [columnNum]="columnNum"
              >
              </twbooking-complex-summary>
            </mat-grid-tile>
            }
          </mat-grid-list>
        </div>
        <div *ngIf="!done" class="pagination-spinner">
          <mat-spinner diameter="35"></mat-spinner>
        </div>
        <!--Infinite Scrolling-->
      </div>
    </div>

    <div class="grid-container" *ngIf="!loadingComplexes && noResult">
      <h1 class="mat-h1">
        No properties match the criteria that you have entered.
      </h1>
      <p>
        Please try
        <a
          class="show-more-link white-color"
          style="text-decoration: underline"
          (click)="openSearchDialog()"
          >adjusting</a
        >
        your search filters or click
        <a
          [routerLink]="['/units']"
          routerLinkActive="router-link-active"
          class="show-more-link white-color"
          >here</a
        >
        to view our inventory.
      </p>
      <p *ngIf="myStay === 1 || myStay === 2">
        You are searching for a {{ myStay }} night trip, please note that much
        of our inventory requires longer (3-4 night) minimum stays and we may
        have discounted rates for longer stays. Our Personal Travel Designers
        are standing by to assist you with finding homes where short stays are
        available.
      </p>
    </div>
  </div>

  <twbooking-footer></twbooking-footer>
</div>
