<div class="chat-container">
  <div class="modal-headline">
    <div class="pseudo-element"></div>
    <img class="logo" src="/assets/logo.svg" alt="TTW logo" />

    <div class="action-buttons">
      <div *ngIf="volume === 'On'" class="modal-headline-close">
        <button mat-icon-button class="new-brand-color" (click)="volumeOff()">
          <mat-icon>volume_up</mat-icon>
        </button>
      </div>
      <div
        *ngIf="volume === 'Off'"
        class="modal-headline-close new-brand-color"
      >
        <button mat-icon-button (click)="volumeOn()">
          <mat-icon>volume_off</mat-icon>
        </button>
      </div>
      <div class="modal-headline-close new-brand-color">
        <button mat-icon-button (click)="minimize()">
          <mat-icon>minimize</mat-icon>
        </button>
      </div>
      <div class="modal-headline-close new-brand-color">
        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content #scrollContainer class="chat-history white-background">
    <div
      *ngIf="!ptdInDialog && !timeoutReached"
      class="connect-to-ptd new-brand-color"
    >
      <p class="new-brand-color lexend-400">
        Waiting for Personal Travel Designer <span class="typing-dot1">.</span
        ><span class="typing-dot2">.</span><span class="typing-dot3">.</span>
      </p>
      <mat-progress-bar class="ttw-progress" mode="indeterminate">
      </mat-progress-bar>
    </div>

    <div class="support-replica" *ngIf="supportTyping">
      <p>
        <span class="typing-dot1">.</span><span class="typing-dot2">.</span
        ><span class="typing-dot3">.</span>
      </p>
    </div>
    @for (dialogReplica of dialogHistory; track replicaIndex; let replicaIndex =
    $index) {
    <div #myReplica>
      <div *ngIf="dialogReplica.author !== 'system'">
        <div *ngIf="dialogReplica.author === 'user'" class="user-replica">
          <p class="brand-offwhite-background new-brand-color">
            {{ dialogReplica.replica }}
          </p>
        </div>

        <div
          *ngIf="
            dialogReplica.author !== 'user' &&
            ((dialogReplica.replica && dialogReplica.replica !== '') ||
              dialogReplica.link)
          "
          class="support-replica new-brand-color"
        >
          <div class="ttw-ava" *ngIf="dialogReplica.author === 'bot'"></div>
          <div
            class="ttw-ava"
            *ngIf="dialogReplica.author === 'ptd' && !dialogReplica.authorPhoto"
          >
            <img src="/assets/icons/icon-72x72.png" width="24px" alt="TTW" />
          </div>
          <div
            class="ttw-ava"
            *ngIf="dialogReplica.author === 'ptd' && dialogReplica.authorPhoto"
          >
            <img [src]="dialogReplica.authorPhoto" width="32px" alt="Avatar" />
          </div>
          <div class="support-text">
            <div
              class="agent-name"
              *ngIf="
                dialogReplica.author !== 'user' &&
                dialogReplica.author !== 'system'
              "
            >
              {{ dialogReplica.authorName ? dialogReplica.authorName : "" }}

              <span
                *ngIf="
                  dialogReplica.replica &&
                  dialogReplica.replica === 'has joined the chat'
                "
              >
                has joined the chat</span
              >
            </div>
            <p
              class="brand-accent-background p-ptd new-brand-color"
              *ngIf="
                dialogReplica.replica &&
                dialogReplica.replica !== '' &&
                dialogReplica.replica !== 'has joined the chat' &&
                dialogReplica.replica !== 'User has joined the chat.'
              "
            >
              {{ dialogReplica.replica }}
            </p>

            <a
              class="no-decoration"
              *ngIf="
                dialogReplica.link &&
                !dialogReplica.externalLink &&
                dialogReplica.showUnitCard
              "
              [routerLink]="dialogReplica.link"
              routerLinkActive="router-link-active"
            >
              <div
                *ngIf="dialogReplica.showUnitCard"
                class="unit-card figure-shadow"
              >
                <img
                  src="{{ dialogReplica.showUnitCard.URL__c }}"
                  alt="{{ dialogReplica.showUnitCard.MarketingHeadline__c }}"
                  width="100%"
                />
                <div class="uppercase-header new-brand-color">
                  {{ dialogReplica.linkDescription }}
                </div>
              </div>
            </a>
            <!--Internal link-->
            <p
              *ngIf="
                dialogReplica.link &&
                !dialogReplica.externalLink &&
                !dialogReplica.showUnitCard
              "
              [class.brand-accent-background]="dialogReplica.author !== 'user'"
              [class.brand-offwhite-background]="
                dialogReplica.author === 'user'
              "
            >
              &#9758;&nbsp;&nbsp;
              <a
                [class.brand-accent-background]="
                  dialogReplica.author !== 'user'
                "
                [class.brand-offwhite-background]="
                  dialogReplica.author === 'user'
                "
                class="white-link"
                [routerLink]="[dialogReplica.link]"
                routerLinkActive="router-link-active"
                [queryParams]="dialogReplica.searchQuery"
              >
                {{ dialogReplica.linkDescription }}
              </a>
            </p>

            <!--External link-->
            <p
              *ngIf="dialogReplica.link && dialogReplica.externalLink"
              [class.brand-accent-background]="dialogReplica.author !== 'user'"
              [class.brand-offwhite-background]="
                dialogReplica.author === 'user'
              "
            >
              &#9758;&nbsp;&nbsp;
              <a
                [href]="[dialogReplica.link]"
                target="_blank"
                rel="noreferrer noopener"
                routerLinkActive="router-link-active"
                class="white-link"
              >
                {{ dialogReplica.linkDescription }}
              </a>
            </p>
          </div>
          <!-- End of the support text-->
        </div>
      </div>
      <!--not system replica-->
    </div>
    <!--let dialogReplica of dialogHistory-->
    }
  </mat-dialog-content>

  <mat-dialog-actions class="space-between">
    <mat-card appearance="outlined" class="action-full">
      <textarea
        class="brand-dark-caret bordered-textarea brand-offwhite-background"
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="3"
        maxlength="255"
        [formControl]="userText"
        (keyup.enter)="submitUserText()"
      ></textarea>
    </mat-card>
    <button
      type="button"
      mat-icon-button
      class="new-brand-background"
      (click)="submitUserText()"
    >
      <mat-icon class="white-color">send</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
