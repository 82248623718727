import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './../api.service';
import { AuthenticatedApiService } from './authenticated-api.service';

@Injectable()
export class CheckoutService {
  constructor(
    private apiService: ApiService,
    private authApi: AuthenticatedApiService
  ) {}

  encrypt(form): Observable<any> {
    return this.apiService.httpCall('/api/encrypt', form).pipe(
      map((res) => {
        console.log(res);
        return res;
      })
    );
  }
}
