import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { linksList, TTWLink } from './menu-links';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AuthService, Role } from '../../services/auth.service';
import {
  newbrand,
  stagingwtlbl,
  mm,
  mainsite,
  amanda,
  smartflyer,
  staging,
  jettsettravel,
  packhappytravel,
  firstteetravel,
  localhost,
} from '../../store/models/hostings';
import { PaymentsService } from 'src/app/services/payments.service';
import { TooltipDirective } from 'src/app/shared/ui/tooltip/tooltip.directive';
import { LodgingQuoteService } from 'src/app/services/lodging-quote.service';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchParamsService } from 'src/app/services/search-params.service';

@Component({
  selector: 'twbooking-sitenav',
  templateUrl: './site-nav.component.html',
  styleUrls: ['./site-nav.component.scss'],
})
export class SiteNavComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('drawer') drawer;
  @ViewChild('search') public search;
  @ViewChild(TooltipDirective) tooltip: TooltipDirective;
  @ViewChildren(MatMenuTrigger) menuTriggerRefs!: QueryList<MatMenuTrigger>;
  private closeTimeoutIds: { [key: number]: any } = {};
  hideChat = false;

  authorized = false;
  userRole: Role;
  routerSubscription: Subscription;
  userSub: Subscription; // TODO: remove this, substitute with Role
  roleSub: Subscription;
  statusSub: Subscription;
  userVerifiedSub: Subscription;
  bookingWithPayment: string = null;
  logoAlt = '';

  guestPortal = false;
  mode:
    | 'basic'
    | 'MM'
    | 'Laurie'
    | 'Amanda'
    | 'smartflyer'
    | 'jettsettravel'
    | 'packhappytravel'
    | 'firstteetravel'
    | 'Neutral' = 'basic';

  display = false;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  reducedMenu = false;

  logoSrc: String;

  urlBase: String;
  links: TTWLink[];
  linksNoParams: TTWLink[];

  pendingPayments = 0;
  lodgingQuote = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private sidenavService: SidenavService,
    private paymentsService: PaymentsService,
    private lodgingQuoteService: LodgingQuoteService,
    private searchParamsService: SearchParamsService
  ) {
    this.routerSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;

        this.hideChat = url.includes('unit-details');

        this.drawer.close();
        this.search.close();
      }
    });

    if (
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    ) {
      this.mode = 'basic';
      this.logoSrc = 'assets/logo.svg';
      this.urlBase = 'https://www.thetravelwhisperer.com';
      this.links = linksList.TTW;
      this.linksNoParams = linksList.TTW;
      this.logoAlt = 'The Travel Whisperer';
    } else if (window.location.hostname.includes(mm)) {
      this.logoSrc = 'assets/mm_big.png';
      this.urlBase = 'https://www.mountainmanagement.com';
      this.links = linksList.MM;
      this.linksNoParams = linksList.MM;
      this.mode = 'MM';
      this.logoAlt = 'Mountain Management';
    } else if (
      window.location.hostname.includes(newbrand) ||
      window.location.hostname.includes(stagingwtlbl)
    ) {
      this.mode = 'Laurie';
      this.logoSrc = 'assets/logolaurie.png';
      this.urlBase = 'https://www.vacationsbylaurie.com/';
      this.links = linksList.Laurie;
      this.linksNoParams = linksList.Laurie;
      this.logoAlt = 'Vacations by Laurie';
    } else if (window.location.hostname.includes(amanda)) {
      this.mode = 'Amanda';
      this.logoSrc =
        'https://images.thetravelwhisperer.com/QoUr5-FutWpUJCOe811ZF-sSaHw=/0x0/storage.googleapis.com/ttw-marketing-images/Logos/AmandaLogo.gif';
      this.urlBase = 'http://www.amandastravels.com/';
      this.links = linksList.Amanda;
      this.linksNoParams = linksList.Amanda;
      this.logoAlt = 'Amanda Travel';
    } else if (window.location.hostname.includes(smartflyer)) {
      this.mode = 'smartflyer';
      this.logoSrc =
        'https://images.thetravelwhisperer.com/6SXxM3d-cBNcSVrOM_SwtSHojAY=/0x0/storage.googleapis.com/ttw-marketing-images/Logos/smartflyer_logo.png';
      this.urlBase = 'https://smartflyer.com/agent/smartflyer-nussbaum/';
      this.links = linksList.smartflyer;
      this.linksNoParams = linksList.smartflyer;
      this.logoAlt = 'Smartflyer';
    } else if (window.location.hostname.includes(jettsettravel)) {
      this.mode = 'jettsettravel';
      this.logoSrc =
        'https://images.thetravelwhisperer.com/UrQf_nhsMD5WrSvdzjpCluPh5rk=/0x0/storage.googleapis.com/ttw-marketing-images/Logos/JettSetlogo.png';
      this.urlBase = 'https://www.jettsettravel.us/Page/Home';
      this.links = linksList.jettsettravel;
      this.linksNoParams = linksList.jettsettravel;
      this.logoAlt = 'Jett Set Travel';
    } else if (window.location.hostname.includes(packhappytravel)) {
      this.mode = 'packhappytravel';
      this.logoSrc =
        'https://images.thetravelwhisperer.com/OZADITCNdzeU_tRsijQqgVE_L3w=/0x0/storage.googleapis.com/ttw-marketing-images/Logos/pack-happy-travel-logo.png';
      this.urlBase = 'https://www.jettsettravel.us/Page/Home';
      this.links = linksList.packhappytravel;
      this.linksNoParams = linksList.packhappytravel;
      this.logoAlt = 'Pack Happy Travel';
    } else {
      this.mode = 'basic';
      this.logoSrc = 'assets/TTW-logo.jpg';
      this.urlBase = 'https://www.thetravelwhisperer.com';
      this.links = linksList.TTW;
      this.linksNoParams = linksList.TTW;
    }
  }

  ngOnInit() {
    this.searchParamsService.searchParams$.subscribe((params) => {
      let startDate = null;
      let endDate = null;
      let adults = null;
      let children = null;
      if (params && params['StartDate']) {
        startDate = params['StartDate'];
      }
      if (params && params['EndDate']) {
        endDate = params['EndDate'];
      }
      if (params && params['Adults']) {
        adults = params['Adults'];
      }
      if (params && params['Children']) {
        children = params['Children'];
      }
      if (
        this.mode == 'basic' &&
        ((startDate && endDate) || adults || children)
      ) {
        this.updateLinks({
          StartDate: startDate,
          EndDate: endDate,
          Adults: adults,
          Children: children,
        });
      }
    });
    this.paymentsService.checkPendingPayments().subscribe((result) => {
      console.log('pending payments result = ', result);
    });
    this.lodgingQuoteService.fetchLodgingQuote().subscribe((result) => {
      if (
        result &&
        result.lodgingQuote &&
        result.lodgingQuote.length &&
        result.lodgingQuote !== 'loading'
      ) {
        this.lodgingQuote = 1;
      } else {
        this.lodgingQuote = 0;
      }
    });
    this.breakpointObserver
      .observe(['(min-width: 1030px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.reducedMenu = false;
        } else {
          this.reducedMenu = true;
        }
      });
    if (this.mode === 'basic' || this.mode === 'MM') {
      this.roleSub = this.authService.userRole$.subscribe((role: Role) => {
        this.userRole = role;
        this.paymentsService.resetPayments();
        if (this.userRole === 'guest' || this.userRole === 'ptd') {
          this.guestPortal = true;
          this.paymentsService.pendingPayments$.subscribe((pending) => {
            this.pendingPayments = pending;
            if (pending && pending > 0) {
              this.bookingWithPayment =
                this.paymentsService.bookingWithPayments$.getValue();
            }
          });
        } else {
          this.guestPortal = false;
          this.pendingPayments = 0;
          this.bookingWithPayment = null;
        }
      });
      this.userSub = this.authService.userEmail$.subscribe((email) => {
        if (email !== null) {
          this.authorized = true;
          console.log('authorized = true');
        } else {
          this.authorized = false;
          console.log('authorized = false');
        }
      });
    } else {
      this.userRole = 'anonymous';
      this.authorized = false;
    }
  }

  updateLinks(params: {
    StartDate: string | null;
    EndDate: string | null;
    Adults: number | null;
    Children: number | null;
  }) {
    let newLinks: TTWLink[] = [];
    this.linksNoParams.forEach((link: TTWLink) => {
      let updatedurl = this.addQueryParams(link.url, params);
      let newLink: TTWLink = {
        text: link.text,
        url: updatedurl,
        items: [],
      };
      if (link.items && link.items.length) {
        link.items.forEach(
          (item: { text: string; url: string; sametab?: boolean }) => {
            let newItem = { ...item };
            if (item.url && !item.sametab) {
              newItem.url = this.addQueryParams(item.url, params);
            }
            newLink.items.push(newItem);
          }
        );
      }
      newLinks.push(newLink);
    });

    this.links = newLinks;
  }

  addQueryParams(
    url: string,
    params: {
      StartDate: string | null;
      EndDate: string | null;
      Adults: number | null;
      Children: number | null;
    }
  ): string {
    // let updatedUrl = url;
    let updatedUrl = new URL(url); // Use URL constructor
    const queryParams: any = {};

    if (params.StartDate) {
      updatedUrl.searchParams.set('StartDate', params.StartDate);
    }
    if (params.EndDate) {
      updatedUrl.searchParams.set('EndDate', params.EndDate);
    }
    if (params.Adults) {
      updatedUrl.searchParams.set('Adults', params.Adults.toString());
    }
    if (params.Children) {
      updatedUrl.searchParams.set('Children', params.Children.toString());
    }

    let finalUrl = updatedUrl.toString();

    // Remove the leading slash if it exists
    if (finalUrl.includes('/?')) {
      finalUrl = finalUrl.replace('/?', '?');
    }

    return finalUrl;
  }

  ngAfterViewInit() {
    this.statusSub = this.authService.status$.subscribe((status) => {
      if (status && status.error) {
        this.openIncognitoSignInWarning();
      }
    });
    this.sidenavService.setSidenav(this.search);
  }

  logOut() {
    this.authService.signOut();
  }

  openProfile() {
    this.router.navigate(['/guest/profile']);
  }
  openFavorites() {
    this.router.navigate(['favorites']);
  }
  openBookings() {
    this.router.navigate(['/guest/reservations']);
  }

  openPayments() {
    let queryParams = { tab: 'Payments' };
    this.router.navigate(
      ['/guest/reservation-details/' + this.bookingWithPayment],
      { queryParams: queryParams }
    );
  }

  openLodgingQuote() {
    this.router.navigate(['/guest/lodging-quote']);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.roleSub.unsubscribe();
    this.statusSub.unsubscribe();
    this.routerSubscription.unsubscribe();

    if (this.userVerifiedSub) {
      this.userVerifiedSub.unsubscribe();
    }
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {},
      panelClass: 'search-dialog-container',
      maxWidth: '96vw',
      maxHeight: '85vh',
      width: 'auto',
    });
  }

  openIncognitoSignInWarning() {
    this.tooltip.openTooltip();
    /*setTimeout(() => {
      console.log('timeout passed, closing');
      this.tooltip.closeTooltip();
    }, 7000);*/
  }

  openMenu(index: number) {
    this.clearCloseTimeout(index);
  }

  startCloseTimeout(index: number, menulink: MatMenuTrigger) {
    const menuTriggerArray = this.menuTriggerRefs.toArray();
    this.closeTimeoutIds[index] = setTimeout(() => {
      if (menulink) {
        menulink.closeMenu();
      }
    }, 30);
  }

  clearCloseTimeout(index: number) {
    if (this.closeTimeoutIds[index]) {
      clearTimeout(this.closeTimeoutIds[index]);
      this.closeTimeoutIds[index] = null;
    }
  }
}
