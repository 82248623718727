<div
  class="footer-container"
  [class.new-brand-background]="mode === 'basic'"
  [class.brand-accent-background]="mode !== 'basic'"
>
  <div *ngIf="mode === 'basic'" class="footer-data">
    <div class="copyright-note">
      © Copyright The Travel Whisperer 2015 - 2024
    </div>
    <div class="social-icons">
      <a
        class="one-icon"
        target="_blank"
        href="https://www.instagram.com/thetravelwhispererofficial/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-instagram"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/thetravelwhispererofficial"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://twitter.com/TravelWhisper1"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-twitter"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://www.linkedin.com/company/thetravelwhisperer/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-linkedin"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://www.youtube.com/channel/UCLnBrQf1ZvUuN8KcWKDmocg"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-youtube"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="mode === 'MM'" class="footer-data">
    <div class="copyright-note">© 2020-2024 Mountain Management.</div>
    <div class="social-icons">
      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/Mountain-Management-1630394047208833/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="mode === 'neutral'" class="footer-data">
    <div class="copyright-note">© 2020-2024</div>
  </div>

  <div *ngIf="mode === 'Amanda'" class="footer-data">
    <div class="copyright-note">
      © 2020-2024 AMANDAS TRAVELS
      <a href="mailto:amanda@amandastravels.com" class="copyright-note"
        >amanda&#64;amandastravels.com</a
      >
    </div>

    <div class="social-icons">
      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/amandastormtravels"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://twitter.com/amandastravels"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-twitter"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="mode === 'smartflyer'" class="footer-data">
    <div class="copyright-note">© 2020-2024 SmartFlyer</div>

    <div class="social-icons">
      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/smartflyernyc"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://www.instagram.com/thesmartflyer/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-instagram"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="mode === 'jettsettravel'" class="footer-data">
    <div class="copyright-note">© 2020-2024 Jett Set Travel</div>

    <div class="social-icons">
      <a href="mailto:info@jettsettravel.us" class="copyright-note"
        >info&#64;jettsettravel.us</a
      >
    </div>
  </div>

  <div *ngIf="mode === 'packhappytravel'" class="footer-data">
    <div class="copyright-note">© 2020-2024 Pack Happy Travel LLC</div>

    <div class="social-icons">
      <a href="mailto:marti@packhappytravel.com"
        >marti&#64;packhappytravel.com</a
      >

      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/packhappytravelco/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://www.instagram.com/packhappytravel/"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-instagram"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://twitter.com/PackHappyTravel"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-twitter"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="mode === 'Laurie'" class="footer-data">
    <div class="copyright-note">
      © 2020-2024 Vacations by Laurie All rights reserved
    </div>
    <div class="social-icons">
      <a
        class="one-icon"
        target="_blank"
        href="https://www.facebook.com/smartflyernyc"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-facebook"></mat-icon>
      </a>
      <a
        class="one-icon"
        target="_blank"
        href="https://twitter.com/laurie_ebeling"
      >
        <mat-icon fontSet="social" fontIcon="social-ttw-twitter"></mat-icon>
      </a>
    </div>
  </div>
</div>
