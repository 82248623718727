import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'twbooking-summary-carousel',
  templateUrl: './summary-carousel.component.html',
  styleUrls: ['./summary-carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SummaryCarouselComponent implements OnChanges {
  @Input() images: any[];
  @Input() show: number[];
  @Input() slug: string;
  @Input() searchQuery: any;
  @Input() complex = false;
  @Output() imageIndexEmitter = new EventEmitter<number>();

  currentImage = 0;

  constructor() {}

  ngOnChanges(changes) {
    if (changes.slug) {
      this.slug = changes.slug.currentValue;
    }
    if (changes.images) {
      this.images = changes.images.currentValue;
    }
    if (changes.show) {
      this.currentImage = changes.show.currentValue;
      this.preloadNext(this.currentImage);
      this.preloadPrev(this.currentImage);
    }
    if (changes.complex) {
      this.complex =
        changes.complex.currentValue.toLocaleLowerCase() === 'true'
          ? true
          : false;
    }
  }

  preloadNext(currentImage) {
    let nextImage = currentImage + 1;
    if (nextImage >= this.images.length) {
      nextImage = 0;
    }
    new Image().src = this.images[nextImage].URL__c;
  }
  preloadPrev(currentImage) {
    let prevImage = currentImage--;
    if (prevImage < 0) {
      prevImage = this.images.length - 1;
    }
    new Image().src = this.images[prevImage].URL__c;
  }

  showPrev() {
    this.currentImage--;
    if (this.currentImage < 0) {
      this.currentImage = this.images.length - 1;
    }
    this.imageIndexEmitter.emit(this.currentImage);
    this.preloadPrev(this.currentImage);
  }
  showNext() {
    this.currentImage++;
    if (this.currentImage >= this.images.length) {
      this.currentImage = 0;
    }
    this.imageIndexEmitter.emit(this.currentImage);
    this.preloadNext(this.currentImage);
  }

  showAmenity(imageIndex) {
    if (imageIndex) {
      this.currentImage = imageIndex;
    }
  }

  onSwipeLeft(evt) {
    this.showPrev();
  }
  onSwipeRight(evt) {
    this.showNext();
  }

  onImgError(event, url: string, type: string, slug: string) {
    console.error('Summary image load error', url, type, ': ', slug);
  }
}
