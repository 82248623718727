import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../store/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public userData: BehaviorSubject<UserData>;
  quote: BehaviorSubject<any>;

  constructor() {
    this.userData = new BehaviorSubject(null);
  }

  update(newUserData: UserData) {
    this.userData.next(newUserData);
  }

  add(newUserParam: UserData) {
    if (newUserParam.email) {
      this.userData.next({ ...this.userData.value, email: newUserParam.email });
    }
    if (newUserParam.firstName) {
      this.userData.next({
        ...this.userData.value,
        firstName: newUserParam.firstName,
      });
    }
    if (newUserParam.lastName) {
      this.userData.next({
        ...this.userData.value,
        lastName: newUserParam.lastName,
      });
    }
    if (newUserParam.phone) {
      this.userData.next({ ...this.userData.value, phone: newUserParam.phone });
    }
    if (newUserParam.addressLine1) {
      this.userData.next({
        ...this.userData.value,
        addressLine1: newUserParam.addressLine1,
      });
    }
    if (newUserParam.addressLine2) {
      this.userData.next({
        ...this.userData.value,
        addressLine2: newUserParam.addressLine2,
      });
    }
    if (newUserParam.state) {
      this.userData.next({ ...this.userData.value, state: newUserParam.state });
    }
    if (newUserParam.country) {
      this.userData.next({
        ...this.userData.value,
        country: newUserParam.country,
      });
    }
    if (newUserParam.country1) {
      this.userData.next({
        ...this.userData.value,
        country1: newUserParam.country1,
      });
    }
    if (newUserParam.zip) {
      this.userData.next({ ...this.userData.value, zip: newUserParam.zip });
    }
    if (newUserParam.city) {
      this.userData.next({ ...this.userData.value, city: newUserParam.city });
    }

    if (newUserParam.segment) {
      this.userData.next({
        ...this.userData.value,
        segment: newUserParam.segment,
      });
    }
  }

  get(): any {
    return this.userData.value;
  }
}
