<div class="plus-minus-container-big">
  <div class="plus-minus-container">
    <div class="field-name-description-below">
      <div class="brand-dark-color plus-minus-field-name">{{ fieldName }}</div>
      <div class="label-like hint-color hint-field">{{ fieldHint }}</div>
    </div>
    <div class="plus-minus-buttons">
      <button
        type="button"
        class="plus-minus-button brand-dark-color"
        [class.disabled-color]="value <= min"
        [class.hint-border-color]="value <= min"
        (click)="subtractValue()"
      >
        <span>&minus;</span>
      </button>
      <span class="value-big">{{ value }}</span>
      <button
        type="button"
        class="plus-minus-button brand-dark-color"
        [class.disabled-color]="value >= max"
        [class.hint-border-color]="value >= max"
        (click)="addValue()"
      >
        <span>&#43;</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
