import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'twbooking-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(private unitService: UnitsService, private router: Router) {}

  ngOnInit(): void {}

  close(result) {
    if (result) {
      this.router.navigate(['/booking-enquiry-thank-you']);
      this.unitService.startBooking(result);
    }
  }
}
