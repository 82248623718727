import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ttwNoDoubleTapZoom]',
})
export class NoDoubleTapDirective {
  constructor() {}
  @HostListener('dblclick', ['$event'])
  clickEvent(event) {
    event.preventDefault();
  }
}
