import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 30000;
@Injectable({
  providedIn: 'root',
})
export class SearchCacheService {
  cache = new Map();
  ratesCache = new Map();

  // https://blog.fullstacktraining.com/caching-http-requests-with-angular/
  get(url: string): HttpResponse<any> | undefined {
    const cached = this.cache.get(url);
    // const isExpired = cached.lastRead < (Date.now() - maxAge);
    // const expired = isExpired ? 'expired ' : '';

    if (!cached) {
      return undefined;
    }
    return cached.response;
  }

  put(url: string, response: any): void {
    const entry = { response, lastRead: Date.now() };
    this.cache.set(url, entry);

    /*const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
    */
  }

  getRate(key: string): HttpResponse<any> | undefined {
    const cached = this.ratesCache.get(key);
    if (!cached) {
      return undefined;
    }
    return cached.response;
  }

  putRate(key: string, response: any): void {
    const entry = { response, lastRead: Date.now() };
    this.ratesCache.set(key, entry);
  }

  constructor() {}
}
