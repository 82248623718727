<div class="unit-details-page">
  <div class="breadcrumb-bar" *ngIf="isMobile" class="top-icons-space-between">
    <button
      class="back-button"
      mat-button
      aria-label="Back button"
      (click)="back()"
    >
      <mat-icon class="brand-dark-color">keyboard_arrow_left</mat-icon>
      <span class="back-text">Back to homes listing</span>
    </button>
  </div>

  <div *ngIf="!loading && unitExist">
    <span *ngIf="complex.ImagesProcessed">
      <twbooking-unit-gallery [unit]="complex"></twbooking-unit-gallery>
    </span>
    <div class="unit-details-content-section">
      <div *ngIf="complex" class="unit-details-content-layout">
        <div class="unit-details-tab-container">
          <div class="mobile-description">
            <div class="mobile-content">
              <a
                *ngIf="
                  complex.ImagesProcessed && complex.ImagesProcessed.length > 10
                "
                (click)="
                  showMoreImages = true; scrollToElement(moreImagesAnchor)
                "
                class="show-more-nav"
              >
                <span>Show more images</span>
                <span class="hover-arrows">
                  <mat-icon></mat-icon>
                  <mat-icon class="right-arrow-classic"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon class="right-arrow-on-hover"
                    >arrow_forward</mat-icon
                  >
                </span>
              </a>

              <div class="header-separator-left">
                <h1 class="title-heading">Overview</h1>
                <div class="separator"></div>
              </div>

              <!--
              
 <div *ngIf="shortText">
          <div
            class="standard-text"
            [innerHTML]="
              (unit?.Long_Description__c | words : 200).text | safeHtml
            "
          ></div>
          <a
            class="show-more-nav"
            *ngIf="(unit?.Long_Description__c | words : 200).isTruncated"
            (click)="shortText = !shortText"
          >
            <span>Read more about the space</span>
            <span class="hover-arrows">
              <mat-icon class="right-arrow-classic"
                >keyboard_arrow_right</mat-icon
              >
              <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
            </span>
          </a>
        </div>

-->

              <div *ngIf="shortText">
                <div
                  [innerHTML]="
                    (complex?.Long_Description__c | words : 200).text | safeHtml
                  "
                ></div>
                @if(moreThen200words){
                <a class="show-more-nav" (click)="shortText = !shortText"
                  ><span>Read more about the space</span>
                  <span class="hover-arrows">
                    <mat-icon></mat-icon>
                    <mat-icon class="right-arrow-classic"
                      >keyboard_arrow_right</mat-icon
                    >
                    <mat-icon class="right-arrow-on-hover"
                      >arrow_forward</mat-icon
                    >
                  </span>
                </a>
                }
              </div>
              <div *ngIf="!shortText">
                <div
                  [innerHTML]="complex?.Long_Description__c | safeHtml"
                ></div>
                <a class="show-more-nav" (click)="shortText = !shortText"
                  ><span>Hide description</span>
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </a>
              </div>
              <br />

              <div class="header-separator-left">
                <h1 class="title-heading">
                  Accommodations within {{ complex.MarketingHeadline__c }}
                </h1>
                <div class="separator"></div>
              </div>
              <br />
              <div
                [class.accomodation-big-container-desktop]="!isMobile"
                [class.accomodation-big-container-mobile]="isMobile"
              >
                <div
                  [class.left]="!isMobile"
                  [class.full-widget]="isMobile"
                  class="brand-dark-color brand-offwhite-background"
                >
                  <!------------------------------Quote widget simplified---------------------------------------------->
                  <div
                    *ngIf="!nb"
                    class="quote-simplified brand-dark-color brand-offwhite-background"
                  >
                    <h3 class="new-brand-color">DATES:</h3>
                    <form [formGroup]="fastRatesWidget">
                      <div class="two-in-row">
                        <mat-form-field class="widget-selector-half">
                          <input
                            matInput
                            readonly
                            [matDatepicker]="arrivalPicker"
                            placeholder="Arrival"
                            formControlName="arrivalForRates"
                            [matDatepickerFilter]="arrivalFilterFunction"
                            (dateChange)="arrivalChanged($event)"
                            (click)="arrivalPicker.open()"
                          />
                          <mat-datepicker
                            #arrivalPicker
                            [touchUi]="isMobile"
                            color="primary"
                          ></mat-datepicker>
                        </mat-form-field>
                        <div class="middle">
                          <p>&nbsp;&nbsp;&#8212;&nbsp;&nbsp;</p>
                        </div>
                        <mat-form-field class="widget-selector-half">
                          <input
                            matInput
                            readonly
                            placeholder="Departure"
                            [matDatepicker]="departurePicker"
                            formControlName="departureForRates"
                            [matDatepickerFilter]="departureFilterFunction"
                            (dateChange)="departureChanged($event)"
                            (click)="departurePicker.open()"
                          />
                          <mat-datepicker
                            #departurePicker
                            [startAt]="departureStart"
                            color="primary"
                            [dateClass]="dateClass"
                            [touchUi]="isMobile"
                          ></mat-datepicker>
                        </mat-form-field>
                      </div>
                      -->

                      <div class="two-in-row">
                        <div class="widget-selector-half">
                          <h3 class="new-brand-color">ADULTS:</h3>
                        </div>
                        <div class="middle"></div>
                        <div
                          class="widget-selector-half"
                          style="padding-left: 16px"
                        >
                          <h3 class="new-brand-color">CHILDREN:</h3>
                        </div>
                      </div>

                      <div class="two-in-row">
                        <mat-form-field class="widget-selector-half">
                          <mat-select
                            formControlName="numAdults"
                            (selectionChange)="
                              numberofGuestChanged('numAdults', $event)
                            "
                          >
                            @for (g of guests; track g){
                            <mat-option [value]="g">
                              {{ g }}
                            </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                        <div class="middle"></div>

                        <mat-form-field class="widget-selector-half">
                          <mat-select
                            formControlName="numChildren"
                            (selectionChange)="
                              numberofGuestChanged('numChildren', $event)
                            "
                          >
                            @for (g of guests_children; track $index; ) {
                            <mat-option [value]="g">
                              {{ g }}
                            </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </form>
                    <br />
                    <br />
                    <div class="buttons-row">
                      <button
                        *ngIf="!nb"
                        mat-button
                        class="brand-button"
                        (click)="inquiryNow()"
                      >
                        Inquire now
                      </button>
                      <button
                        *ngIf="!nb"
                        mat-button
                        class="brand-button right-button"
                        (click)="reset()"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <!-------------------End quote widget simplified-->
                </div>
                <div
                  *ngIf="unitDisplay | async as unitDisplayList"
                  [class.right]="!isMobile && !nb"
                  [class.full-widget]="isMobile || nb"
                >
                  @for (room of unitDisplayList | slice : 0 : unitLimit; track
                  room.externalId; let i = $index) {
                  <div>
                    <div *ngIf="showAllUnits || i <= 1">
                      <twbooking-complex-accommodation
                        [unit]="room"
                        [numAdults]="
                          +this.fastRatesWidget.controls['numAdults'].value
                        "
                        [numChildren]="
                          +this.fastRatesWidget.controls['numChildren'].value
                        "
                        [numPets]="numPets"
                        [arrivalField]="arrivalField"
                        [departureField]="departureField"
                        [isMobile]="isMobile"
                        [isSmall]="isSmall"
                        [searchQuery]="searchQuery"
                      >
                      </twbooking-complex-accommodation>
                    </div>
                  </div>
                  }
                  <div
                    *ngIf="loadingUnits"
                    [class.right]="!isMobile && !nb"
                    [class.full-widget]="isMobile || nb"
                  >
                    <div class="unit-loading-container">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <a
                    *ngIf="
                      unitDisplayList.length > 2 &&
                      (!noMoreUnits || showAllUnits === false)
                    "
                    class="show-more-nav"
                    (click)="showAllUnits = true; searchUnits()"
                    ><span>Show more accomodation options</span>
                    <span class="hover-arrows">
                      <mat-icon></mat-icon>
                      <mat-icon class="right-arrow-classic"
                        >keyboard_arrow_right</mat-icon
                      >
                      <mat-icon class="right-arrow-on-hover"
                        >arrow_forward</mat-icon
                      >
                    </span>
                  </a>
                  <a
                    *ngIf="showAllUnits"
                    class="show-more-nav"
                    (click)="showAllUnits = false"
                    ><span>Hide</span>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </a>
                </div>
                <div
                  *ngIf="noResult"
                  [class.right]="!isMobile && !nb"
                  [class.full-widget]="isMobile || nb"
                >
                  <div class="no-units-container" *ngIf="!nb">
                    <div class="text-with-cta">
                      <span>Please </span>
                      <button
                        class="like-link new-brand-hover-light"
                        (click)="inquiryNow()"
                      >
                        inquire
                      </button>
                      <span> for</span>
                      <span> availability</span>
                      <span> in</span>
                      <span> this</span>
                      <span> complex.</span>
                    </div>
                  </div>
                  <div class="no-units-container" *ngIf="nb">
                    <p>Please inquire for availability in this complex.</p>
                  </div>
                </div>
              </div>

              <div *ngIf="complex.Restaurant && complex.Restaurant.length > 0">
                <br />
                <br />
                <br />
                <mat-divider></mat-divider>
                <br />
                <br />
                @for (dining of complex.Restaurant; track dining.Name; let i =
                $index) {
                <div>
                  <div
                    *ngIf="allDining || i === 0"
                    [class.complex-features-desktop]="!isMobile"
                    [class.complex-features-mobile]="isMobile"
                  >
                    <div *ngIf="isMobile || i % 2 === 0" class="half-amenity">
                      <img
                        src="{{ dining.encodedVenue }}"
                        alt="{{ dining.Caption__c }}"
                        width="100%"
                      />
                    </div>
                    <div
                      class="half-amenity"
                      [class.amenity-description-left]="
                        !isMobile && i % 2 === 0
                      "
                      [class.amenity-description]="!isMobile && i % 2 === 1"
                      [class.description-mobile]="isMobile"
                    >
                      <div *ngIf="i === 0" class="header-separator-left">
                        <h1 class="title-heading no-top-margin">
                          Dining/Restaurants
                        </h1>
                        <div class="separator"></div>
                      </div>
                      <h4>{{ dining.Name }}</h4>
                      <div [innerHTML]="dining.Description__c | safeHtml"></div>
                    </div>
                    <div *ngIf="!isMobile && i % 2 === 1" class="half-amenity">
                      <img
                        src="{{ dining.encodedVenue }}"
                        alt="{{ dining.Caption__c }}"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                }
                <br />
              </div>
              <a
                *ngIf="
                  !allDining &&
                  complex.Restaurant &&
                  complex.Restaurant.length > 1
                "
                class="show-more-nav"
                (click)="allDining = !allDining"
                ><span>More dining places</span>
                <span class="hover-arrows">
                  <mat-icon></mat-icon>
                  <mat-icon class="right-arrow-classic"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon class="right-arrow-on-hover"
                    >arrow_forward</mat-icon
                  >
                </span>
              </a>
              <a
                *ngIf="
                  allDining &&
                  complex.Restaurant &&
                  complex.Restaurant.length > 1
                "
                class="show-more-nav"
                (click)="allDining = !allDining"
                ><span>Hide</span>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <div *ngIf="complex.Spa && complex.Spa.length > 0">
                <br />
                <br />
                <br />
                <mat-divider></mat-divider>
                <br />
                <br />
                @for (spa of complex.Spa; track spa.Name; let i = $index) {
                <div>
                  <div
                    *ngIf="allSPA || i === 0"
                    [class.complex-features-desktop]="!isMobile"
                    [class.complex-features-mobile]="isMobile"
                    class="brand-offwhite-background"
                  >
                    <div *ngIf="isMobile || i % 2 === 1" class="half-amenity">
                      <img
                        src="{{ spa.encodedVenue }}"
                        alt="{{ spa.Caption__c }}"
                        width="100%"
                      />
                    </div>
                    <div
                      class="half-amenity"
                      [class.amenity-description]="!isMobile && i % 2 === 0"
                      [class.amenity-description-left]="
                        !isMobile && i % 2 === 1
                      "
                      [class.description-mobile]="isMobile"
                    >
                      <div *ngIf="i === 0" class="header-separator-left">
                        <h1 class="title-heading no-top-margin">
                          Spa/Wellness
                        </h1>
                        <div class="separator"></div>
                      </div>
                      <h4>{{ spa.Name }}</h4>
                      <div [innerHTML]="spa.Description__c | safeHtml"></div>
                    </div>
                    <div *ngIf="!isMobile && i % 2 === 0" class="half-amenity">
                      <img
                        src="{{ spa.encodedVenue }}"
                        alt="{{ spa.Caption__c }}"
                        width="100%"
                      />
                    </div>
                  </div>
                  <br />
                </div>
                }
                <br />
              </div>
              <a
                *ngIf="!allSPA && complex.Spa && complex.Spa.length > 1"
                class="show-more-nav"
                (click)="allSPA = !allSPA"
                ><span>More Spa/Wellness</span>
                <span class="hover-arrows">
                  <mat-icon></mat-icon>
                  <mat-icon class="right-arrow-classic"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon class="right-arrow-on-hover"
                    >arrow_forward</mat-icon
                  >
                </span>
              </a>
              <a
                *ngIf="allSPA && complex.Spa && complex.Spa.length > 1"
                class="show-more-nav"
                (click)="allSPA = !allSPA"
                ><span>Hide</span>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <br />
              <br />

              <mat-divider></mat-divider>
              <br />

              <div
                *ngIf="
                  locationAmenitiesParsed && locationAmenitiesParsed.length
                "
              >
                <div class="header-separator-left">
                  <h1 class="title-heading">Local Amenities</h1>
                  <div class="separator"></div>

                  <div class="location-amenity-group">
                    @for (category of locationAmenitiesParsed; track
                    category.Category; let i = $index) {
                    <div>
                      <div
                        *ngIf="showAllAmenities || i <= 1 || !isMobile"
                        class="location-amenity-column"
                      >
                        <div class="location-amenity-title">
                          <svg
                            fill="#555d5e"
                            style="
                              width: 24px;
                              height: 24px;
                              padding-top: 2px;
                              padding-bottom: 2px;
                              margin-right: 8px;
                            "
                            viewBox="0 0 28 28"
                          >
                            <svg:path [attr.d]="category.icon" />
                          </svg>
                          <span>{{ category.Category }}</span>
                        </div>
                        @for (amenity of category.List; track amenity) {
                        <div class="location-amenity-list">
                          <mat-icon class="brand-accent-color">check</mat-icon>
                          <span>{{ amenity }}</span>
                        </div>
                        }
                      </div>
                    </div>
                    }
                  </div>

                  <div *ngIf="showAllAmenities && isMobile">
                    <a
                      class="show-more-nav"
                      (click)="showAllAmenities = !showAllAmenities"
                      ><span>Hide amenities</span>
                      <mat-icon>keyboard_arrow_left</mat-icon>
                    </a>
                  </div>
                  <div
                    *ngIf="
                      isMobile &&
                      !showAllAmenities &&
                      locationAmenitiesParsed.length > 2
                    "
                  >
                    <a
                      class="show-more-nav"
                      (click)="showAllAmenities = !showAllAmenities"
                      ><span>Show all amenities</span>
                      <span class="hover-arrows">
                        <mat-icon></mat-icon>
                        <mat-icon class="right-arrow-classic"
                          >keyboard_arrow_right</mat-icon
                        >
                        <mat-icon class="right-arrow-on-hover"
                          >arrow_forward</mat-icon
                        >
                      </span>
                    </a>
                  </div>

                  <br />
                  <br />
                  <mat-divider></mat-divider>
                </div>
              </div>

              <twbooking-unit-amenities
                [unit]="complex"
              ></twbooking-unit-amenities>

              <div #moreImagesAnchor>
                <span *ngIf="complex.ImagesProcessed">
                  <twbooking-unit-bottom-gallery
                    [unitImages]="complex.ImagesProcessed"
                    [showMore]="showMoreImages"
                  >
                  </twbooking-unit-bottom-gallery>
                </span>
              </div>

              <div #reviewsAnchor>
                <twbooking-unit-reviews
                  [unit]="complex"
                  (rating)="getRating($event)"
                ></twbooking-unit-reviews>
              </div>

              <br #bottomLine />
            </div>
          </div>
        </div>
      </div>
      <twbooking-unit-related
        [unit]="null"
        [complex]="complex"
      ></twbooking-unit-related>
    </div>
  </div>

  <div class="loading-container-page" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!unitExist && !loading" class="unit-not-found">
    <h1 class="mat-h1">Oops!</h1>

    <h2>We are unable to find the property that you searched for.</h2>
    <p>Please check our inventory:</p>
    <p>
      <a
        [routerLink]="['/units']"
        routerLinkActive="router-link-active"
        class="show-more-nav"
        >View properties</a
      >
    </p>
  </div>

  <twbooking-footer></twbooking-footer>
</div>
