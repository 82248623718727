import {
  Component,
  Input,
  Inject,
  OnInit,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  DamageProtectionDeposit,
  QuoteData,
} from 'src/app/store/models/rate.models';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  EmailValidator,
  UntypedFormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { CampaignInfoService } from 'src/app/services/campaign-info.service';

import { BreakpointObserver } from '@angular/cdk/layout';
import * as moment from 'moment';
import { UserDataService } from 'src/app/services/user-data.service';
import { ApiService } from 'src/app/api.service';
import { UnitsService } from 'src/app/services/units.service';
import {
  Country,
  countryExpanded,
  countryOptions,
} from 'src/app/store/models/country-options';
import { EventService, InitiateCheckout } from 'src/app/services/event.service';
import { AuthService } from 'src/app/services/auth.service';
import { stateOptionsUS, stateOptionsCA } from '../us-states.options';
import {
  BeginCheckoutParamsGA,
  BookingSubmitPaymentGA,
} from 'src/app/store/models/events-ga.model';
import { PurchaseParamsGA } from 'functions/src/ga4';
declare let ga: any;

@Component({
  selector: 'twbooking-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit, OnDestroy {
  @Input() bookingInput: {
    unit?: any;
    booking?: {
      TTW_ID__c: string;
      Name: string;
    }; //for logding quote, salesforce Id of existing web booking
    depositPolicies?: {
      FullPaymentDate__c?: Date;
      InitialDeposit__c?: number;
      DepositPolicyDescription__c?: string;
    };
    filter?: {
      quoteData?: any;
    };
    cancellationPolicy?: any;
  };
  showCancellationPolicy = false;
  showDepositPolicy = false;
  @Input() mode: 'StandardBooking' | 'LodgingQuote';
  @Input() editAllowed: boolean;
  loadingAvailability = false;
  purchase: PurchaseParamsGA = null;
  COMMISSION_RATE = 0.15;

  unit: any = null;

  form: UntypedFormGroup;
  amenityList = [];
  sub: Subscription;
  step = 0;
  readonly countryOptions: Country[] = countryOptions;
  readonly countryExpanded: Country[] = countryExpanded;
  countryExpandedNames: string[] = [];
  countryexp = new UntypedFormControl();
  filteredCountries: Observable<string[]>;
  checkout: InitiateCheckout = { submitted: false };
  haveChanges = false;
  loading = false;

  /* Prices */
  quote: QuoteData = null;
  errorMessage = null;
  lodgingQuoteAvailabilityError: string = null;
  formTotalCost = 0;
  formTotalDeposit = 0;
  inntopiaQuoteNew: any; // instead of rate calculator
  myStay = 0;
  Rent = 0;
  coupon: string | null = null;
  fullPaymentDue: string = null;

  arrivalString: string;
  departureString: string;

  expandedCountry = false;
  editMode = false;
  taxesWarning = false;

  submittedNotValid = false;
  resourcesLoaded = true;

  access_token: any = {};
  token_id: any;
  firstnameField: any = {};
  lastnameField: any = {};
  emailField: any = {};
  phoneField: any = {};

  testBooking = false;
  countryModel: any;

  begin_checkout_submitted = false;

  submitPurchaseEvent = true;

  states: any[];
  adultOptions = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
    { value: '9', viewValue: '9' },
    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' },
    { value: '13', viewValue: '13' },
    { value: '14', viewValue: '14' },
    { value: '15', viewValue: '15' },
    { value: '16', viewValue: '16' },
    { value: '17', viewValue: '17' },
    { value: '18', viewValue: '18' },
    { value: '19', viewValue: '19' },
    { value: '20', viewValue: '20' },
  ];
  childrenOptions = [{ value: '0', viewValue: '0' }].concat(this.adultOptions);
  petOptions = [{ value: '0', viewValue: '0' }].concat(this.adultOptions);

  cardTypes = [
    { value: 'visa', viewValue: 'Visa' },
    { value: 'ae', viewValue: 'American Express' },
    { value: 'disc', viewValue: 'Discover' },
    { value: 'master', viewValue: 'Master Card' },
  ];

  objectKeys = Object.keys;
  arrayFrom = Array.from;

  chipSelectable = false;

  today = new Date();
  minDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  );
  salesforceId: any = '';
  booked = false;
  payRef: string;

  setStep(index: number) {
    if (this.haveChanges) {
      if (this.mode === 'LodgingQuote') {
        this.eventService.triggerUpdateLodgingQuoteEvent(this.checkout);
      } else {
        this.eventService.triggerUpdateCheckoutEvent(this.checkout);
      }

      this.haveChanges = false;
    }
    this.step = index;
    if (index === 3) {
      this.handleSubmit(this.form.value, this.form.valid);
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(
    private router: Router,
    private _fb: UntypedFormBuilder,
    private apiService: ApiService,
    private zone: NgZone,
    private unitService: UnitsService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private userDataService: UserDataService,
    private eventService: EventService,
    private authService: AuthService
  ) {}

  prepopulateData() {
    const savedUser = this.userDataService.get();
    if (savedUser && savedUser.firstName) {
      this.checkout.firstName = savedUser.firstName;
      this.form.controls['firstnameField'].setValue(savedUser.firstName);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.lastName) {
      this.checkout.lastName = savedUser.lastName;
      this.form.controls['lastnameField'].setValue(savedUser.lastName);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.email) {
      this.checkout.email = savedUser.email;
      this.form.controls['emailField'].setValue(savedUser.email);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.phone) {
      this.checkout.phone = savedUser.phone;
      this.form.controls['phoneField'].setValue(savedUser.phone);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.addressLine1) {
      this.checkout.addressLine1 = savedUser.addressLine1;
      this.form.controls['addressLine1'].setValue(savedUser.addressLine1);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.addressLine2) {
      this.checkout.addressLine2 = savedUser.addressLine2;
      this.form.controls['addressLine2'].setValue(savedUser.addressLine2);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.city) {
      this.checkout.city = savedUser.city;
      this.form.controls['city'].setValue(savedUser.city);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.zip) {
      this.checkout.zip = savedUser.zip;
      this.form.controls['zip'].setValue(savedUser.zip);
      this.haveChanges = true;
    }
    if (savedUser && savedUser.country) {
      this.checkout.country = savedUser.country;
      this.form.controls['country'].setValue(savedUser.country);
      this.haveChanges = true;
      if (savedUser.country.toLowerCase() === 'other') {
        this.expandedCountry = true;
        if (savedUser && savedUser.country1) {
          let code = this.findCountryCode(savedUser.country1);
          this.countryexp.setValue(savedUser.country1);
          if (code) {
            this.form.controls['country1'].setValue(code);
          }
        }
      }
    }
    if (savedUser && savedUser.state) {
      this.checkout.state = savedUser.state;
      this.form.controls['state'].setValue(savedUser.state);
      this.haveChanges = true;
    }
  }

  findCountryCode(countryExp: string): string {
    let abbr = null;
    let index = this.countryExpanded.findIndex(
      (country) => country.viewValue === countryExp
    );
    if (index >= 0) {
      abbr = this.countryExpanded[index].value;
    }
    return abbr;
  }

  userDataChanged(field: string, event) {
    let savedUser = this.userDataService.get();
    this.haveChanges = true;
    switch (field) {
      case 'name': {
        savedUser = { ...savedUser, firstName: event.target.value };
        this.checkout.firstName = event.target.value;
        break;
      }
      case 'lastName': {
        savedUser = { ...savedUser, lastName: event.target.value };
        this.checkout.lastName = event.target.value;
        break;
      }
      case 'email': {
        savedUser = { ...savedUser, email: event.target.value };
        this.checkout.email = event.target.value;
        break;
      }
      case 'phone': {
        savedUser = { ...savedUser, phone: event.target.value };
        this.checkout.phone = event.target.value;
        break;
      }
      case 'addressLine1': {
        savedUser = { ...savedUser, addressLine1: event.target.value };
        this.checkout.addressLine1 = event.target.value;
        break;
      }
      case 'addressLine2': {
        savedUser = { ...savedUser, addressLine2: event.target.value };
        this.checkout.addressLine2 = event.target.value;
        break;
      }
      case 'city': {
        savedUser = { ...savedUser, city: event.target.value };
        this.checkout.city = event.target.value;
        break;
      }
      case 'zip': {
        savedUser = { ...savedUser, zip: event.target.value };
        this.checkout.zip = event.target.value;
        break;
      }
      case 'country': {
        this.form.controls.state.setValue(null);
        this.setStatesOptions();
        savedUser = { ...savedUser, country: event.value };
        this.checkout.country = event.value;
        if (this.checkout.country.toLowerCase() !== 'other') {
          this.expandedCountry = false;
        } else {
          this.expandedCountry = true;
        }
        break;
      }
      case 'country1': {
        this.form.controls.state.setValue(null);
        if (event.option && event.option.value) {
          let code = this.findCountryCode(event.option.value);
          if (code) {
            this.form.controls['country1'].setValue(code);
          }

          savedUser = { ...savedUser, country1: event.option.value };

          break;
        }
      }
      case 'stateUS': {
        savedUser = { ...savedUser, state: event.value };
        this.checkout.state = event.value;
        break;
      }
      case 'state': {
        savedUser = { ...savedUser, state: event.target.value };
        this.checkout.state = event.target.value;
        break;
      }
    }
    this.authService.updateUserData(savedUser);
    // this.userDataService.update(savedUser);
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  ngOnInit() {
    this.editMode = this.editAllowed;
    this.countryExpanded.forEach((value: Country) => {
      this.countryExpandedNames.push(value.viewValue);
    });
    this.loading = false;

    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
    );
    this.unit = this.bookingInput.unit;

    this.checkout = {
      unit: this.unit.Name,
      unitType: this.unit.UnitType__c,
      propertyCollection: this.unit.Property_Collection__c,
      submitted: false,
      country: 'US',
    };

    if (this.bookingInput.booking) {
      this.salesforceId = this.bookingInput.booking.TTW_ID__c;
    }

    this.form = this._fb.group({
      firstnameField: ['', [Validators.required]],
      lastnameField: ['', [Validators.required]],
      emailField: ['', [Validators.required, Validators.pattern(emailRegex)]],
      phoneField: ['', [Validators.required]],
      addressLine1: ['', []],
      addressLine2: ['', []],
      city: ['', []],
      zip: ['', [Validators.required]],
      country: ['', []],
      country1: [null, []],
      state: ['', []],
      region: ['', []],
      workPhone: ['', []],
      insurance: [false, []],
      damageProtection: [true, []],
      agreed: ['', []],
    });
    this.form.controls['country'].setValue('US');

    if (this.bookingInput.filter && this.bookingInput.filter.quoteData) {
      this.quote = this.bookingInput.filter.quoteData;
      this.keepQuote(this.quote, 'initiate');
      if (this.formTotalCost > 0) {
        this.editMode = false;
      }
    }

    if (this.mode === 'LodgingQuote') {
      this.eventService.triggerInitiateLodgingQuoteEvent(this.checkout);
    } else {
      this.eventService.triggerInitiateCheckoutEvent(this.checkout);
    }
    /* Parsing URL if it is testBookingMode */
    this.sub = this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.testBooking) {
        this.testBooking = params.testBooking;
        console.log('Test Booking');
      }
    });

    this.prepopulateData();

    this.setStatesOptions();

    this.form.get('country').valueChanges.subscribe((country: string) => {
      if (country === 'US') {
        this.form
          .get('zip')
          .setValidators([
            Validators.required,
            Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'),
          ]);
      } else if (country === 'CA') {
        this.form
          .get('zip')
          .setValidators([
            Validators.required,
            Validators.pattern(
              '^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$'
            ),
          ]);
      } else if (country === 'GB') {
        this.form
          .get('zip')
          .setValidators([
            Validators.required,
            Validators.pattern(
              '^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$'
            ),
          ]);
      } else if (country === 'OTHER') {
        this.expandedCountry = true;
      }

      this.form.get('zip').updateValueAndValidity();
    });

    this.filteredCountries = this.countryexp.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countryExpandedNames.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  setStatesOptions() {
    if (this.form.get('country').value === 'US') {
      this.states = stateOptionsUS;
    } else if (this.form.get('country').value === 'CA') {
      this.states = stateOptionsCA;
    }
  }

  checkifPurchaseEventAllowed(
    unit: string,
    arrival: string,
    departure: string
  ) {
    let user = this.authService.getUserId();
    if (user) {
      this.unitService
        .isPurchaseEventAllowed({
          unit: unit,
          arrival: arrival,
          departure: departure,
          user: user,
          mode: this.mode,
        })
        .subscribe((result) => {
          this.submitPurchaseEvent = result.result ? result.result : false;
        });
    }
  }

  handleSubmit(searchForm: any, isValid: boolean) {
    /*  if (this.step === 1 && !this.salesforceId) {
      if (
        searchForm.firstnameField &&
        searchForm.lastnameField &&
        searchForm.emailField
      ) {
        this.saveWebBooking(searchForm);
      }
    }
    if (this.step === 1 && this.salesforceId) {
      const res = {
        salesforceId: this.salesforceId
      };
      // Update Booking With Result
      this.updateWebBooking(res, searchForm);
    }*/
  }

  completeBooking(searchForm: any, isValid: boolean) {
    this.submittedNotValid = this.step === 3 && !isValid;
    if (isValid && !this.booked && !this.loading) {
      this.loading = true;
      this.payRef = Math.floor(Math.random() * 100000000 + 1).toString();
      let paymentStatus = this.testBooking
        ? 'TestBooking'
        : this.fullPaymentDue
        ? 'Submitted. Will request 50% prepayment on the next step'
        : 'Submitted. Will request 100% payment on the next step';
      if (this.taxesWarning) {
        paymentStatus = 'Payment not requested because there are no taxes';
      }
      const res = {
        salesforceId: this.salesforceId,
        Name:
          this.bookingInput.booking && this.bookingInput.booking.Name
            ? this.bookingInput.booking.Name
            : null,
        referenceId: this.payRef,
        paymentStatus: paymentStatus,
        Deposit: this.taxesWarning ? 0 : Math.round(this.formTotalDeposit),
      };
      // Update Booking With Result
      this.updateWebBooking(res, searchForm);
      this.checkout.submitted = true;

      if (this.mode === 'LodgingQuote') {
        this.eventService.triggerUpdateLodgingQuoteEvent(this.checkout);
      } else {
        this.eventService.triggerUpdateCheckoutEvent(this.checkout);
      }
    } else {
      if (this.step !== 2 || this.booked || this.loading) {
        const error =
          this.step !== 2
            ? 'Wrong step ' + this.step.toString()
            : this.booked
            ? 'this.booked ===true'
            : 'Booking is in progress already. Double click. This.loading === true.';
        if (isValid) {
          console.error(
            'Booking error in booking ' + this.salesforceId + error
          );
        } else {
          console.error('Complete booking for invalid form ', searchForm);
        }
      }
    }
  }

  private updateWebBooking(result: any, searchForm: any): any {
    if (this.unit.isInntopiaUnit__c && !this.quote.lodgingQuote) {
      const rateType = this.quote.quoteInntopia.rateType ? 'NET' : 'RAC';

      //TODO correctly handle DisplayRent
      this.inntopiaQuoteNew = {
        InntopiaRateType__c: rateType,
        InntopiaRatePlanCode__c: this.quote.quoteInntopia.rateCode,
        InntopiaDisplayRent__c:
          this.quote.quoteInntopia.savingsAmount > 0
            ? this.quote.quoteInntopia.baseDisplayRent
            : this.quote.quoteInntopia.DisplayRent,
        InntopiaDiscountDisplayRent__c:
          this.quote.quoteInntopia.savingsAmount > 0
            ? this.quote.quoteInntopia.DisplayRent
            : null,
        //there is also baseDisplayRent - the rent without discount and without fees included
        InntopiaRent__c:
          this.quote.quoteInntopia.savingsAmount > 0
            ? this.quote.quoteInntopia.baseRent
            : this.quote.quoteInntopia.rent,

        InntopiaDiscountedRent__c:
          this.quote.quoteInntopia.savingsAmount > 0
            ? this.quote.quoteInntopia.rent
            : null,
        InntopiaFees__c: this.quote.quoteInntopia.fees,
        InntopiaDisplayFees__c: this.quote.quoteInntopia.DisplayFees,

        InntopiaTaxes__c: this.quote.quoteInntopia.taxes,
        InntopiaDisplayTaxes__c: this.quote.quoteInntopia.DisplayTaxes,
        InntopiaTotal__c: this.formTotalCost,
        InntopiaTravelInsurance__c:
          this.quote.quoteInntopia.insurance > 0
            ? this.quote.quoteInntopia.insurance
            : 0,
      };
      console.log('inntopiaQuoteNew = ', this.inntopiaQuoteNew);
    }
    let GCLID = this.authService.gclid;
    if (!GCLID) {
      if (ga && ga.getAll && ga.getAll() && ga.getAll().length > 0) {
        try {
          GCLID = ga.getAll()[0].get('clientId');
        } catch (e) {
          GCLID = null;
        }
      }
    }
    let myCountry = searchForm.country;
    if (searchForm.country === 'OTHER') {
      const tmp = this.countryexp.value.toLowerCase();
      this.countryExpanded.forEach((option: Country) => {
        if (option.viewValue.toLowerCase() === tmp) {
          myCountry = option.value;
          this.form.controls['country'].setValue(myCountry);
        }
      });
    }

    /** **/
    let VRDPDamagePolicy = 0;
    let DamageProtectionDeposit = 0;
    let TravelInsurance = 0;
    if (this.quote.quoteMM) {
      VRDPDamagePolicy = this.quote.quoteMM.VRDPDamagePolicy__c;
      DamageProtectionDeposit = this.quote.quoteMM.DamageProtectionDeposit__c;
      TravelInsurance = this.quote.quoteMM.TravelInsurance__c;
    }
    if (this.quote.lodgingQuote) {
      VRDPDamagePolicy = this.quote.lodgingQuote.VRDPDamagePolicy__c;
      DamageProtectionDeposit =
        this.quote.lodgingQuote.DamageProtectionDeposit__c;
      TravelInsurance = this.quote.lodgingQuote.TravelInsurance__c;
    }
    if (this.quote.quoteInntopia) {
      VRDPDamagePolicy = 0;
      DamageProtectionDeposit = 0;
      TravelInsurance = this.quote.quoteInntopia.TravelInsurance__c;
    }

    let res = {
      ...result,
      email: searchForm.emailField,
      firstName: searchForm.firstnameField,
      lastName: searchForm.lastnameField,
      adults: this.quote.adults,
      children: this.quote.children,
      infants: this.quote.infants,
      pets: this.quote.pets ? this.quote.pets : null,
      arrival: this.quote.arrival,
      departure: this.quote.departure,
      phone: searchForm.phoneField,
      unit: {
        Name: this.unit.Name,
        Id: this.unit.externalId,
      },
      calcId: this.quote && this.quote.quoteMM ? this.quote.quoteMM.Id : null,
      inntopiaQuote: null,
      InntopiaRateType__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaRateType__c
        : null,
      InntopiaRatePlanCode__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaRatePlanCode__c
        : null,
      InntopiaDiscountedRent__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaDiscountedRent__c
        : null,
      InntopiaRent__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaRent__c
        : null,
      InntopiaFees__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaFees__c
        : null,
      InntopiaTaxes__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaTaxes__c
        : null,
      InntopiaTotal__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaTotal__c
        : null,
      InntopiaDisplayRent__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaDisplayRent__c
        : null,
      InntopiaDiscountDisplayRent__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaDiscountDisplayRent__c
        : null,
      InntopiaDisplayFees__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaDisplayFees__c
        : null,
      InntopiaDisplayTaxes__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaDisplayTaxes__c
        : null,
      InntopiaTravelInsurance__c: this.inntopiaQuoteNew
        ? this.inntopiaQuoteNew.InntopiaTravelInsurance__c
        : null,
      LodgingGrossSales__c:
        this.quote &&
        this.quote.lodgingQuote &&
        this.quote.lodgingQuote.LodgingGrossSales__c &&
        this.quote.lodgingQuote.LodgingGrossSales__c > 0
          ? this.formTotalCost
          : null,
      LodgingReservationTotal__c:
        this.quote &&
        this.quote.lodgingQuote &&
        this.quote.lodgingQuote.Subtotal &&
        this.quote.lodgingQuote.Subtotal > 0
          ? this.quote.lodgingQuote.Subtotal +
            this.quote.lodgingQuote.VRDPDamagePolicy__c +
            this.quote.lodgingQuote.TravelInsurance__c
          : null,
      GrossSales__c: this.formTotalCost,
      addressLine1: searchForm.addressLine1,
      addressLine2: searchForm.addressLine2,
      city: searchForm.city,
      region: '', // searchForm.region,
      state: searchForm.state,
      country: myCountry,
      zip: searchForm.zip,
      workPhone: '', // searchForm.workPhone,
      token: '', // this.token_id,
      insurance: searchForm.insurance,
      Opt_out_of_Damage_Protection__c: !searchForm.damageProtection,
      VRDPDamagePolicy__c: VRDPDamagePolicy,
      DamageProtectionDeposit__c: DamageProtectionDeposit,
      TravelInsurance__c: TravelInsurance,
      terms: searchForm.agreed,
      GCLID: GCLID,
      sessionId: this.authService.getSessionId(),
      hostname: window.location.hostname.includes('thetravelwhisperer')
        ? 'ttw-booking'
        : window.location.hostname.includes('mountainmanagement')
        ? 'mm-booking'
        : window.location.hostname,
    };

    let ga_sessionId = this.authService.ga_sessionId;
    if (ga_sessionId) {
      res = { ...res, ga_sessionId: ga_sessionId };
    }

    let Tax = 0;
    let Rent = 0;
    if (this.quote.quoteMM) {
      Tax = this.quote.quoteMM.TAX__c;
      if (this.quote.quoteMM.Discounted_Rent__c > 0) {
        Rent = this.quote.quoteMM.Discounted_Rent__c;
      } else {
        Rent = this.quote.quoteMM.TotalRent__c;
      }
    } else if (this.inntopiaQuoteNew && this.inntopiaQuoteNew.InntopiaRent__c) {
      Tax = this.inntopiaQuoteNew.InntopiaTaxes__c;
      Rent = this.inntopiaQuoteNew.InntopiaRent__c;
    } else if (
      this.quote.lodgingQuote &&
      this.quote.lodgingQuote.LodgingNightlyRate__c
    ) {
      Rent = this.quote.lodgingQuote.LodgingNightlyRate__c;
      Tax = this.quote.lodgingQuote.LodgingTaxAmount__c;
    }
    if (Rent <= 0 || Tax <= 0) {
      console.error(
        `Error! Rent <0 for booking ${searchForm.emailField}: ${this.unit.Name} ${this.quote.arrival}-${this.quote.departure}`
      );
    }

    if (this.submitPurchaseEvent) {
      console.log('Event allowed');
      this.purchase = {
        currency: 'USD',
        value:
          Math.round(
            (Number(Rent.toFixed(2)) * this.COMMISSION_RATE * 100) / 3
          ) / 100, // *100/100 to have 2 decimal places
        transaction_id: this.payRef,
        coupon: this.coupon,
        UnitName: this.unit.Name,
        StartDate: this.quote.arrival,
        EndDate: this.quote.departure,
        Adults: this.quote.adults,
        booking_type:
          this.mode === 'LodgingQuote'
            ? 'lodging_quote_convert'
            : 'direct_booking',
      };
      console.log('value = ', this.purchase.value);

      let userData = {
        email: res.email,
        phone: res.phone,
      };

      this.eventService.sendPurchaseGA(this.purchase, userData);
    } else {
      console.log('Event is not allowed');
    }
    console.log('res = ', res);

    this.apiService
      .httpCall('/api/webBookingUpdate', res)
      .subscribe((resp: any) => {
        if (resp) {
          this.loading = false;
          this.booked = true;
          this.editMode = false;

          if (resp.id) {
            this.salesforceId = resp.id;
            this.setStep(4);
          }

          if (this.testBooking && this.step === 5) {
            this.zone.run(() => {
              //this.closeCurrentDialog();
              this.router.navigate(['/booking-reservation-thankyou']);
            });
          }
          if (resp.errorCode) {
            throw new Error(
              'webBookingUpdate problem: ' + resp + ' Update params: ' + res
            );
          }
        } else {
          throw new Error(
            'webBookingUpdate problem: ' + resp + ' Update params: ' + res
          );
        }
      });
  }

  insuranceChange(amt, checked, subTotal) {
    let totalCost = subTotal;

    if (this.quote.quoteMM) {
      totalCost +=
        this.quote.quoteMM.RefundableDeposit__c +
        this.quote.quoteMM.DamageProtectionDeposit__c +
        this.quote.quoteMM.VRDPDamagePolicy__c;
    }
    if (this.quote.lodgingQuote) {
      totalCost +=
        this.quote.lodgingQuote.LodgingSecurityDeposit__c +
        this.quote.lodgingQuote.DamageProtectionDeposit__c +
        this.quote.lodgingQuote.VRDPDamagePolicy__c;
    }
    if (checked.checked) {
      if (this.quote.quoteMM) {
        this.quote.quoteMM.TravelInsurance__c = this.quote.quoteMM.Insurance__c;
      }
      if (this.quote.lodgingQuote) {
        this.quote.lodgingQuote.TravelInsurance__c =
          this.quote.lodgingQuote.LodgingTravelInsurance__c;
      }
      if (this.quote.quoteInntopia) {
        this.quote.quoteInntopia.TravelInsurance__c =
          this.quote.quoteInntopia.insurance;
      }
      this.formTotalCost = totalCost + amt;
      this.formTotalDeposit = this.fullPaymentDue
        ? totalCost / 2 + amt
        : this.formTotalCost;
    } else {
      if (this.quote.quoteMM) {
        this.quote.quoteMM.TravelInsurance__c = 0;
      }
      if (this.quote.lodgingQuote) {
        this.quote.lodgingQuote.TravelInsurance__c = 0;
      }
      if (this.quote.quoteInntopia) {
        this.quote.quoteInntopia.TravelInsurance__c = 0;
      }
      this.formTotalCost = totalCost;
      this.formTotalDeposit = this.fullPaymentDue
        ? this.formTotalCost / 2
        : this.formTotalCost;
    }
  }

  getDamageProtectionDeposit(fee: number): number | null {
    for (let i = 0; i < DamageProtectionDeposit.length; i++) {
      if (DamageProtectionDeposit[i].fee === fee) {
        return DamageProtectionDeposit[i].deposit;
      }
    }
    console.error(
      `Error in getDamageProtectionDeposit function. Fee ${fee} not found in the list of available fees.`
    );
    return 5000;
  }

  checkDamageProtectionDeposit(DamageProtectionFee__c: number): boolean {
    const isFeeInArray = DamageProtectionDeposit.some(
      (item) => item.fee === DamageProtectionFee__c
    );
    if (isFeeInArray) {
      return true;
    }
    {
      console.error(
        `Fee ${DamageProtectionFee__c} not found in DamageProtectionDeposit array for unit ${this.unit.externalId} ${this.unit.UnitSlug__c}`
      );
      return false;
    }
  }

  damageProtectionChange(checked, subTotal) {
    if (checked.checked) {
      //User wants to pay Damage protection Fee
      this.quote.quoteMM.VRDPDamagePolicy__c =
        this.quote.quoteMM.DamageProtectionFee__c;
      //Then he doesn't need to pay Damage Protection Deposit
      this.quote.quoteMM.DamageProtectionDeposit__c = 0;
    } else {
      //User do not want to to pay Damage protection Fee
      this.quote.quoteMM.VRDPDamagePolicy__c = 0;
      //Then he doesn't need to pay Damage Protection Deposit
      this.quote.quoteMM.DamageProtectionDeposit__c =
        this.getDamageProtectionDeposit(
          this.quote.quoteMM.DamageProtectionFee__c
        );
    }
    this.formTotalCost =
      subTotal +
      this.quote.quoteMM.RefundableDeposit__c +
      this.quote.quoteMM.VRDPDamagePolicy__c +
      this.quote.quoteMM.DamageProtectionDeposit__c +
      this.quote.quoteMM.TravelInsurance__c;
    this.formTotalDeposit = this.fullPaymentDue
      ? this.formTotalCost / 2
      : this.formTotalCost;
  }

  damageProtectionChangeLodging(checked, subTotal) {
    if (checked.checked) {
      //User wants to pay Damage protection Fee
      this.quote.lodgingQuote.VRDPDamagePolicy__c =
        this.quote.lodgingQuote.LodgingDamageProtectionFee__c;
      //Then he doesn't need to pay Damage Protection Deposit
      this.quote.lodgingQuote.DamageProtectionDeposit__c = 0;
    } else {
      //User do not want to to pay Damage protection Fee
      this.quote.lodgingQuote.VRDPDamagePolicy__c = 0;
      //Then he doesn't need to pay Damage Protection Deposit
      this.quote.lodgingQuote.DamageProtectionDeposit__c =
        this.getDamageProtectionDeposit(
          this.quote.lodgingQuote.LodgingDamageProtectionFee__c
        );
    }
    this.formTotalCost =
      subTotal +
      this.quote.lodgingQuote.LodgingSecurityDeposit__c +
      this.quote.lodgingQuote.VRDPDamagePolicy__c +
      this.quote.lodgingQuote.DamageProtectionDeposit__c +
      this.quote.lodgingQuote.TravelInsurance__c;
    this.formTotalDeposit = this.fullPaymentDue
      ? this.formTotalCost / 2
      : this.formTotalCost;
  }

  async keepQuote(quote: QuoteData, initiate?: string) {
    this.checkifPurchaseEventAllowed(
      this.unit.Name,
      quote.arrival,
      quote.departure
    );

    this.coupon = null;
    this.form.controls['damageProtection'].setValue(true);
    this.form.controls['insurance'].setValue(false);
    if (quote.errorMessage) {
      this.errorMessage = quote.errorMessage;
    } else {
      this.errorMessage = null;
    }
    this.quote = quote;
    if (this.quote && this.quote.arrival && this.quote.departure) {
      this.myStay = moment
        .duration(
          moment
            .utc(this.quote.departure, 'YYYY-MM-DD')
            .diff(moment.utc(this.quote.arrival, 'YYYY-MM-DD'))
        )
        .asDays();
      this.checkout.arrivalDate = moment
        .utc(this.quote.arrival, 'YYYY-MM-DD')
        .toDate();
      this.checkout.departureDate = moment
        .utc(this.quote.departure, 'YYYY-MM-DD')
        .toDate();
      this.checkout.nights = this.myStay;
      this.checkout.adults = this.quote.adults;
      this.checkout.children = this.quote.children;
      this.checkout.infants = this.quote.infants;
      this.checkout.pets = this.quote.pets ? this.quote.pets : null;
      this.checkout.guests = this.checkout.adults + this.checkout.children;
      this.arrivalString = moment
        .utc(this.quote.arrival, 'YYYY-MM-DD')
        .format('MMM DD, YYYY');
      this.departureString = moment
        .utc(this.quote.departure, 'YYYY-MM-DD')
        .format('MMM DD, YYYY');

      if (
        this.bookingInput &&
        this.bookingInput.depositPolicies &&
        this.bookingInput.depositPolicies.FullPaymentDate__c &&
        this.bookingInput.depositPolicies.InitialDeposit__c
      ) {
        let fullPaymentDate = moment.utc(
          this.bookingInput.depositPolicies.FullPaymentDate__c
        );
        this.fullPaymentDue = fullPaymentDate.format('MMMM DD, YYYY');
      } else {
        let fullPaymentDate = moment.utc(this.quote.arrival).clone();
        const arrival = moment.utc(this.quote.arrival).clone();
        let daysPriorArrival = 30;
        if (this.unit.PropertyManager__c == 'Mountain Management') {
          /** Always request full payment 30 days before arrival for MM **/
          console.log('it is MM unit, always request 30 days prior arrival');
          daysPriorArrival = 30;
        } else {
          /** 30 days in summer, 60 days in winter **/
          if (arrival.month() >= 4 && arrival.month() < 10) {
            // month() in format jan=0, dec=11
            // May 1 - Oct 31 : 30 days
            daysPriorArrival = 30;
          } else {
            // Nov 1 - Apr 30: 60 days
            daysPriorArrival = 60;
          }
        }

        const today = moment.utc();
        fullPaymentDate.subtract(daysPriorArrival, 'days');
        if (fullPaymentDate.isBefore(today)) {
          this.fullPaymentDue = null;
        } else {
          this.fullPaymentDue = fullPaymentDate.clone().format('MMMM DD, YYYY');
        }
      }

      if (this.quote.lodgingQuote) {
        let available = true;
        if (
          this.unit.DisplayRates__c === true &&
          !this.quote.lodgingQuote.Hold_Dates__c
        ) {
          this.loadingAvailability = true;
          available = await this.checkAvailability(
            this.unit.externalId,
            moment.utc(this.quote.arrival, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            moment.utc(this.quote.departure, 'YYYY-MM-DD').format('YYYY-MM-DD')
          );
          this.loadingAvailability = false;
        }
        if (available) {
          this.quote.lodgingQuote.VRDPDamagePolicy__c =
            this.quote.lodgingQuote.LodgingDamageProtectionFee__c;
          this.quote.lodgingQuote.DamageProtectionDeposit__c = 0;
          this.quote.lodgingQuote.TravelInsurance__c = 0;

          this.quote.lodgingQuote.LodgingGrossSales__c =
            this.quote.lodgingQuote.LodgingNightlyRate__c +
            this.quote.lodgingQuote.LodgingTaxAmount__c +
            this.quote.lodgingQuote.LodgingSecurityDeposit__c +
            this.quote.lodgingQuote.VRDPDamagePolicy__c +
            this.quote.lodgingQuote.DamageProtectionDeposit__c +
            this.quote.lodgingQuote.TravelInsurance__c;

          this.formTotalCost = this.quote.lodgingQuote.LodgingGrossSales__c;

          this.quote.lodgingQuote.Subtotal =
            this.quote.lodgingQuote.LodgingNightlyRate__c +
            this.quote.lodgingQuote.LodgingTaxAmount__c;

          this.quote.lodgingQuote.LodgingReservationTotal__c =
            this.quote.lodgingQuote.Subtotal +
            this.quote.lodgingQuote.VRDPDamagePolicy__c +
            this.quote.lodgingQuote.TravelInsurance__c;

          this.Rent = this.quote.lodgingQuote.LodgingNightlyRate__c;
          this.checkout.Taxes = this.quote.lodgingQuote.LodgingTaxAmount__c;
          let fees = 0;
          this.quote.lodgingQuote.LodgingTravelInsurance__c;

          this.checkout.Fees = fees;
          this.checkout.RefundableDeposit =
            this.quote.lodgingQuote.LodgingSecurityDeposit__c;
          this.checkout.DiscountedRent = this.Rent;
          this.checkout.Rent = this.Rent;
        } else {
          this.formTotalCost = 0;
          this.formTotalDeposit = 0;
          this.fullPaymentDue = null;
          this.editMode = false;
          this.myStay = 0;
          this.arrivalString = null;
          this.departureString = null;
          this.Rent = 0;
          this.lodgingQuoteAvailabilityError =
            'Oh no! This property may have been booked already. Please contact your Personal Travel Designer for help.';
        }
      } else if (this.unit.isInntopiaUnit__c) {
        this.formTotalCost =
          this.quote.quoteInntopia.rent +
          this.quote.quoteInntopia.taxes +
          this.quote.quoteInntopia.fees;
        this.quote.quoteInntopia.TravelInsurance__c = 0;
        this.taxesWarning = this.quote.quoteInntopia.taxes > 0 ? false : true;
        this.Rent = this.quote.quoteInntopia.rent;
        this.checkout.Taxes = this.quote.quoteInntopia.taxes;
        this.checkout.Fees = this.quote.quoteInntopia.fees;
        this.checkout.Rent = this.quote.quoteInntopia.baseRent;
        this.checkout.DiscountedRent = this.quote.quoteInntopia.rent;
        if (this.quote.quoteInntopia.rent < this.quote.quoteInntopia.baseRent) {
          this.coupon = this.quote.quoteInntopia.rateCode; //FIXME check if it is discounted code
        }
      } else {
        //MM Quote

        this.quote.quoteMM.Subtotal =
          this.quote.quoteMM.TotalCost__c -
          (this.quote.quoteMM.DamageProtectionFee__c
            ? this.quote.quoteMM.DamageProtectionFee__c
            : 0) -
          (this.quote.quoteMM.RefundableDeposit__c
            ? this.quote.quoteMM.RefundableDeposit__c
            : 0);

        if (this.quote.quoteMM.Subtotal <= 0) {
          console.error(`Subtotal <= 0 for the  unit ${this.unit.UnitSlug__c}`);
        }
        if (
          this.unit.UnitSlug__c !== '203-Arrowhead-Drive' &&
          this.unit.PropertyManager__c &&
          this.unit.PropertyManager__c === 'Mountain Management'
        ) {
          let feeIsCorrect = this.checkDamageProtectionDeposit(
            this.quote.quoteMM.DamageProtectionFee__c
          );
          if (!feeIsCorrect) {
            this.quote.quoteMM.DamageProtectionFee__c = 129;
          }
        } else {
          if (
            this.quote.quoteMM &&
            this.quote.quoteMM.DamageProtectionFee__c &&
            this.quote.quoteMM.DamageProtectionFee__c > 0
          ) {
            console.error(
              `Error, fee ${this.quote.quoteMM.DamageProtectionFee__c} greater then 0 for the  unit ${this.unit.UnitSlug__c} with Property Manager ${this.unit.PropertyManager__c}.`
            );
          }
        }
        this.quote.quoteMM.VRDPDamagePolicy__c =
          this.quote.quoteMM.DamageProtectionFee__c;
        this.quote.quoteMM.DamageProtectionDeposit__c = 0;
        this.quote.quoteMM.TravelInsurance__c = 0;

        this.formTotalCost = this.quote.quoteMM.TotalCost__c;

        this.Rent =
          this.quote.quoteMM.Discounted_Rent__c &&
          this.quote.quoteMM.Discounted_Rent__c > 0
            ? this.quote.quoteMM.Discounted_Rent__c
            : this.quote.quoteMM.TotalRent__c;
        if (
          quote.quoteMM.Discount__c &&
          quote.quoteMM.Discount__c > 0 &&
          quote.quoteMM.Discounted_Rent__c &&
          quote.quoteMM.Discounted_Rent__c > 0
        ) {
          this.coupon = 'MM discount';
        }
        let fees = 0;

        if (this.quote.quoteMM.CleaningService__c) {
          fees += this.quote.quoteMM.CleaningService__c;
        }
        if (this.quote.quoteMM.GuestServicePackage__c) {
          fees += this.quote.quoteMM.GuestServicePackage__c;
        }
        if (this.quote.quoteMM.PetFee__c) {
          fees += this.quote.quoteMM.PetFee__c;
        }

        if (
          Math.ceil(this.quote.quoteMM.Subtotal) !==
          Math.ceil(
            this.quote.quoteMM.TAX__c +
              fees +
              this.quote.quoteMM.Discounted_Rent__c
          )
        ) {
          console.error(
            `Subtotal is $${this.quote.quoteMM.Subtotal} for the  unit ${this.unit.UnitSlug__c} is not equal to 
              rent $${this.quote.quoteMM.Discounted_Rent__c} + fees $${fees} + taxes $${this.quote.quoteMM.TAX__c}
              for dates ${this.quote.arrival} - ${this.quote.departure}`
          );
        }
        this.checkout.Taxes = this.quote.quoteMM.TAX__c;
        this.checkout.Fees = fees;
        this.checkout.RefundableDeposit =
          this.quote.quoteMM.RefundableDeposit__c;
        this.checkout.DiscountedRent = this.quote.quoteMM.Discounted_Rent__c;
        this.checkout.Rent = this.quote.quoteMM.TotalRent__c;
      }
      this.checkout.TotalCost = this.formTotalCost;

      if (
        this.bookingInput &&
        this.bookingInput.depositPolicies &&
        this.bookingInput.depositPolicies.FullPaymentDate__c &&
        this.bookingInput.depositPolicies.InitialDeposit__c
      ) {
        this.formTotalDeposit =
          this.bookingInput.depositPolicies.InitialDeposit__c;
      } else {
        this.formTotalDeposit = this.fullPaymentDue
          ? this.formTotalCost / 2
          : this.formTotalCost;
      }
    } else {
      /* if NOT (this.quote && this.quote.arrival && this.quote.departure)  */
      this.formTotalCost = 0;
      this.formTotalDeposit = 0;
      this.fullPaymentDue = null;
      this.editMode = this.editAllowed;
      this.myStay = 0;
      this.arrivalString = null;
      this.departureString = null;
      this.Rent = 0;
      if (this.quote.errorMessage) {
        this.errorMessage = this.quote.errorMessage;
      } else {
        this.errorMessage = null;
      }

      // reset stored checkout quote
      this.checkout.TotalCost = null;
      this.checkout.Rent = null;
      this.checkout.Taxes = null;
      this.checkout.Fees = null;
      this.checkout.arrivalDate = null;
      this.checkout.departureDate = null;
      this.checkout.nights = null;
      this.checkout.adults = null;
      this.checkout.infants = null;
      this.checkout.pets = null;
      this.checkout.children = null;
      this.checkout.guests = null;
      this.checkout.RefundableDeposit = null;
      this.checkout.DiscountedRent = null;
    }

    if (!this.begin_checkout_submitted && this.Rent > 0) {
      let beginCheckoutEvent: BeginCheckoutParamsGA = {
        currency: 'USD',
        value: this.Rent,
        UnitName: this.unit.Name,
        Adults: this.quote.adults,
        Children: this.quote.children,
        StartDate: this.quote.arrival,
        EndDate: this.quote.departure,
      };
      if (this.coupon) {
        beginCheckoutEvent = {
          ...beginCheckoutEvent,
          coupon: this.coupon,
        };
      }
      this.eventService.sendBeginCheckoutEventGA(beginCheckoutEvent);
      this.begin_checkout_submitted = true;
    }
    // TODO send update quote

    if (!initiate) {
      if (this.mode === 'LodgingQuote') {
        this.eventService.triggerUpdateLodgingQuoteEvent(this.checkout);
      } else {
        this.eventService.triggerUpdateCheckoutEvent(this.checkout);
      }
    }
  } // end keepQuote(quote: QuoteData)

  async checkAvailability(unit, arrival, departure) {
    let available = await this.unitService.checkAvailability(
      unit,
      arrival,
      departure
    );
    return available;
  }

  ngOnDestroy() {
    if (this.mode === 'LodgingQuote') {
      this.eventService.triggerUpdateLodgingQuoteEvent(null);
    } else {
      this.eventService.triggerUpdateCheckoutEvent(null);
    }

    this.sub.unsubscribe();
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  goLoading(load) {
    this.loading = load;
  }
}
