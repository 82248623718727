import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthService } from './auth.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import {  from, throwError } from 'rxjs';
import { rewrites } from '../rewrites';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
declare let gtag: any;


@Injectable({
  providedIn: 'root',
})
export class AuthenticatedApiService {
  local = false;
  rewrites = rewrites;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private apiService: ApiService,
    private functions: Functions
  ) {
    this.local = this.apiService.local;
  }




  public  httpCallWithRefresh(
    apiurl: string,
    body: any,
  ) {
    
    let functionName = this.getNameByApi(apiurl);
    if (!functionName) { 
      console.error('httpCall: functionName not found for apiurl: ' + apiurl);
      return null;
    }
     console.log ('refresh  ', functionName);
    const httpCallFn = httpsCallable(this.functions, functionName);

    return from(this.authService.getFreshToken()).pipe(
    switchMap(token => {
      if (body.user){
        body.user = this.authService.getUserId();
      }
       if (body.uuid){
        body.user = this.authService.getUserId();
      }
      return from(httpCallFn(body)).pipe(
        map(result => {
          const res: any = result.data;
          return res;
        })
      );
    }),
    catchError(async (err) => {
     
      let errorString = 'API error: ' + JSON.stringify(err);
      if (err.code) {
        errorString += ', code: ' + err.code;
      }
      if (err.error && err.error.message) {
        errorString += ', message: ' + err.error.message;
      }
      if (err.message) {
        errorString += ', message: ' + err.message;
      }
      let saved = await this.saveApiException(err, apiurl);
      console.error('errorHandlerWrapper on ' + apiurl, err);
      return throwError(() => new Error(errorString)); // Properly return an observable error
    })
  );
  
  }

  getClientId() {
    return new Promise((resolve, reject) => {
      gtag('get', 'G-JR581MM61N', 'client_id', clientId => {
        if (clientId) {
          resolve(clientId);
        } else {
          reject('Failed to get client_id');
        }
      });
    });
  }

  public  httpCall(
    apiurl: string,
    body: any,
  ) {
    
    let functionName = this.getNameByApi(apiurl);
    if (!functionName) { 
      console.error('httpCall: functionName not found for apiurl: ' + apiurl);
      return null;
    }
    const httpCallFn = httpsCallable(this.functions, functionName);
    return from(httpCallFn(body)).pipe(
      map(result => {
        const res: any = result.data;
        return res;
    }),
    catchError(async (err) => {

      let errorString = 'API error: ' + JSON.stringify(err);
      if (err.code) {
        errorString += ', code: ' + err.code;
      }
      if (err.error && err.error.message) {
        errorString += ', message: ' + err.error.message;
      }
      if (err.message) {
        errorString += ', message: ' + err.message;
      }
      if (apiurl.indexOf('leadCreate') != -1){
        let saved = await this.saveApiException(err, apiurl, body);
      } else {
        let saved = await this.saveApiException(err, apiurl);
      }
      
      console.error('errorHandlerWrapper on ' + apiurl, err);
     
      return throwError(() => new Error(errorString)); // Properly return an observable error
    })
  );
  }

  async saveApiException(err: any, apiurl, leaddata?: string) {
     let gclid = await this.getClientId();
      console.log('gclid', gclid);
      console.log('httpCall error', err.message?err.message:err);
      console.log('url', window.location.href);
      console.log('appversion', environment.version);
      let sessionId = this.authService.getSessionId();
      console.log('sessionId', sessionId);
      let exceptionData: any = {
                  message: err.message?err.message:err,
                  endpoint: apiurl,
                  url: window.location.href,
                  appversion:  environment.version,
                  gclid: gclid, 
                  sessionId: sessionId
      }

      if (apiurl.indexOf('leadCreate') != -1){
        exceptionData = {
          ...exceptionData,
          lead: leaddata
        }
      }
      if (apiurl.indexOf('saveException') === -1){
        this.httpCall('/api/saveException', {
                exceptionData:{
                  message: err.message?err.message:err,
                  endpoint: apiurl,
                  url: window.location.href,
                  appversion:  environment.version,
                  gclid: gclid, 
                  sessionId: sessionId
                }
        })
        .subscribe((saved: any) => {
            console.log('exception saved');
        });
      } else {
        console.log('error in saveException ');
      }
  }

  getNameByApi(apiurl: string) {
    const rewrite = rewrites.find(r => r.source === apiurl);
    return rewrite ? rewrite.function : undefined;
  }


  getUrl(url: string) {
    if (this.local) {
      return '/katya' + url;
    } else {
      return url;
    }
  }
}
