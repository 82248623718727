<section class="bottom-gallery" *ngIf="this.galleryTotal && this.galleryTotal.length > 0">
  <div class="header-separator-left">
    <h1 class="title-heading">Gallery</h1>
    <div class="separator"></div>
  </div>
  <div *ngIf="!showAll">
    <ks-plain-gallery [id]="3" [images]="galleryPreview" [config]="libConfigPlainGallery"
      [currentImageConfig]="customFullDescriptionHidden" (clickImage)="onShow(14, $event )">
    </ks-plain-gallery>


    <div *ngIf="galleryTotal.length > picturesInRow">
      <a class="show-more-nav" (click)="this.showAll = true"><span>Show all images</span>
        <span class='hover-arrows'>
          <mat-icon class="right-arrow-classic">keyboard_arrow_right</mat-icon>
          <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
        </span></a>
    </div>
  </div>
  <div [class.invisible]="!showAll">
    <ks-plain-gallery [id]="14" [images]="galleryTotal" [config]="libConfigPlainGallery"
      [currentImageConfig]="customFullDescriptionHidden" (clickImage)="onShow(14, $event )"></ks-plain-gallery>
    <a class="show-more-nav" (click)="showAll = false"><span>Hide images</span>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
  </div>

  <br />
</section>