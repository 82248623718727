import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'words',
})
export class TruncateWordsPipe implements PipeTransform {
  transform(
    value: string,
    limit: number = 10
  ): { text: string; isTruncated: boolean } {
    if (!value) return { text: '', isTruncated: false };
    let words = value.split(' ');
    let isTruncated = words.length > limit;
    let truncatedText = isTruncated
      ? words.slice(0, limit).join(' ') + '...'
      : value;
    return { text: truncatedText, isTruncated };
  }
}
