<div class="full-page">
  <div class="unit-enquiry-thank-you-page">
    <div class="unit-details-concierge-card">
      <div>
        <p class="new-brand-color thank-you-text">
          Thank you for contacting The Travel Whisperer. Please keep an eye on
          your email, and don't forget to check your spam box for our response.
          Our Personal Travel Designers are available for consultations daily
          from 9am until 5pm (Mountain Time). They will plan every detail to
          ensure your personalized experience combines luxury and comfort with
          your ideal balance between relaxation, wellness, and adventure.
        </p>
      </div>
      <div>
        <p class="new-brand-color thank-you-subtext">
          If you have any questions in the meantime, please feel free to call us
          at
          <a class="clickable-link new-brand-color" href="tel:+19706885193"
            >+1 (970) 688-5193</a
          >
          or email us at
          <a
            class="clickable-link new-brand-color"
            href="mailto:reservations@thetravelwhisperer.com"
            >reservations&#64;thetravelwhisperer.com</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>
