import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchParamsService {
  public searchParams$: BehaviorSubject<any>;
  quote: BehaviorSubject<any>;

  constructor() {
    this.searchParams$ = new BehaviorSubject(null);
  }

  update(newSearchParams: any) {
    this.searchParams$.next(newSearchParams);
  }

  get(): any {
    return this.searchParams$.value;
  }
}
