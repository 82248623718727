<div *ngIf="saved && loggedIn" class="new-brand-background white-color">
  This unit is saved to your favorites to help our personal travel designers
  find the best options for you
</div>
<div *ngIf="saved && !loggedIn" class="new-brand-background white-color">
  Sign up to view your favourites
</div>

<div *ngIf="!saved" class="new-brand-background white-color">
  This unit is removed from your favorites
</div>
