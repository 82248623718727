import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './core/core.module';
import { UnitsModule } from './units/units.module';

import { environment } from '../environments/environment';
import { UnitsService } from './services/units.service';

import { CheckoutService } from './services/checkout.service';
import { DatePipe } from '@angular/common';
import { SearchCacheService } from './services/search-cache.service';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';

import { OverlayModule } from '@angular/cdk/overlay';

import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';

import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';

import 'hammerjs';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // I will only use the swap gesture so
    // I will deactivate the others to avoid overlaps
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

import * as Sentry from '@sentry/angular-ivy';

import {
  ReportingObserver as ReportingObserverIntegration,
  CaptureConsole,
} from '@sentry/integrations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  getApp,
  initializeApp,
  provideFirebaseApp,
  setLogLevel,
} from '@angular/fire/app';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import {
  ReCaptchaEnterpriseProvider,
  ReCaptchaV3Provider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import {
  Firestore,
  enableIndexedDbPersistence,
  initializeFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getFirestore } from 'firebase/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

Sentry.init({
  dsn: environment.sentry,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  environment: environment.sentryEnv,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    if (environment.sentryEnv === 'staging') {
      console.log(error.originalError || error);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CoreModule,
    UnitsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAppCheck(() => {
      return initializeAppCheck(getApp(), {
        provider: new ReCaptchaEnterpriseProvider(
          environment.recaptchaEnterpriseSiteKey
        ),
        isTokenAutoRefreshEnabled: true,
      });
    }),

    provideFunctions(() => getFunctions()),
    provideFirestore(() => {
      return initializeFirestore(getApp(), {
        experimentalForceLongPolling: true,
      });
    }),
    OverlayModule,
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    GoogleMapsModule,
  ],
  providers: [
    UnitsService,
    CheckoutService,
    DatePipe,
    SearchCacheService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
