import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter',
})
export class NameFilterPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (!args || !args[0]) {
      return value;
    }
    const filter = args[0].toLocaleLowerCase();
    return filter
      ? value.filter(
          unit =>
            unit.MarketingHeadline__c &&
            unit.MarketingHeadline__c.toLocaleLowerCase().indexOf(filter) !== -1
        )
      : value;
  }
}
