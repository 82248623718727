<div class="full-page">
  <div class="unit-enquiry-thank-you-page">
    <div class="unit-details-concierge-card">
      <div>
        <p class="new-brand-color thank-you-text">
          Thank you for making a reservation with Travel Whisperer. One of our
          Personal Travel Designers will be in touch within 24 hours to assist
          you further.
        </p>
      </div>
      <div>
        <p class="new-brand-color thank-you-subtext">
          If you have any questions in the meantime, please feel free to call us
          at
          <a class="clickable-link new-brand-color" href="tel:+19706885193"
            >+1 (970) 688-5193</a
          >
          or email us at
          <a
            class="clickable-link new-brand-color"
            href="mailto:reservations@thetravelwhisperer.com"
            >reservations&#64;thetravelwhisperer.com</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>
