import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  locations,
  VillageOptions,
  villagesDescription,
  propertyTypeOptions,
} from '../../store/models/search-options';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { UnitsService } from 'src/app/services/units.service';
import { UnitSummary } from 'functions/src/common/unit-summary.model';
import { Router, ActivatedRoute } from '@angular/router';
import { fadeIn, fadeOut } from '../../utils/animations/fade-animations';
import { trigger } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { ComplexSummary } from 'src/app/store/models/complex.model';

export interface FeaturedCategories {
  ImageLink__c: string;
  Caption__c: string;
  Name: string;
  Description__c: string;
  ExternalLink__c: boolean;
  Link__c: string;
  searchQuery?: any;
  navigateTo?: string;
}

export interface LocationUnit {
  Destination: string;
  Units: UnitSummary[];
  Complexes: ComplexSummary[];
  FeaturedCategories?: FeaturedCategories[];
}

@Component({
  selector: 'twbooking-main-page',
  templateUrl: './main-page.component.html',
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
  styleUrls: ['./main-page.component.scss', './ghost-elements.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
  readonly locations: any[] = locations;
  readonly villageOptions: any[] = VillageOptions;
  readonly villagesDescription: any[] = villagesDescription;
  readonly propertyTypeOptions: any[] = propertyTypeOptions;
  heading = 'Homes for Every Occasion';

  mainPage = true;
  locationUnits: LocationUnit[] = [];
  columnNum = 1;
  columnNum2 = 2;
  gridSize = '55:47';
  subMedia: Subscription;
  sub: Subscription;
  mmOnly = false;
  Destination = null;
  params: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private unitService: UnitsService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit(): void {
    this.mainPage = true;
    this.Destination = null;
    this.columnNum = 2;
    this.subMedia = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.columnNum = 1;
          this.columnNum2 = 1;
          this.gridSize = '55:54';
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.columnNum = 2;
          this.columnNum2 = 3;
          this.gridSize = '55:52';
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.columnNum = 3;
          this.columnNum2 = 4;
          this.gridSize = '55:60';
        } else if (result.breakpoints[Breakpoints.Large]) {
          this.columnNum = 3;
          this.columnNum2 = 5;
          this.gridSize = '55:50';
        } else if (result.breakpoints[Breakpoints.XLarge]) {
          this.columnNum = 4;
          this.columnNum2 = 6;
          this.gridSize = '55:47';
        }
      });

    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
      this.titleService.setTitle('Mountain Management');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'Mountain Management',
      });
    } else {
      this.titleService.setTitle('The Travel Whisperer');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'The Travel Whisperer',
      });
    }

    this.sub = this.route.params.subscribe((params) => {
      this.params = params;
      if (params['Destination']) {
        this.Destination = params['Destination'];
        console.log('Village mode. Destination = ', this.Destination);
        this.heading = 'Villages in ' + this.Destination;
        const villagesList = this.villageOptions
          .filter((option) => option.location === this.Destination)
          .map((option) => option.value);
        console.log('villages list for this location: ', villagesList);
        this.mainPage = false;
        this.searchUnitsByLocation(villagesList, this.mainPage);
      } else {
        this.mainPage = true;
        this.searchUnitsByLocation(this.locations, this.mainPage);
      }
    });
  }

  searchUnitsByLocation(destinations, mainpage: boolean) {
    for (let i = 0; i < destinations.length; i++) {
      const location = destinations[i];
      const lu: LocationUnit = {
        Destination: location,
        Units: new Array(4).fill(null),
        Complexes: [],
        FeaturedCategories: mainpage ? new Array(4).fill({ Link__c: '' }) : [],
      };
      this.locationUnits.push(lu);

      let searchQuery;
      if (mainpage) {
        this.unitService
          .fetchFeaturedCategories(location)
          .subscribe((resp: any) => {
            if (resp && resp.length > 0) {
              this.locationUnits[i].FeaturedCategories = resp;
              this.locationUnits[i].FeaturedCategories.forEach((category) => {
                if (category.Link__c && !category.ExternalLink__c) {
                  const tmp = this.generateSearchQuery(
                    category.Link__c,
                    destinations[i]
                  );
                  category.navigateTo = tmp.navigateTo;
                  category.searchQuery = tmp.searchQuery;
                }
              });
            } else {
              this.locationUnits[i].FeaturedCategories = [];
            }
          });
        searchQuery = { Destination: location };
      } else {
        searchQuery = { Village: location };
      }

      if (
        this.mainPage &&
        searchQuery.Destination !== 'Beaver Creek, Colorado' &&
        searchQuery.Destination !== 'Crested Butte, Colorado'
      ) {
        searchQuery = { ...searchQuery, featured: true };
        this.unitService
          .complexSearch(searchQuery, this.mmOnly, 4, 0)
          .subscribe((resp: any) => {
            if (resp.complexes && resp.complexes.length > 0) {
              this.locationUnits[i].Complexes = resp.complexes;
            } else {
              this.locationUnits[i].Complexes = [];
            }
          });
      } else {
        this.unitService
          .unitSearch(searchQuery, this.mmOnly, this.router.url, 4, 0)
          .subscribe((resp: any) => {
            if (resp.units && resp.units.length > 0) {
              this.locationUnits[i].Units = resp.units;
            } else {
              this.locationUnits[i].Units = [];
            }
          });
      }
    }
  }

  generateSearchQuery(url, destination) {
    const tree = this.router.parseUrl(url);

    let navigateTo = '';
    tree.root.children.primary.segments.forEach((segment) => {
      navigateTo += '/' + segment.path;
    });

    let searchQuery: any = tree.queryParams;
    searchQuery = {
      Destination: destination,
      ...searchQuery,
    };
    return { searchQuery: searchQuery, navigateTo: navigateTo };
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
    this.sub.unsubscribe();
  }

  openFeatured(featured: FeaturedCategories) {
    if (featured.navigateTo) {
      const queryParams = featured.searchQuery ? featured.searchQuery : null;
      this.router.navigate([featured.navigateTo], { queryParams });
    }
  }
  identifyUnit(index, unit) {
    return unit ? unit.externalId : null;
  }
}
