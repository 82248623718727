<div class="unit-details-tab" *ngIf="reviewsList && reviewsTotal > 0">
  <div class="header-separator-left">
    <h2 class="title-heading">Reviews</h2>
    <div class="separator"></div>
  </div>

  <div class="reviews-summary-wrapper padding-bottom">
    <div class="review-sub-rating" *ngIf="reviewsSummary.Rating__c">
      <h4 class="reviews-total-header">
        {{ reviewsTotal }} Review<ng-container *ngIf="!(reviewsTotal == 1)"
          >s</ng-container
        >
      </h4>
      <div class="review-sub-stars">
        @for (ratingId of ratingArr; track $index ;let i = $index){
        <span [id]="'star_' + i">
          <mat-icon class="review-stars">{{
            showStarsIcon(i, reviewsSummary.Rating__c)
          }}</mat-icon>
        </span>
        }
      </div>
    </div>
  </div>

  <div
    class="reviews-summary-wrapper"
    *ngIf="reviewsSummary.Cleanliness__c || reviewsSummary.Location__c"
  >
    <div class="review-sub-rating" *ngIf="reviewsSummary.Cleanliness__c">
      <div class="reviews-total-header">Cleanliness</div>
      <div class="review-sub-stars">
        @for (ratingId of ratingArr; track $index ;let i = $index){
        <span [id]="'star_' + i">
          <mat-icon class="review-stars small-star">{{
            showStarsIcon(i, reviewsSummary.Cleanliness__c)
          }}</mat-icon>
        </span>
        }
      </div>
    </div>
    <div class="review-sub-rating" *ngIf="reviewsSummary.Location__c">
      <div class="reviews-total-header">Location</div>
      <div class="review-sub-stars">
        @for (ratingId of ratingArr; track $index ;let i = $index){
        <span [id]="'star_' + i">
          <mat-icon class="review-stars small-star">{{
            showStarsIcon(i, reviewsSummary.Location__c)
          }}</mat-icon>
        </span>
        }
      </div>
    </div>
  </div>
  <div
    class="reviews-summary-wrapper"
    *ngIf="
      reviewsSummary.Property_Condition__c || reviewsSummary.Services_From_PM__c
    "
  >
    <div class="review-sub-rating" *ngIf="reviewsSummary.Property_Condition__c">
      <div class="reviews-total-header">Property Condition</div>
      <div class="review-sub-stars">
        @for (ratingId of ratingArr; track $index ;let i = $index){
        <span [id]="'star_' + i">
          <mat-icon class="review-stars small-star">{{
            showStarsIcon(i, reviewsSummary.Property_Condition__c)
          }}</mat-icon>
        </span>
        }
      </div>
    </div>
    <div class="review-sub-rating" *ngIf="reviewsSummary.Services_From_PM__c">
      <div class="reviews-total-header">Property Manager Services</div>
      <div class="review-sub-stars">
        @for (ratingId of ratingArr; track $index ;let i = $index){
        <span [id]="'star_' + i">
          <mat-icon class="review-stars small-star">{{
            showStarsIcon(i, reviewsSummary.Services_From_PM__c)
          }}</mat-icon>
        </span>
        }
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  @for (review of reviewsList; track review.externalId ;let i = $index){
  <div>
    <div *ngIf="i < 3 || showAll">
      <div class="review-container">
        <div class="review-meta">
          <div class="name-and-stars">
            <h4 class="review-name">{{ review.GuestDisplayName__c }}</h4>
            <div class="single-review-stars-container">
              @for (ratingId of ratingArr; track $index ;let i = $index){
              <span [id]="'star_' + i">
                <mat-icon class="review-stars-user review-stars">{{
                  showStarsIcon(i, review.Rating__c)
                }}</mat-icon>
              </span>
              }
            </div>
          </div>
          <div class="review-date">{{ review.Review_Received_Date }}</div>
        </div>
        <div class="review-content">
          <div class="review-description" *ngIf="review.Description__c">
            <span
              *ngIf="!review.fullReview"
              [innerHTML]="
                review.Description__c | truncate : 200 : false : '' | safeHtml
              "
            ></span>
            <span
              *ngIf="!review.fullReview && review.Description__c.length > 200"
            >
              <a class="show-more-nav" (click)="review.fullReview = true"
                >see more.</a
              ></span
            >
          </div>

          <div
            *ngIf="review.Description__c && review.fullReview"
            class="review-description"
            [innerHTML]="review.Description__c | safeHtml"
          ></div>

          <div
            class="review-manager-header"
            *ngIf="review.Manager_s_Response__c"
          >
            Response from management team:
          </div>
          <div
            class="review-manager-response"
            *ngIf="review.Manager_s_Response__c"
          >
            {{ review.Manager_s_Response__c }}
          </div>
        </div>
      </div>

      <br />
    </div>
  </div>
  }
  <div *ngIf="showAll">
    <a class="show-more-nav" (click)="showAll = !showAll"
      ><span>Hide reviews</span>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
  </div>
  <div *ngIf="!showAll && reviewsList.length > 3">
    <a class="show-more-nav" (click)="showAll = !showAll"
      ><span>Show all reviews</span>
      <span class="hover-arrows">
        <mat-icon></mat-icon>
        <mat-icon class="right-arrow-classic">keyboard_arrow_right</mat-icon>
        <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
      </span></a
    >
  </div>
</div>
<!-- end of *ngIf='reviewsExist'-->

<div class="unit-details-tab" *ngIf="!(reviewsList && reviewsTotal > 0)">
  <div class="header-separator-left">
    <h1 class="title-heading">Reviews</h1>
    <div class="separator"></div>
  </div>
  <div class="reviews-summary-wrapper">
    <p class="review-description">
      There are currently no reviews for {{ unit?.MarketingHeadline__c }}
    </p>
  </div>
</div>
