import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'twbooking-inquiry-thankyou',
  templateUrl: './unit-enquiry-thank-you.component.html',
  styleUrls: ['./unit-enquiry-thank-you.component.scss'],
})
export class UnitEnquiryThankYouComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    (<any>window).fbq.push('track', 'Lead');
  }
}
