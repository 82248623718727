<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    position="start"
    fixedTopGap="91"
    fixedInViewport="true"
    attr.role="dialog"
    mode="over"
  >
    <mat-nav-list
      class="new-brand-color"
      disableRipple="true"
      autoFocus="false"
    >
      <a
        *ngIf="mode !== 'MM'"
        class="mobile-menu-item"
        mat-list-item
        (click)="openSearchDialog()"
        label="Show"
      >
        <mat-icon>search</mat-icon> Search
      </a>

      @for (link of links; track link.url; let idx = $index) {
      <mat-accordion>
        @if(link.items && link.items.length > 0){
        <mat-expansion-panel class="mobile-menu-expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <a
                mat-list-item
                [class.safari-mobile-menu-item]="link.text == 'Safari'"
                target="_blank"
                href="{{ link.url }}"
                >{{ link.text }}</a
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for (item of link.items; track item.url; let item_idx = $index) {
          <a
            mat-menu-item
            [class.safari-menu]="link.text == 'Safari'"
            [target]="item.sametab ? '_self' : '_blank'"
            href="{{ item.url }}"
            >{{ item.text }}</a
          >
          }
        </mat-expansion-panel>
        } @else {
        <a
          target="_blank"
          mat-list-item
          class="no-hover mobile-menu-item"
          href="{{ link.url }}"
          >{{ link.text }}</a
        >
        }
      </mat-accordion>

      }
      <a
        *ngIf="mode === 'MM'"
        mat-list-item
        class="no-hover mobile-menu-item"
        href="/units/"
        >Properties</a
      >
      <a
        *ngIf="mode === 'MM'"
        mat-list-item
        class="no-hover mobile-menu-item"
        target="_blank"
        href="https://www.mountainmanagement.com/about/"
        >About</a
      >

      <a
        *ngIf="mode === 'MM'"
        mat-list-item
        class="no-hover mobile-menu-item"
        target="_blank"
        href="https://www.mountainmanagement.com/contact/"
        >Contact</a
      >
      <a
        *ngIf="mode === 'MM'"
        mat-list-item
        class="no-hover mobile-menu-item"
        href="/contact-us"
        >Book with us</a
      >

      <a
        *ngIf="mode === 'Laurie'"
        mat-list-item
        class="no-hover mobile-menu-item"
        href="tel:+3038688431"
        >Call: (303) 868-8431</a
      >
      <a
        *ngIf="mode === 'Amanda'"
        mat-list-item
        class="no-hover mobile-menu-item"
        href="tel:+17857669827"
        >Call: 785-766-9827</a
      >
      <a
        *ngIf="mode === 'smartflyer'"
        mat-list-item
        class="no-hover mobile-menu-item"
        ref="tel:+18006264449"
        >Call: 1-800-626-4449</a
      >
      <a
        *ngIf="mode === 'jettsettravel'"
        mat-list-item
        class="no-hover mobile-menu-item"
        ref="tel:9403831528"
        >Call: 940-383-1528</a
      >
      <a
        *ngIf="mode === 'packhappytravel'"
        mat-list-item
        class="no-hover mobile-menu-item"
        ref="tel:8168076737"
        >Call: 816-807-6737
      </a>
    </mat-nav-list>
    <router-outlet></router-outlet>
  </mat-sidenav>

  <mat-sidenav
    #search
    class="sidenav"
    position="end"
    fixedInViewport="true"
    attr.role="dialog"
    mode="over"
    [ngClass]="{
      'search-fullscreen': (isHandset | async)!.matches,
      'search-sidenav': !(isHandset | async)!.matches
    }"
  >
    <mat-sidenav-content class="search-sidenav-content">
      <div>
        <twbooking-registration-form
          [sidenav]="search"
          formMode="sidebar"
        ></twbooking-registration-form>
      </div>
    </mat-sidenav-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="tw-toolbar">
      <ng-template #incognitoPopover>
        <div class="incognito-popover brand-accent-background">
          Sign in not supported in Incognito mode
        </div>
      </ng-template>
      <div *ngIf="reducedMenu" class="tw-toolbar-mobile">
        <div>
          <a
            mat-icon-button
            *ngIf="mode === 'basic'"
            class="no-hover like-button side-nav-item"
            href="tel:+19706885193"
          >
            <mat-icon
              svgIcon="phone-light"
              class="new-brand-color"
              style="vertical-align: middle"
            ></mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="mode === 'Laurie'"
            class="no-hover like-button side-nav-item"
            href="tel:+3038688431"
          >
            <mat-icon
              svgIcon="phone-light"
              style="vertical-align: middle"
              class="new-brand-color"
            ></mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="mode === 'Amanda'"
            class="no-hover like-button side-nav-item"
            href="tel:+17857669827"
          >
            <mat-icon
              svgIcon="phone-light"
              style="vertical-align: middle"
              class="new-brand-color"
            ></mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="mode === 'smartflyer'"
            class="no-hover like-button side-nav-item"
            href="tel:+18006264449"
          >
            <mat-icon
              svgIcon="phone-light"
              style="vertical-align: middle"
              class="new-brand-color"
            ></mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="mode === 'jettsettravel'"
            class="no-hover like-button side-nav-item"
            href="tel:9403831528"
          >
            <mat-icon
              svgIcon="phone-light"
              style="vertical-align: middle"
              class="new-brand-color"
            ></mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="mode === 'packhappytravel'"
            class="no-hover like-button side-nav-item"
            href="tel:8168076737"
          >
            <mat-icon
              svgIcon="phone-light"
              style="vertical-align: middle"
              class="new-brand-color"
            ></mat-icon>
          </a>

          <button
            type="button"
            aria-label="Toggle search"
            mat-icon-button
            (click)="openSearchDialog()"
            class="side-nav-item"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <a class="tw-toolbar-logo-container" href="{{ this.urlBase }}">
          <img src="{{ logoSrc }}" alt="{{ this.logoAlt }}" />
        </a>
        <div>
          <button
            class="side-nav-item"
            *ngIf="
              !authorized &&
              (mode === 'basic' || mode === 'MM') &&
              userRole !== 'loading'
            "
            type="button"
            aria-label="Toggle search"
            mat-icon-button
            (click)="search.toggle()"
            ttw-tooltip
            [tooltipHtml]="incognitoPopover"
            tooltipHideDelay="1000"
            tooltipOnHover="false"
          >
            <mat-icon>perm_identity</mat-icon>
          </button>
          <button
            class="side-nav-item transparent-focus-indicator"
            *ngIf="authorized && (mode === 'basic' || mode === 'MM')"
            type="button"
            aria-label="Toggle search"
            mat-icon-button
            [matMenuTriggerFor]="menuMobile"
          >
            <mat-icon
              *ngIf="(pendingPayments && pendingPayments > 0) || lodgingQuote"
              [matBadge]="pendingPayments + lodgingQuote"
              matBadgePosition="before"
              matBadgeSize="small"
              svgIcon="account-circle"
            ></mat-icon>
            <mat-icon
              *ngIf="!pendingPayments && !lodgingQuote"
              svgIcon="account-circle"
            ></mat-icon>
          </button>
          <button
            *ngIf="userRole === 'loading'"
            type=" button"
            aria-label="Toggle search"
            mat-icon-button
            [matMenuTriggerFor]="menuMobile"
            class="spinner-button"
          >
            <mat-spinner [diameter]="18"></mat-spinner>
          </button>
          <mat-menu #menuMobile="matMenu" hasBackdrop="false">
            <button mat-menu-item (click)="openProfile()">
              <mat-icon>account</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item (click)="openFavorites()">
              <mat-icon>favorite</mat-icon>
              <span>Favorites</span>
            </button>
            <button mat-menu-item (click)="openBookings()" *ngIf="guestPortal">
              <mat-icon>card_travel</mat-icon>
              <span>Reservations</span>
            </button>
            <button
              mat-menu-item
              (click)="openPayments()"
              *ngIf="pendingPayments && pendingPayments > 0"
            >
              <mat-icon [matBadge]="pendingPayments" matBadgePosition="after"
                >receipt</mat-icon
              >
              <span>Payments</span>
            </button>
            <button
              mat-menu-item
              (click)="openLodgingQuote()"
              *ngIf="lodgingQuote && lodgingQuote > 0"
            >
              <mat-icon [matBadge]="lodgingQuote" matBadgePosition="after"
                >receipt</mat-icon
              >
              <span>Lodging Quote</span>
            </button>
            <button mat-menu-item (click)="logOut()">
              <mat-icon>logout</mat-icon>
              <span>Log out</span>
            </button>
          </mat-menu>

          <button
            *ngIf="!drawer.opened"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
          >
            <mat-icon class="new-brand-color" aria-label="Side nav toggle icon"
              >menu</mat-icon
            >
          </button>
          <button
            *ngIf="drawer.opened"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            class="close-icon"
          >
            <mat-icon class="new-brand-color" aria-label="Side nav toggle icon"
              >close</mat-icon
            >
          </button>
        </div>
      </div>
      <a
        *ngIf="!reducedMenu"
        class="tw-toolbar-logo-container"
        href="{{ this.urlBase }}"
      >
        <img src="{{ logoSrc }}" alt="{{ this.logoAlt }}" />
      </a>
      <div class="tw-toolbar-buttons-container" *ngIf="!reducedMenu">
        <button
          *ngIf="mode !== 'MM'"
          mat-button
          class="no-hover top-panel-item"
          (click)="openSearchDialog()"
          label="Show"
        >
          <mat-icon>search</mat-icon> Search
        </button>
        @for (link of links; track link.url; let idx = $index) { @if(link.items
        && link.items.length > 0){

        <a
          href="{{ link.url }}"
          target="_blank"
          class="no-hover top-panel-item"
          mat-button
          [disableRipple]="true"
          autoFocus="false"
          #menuTriggerRef="matMenuTrigger"
          [matMenuTriggerFor]="menuDropdownOptions"
          (mouseenter)="openMenu(idx); menuTriggerRef.openMenu()"
          (mouseleave)="startCloseTimeout(idx, menuTriggerRef)"
        >
          <span class="menu-dropdown"
            ><span>{{ link.text }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon></span
          ></a
        >
        <mat-menu
          #menuDropdownOptions="matMenu"
          hasBackdrop="false"
          yPosition="below"
        >
          @for (item of link.items; track item.url; let item_idx = $index) {

          <a
            mat-menu-item
            [class.safari-menu]="link.text == 'Safari'"
            [target]="item.sametab ? '_self' : '_blank'"
            href="{{ item.url }}"
            (mouseenter)="clearCloseTimeout(idx)"
            (mouseleave)="startCloseTimeout(idx, menuTriggerRef)"
            >{{ item.text }}</a
          >
          }
        </mat-menu>

        } @else {
        <a
          class="no-hover top-panel-item"
          [class.safari-new-menu-item]="link.text == 'Safari'"
          target="_blank"
          href="{{ link.url }}"
          >{{ link.text }}</a
        >
        } }
        <a *ngIf="mode === 'MM'" class="no-hover top-panel-item" href="/units/"
          >Properties</a
        >
        <a
          *ngIf="mode === 'MM'"
          class="no-hover top-panel-item"
          target="_blank"
          href="https://www.mountainmanagement.com/about/"
          >About</a
        >

        <a
          *ngIf="mode === 'MM'"
          class="no-hover top-panel-item"
          mat-button
          [matMenuTriggerFor]="contactBelowMenuMM"
          >Contact</a
        >
        <mat-menu
          #contactBelowMenuMM="matMenu"
          yPosition="below"
          hasBackdrop="false"
        >
          <a
            mat-menu-item
            href="https://www.mountainmanagement.com/contact/"
            target="_blank"
            >Contact Us</a
          >
          <a mat-menu-item href="/contact-us/">Book with us</a>
        </mat-menu>

        <a
          *ngIf="mode === 'Laurie'"
          class="mat-button no-hover top-panel-item"
          href="tel:+3038688431"
          >Call: (303) 868-8431</a
        >
        <a
          *ngIf="mode === 'Amanda'"
          class="mat-button no-hover top-panel-item"
          href="tel:+17857669827"
          >Call: 785-766-9827</a
        >
        <a
          *ngIf="mode === 'smartflyer'"
          class="mat-button no-hover top-panel-item"
          href="tel:+18006264449"
          >Call: 1-800-626-4449</a
        >
        <a
          *ngIf="mode === 'jettsettravel'"
          class="mat-button no-hover top-panel-item"
          href="tel:9403831528"
          >Call: 940-383-1528</a
        >
        <a
          *ngIf="mode === 'packhappytravel'"
          class="mat-button no-hover top-panel-item"
          href="tel:8168076737"
          >Call: 816-807-6737
        </a>
        <button
          *ngIf="
            !authorized &&
            (mode === 'basic' || mode === 'MM') &&
            userRole !== 'loading'
          "
          mat-button
          class="no-hover top-panel-item"
          (click)="search.toggle()"
          label="Show"
          ttw-tooltip
          [tooltipHtml]="incognitoPopover"
          tooltipHideDelay="1000"
          tooltipOnHover="false"
        >
          <mat-icon>perm_identity</mat-icon>
        </button>

        <button
          *ngIf="userRole === 'loading'"
          type="button"
          aria-label="Toggle search"
          mat-icon-button
          class="spinner-button"
        >
          <mat-spinner [diameter]="18"></mat-spinner>
        </button>
        <mat-menu-trigger
          #guestMenuTriggerRef="matMenuTrigger"
          [matMenuTriggerFor]="guestMenu"
        ></mat-menu-trigger>
        @if(authorized && (mode === 'basic' || mode === 'MM')){
        <button
          #guestMenuTriggerRef="matMenuTrigger"
          [matMenuTriggerFor]="guestMenu"
          mat-button
          class="no-hover top-panel-item"
          label="Show"
          (mouseenter)="openMenu(links.length); guestMenuTriggerRef.openMenu()"
          (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
        >
          <span
            *ngIf="(pendingPayments && pendingPayments > 0) || lodgingQuote"
            class="menu-dropdown"
          >
            <span
              [matBadge]="pendingPayments + lodgingQuote"
              matBadgePosition="before"
              matBadgeOverlap="false"
              matBadgeSize="small"
              class="label-with-badge"
              >You</span
            >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </span>
          <span *ngIf="!pendingPayments && !lodgingQuote">
            <span class="menu-dropdown"
              ><span>You</span> <mat-icon>keyboard_arrow_down</mat-icon></span
            ></span
          >
        </button>

        <mat-menu #guestMenu="matMenu" hasBackdrop="false" yPosition="below">
          <button
            mat-menu-item
            (click)="openProfile()"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon>account</mat-icon>
            <span>Profile</span>
          </button>
          <button
            mat-menu-item
            (click)="openFavorites()"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon>favorite</mat-icon>
            <span>Favorites</span>
          </button>

          <button
            mat-menu-item
            (click)="openBookings()"
            *ngIf="guestPortal"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon>card_travel</mat-icon>
            <span>Reservations</span>
          </button>
          <button
            mat-menu-item
            (click)="openPayments()"
            *ngIf="pendingPayments && pendingPayments > 0"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon [matBadge]="pendingPayments" matBadgePosition="after"
              >receipt</mat-icon
            >
            <span>Payments</span>
          </button>

          <button
            mat-menu-item
            (click)="openLodgingQuote()"
            *ngIf="lodgingQuote && lodgingQuote > 0"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon [matBadge]="lodgingQuote" matBadgePosition="after"
              >receipt</mat-icon
            >
            <span>Lodging Quote</span>
          </button>

          <button
            mat-menu-item
            (click)="logOut()"
            class="side-nav-item"
            (mouseenter)="clearCloseTimeout(links.length)"
            (mouseleave)="startCloseTimeout(links.length, guestMenuTriggerRef)"
          >
            <mat-icon>logout</mat-icon>
            <span>Log out</span>
          </button>
        </mat-menu>
        }
      </div>
    </mat-toolbar>
    <div class="spacer">&nbsp;</div>
    @if(!hideChat){
    <twbooking-chat mode="button"></twbooking-chat>
    }
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
