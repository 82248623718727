<div class="not-text-mode" *ngIf="mode === 'button' &&
id && checkExistingChatsDone && ((status && status === 'On') || (testMode && testMode === 'On')) && !nb
">
  <div class="sticky-big-chat">
    <img *ngIf="ptdImage" [src]="ptdImage" width="28px" alt="Chat" />
    <button mat-button class="fixed-width-button brand-accent-background new-brand-color " [class.call-animation]="
      (id &&
        ((status && status === 'On') || (testMode && testMode === 'On')) &&
        !chatId) ||
      newMessage
    " aria-label="Chat button" (click)="openChatDialog()">

      <span *ngIf="newMessage" class="notification-dot">&#183;</span>

      <span class='padding-for-image'>Let's chat </span>

    </button>
  </div>
</div>
<div *ngIf="mode === 'text' && !nb" class="text-mode">
  <button *ngIf="
      id && ((status && status === 'On') || (testMode && testMode === 'On'))
    " class="chat-button brand-light-background brand-show-more-color" (click)="openChatDialog()">
    Chat <span *ngIf="newMessage" class="notification-dot">&#183;</span>
  </button>
</div>

<div *ngIf="mode === 'icon' && !nb" class="icon-mode">
  <button *ngIf="
      id && ((status && status === 'On') || (testMode && testMode === 'On'))
    " mat-icon-button class="chat-icon brand-accent-background new-brand-color" aria-label="Chat button"
    (click)="openChatDialog()">
    <span *ngIf="newMessage" class="notification-dot">&#183;</span>
    <mat-icon class="large-button brand-offwhite-color">chat</mat-icon>
  </button>
</div>