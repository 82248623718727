import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'twbooking-map-search-page',
  templateUrl: './map-search-page.component.html',
  styleUrls: ['./map-search-page.component.scss'],
})
export class MapSearchPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
