import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { AuthenticatedApiService } from 'src/app/services/authenticated-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'twbooking-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  mode = 'share';
  link = null;
  shareInProgress = false;
  sharedstatus = false;
  needtoshare = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private authApiService: AuthenticatedApiService
  ) {
    if (this.data && this.data.sharedlink) {
      if (environment.sentryEnv === 'production') {
        this.link = environment.ttw + 'favorites/' + this.data.sharedlink;
      } else {
        this.link =
          environment.stagingwebapp + 'favorites/' + this.data.sharedlink;
      }
    }

    this.needtoshare = this.data.needtoshare;
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  getLink() {
    //TODO
    this.mode = 'link';
    this.shareLink();
  }

  async shareLink() {
    if (this.needtoshare) {
      this.shareInProgress = true;
      try {
        this.authApiService
          .httpCallWithRefresh(
            '/api/sharefavorites',
            {
              user: this.authService.getUserId(),
              sharedlink: this.data.sharedlink,
            }
          )
          .subscribe((resp: any) => {
            if (resp) {
              this.shareInProgress = false;
              if (resp && resp.status) {
                this.sharedstatus = resp.status;
              }
            }
          });
      } catch (e) {
        console.error('error in shareLink', e);
      }
    }
  }
}
