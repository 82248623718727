import {
  Component,
  forwardRef,
  Host,
  Input,
  Optional,
  Self,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'twbooking-plus-minus-input',
  templateUrl: './plus-minus-input.component.html',
  styleUrls: ['./plus-minus-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PlusMinusInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PlusMinusInputComponent,
      multi: true,
    },
  ],
})
export class PlusMinusInputComponent
  implements ControlValueAccessor, Validator
{
  @Input() min = 0;
  @Input() max = 10;
  @Input() fieldName;
  @Input() fieldHint;
  value = 2;

  onTouched = () => {};

  touched = false;

  constructor() {}

  ngOnInit(): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    const counter = control.value;
    if (counter < 0) {
      return {
        mustBeGreaterThenZero: {
          counter,
        },
      };
    }
  }

  addValue() {
    if (this.value < this.max) {
      this.markAsTouched();
      this.value++;
      this.onChange(this.value);
    }
  }

  subtractValue() {
    if (this.value > this.min) {
      this.markAsTouched();
      this.value--;
      this.onChange(this.value);
    }
  }

  writeValue(newValue: number) {
    this.value = newValue;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onChange = (value) => {};

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
