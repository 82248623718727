import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

export interface IResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Component({
  selector: 'twbooking-favorites-list',
  templateUrl: './favorites-list.component.html',
  styleUrls: ['./favorites-list.component.scss'],
})
export class FavoritesListComponent implements OnInit, OnDestroy {
  mediaSub: Subscription = null;
  @Input() unitList;
  @Input() name;

  columnNum: number;
  rowHeightRatio: string;
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.mediaSub = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.columnNum = 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.columnNum = 2;
        } else if (
          result.breakpoints[Breakpoints.Medium] ||
          result.breakpoints[Breakpoints.Large]
        ) {
          this.columnNum = 3;
        } else if (result.breakpoints[Breakpoints.XLarge]) {
          this.columnNum = 4;
        }
      });
  }

  identifyUnit(index, unit) {
    return unit ? unit.externalId : null;
  }

  ngOnDestroy(): void {
    if (this.mediaSub) {
      this.mediaSub.unsubscribe();
    }
  }
}
