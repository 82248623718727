import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { Payment } from '../user/models/payments';
import { AuthService } from './auth.service';
import { AuthenticatedApiService } from './authenticated-api.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  public payments$: BehaviorSubject<Payment[]>;
  public pendingPayments$: BehaviorSubject<number>;
  public bookingWithPayments$: BehaviorSubject<string>;
  userVerifiedSub: Subscription;
  constructor(
    private authApiService: AuthenticatedApiService,
    private authService: AuthService
  ) {
    this.payments$ = new BehaviorSubject(null);
    this.pendingPayments$ = new BehaviorSubject(0);
    this.bookingWithPayments$ = new BehaviorSubject(null);
  }

  resetPayments() {
    this.payments$.next(null);
    this.pendingPayments$ = new BehaviorSubject(0);
    this.bookingWithPayments$ = new BehaviorSubject(null);
  }

  getPayments(): Payment[] {
    return this.payments$.value;
  }

  parsePayments(input: any): boolean {
    try {
      let bookingWithPayment = null;
      let pendingPayments = 0;

      let newPayments: Payment[] = [];
      if (input && input.length) {
        input.forEach((bookingPayment) => {
          if (
            bookingPayment &&
            bookingPayment.PaymentHistory__r &&
            bookingPayment.PaymentHistory__r.records &&
            bookingPayment.PaymentHistory__r.records.length
          ) {
            bookingPayment.PaymentHistory__r.records.forEach((payment) => {
              if (
                payment.Status__c === 'Pending' ||
                payment.Status__c === 'Initiated' ||
                payment.Status__c === 'Failed'
              ) {
                pendingPayments += 1;
                bookingWithPayment = bookingPayment.Id;
              }
              let newPayment: Payment = {
                Id: payment.Id,
                bookingId: bookingPayment.Id,
                Amount__c: payment.Amount__c,
                Component_Payment__c: payment.Component_Payment__c,
                GuestReference__c: payment.GuestReference__c
                  ? payment.GuestReference__c
                  : payment.Id.toLowerCase(),
                Date__c: payment.Date__c,
                Description__c: payment.Description__c,
                Name: payment.Name,
                showDetails: true,
                Status__c: payment.Status__c,
                ACHOnly__c: payment.ACHOnly__c ? payment.ACHOnly__c : false,
                InitiatedPaymentDate__c: payment.InitiatedPaymentDate__c
                  ? payment.InitiatedPaymentDate__c
                  : null,
                Travel_Insurance__c: payment.Travel_Insurance__c
                ? payment.Travel_Insurance__c
                : null,
                Final_Payment__c: payment.Final_Payment__c
                ? payment.Final_Payment__c
                : null,
              };
              newPayments.push(newPayment);
            });

            bookingWithPayment = bookingWithPayment
              ? bookingWithPayment
              : bookingPayment.Id;
          }
        });
        newPayments = newPayments.sort((a, b) => this.sortByStatuses(a, b));
        this.payments$.next(newPayments);
        this.bookingWithPayments$.next(bookingWithPayment);
        this.pendingPayments$.next(pendingPayments);
      } else {
        this.payments$.next([]);
        this.bookingWithPayments$.next(bookingWithPayment);
        this.pendingPayments$.next(pendingPayments);
      }

      return true;
    } catch (err) {
      console.error('error in parsePayments', err);
      return false;
    }
  }

  updatePayments(newPayments: Payment[]) {
    let pendingPayments = 0;
    let bookingWithPayment = null;
    newPayments.forEach((payment) => {
      if (
        payment.Status__c === 'Pending' ||
        payment.Status__c === 'Initiated' ||
        payment.Status__c === 'Failed'
      ) {
        pendingPayments += 1;
        bookingWithPayment = payment.bookingId;
      }
    });
    this.payments$.next(newPayments);

    this.bookingWithPayments$.next(bookingWithPayment);
    this.pendingPayments$.next(pendingPayments);
  }

  changeStatus(paymentId, newStatus): Observable<any> {
    return this.authApiService
      .httpCallWithRefresh('/api/updatePaymentStatus', {
        uuid: this.authService.getUserId(),
        updatedPayment: {
          Id: paymentId,
          Status__c: newStatus,
          GuestReference__c: paymentId.toLowerCase(),
        },
      })
      .pipe(
        map((res) => {
          let payments = this.payments$.value;
          let index = payments.findIndex((payment) => payment.Id === paymentId);
          if (index >= 0) {
            payments[index].Status__c = newStatus;
            this.updatePayments(payments);
          } else {
            throw new Error(
              'Payment not found with Id ' +
                paymentId +
                'cannot change status on front to ' +
                newStatus
            );
          }
          return res;
        })
      );
  }

  checkPendingPayments(): Observable<any> {
    //FIXME: remove me when beta tests will be over
    return new Observable((observer) => {
      this.userVerifiedSub = this.authService.userVerified$.subscribe((verified) => {
          if (verified && verified.verified && verified.email) {
            this.authApiService
              .httpCallWithRefresh(
                '/api/fetchPayments',
                {
                  uuid: this.authService.getUserId(),
                  email: verified.email,
                }
              )
              .subscribe((payments: any) => {
                if (payments && payments.result) {
                  let processedPayments = this.parsePayments(payments.result);
                  return observer.next(processedPayments);
                } else {
                  console.error('unable to fetch checkPendingPayments ');
                  return observer.next({
                    error: 'unable to fetch checkPendingPayments  ',
                  });
                }
              });
          } 
        }
      );
    });
  }

  checkPendingPaymentsForUser(requestedUser): Observable<any> {
    return new Observable((observer) => {
      this.userVerifiedSub = this.authService.userVerified$.subscribe((verified) => {
          if (verified && verified.verified && verified.email) {
            let bodyObject: any = {
              uuid: this.authService.getUserId(),
              email: verified.email,
            };
            if (this.authService.userRole$.value === 'ptd') {
              bodyObject = {
                ...bodyObject,
                requestedUser: requestedUser,
                ptdAccess: true,
              };
            }
            this.authApiService
              .httpCallWithRefresh('/api/fetchPayments', bodyObject)
              .subscribe((payments: any) => {
                if (payments && payments.result) {
                  let processedPayments = this.parsePayments(payments.result);
                  return observer.next(processedPayments);
                } else {
                  console.error('unable to fetch checkPendingPaymentsForUser ');
                  return observer.next({
                    error: 'unable to fetch checkPendingPaymentsForUser  ',
                  });
                }
              });
          } else {
            console.log(
              'user email is not verified,  no access to reservations'
            );
          }
        }
      );
    });
  }

  checkIfRecentDate(paymentDate: Date): boolean {
    let now = moment.utc();
    let initiatedPayment = moment.utc(paymentDate);
    let difference = now.diff(initiatedPayment) / 1000 / 60;
    return difference <= 60;
  }

  checkInitiatedPayments(
    status: string,
    paymentId: string
  ): Observable<boolean> {
    return new Observable((observer) => {
      this.payments$
        .pipe(
          takeWhile(
            (payments) => payments === null || payments === undefined,
            true
          )
        )
        .subscribe((payments) => {
          if (payments && payments.length) {
            payments.forEach((payment) => {
              if (payment.Id.toLowerCase() === paymentId.toLowerCase()) {
                this.changeStatus(payment.Id, status).subscribe((result) => {});
                observer.next(true);
                observer.complete();
                return;
              }
            });

            observer.next(false);
            observer.complete();
          } else {
            if (payments) {
              observer.next(false);
              observer.complete();
            }
          }
        });
    });
  }

  sortByStatuses(x: Payment, y: Payment) {
    /**
     * Pending payments first
     * then Initiated
     * other in alphabet order
     */
    let result;
    if (x.Status__c === y.Status__c) {
      return 0;
    }
    if (x.Status__c === 'Pending') {
      return -1;
    }
    if (y.Status__c === 'Pending') {
      return 1;
    }
    if (x.Status__c === 'Initiated' && y.Status__c !== 'Pending') {
      return -1;
    }
    if (y.Status__c === 'Initiated' && x.Status__c !== 'Pending') {
      return 1;
    }

    if (x.Status__c === 'Failed') {
      return -1;
    }

    if (y.Status__c === 'Failed') {
      return 1;
    }

    ('Failed');
    return x.Status__c < y.Status__c ? -1 : 1;
  }
}
