import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { GuestData } from 'src/app/store/models/guest-data.model';
import { EventEmitter } from '@angular/core';
import { MatSelect } from '@angular/material/select';

type GuestType = 'adults' | 'children' | 'infants' | 'pets';
type ActionType = '+' | '-';

@Component({
  selector: 'twbooking-guests-select',
  templateUrl: './guests-select.component.html',
  styleUrls: ['./guests-select.component.scss'],
})
export class GuestsSelectComponent implements OnInit {
  @ViewChild('mySelect') matSelect: MatSelect;
  @Input() numAdults: number;
  @Input() numChildren: number;
  @Input() numInfants: number;
  @Input() numPets: number;
  @Input() petFriendly: boolean;
  @Output() guestEmitter = new EventEmitter<GuestData>();

  adults = 2;
  children = 0;
  infants = 0;
  pets = 0;
  guests = '2 adults';

  constructor() {}

  ngOnInit(): void {
    this.adults = this.numAdults;
    this.children = this.numChildren;
    this.infants = this.numInfants;
    this.pets = this.numPets;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numAdults) {
      this.adults = changes.numAdults.currentValue;
    }
    if (changes.numChildren) {
      this.children = changes.numChildren.currentValue;
    }
    if (changes.numInfants) {
      this.infants = changes.numInfants.currentValue;
    }
    if (changes.numPets) {
      this.pets = changes.numPets.currentValue;
    }
    this.generateGuestsString();
  }

  guestsPanelClose() {
    this.numberofGuestChanged();
    this.matSelect.close();
  }

  numberofGuestChanged() {
    this.guestEmitter.emit({
      adults: this.adults,
      children: this.children,
      pets: this.pets,
      infants: this.infants,
    });
  }

  changeGuests(action: ActionType, guestType: GuestType): void {
    const guestMap: Record<GuestType, string> = {
      adults: 'adults',
      children: 'children',
      infants: 'infants',
      pets: 'pets',
    };

    if (action === '+') {
      this[guestMap[guestType]]++;
    } else if (
      action === '-' &&
      this[guestMap[guestType]] > (guestType === 'adults' ? 1 : 0)
    ) {
      this[guestMap[guestType]]--;
    }

    this.generateGuestsString();
  }

  generateGuestsString() {
    this.guests = this.numAdults.toString() + ' adult';
    if (this.adults > 1) {
      this.guests += 's';
    }
    if (this.children > 0) {
      this.guests += ', ' + this.children.toString() + ' child';
      if (this.children > 1) {
        this.guests += 'ren';
      }
    }

    if (this.infants > 0) {
      this.guests += ', ' + this.infants.toString() + ' infant';
      if (this.infants > 1) {
        this.guests += 's';
      }
    }

    if (this.pets > 0) {
      this.guests += ', ' + this.pets.toString() + ' pet';
      if (this.pets > 1) {
        this.guests += 's';
      }
    }
  }
}
