import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthenticatedApiService } from './authenticated-api.service';

export class FavoriteUnit {
  sessionId?: number;
  unit: string;
  user: string;
}

export class Favorite {
  unit: string;
  Name: string;
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  public favorites$: BehaviorSubject<Favorite[]>;

  constructor(
    private authApiService: AuthenticatedApiService,
    private authService: AuthService
  ) {
    this.favorites$ = new BehaviorSubject([]);
  }

  async init() {
    this.authService.userUID$.subscribe(async (uuid) => {
      if (uuid) {
        this.authApiService
          .httpCallWithRefresh(
            '/api/getfavorites',
            {
              user: uuid,
            }
          )
          .subscribe((result: any) => {
            let previousfavorites = this.favorites$.value;
            let newfavorites = [];

            if (result && result.length) {
              newfavorites = result;
              this.favorites$.next(result);
            } else {
              newfavorites = [];
              this.favorites$.next([]);
            }

            if (previousfavorites && previousfavorites.length) {
              previousfavorites.forEach((favorite) => {
                if (
                  !(
                    newfavorites &&
                    newfavorites.length &&
                    newfavorites.indexOf(favorite) >= 0
                  )
                ) {
                  this.add(favorite);
                }
              });
            }
          });
      } /*else {
        this.favorites$.next([]);
      }*/ //QUESTION should I null likes on log out?
    });
  }

  retryFavorites() {}

  public get() {
    return this.favorites$.value;
  }

  public async add(favoriteUnit: Favorite) {
    let units = this.favorites$.value;
    units.push(favoriteUnit);
    this.favorites$.next(units);
    let uuid = this.authService.getUserId();
    if (uuid) {
      let sessionId = this.authService.sessionId.value;
      this.authApiService
        .httpCallWithRefresh(
          '/api/savefavorite',
          {
            favorite: {
              user: uuid,
              sessionId: sessionId,
              unit: favoriteUnit.unit,
            },
            user: uuid,
          }
        )
        .subscribe((result: any) => {});
    }
  }

  public isFavorite(unit: Favorite): boolean {
    let units = this.favorites$.value;
    let obj = units.find((o) => o.unit === unit.unit);
    return obj ? true : false;
  }

  public async remove(unit: Favorite) {
    let units = this.favorites$.value;
    let obj = units.find((o) => o.unit === unit.unit);
    if (obj) {
      const index = units.indexOf(obj);
      if (index > -1) {
        units.splice(index, 1);
      }
      this.favorites$.next(units);
    }

    let uuid = this.authService.getUserId();
    if (uuid) {
      this.authApiService
        .httpCallWithRefresh(
          '/api/deletefavorite',
          {
            toDelete: {
              user: uuid,
              unit: unit.unit,
            },
            user: uuid,
          }
        )
        .subscribe((result: any) => {});
    }
  }
}
