// If this is update, make sure changes are update in both function/src/common and src/store/models

export const mobileSM = 576;
export const maxImageWidth = 1920;

export interface Unit {
  externalId: string;
  MarketingHeadline__c: string;
  Name: string;
  BunkBeds__c: number;
  Bedrooms__c: string;
  Bathrooms__c: string;
  Sleeps__c: number;
  DisplayRate__c: string;
  Active__c: boolean;
  AddressLine1__c: string;
  AddressLine2__c: string;
  Address_Line_2__c: string;
  Amenities__r: any[];
  CatsAllowed__c: boolean;
  Coordinates__c: any;
  Coordinates__Latitude__s: any;
  Coordinates__Longitude__s: any;
  CreatedDate: string;
  IsDeleted: boolean;
  Dining_Seats__c: number;
  Dogs_Allowed__c: boolean;
  Driving_Directions__c: string;
  Full_Beds__c: number;
  gridHero__c: boolean;
  IncludeInSearch__c: boolean;
  Inspection__c: string;
  Inspection_Comment__c: string;
  King_Beds__c: number;
  LastActivityDate: string;
  LastModifiedDate: string;
  LastReferencedDate: string;
  LastViewedDate: string;
  Long_Description__c: string;
  Lofts__c: number;
  Pets_Allowed__c: boolean;
  Postal_Code__c: string;
  Property_Collection__c: string;
  Village__Name: string;
  Destination__Name: string;
  Queen_Beds__c: number;
  ReservationEnd__c: string;
  ReservationStart__c: string;
  Reviews__r: ReviewRecord[];
  FeaturedAmenities__r: FeaturedAmenity[];
  Sleeper_Sofas__c: number;
  Smoking_Allowed__c: boolean;
  SystemModstamp: string;
  Twin_Beds__c: number;
  UnitCode__c: string;
  UnitNotes__c: string;
  UnitType__c: string;
  UnitTaxesFees__r: UnitTaxFee[];
  WholesaleLink__c: string;
  Images__r: UnitImage[];
  ImagesProcessed?: UnitImage[];
  UnitsInformation__r: UnitsInformation;
  PriorityListing__c?: boolean;
  UnitSlug__c?: string;
  TaxRate__c: number;
  CheckOutCleanFee__c: number;
  Damage_Protection_Fee__c: number;
  PetFee__c: number;
  DisplayRates__c: boolean;
  Unit_Rates__r: UnitRates[];
  BookedDates: BookedDates[];
  inntopiaProductId__c?: string;
  inntopiaSupplierId__c?: string;
  isInntopiaUnit__c: boolean;
  DailyHousekeepingFee__c?: string;
  isComplex: boolean;
  PropertyManager__c?: string;
}

export interface FeaturedAmenity {
  AmenityType__c: string;
  Description__c: string;
  ImageLink__c: string;
  Priority__c?: number;
  sliderIndex?: number;
  Long_Description__c?: string;
  promotioncount?: number; // number of available promotions
}

export interface UnitImage {
  Name: string;
  Caption__c?: string;
  URL__c: string;
  preview?: string;
  Display__c?: string;
  DisplayOrder__c?: number;
  Parameters__c?: string; // imgix optional parameters
  Season__c: 'Winter' | 'Summer' | 'Any';
  representative?: boolean;
}
export interface BookedDates {
  startDate: Date;
  endDate: Date;
}

export interface UnitRates {
  NightlyRate__c: number;
  NightlyWeekNightRate__c: number;
  Deposit__c: number;
  MinNights__c: number;
  PromoDiscount__c: number;
  PromoNoNights__c: number;
}

export interface UnitTaxFee {
  Name: string;
  Amount__c: string;
  Type__c: string;
  FeeOrTax__c: string;
  Mandatory__c: boolean;
}

export interface UnitsInformation {
  AdditionalInformation__c: string;
  AfterHoursAndEmergencies__c: string;
  AirConditioning__c: string;
  Alarm__c: string;
  CheckOutTime__c: string;
  Concierge__c: string;
  CreatedById: string;
  CreatedDate: string;
  DailARideNumber__c: string;
  IsDeleted: boolean;
  GarageCode__c: string;
  GarbageCollection__c: string;
  HotTub__c: string;
  DialARideInformation__c: string;
  LastModifiedById: string;
  LastModifiedDate: string;
  LastReferencedDate: string;
  LastViewedDate: string;
  Medical__c: string;
  Number__c: string;
  Parking__c: string;
  Password__c: string;
  PublicSafety__c: string;
  Id: string;
  Housekeeping__c: string;
  SkiStorage__c: string;
  SystemModstamp: string;
  Television__c: string;
  Unit__c: string;
  Name: string;
  WiFiNetwork__c: string;
  WindowsAndDoors__c: string;
}

export interface ReviewRecord {
  externalId: string;
  Booking__c?: string;
  Cleanliness__c: string;
  CreatedById: string;
  Description__c: string;
  Guest__c: string;
  Heading__c: string;
  Location__c: string;
  Manager_s_Response__c: string;
  Name: string;
  OwnerId: string;
  Property_Condition__c: string;
  Rating__c: string;
  Recommend_to_Other__c: string;
  Review_Received_On__c: string;
  Services_From_PM__c: string;
  Unit__c: string;
  Review_Received_Date?: string;
  fullReview?: boolean;
}
