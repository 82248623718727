import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/services/auth.service';
import _ from 'lodash';
import { UnitEnquiryDialogComponent } from '../unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticatedApiService } from 'src/app/services/authenticated-api.service';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { UnitSummary } from 'functions/src/common/unit-summary.model';
import { EventService } from 'src/app/services/event.service';

export interface IResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Component({
  selector: 'twbooking-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, OnDestroy {
  unitDisplay: UnitSummary[] = [];
  recommendedDisplay: UnitSummary[] = [];

  done = false;
  offset = 0;
  pageSize = 50;
  mmOnly = false;
  loading = false;
  loadingInProgress = false;
  noResult = false;
  userRole = 'loading';
  sharedlink: string = null;
  mysharedlink: string = null;
  favoritesMode: 'My' | 'Shared' | null = null;
  recommendedname: string = null;
  recommendedlink: string = null;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private authApiService: AuthenticatedApiService,
    public dialog: MatDialog,
    private unitService: UnitsService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService
  ) {}

  userSub: Subscription;
  roleSub: Subscription;
  paramsSub: Subscription;

  ngOnInit(): void {
    this.roleSub = this.authService.userRole$.subscribe((role: any) => {
      this.userRole = role;
      if (this.userRole === 'anonymous') {
        this.loading = false;
      }
    });
    this.paramsSub = this.route.params.subscribe((params) => {
      this.sharedlink = params['favoritesId'];
      if (this.userSub) {
        this.userSub.unsubscribe();
      }

      if (this.sharedlink) {
        this.eventService.triggerFavoritesViewEvent({
          sharelink: this.sharedlink,
        });
        this.favoritesMode = 'Shared';
        this.loading = true;
        this.favoritesSearch().then(() => {});
      } else {
        this.favoritesMode = 'My';

        this.userSub = this.authService.userVerified$.subscribe((verified) => {
          if (verified && verified.email) {
            this.loading = true;
            this.favoritesSearch().then(() => {});
          } else {
            this.unitDisplay = [];
          }
        });
      }
    });
  }

  tabChanged() {
    if (this.recommendedlink) {
      this.eventService.triggerFavoritesViewEvent({
        sharelink: this.recommendedlink,
      });
    }
  }
  async favoritesSearch() {
    if (this.favoritesMode) {
      if (this.favoritesMode === 'My') {
        try {
          this.authApiService
            .httpCallWithRefresh(
              '/api/getfavoritescards',
              {
                user: this.authService.getUserId(),
                pageSize: this.pageSize,
                offset: this.offset,
              }
            )
            .subscribe((resp: any) => {
              this.loading = false;
              this.processResult(resp);
            });
        } catch (e) {
          console.error('error in favoritesSearch', e);
        }
      } else if (this.sharedlink) {
        try {
          this.authApiService
            .httpCall('/api/getfavoritescards', {
              sharedlink: this.sharedlink,
              pageSize: this.pageSize,
              offset: this.offset,
            })
            .subscribe((resp: any) => {
              this.loading = false;
              this.processResult(resp);
            });
        } catch (e) {
          console.error('error in favoritesSearch', e);
        }
      } else {
        console.error('shared mode but shared link is not provided');
      }
    }
  }

  processResult(resp: any) {
    if (resp) {
      if (resp.error && resp.error === 'TooManyRequests') {
        console.log('TooManyRequests!');
        throw new Error('TooManyRequests error in unit listing!!');
      }
      if (resp.sharelink) {
        this.mysharedlink = resp.sharelink;
        this.eventService.triggerFavoritesViewEvent({
          sharelink: this.mysharedlink,
        });
      }
      if (resp.units && resp.units.length > 0) {
        this.unitDisplay = [];
        this.recommendedDisplay = [];
        resp.units.forEach((unit) => {
          if (unit.createdbyptd || this.favoritesMode === 'Shared') {
            this.recommendedDisplay.push(unit);
            if (unit.listname) {
              this.recommendedname = unit.listname;
            }
            if (unit.sharelink) {
              this.recommendedlink = unit.sharelink;
            }
          } else {
            this.unitDisplay.push(unit);
          }
        });
        this.offset += this.pageSize;
      } else {
        this.done = true;

        if (this.offset === 0) {
          this.noResult = true;
        }
      }
    } else {
      this.done = true;
      if (this.offset === 0) {
        this.noResult = true;
      }
    }
  }

  identifyUnit(index, unit) {
    return unit ? unit.externalId : null;
  }

  enquiryNow(units: UnitSummary[]) {
    let fakeUnit = {
      Name: 'User requests favorite units',
      MarketingHeadline__c: 'User requests favorite units',
    };
    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: units && units.length ? units[0] : fakeUnit,
        filter: {},
      },
      autoFocus: false,
      panelClass: 'inquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }

  openShareDialog(sharedlink: string, needtoshare: boolean) {
    let uuid = this.authService.getUserId();
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      data: {
        user: uuid,
        sharedlink: sharedlink,
        needtoshare: needtoshare,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.roleSub) {
      this.roleSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }
}
