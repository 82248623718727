<div
  class="ttw-draggable-bottom"
  #bottomSheet
  cdkDragLockAxis="y"
  [cdkDragConstrainPosition]="computeDragRenderPos"
  [cdkDragFreeDragPosition]="dragPosition"
  cdkDrag
  (cdkDragEnded)="onDragEnded($event)"
  (cdkDragMoved)="onDragMoved($event)"
>
  <div class="drag-handle" cdkDragHandle>
    @if (this.direction == 'expand'){
    <mat-icon class="handler ttw-placeholder-color">keyboard_arrow_up</mat-icon>
    } @else {
    <mat-icon class="handler ttw-placeholder-color"
      >keyboard_arrow_down</mat-icon
    >
    }
  </div>
  <div class="ttw-draggable-content" #bottomContent>
    <ng-content></ng-content>
  </div>
</div>
