import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safehtml.pipe';
import { NameFilterPipe } from './nameFilter.pipe';
import { LocationFilterPipe } from './locationFilter.pipe';
import { BedroomFilterPipe } from './bedroomFilter.pipe';
import { MomentTransformPipe } from './momentTransform.pipe';
import { CheckoutStep1Component } from './checkout-step1/checkout-step1.component';
import { TooltipComponent } from './ui/tooltip/tooltip.component';
import { TooltipDirective } from './ui/tooltip/tooltip.directive';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { GalleryModule } from '@ks89/angular-modal-gallery';

import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { QuoteWidgetComponent } from './quote-widget/quote-widget.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';

import { UnitGalleryComponent } from './unit-gallery/unit-gallery.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GuestsSelectComponent } from './guests-select/guests-select.component';
import { UnitMapComponent } from './unit-map/unit-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ChatComponent } from './chat/chat.component';
import { ChatDialogComponent } from './chat/chat-dialog/chat-dialog.component';
import { RouterModule } from '@angular/router';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';
import { NoDoubleTapDirective } from './directives/no-double-tap.directive';
import { MapSearchComponent } from './maps-search/maps-search.component';
import { TruncatePipe } from './ui/truncate.pipe';
import { TruncateWordsPipe } from './ui/truncate-words.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { TtwBottomSheetComponent } from './ui/ttw-bottom-sheet/ttw-bottom-sheet.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatPaginatorModule,
    GalleryModule,
    GoogleMapsModule,
    DragDropModule,
    ScrollingModule,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  declarations: [
    SafeHtmlPipe,
    NameFilterPipe,
    LocationFilterPipe,
    BedroomFilterPipe,
    MomentTransformPipe,
    CheckoutStep1Component,
    TtwBottomSheetComponent,
    TooltipComponent,
    TooltipDirective,
    BookingFormComponent,
    QuoteWidgetComponent,
    UnitGalleryComponent,
    SearchDialogComponent,
    GuestsSelectComponent,
    UnitMapComponent,
    ChatComponent,
    ChatDialogComponent,
    CurrencyFormatterDirective,
    NoDoubleTapDirective,
    MapSearchComponent,
    TruncatePipe,
    TruncateWordsPipe,
  ],
  exports: [
    SafeHtmlPipe,
    NameFilterPipe,
    LocationFilterPipe,
    BedroomFilterPipe,
    MomentTransformPipe,
    CheckoutStep1Component,
    TooltipDirective,
    BookingFormComponent,
    QuoteWidgetComponent,
    UnitGalleryComponent,
    UnitMapComponent,
    GuestsSelectComponent,
    ChatComponent,
    ChatDialogComponent,
    MapSearchComponent,
    CurrencyFormatterDirective,
    TruncatePipe,
    TruncateWordsPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
