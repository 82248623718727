import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'twbooking-booking-dialog',
  templateUrl: './unit-rates.component.html',
  styleUrls: ['./unit-rates.component.scss'],
})
export class BookingDialogComponent implements OnInit {
  @Input()
  unit: any;

  goToThankYou() {
    this.closeCurrentDialog();
    this.router.navigate(['/booking-reservation-thankyou']);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BookingDialogComponent>,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  private closeCurrentDialog() {
    this.dialogRef.close();
  }
}
