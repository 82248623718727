import { Component, OnInit, Input } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { UnitShort } from '../../../store/models/complex.model';
import { mainsite, mm, staging } from '../../../store/models/hostings';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { BookingDialogComponent } from '../../unit-booking/unit-booking-dialog/unit-rates.component';
import { MMRate, InntopiaRates } from '../../../store/models/rate.models';
import { mobileSM } from '../../../store/models/unit.model';
import {
  InntopiaPromotions,
  PromoCondition,
} from 'src/app/store/models/inntopia-promotion';

@Component({
  selector: 'twbooking-complex-accommodation',
  templateUrl: './complex-accommodation.component.html',
  styleUrls: ['./complex-accommodation.component.scss'],
})
export class ComplexAccommodationComponent implements OnInit {
  @Input() unit: UnitShort;
  @Input() numAdults: number;
  @Input() numChildren: number;
  @Input() numPets: number;
  @Input() arrivalField: moment.Moment;
  @Input() departureField: moment.Moment;
  @Input() isMobile: boolean;
  @Input() isSmall: boolean;
  @Input() searchQuery: any;
  nb = true;
  myStay = 0;
  spaImagePrefix = 'auto=enhance';

  processedDeals: InntopiaPromotions[] = [];
  showDeals: string[] = [];

  constructor(private unitService: UnitsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging)
    );

    try {
      const innerWidth = window.innerWidth <= 1366 ? window.innerWidth : 1366;
      const width = innerWidth < mobileSM ? innerWidth : innerWidth / 2;
      this.spaImagePrefix = '?auto=enhance&w=' + width;
    } catch (e) {
      this.spaImagePrefix = 'auto=enhance';
      console.error(
        'Problem with window.innerWidth detection, request full size image'
      );
    }
  }

  getQuote() {
    if (this.nb) {
      this.unit.errorMessage = 'Please contact us to get a quote';
    } else {
      this.unit.errorMessage = null;
      this.unit.Total = 0;
      this.unit.Rent = 0;
      this.unit.minStay = 0;
      this.unit.rateDetails = null;
      this.unit.inntopiaRate = null;
      if (this.numAdults + this.numChildren > this.unit.Sleeps__c) {
        this.unit.errorMessage =
          'Please note that the maximum occupancy for this property is ' +
          this.unit.Sleeps__c.toString() +
          ' guests.';
        return;
      }
      this.myStay = moment
        .duration(this.departureField.diff(this.arrivalField))
        .asDays();
      const quoteData = null;

      this.unitService
        .getQuote(
          this.unit.externalId,
          this.unit,
          this.numAdults,
          this.numChildren,
          this.numPets,
          this.arrivalField,
          this.departureField,
          true
        )
        .subscribe((result) => {
          if (result.minStay) {
            this.unit.minStay = result.minStay;
          }
          if (result.errorMessage) {
            this.unit.errorMessage = result.errorMessage;
          }
          if (result.Total) {
            this.unit.Total = result.Total;
            if (result.rateDetails) {
              this.unit.rateDetails = result.rateDetails;
              this.unit.Rent = this.unit.rateDetails.Rent__c;

              if (
                !this.unit.rateDetails ||
                !this.unit.rateDetails.TotalCost__c ||
                !this.unit.rateDetails.Rent__c ||
                this.unit.rateDetails.Rent__c <= 0 ||
                this.unit.rateDetails.TotalCost__c <= 0 ||
                (this.unit.rateDetails.Discount__c &&
                  this.unit.rateDetails.Discount__c > 0 &&
                  (!this.unit.rateDetails.Discounted_Rent__c ||
                    this.unit.rateDetails.Discounted_Rent__c <= 0))
              ) {
                this.unit.Rent = null;
                this.unit.Total = null;
                this.unit.errorMessage =
                  'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
                this.unit.rateDetails = null;
              } else {
                this.unit.errorMessage = null;
              }
              // process MM
            } else if (result.quoteInntopia) {
              this.unit.errorMessage = null;
              this.unit.inntopiaRate = result.quoteInntopia;
              this.unit.Rent = this.unit.inntopiaRate.DisplayRent;
              // process Inntopia
            }
          }
        });
    }
  }

  bookNow(rateDetails: MMRate, inntopiaRate: InntopiaRates) {
    let quote = null;
    if (
      this.arrivalField &&
      this.departureField &&
      rateDetails &&
      rateDetails.TotalRent__c > 0 &&
      rateDetails.TotalCost__c > 0 &&
      this.unit &&
      !this.unit.isInntopiaUnit
    ) {
      quote = {
        arrival: this.arrivalField.format('YYYY-MM-DD'),
        departure: this.departureField.format('YYYY-MM-DD'),
        adults: this.numAdults,
        children: this.numChildren,
        pets: 0,
        quoteMM: rateDetails,
      };
    }
    if (
      this.arrivalField &&
      this.departureField &&
      inntopiaRate &&
      inntopiaRate.DisplayRent > 0 &&
      this.unit &&
      this.unit.isInntopiaUnit
    ) {
      quote = {
        arrival: this.arrivalField.format('YYYY-MM-DD'),
        departure: this.departureField.format('YYYY-MM-DD'),
        adults: this.numAdults,
        children: this.numChildren,
        pets: 0,
        quoteInntopia: inntopiaRate,
      };
    }
    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          quoteData: quote,
        },
      },
      panelClass: 'unit-rates-container',
      maxWidth: this.isMobile ? '96vw' : 'auto',
      width: this.isMobile ? '96%' : 'auto',
    });
  }

  showPromotion() {
    const start = this.arrivalField ? this.arrivalField.toDate() : null;
    const end = this.departureField ? this.departureField.toDate() : null;
    if (this.processedDeals.length === 0) {
      this.unitService
        .getPromotions(
          this.unit.isInntopiaUnit__c
            ? this.unit.inntopiaSupplierId__c
            : '999999',
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : '1',
          this.unit.externalId,
          start,
          end
        )
        .subscribe((response: any) => {
          const availableDeals = response;
          if (!this.unit.isInntopiaUnit__c) {
            this.processedDeals = availableDeals;
          } else {
            this.processedDeals = [];
            let i = 0;
            while (availableDeals && i < availableDeals.length) {
              const promo: InntopiaPromotions = {
                Name: availableDeals[i].Name,
                PromotionCode__c: availableDeals[i].PromotionCode__c,
                Description__c: availableDeals[i].Description__c,
                ValidStart__c: availableDeals[i].ValidStart__c,
                ValidEnd__c: availableDeals[i].ValidEnd__c,
                BookByDate__c: availableDeals[i].BookByDate__c,
                Details: [],
              };
              const promoDetails: PromoCondition = {
                Discount__c: availableDeals[i].Discount__c,
                MinNights__c: availableDeals[i].MinNights__c,
                MaxNights__c: availableDeals[i].MaxNights__c,
              };
              promo.Details.push(promoDetails);
              while (
                i + 1 < availableDeals.length &&
                availableDeals[i + 1].Name === promo.Name &&
                availableDeals[i + 1].ValidStart__c === promo.ValidStart__c &&
                availableDeals[i + 1].ValidEnd__c === promo.ValidEnd__c
              ) {
                const promoDetails: PromoCondition = {
                  Discount__c: availableDeals[i + 1].Discount__c,
                  MinNights__c: availableDeals[i + 1].MinNights__c,
                  MaxNights__c: availableDeals[i + 1].MaxNights__c,
                };
                promo.Details.push(promoDetails);
                i++;
              }
              this.processedDeals.push(promo);
              i++;
            }
          }
        });
    }
  }
}
