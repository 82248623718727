import { RelatedStrategy } from './unit-related.component';
import { Complex } from '../../store/models/complex.model';
import { SearchParamsService } from '../../services/search-params.service';

export class ComplexRelated implements RelatedStrategy {
  complex: Complex;
  constructor(complexInput: Complex, private searchQueryInitial: any) {
    this.complex = complexInput;
  }

  public getSearchQuery(): any {
    const searchQuery = {
      ...this.searchQueryInitial,
      City: this.complex.Destination.split(', ')[0],
      State: this.complex.Destination.split(', ')[1],
      Village: this.complex.Village,
      PropertyCollection: this.complex.Property_Collection__c
    };
    return searchQuery;
  }
}
