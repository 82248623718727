<div class="full-page">
  <div class="unit-enquiry-thank-you-page">
    <div class="unit-details-concierge-card">
      <div>
        <p class="new-brand-color thank-you-text">
          Your payment has been successfully canceled.
        </p>
      </div>
      <div>
        <p class="new-brand-color thank-you-subtext">
          Please call us at
          <a class="clickable-link new-brand-color" href="tel:+19706885193"
            >+1 (970) 688-5193</a
          >
          or email us at
          <a
            class="clickable-link new-brand-color"
            href="mailto:reservations@thetravelwhisperer.com"
            >reservations&#64;thetravelwhisperer.com</a
          >
          with any questions.
        </p>
      </div>
    </div>
  </div>
</div>
