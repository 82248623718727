export const rewrites = [
  {
    source: '/api/isPurchaseEventAllowed',
    function: 'booking-isPurchaseEventAllowed',
  },
  {
    source: '/api/geoSearh',
    function: 'basic-geoSearh',
  },
  {
    source: '/api/saveException',
    function: 'basic-saveException',
  },
  {
    source: '/api/submitEmailLink',
    function: 'user-submitEmailLink',
  },
  {
    source: '/api/checkExistingChats',
    function: 'chat-checkExistingChats',
  },
  {
    source: '/api/fetchMapsInput',
    function: 'basic-fetchMapsInput',
  },
  {
    source: '/api/checkAvailability',
    function: 'quoting-checkAvailability',
  },
  {
    source: '/api/fetchLodgingQuote',
    function: 'user-fetchLodgingQuote',
  },
  {
    source: '/api/requestPasswordLink',
    function: 'auth-requestPasswordLink',
  },
  {
    source: '/api/decodeEmail',
    function: 'auth-decodeEmail',
  },
  {
    source: '/api/updateWebBookingPaymentStatus',
    function: 'booking-updateWebBookingPaymentStatus',
  },
  {
    source: '/api/saveEmailLog',
    function: 'analytics-saveEmailLog',
  },
  {
    source: '/api/sendVerificationEmail',
    function: 'auth-sendVerificationEmail',
  },
  {
    source: '/api/updatePaymentStatus',
    function: 'user-updatePaymentStatus',
  },
  {
    source: '/api/sharefavorites',
    function: 'user-sharefavorites',
  },
  {
    source: '/api/refreshSignIn',
    function: 'auth-refreshSignIn',
  },
  {
    source: '/api/getfavoritescards',
    function: 'user-getfavoritescards',
  },
  {
    source: '/api/savefavorite',
    function: 'user-savefavorite',
  },
  {
    source: '/api/getfavorites',
    function: 'user-getfavorites',
  },
  {
    source: '/api/deletefavorite',
    function: 'user-deletefavorite',
  },
  {
    source: '/api/savePortalComponentRequest',
    function: 'analytics-savePortalComponentRequest',
  },
  {
    source: '/api/saveUserInitiatePayment',
    function: 'analytics-saveUserInitiatePayment',
  },
  {
    source: '/api/savePartyMemberChange',
    function: 'analytics-savePartyMemberChange',
  },
  {
    source: '/api/saveGroceryList',
    function: 'user-saveGroceryList',
  },
  {
    source: '/api/guestPortalStatus',
    function: 'user-guestPortalStatus',
  },
  {
    source: '/api/saveNewRequest',
    function: 'user-saveNewRequest',
  },
  {
    source: '/api/updateTransportation',
    function: 'user-updateTransportation',
  },
  {
    source: '/api/saveFlightAndTransportation',
    function: 'user-saveFlightAndTransportation',
  },
  {
    source: '/api/updateContactAndPartyMember',
    function: 'user-updateContactAndPartyMember',
  },
  {
    source: '/api/fetchAirlines',
    function: 'user-fetchAirlines',
  },

  {
    source: '/api/saveContactAndPartyMember',
    function: 'user-saveContactAndPartyMember',
  },
  {
    source: '/api/savePartyMember',
    function: 'user-savePartyMember',
  },
  {
    source: '/api/fetchPayments',
    function: 'user-fetchPayments',
  },
  {
    source: '/api/saveGuestPortalView',
    function: 'analytics-saveGuestPortalView',
  },
  {
    source: '/api/saveFavoritesView',
    function: 'analytics-saveFavoritesView',
  },
  {
    source: '/api/fetchPossibleMembers',
    function: 'user-fetchPossibleMembers',
  },
  { source: '/api/fetchReviews', function: 'user-fetchReviews' },
  { source: '/api/saveReview', function: 'user-saveReview' },
  { source: '/api/fetchThumbnails', function: 'user-fetchThumbnails' },
  { source: '/api/fetchUnitData', function: 'user-fetchUnitData' },
  { source: '/api/fetchBookings', function: 'user-fetchBookings' },
  {
    source: '/api/fetchBookingDetails',
    function: 'user-fetchBookingDetails',
  },
  { source: '/api/saveChatEvent', function: 'chat-saveChatEvent' },

  {
    source: '/api/savePWAEvent',
    function: 'analytics-savePWAEvent',
  },
  { source: '/api/encrypt', function: 'booking-encrypt' },
  { source: '/api/postcheckout', function: 'booking-postcheckout' },
  {
    source: '/api/getFeaturedCategories',
    function: 'basic-getFeaturedCategories',
  },
  { source: '/api/openSearch', function: 'basic-openSearch' },
  {
    source: '/api/fetchAndUpdate',
    function: 'auth-fetchAndUpdate',
  },
  {
    source: '/api/saveCampaign',
    function: 'analytics-saveCampaign',
  },
  {
    source: '/api/fetchUserData',
    function: 'auth-fetchUserData',
  },
  {
    source: '/api/getUnit',
    function: 'basic-getUnit',
  },
  {
    source: '/api/saveQuoteEvent',
    function: 'analytics-saveQuoteEvent',
  },
  {
    source: '/api/saveSearchEvent',
    function: 'analytics-saveSearchEvent',
  },
  {
    source: '/api/savePropertyViewed',
    function: 'analytics-savePropertyViewed',
  },
  {
    source: '/api/saveComplexViewed',
    function: 'analytics-saveComplexViewed',
  },
  {
    source: '/api/saveInquiry',
    function: 'analytics-saveInquiry',
  },
  {
    source: '/api/saveLodgingQuoteView',
    function: 'analytics-saveLodgingQuoteView',
  },
  {
    source: '/api/saveInitiateCheckout',
    function: 'analytics-saveInitiateCheckout',
  },
  {
    source: '/api/getToken',
    function: 'auth-getToken',
  },
  {
    source: '/api/updateUser',
    function: 'auth-updateUser',
  },
  {
    source: '/api/createUser',
    function: 'auth-createUser',
  },
  {
    source: '/api/saveSession',
    function: 'auth-saveSession',
  },
  {
    source: '/api/promotionsQuery',
    function: 'basic-promotionsQuery',
  },
  {
    source: '/api/setFullLoadSwitch',
    function: 'setFullLoadSwitch',
  },
  {
    source: '/api/inntopiaBookedDatesQuery',
    function: 'quoting-inntopiaBookedDatesQuery',
  },
  {
    source: '/api/getRatesInntopia',
    function: 'quoting-getRatesInntopia',
  },
  {
    source: '/api/webBookingUpdate',
    function: 'booking-webBookingUpdate',
  },
  {
    source: '/api/leadCreate',
    function: 'leadCreate',
  },
  {
    source: '/api/unitBookedDatesQueryNew',
    function: 'quoting-unitBookedDatesQueryNew',
  },
  {
    source: '/api/unitBookedDatesQuery',
    function: 'quoting-unitBookedDatesQuery',
  },
  {
    source: '/api/unitMinStayQuery',
    function: 'quoting-unitMinStayQuery',
  },
  {
    source: '/api/getCalculatedRatesFunction',
    function: 'quoting-getCalculatedRatesFunction',
  },
  {
    source: '/api/complexSearchFunction',
    function: 'basic-complexSearchFunction',
  },
  {
    source: '/api/unitSearchFunction',
    function: 'basic-unitSearchFunction',
  },
  {
    source: '/api/unitBookedDatesQuery',
    function: 'quoting-unitBookedDatesQuery',
  },
  {
    source: '/links/**',
    dynamicLinks: true,
  },
  {
    source: '!/links/**',
    destination: '/index.html',
  },
];
