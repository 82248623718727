<section class="ks89-gallery">
  <mat-grid-list
    *ngIf="isMobile && galleryImages && galleryImages.length > 1"
    cols="1"
    rowHeight="430:287"
    class="my-grid"
  >
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <div class="mobile-image-container">
        <div
          class="left-arrow"
          (click)="showPrev()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_left</mat-icon>
        </div>
        <ng-container>
          <img
            width="100%"
            height="100%"
            (swipeleft)="showNext($event)"
            (swiperight)="showPrev($event)"
            src="{{
              galleryImages[index].modal.img.includes('imgix')
                ? galleryImages[index].modal.img
                    .toString()
                    .substring(
                      0,
                      galleryImages[index].modal.img.toString().indexOf('?')
                    ) + this.mobilePostfix
                : galleryImages[index].modal.img
            }}"
            (click)="onShow(101, index)"
            alt="{{ galleryImages[index].modal.description }}"
            (error)="onImgError($event, image.URL__c)"
          />
        </ng-container>

        @if(galleryImages.length > 4){
        <div
          class="projected-show-all-mobile ttw-projected ttw-show-images-paddings"
          (click)="onShow(101, index)"
        >
          <mat-icon>apps</mat-icon><span>Show all photos</span>
        </div>
        }

        <div
          class="right-arrow"
          (click)="showNext()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list
    *ngIf="!isMobile && galleryImages && galleryImages.length > 3"
    cols="4"
    rowHeight="3:2"
    class="my-grid"
    gutterSize="3px"
  >
    <mat-grid-tile [colspan]="3" [rowspan]="3">
      <div class="main-image-container">
        <div
          class="left-arrow"
          (click)="showPrev()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_left</mat-icon>
        </div>
        @for (image of galleryImages; track image.id; let i = $index){
        <ng-container>
          <img
            *ngIf="i === index"
            width="100%"
            height="100%"
            (swipeleft)="showNext($event)"
            (swiperight)="showPrev($event)"
            src="{{
              image.modal.img.includes('imgix')
                ? image.modal.img
                    .toString()
                    .substring(0, image.modal.img.toString().indexOf('?')) +
                  this.imgixPostfix
                : image.modal.img
            }}"
            alt="{{ galleryImages[index].modal.description }}"
            (click)="onShow(101, index)"
            class="clickable-image"
          />
        </ng-container>
        } @if(galleryImages.length > 4){
        <div
          class="projected-show-all ttw-projected ttw-show-images-paddings"
          (click)="onShow(101, index)"
        >
          <mat-icon>apps</mat-icon><span>Show all photos</span>
        </div>
        }

        <div
          class="right-arrow"
          (click)="showNext()"
          (swipeleft)="showNext($event)"
          (swiperight)="showPrev($event)"
        >
          <mat-icon class="slider-arrow">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <ng-container>
        <img
          class="thumbnail-image"
          width="100%"
          height="100%"
          src="{{
            galleryImages[
              index + 1 < carouselMax ? index + 1 : index + 1 - carouselMax
            ].plain.img
          }}"
          (click)="increaseThumb(index + 1)"
          alt="{{
            galleryImages[
              index + 1 < carouselMax ? index + 1 : index + 1 - carouselMax
            ].modal.description
          }}"
        />
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <ng-container>
        <img
          class="thumbnail-image"
          width="100%"
          height="100%"
          src="{{
            galleryImages[
              index + 2 < carouselMax ? index + 2 : index + 2 - carouselMax
            ].plain.img
          }}"
          alt="{{
            galleryImages[
              index + 2 < carouselMax ? index + 2 : index + 2 - carouselMax
            ].modal.description
          }}"
          (click)="increaseThumb(index + 2)"
        />
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <ng-container>
        <img
          class="thumbnail-image"
          width="100%"
          height="100%"
          src="{{
            galleryImages[
              index + 3 < carouselMax ? index + 3 : index + 3 - carouselMax
            ].plain.img
          }}"
          alt="{{
            galleryImages[
              index + 3 < carouselMax ? index + 3 : index + 3 - carouselMax
            ].modal.description
          }}"
          (click)="increaseThumb(index + 3)"
        />
      </ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</section>
