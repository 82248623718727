export const stateOptionsUS = [
  { value: 'AL', viewValue: 'Alabama' },
  { value: 'AK', viewValue: 'Alaska' },
  { value: 'AS', viewValue: 'American Samoa' },
  { value: 'AZ', viewValue: 'Arizona' },
  { value: 'AR', viewValue: 'Arkansas' },
  { value: 'CA', viewValue: 'California' },
  { value: 'CO', viewValue: 'Colorado' },
  { value: 'CT', viewValue: 'Connecticut' },
  { value: 'DE', viewValue: 'Delaware' },
  { value: 'DC', viewValue: 'District Of Columbia' },
  { value: 'FL', viewValue: 'Florida' },
  { value: 'FM', viewValue: 'Federated States of Micronesia' },
  { value: 'GA', viewValue: 'Georgia' },
  { value: 'GU', viewValue: 'Guam' },
  { value: 'HI', viewValue: 'Hawaii' },
  { value: 'ID', viewValue: 'Idaho' },
  { value: 'IL', viewValue: 'Illinois' },
  { value: 'IN', viewValue: 'Indiana' },
  { value: 'IA', viewValue: 'Iowa' },
  { value: 'KS', viewValue: 'Kansas' },
  { value: 'KY', viewValue: 'Kentucky' },
  { value: 'LA', viewValue: 'Louisiana' },
  { value: 'ME', viewValue: 'Maine' },
  { value: 'MH', viewValue: 'Marshall Islands' },
  { value: 'MD', viewValue: 'Maryland' },
  { value: 'MA', viewValue: 'Massachusetts' },
  { value: 'MI', viewValue: 'Michigan' },
  { value: 'MN', viewValue: 'Minnesota' },
  { value: 'MS', viewValue: 'Mississippi' },
  { value: 'MO', viewValue: 'Missouri' },
  { value: 'MT', viewValue: 'Montana' },
  { value: 'NE', viewValue: 'Nebraska' },
  { value: 'NV', viewValue: 'Nevada' },
  { value: 'NH', viewValue: 'New Hampshire' },
  { value: 'NJ', viewValue: 'New Jersey' },
  { value: 'NM', viewValue: 'New Mexico' },
  { value: 'NY', viewValue: 'New York' },
  { value: 'NC', viewValue: 'North Carolina' },
  { value: 'ND', viewValue: 'North Dakota' },
  { value: 'MP', viewValue: 'Northern Mariana Islands' },
  { value: 'OH', viewValue: 'Ohio' },
  { value: 'OK', viewValue: 'Oklahoma' },
  { value: 'OR', viewValue: 'Oregon' },
  { value: 'PA', viewValue: 'Pennsylvania' },
  { value: 'PR', viewValue: 'Puerto Rico' },
  { value: 'RI', viewValue: 'Rhode Island' },
  { value: 'SC', viewValue: 'South Carolina' },
  { value: 'SD', viewValue: 'South Dakota' },
  { value: 'TN', viewValue: 'Tennessee' },
  { value: 'TX', viewValue: 'Texas' },
  { value: 'UT', viewValue: 'Utah' },
  { value: 'VT', viewValue: 'Vermont' },
  { value: 'VI', viewValue: 'Virgin Islands' },
  { value: 'VA', viewValue: 'Virginia' },
  { value: 'WA', viewValue: 'Washington' },
  { value: 'WV', viewValue: 'West Virginia' },
  { value: 'WI', viewValue: 'Wisconsin' },
  { value: 'WY', viewValue: 'Wyoming' },
];

export const stateOptionsCA = [
  { viewValue: 'Alberta', value: 'AB' },
  { viewValue: 'British Columbia', value: 'BC' },
  { viewValue: 'Manitoba', value: 'MB' },
  { viewValue: 'New Brunswick', value: 'NB' },
  { viewValue: 'Newfoundland and Labrador', value: 'NL' },
  { viewValue: 'Northwest Territories', value: 'NT' },
  { viewValue: 'Nova Scotia', value: 'NS' },
  { viewValue: 'Nunavut', value: 'NU' },
  { viewValue: 'Ontario', value: 'ON' },
  { viewValue: 'Prince Edward Island', value: 'PE' },
  { viewValue: 'Quebec', value: 'QC' },
  { viewValue: 'Saskatchewan', value: 'SK' },
  { viewValue: 'Yukon', value: 'YT' },
];
