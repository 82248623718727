import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuthService, Role } from 'src/app/services/auth.service';

@Component({
  selector: 'twbooking-favorite-snack',
  templateUrl: './favorite-snack.component.html',
  styleUrls: ['./favorite-snack.component.scss'],
})
export class FavoriteSnackComponent implements OnInit {
  saved = true;
  roleSub: Subscription;

  loggedIn = true;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private authService: AuthService
  ) {
    this.saved = data && data.saved ? true : false;
  }

  async ngOnInit() {
    const role: Role = await firstValueFrom(this.authService.userRole$);
    this.loggedIn = role !== 'anonymous';
  }
}
