<div class="grid-container">

    <div *ngIf="userRole === 'anonymous' && favoritesMode==='My'">
        <p>Please log in to have access to your favorites.</p>
    </div>



    <div *ngIf="favoritesMode==='My'">

        <div class="loading-container-page" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>

        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)" *ngIf="!loading">
            <mat-tab label="Favorite Units" class="favorite-tab">
                <div *ngIf="userRole === 'loading' ">
                    <p>Please wait, favorites are loading.</p>
                    <br />
                    <br />
                </div>

                <div class="tab-content"
                    *ngIf="userRole !== 'loading' && (noResult || !unitDisplay || unitDisplay&&unitDisplay.length===0)">
                    <br>
                    <h1 class="mat-h1">
                        You have no favorite units now.

                    </h1>
                    <p>Please use the <mat-icon class="brand-dark-color">favorite</mat-icon> icon at the top right of
                        the unit card
                        to
                        select units that you are interested in
                        to help our personal travel designers find the best options for you.</p>
                    <p>
                        Please click
                        <a [routerLink]="['/units']" routerLinkActive="router-link-active"
                            class="show-more-link">here</a>
                        to view our inventory and select favorite units.
                    </p>



                </div>

                <div class="tab-content" *ngIf="unitDisplay && unitDisplay.length">
                    <div class="action-buttons-row">

                        <button mat-button class="brand-button same-width margin-right-mobile"
                            (click)="enquiryNow(unitDisplay)">
                            Inquire now
                        </button>

                        <button *ngIf="userRole !== 'anonymous' && userRole !== 'loading' && mysharedlink" mat-button
                            class="brand-button same-width" (click)="openShareDialog(mysharedlink, true)">
                            Share
                        </button>
                    </div>


                    <br>
                    <mat-divider></mat-divider>
                    <br>
                    <br>

                    <twbooking-favorites-list [unitList]="unitDisplay"></twbooking-favorites-list>
                </div>
            </mat-tab>
            <mat-tab label="Recommended" *ngIf="recommendedDisplay && recommendedDisplay.length">
                <div class="tab-content">
                    <div class="action-buttons-row">

                        <button mat-button class="brand-button same-width margin-right-mobile"
                            (click)="enquiryNow(recommendedDisplay)">
                            Inquire now
                        </button>

                        <button *ngIf="userRole !== 'anonymous' && userRole !== 'loading' && recommendedlink" mat-button
                            class="brand-button same-width" (click)="openShareDialog(recommendedlink, false)">
                            Share
                        </button>
                    </div>

                    <twbooking-favorites-list [unitList]="recommendedDisplay" [name]="recommendedname">
                    </twbooking-favorites-list>

                </div>
            </mat-tab>
        </mat-tab-group>




    </div>

    <div *ngIf="favoritesMode === 'Shared' ">
        <div class="loading-container" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!loading && recommendedDisplay && recommendedDisplay.length">

            <twbooking-favorites-list [unitList]="recommendedDisplay" [name]="recommendedname">
            </twbooking-favorites-list>
        </div>
    </div>




    <!--Infinite Scrolling-->



    <div class="grid-container" *ngIf="!loading && noResult && favoritesMode==='Shared'">
        <br>
        <h1 class="mat-h1">
            Requested list of favorites is not available.

        </h1>
    </div>
</div>