export const iconByAmenity = {
  'Games room': 'ttw-games-room',
  'Games Room': 'ttw-games-room',
  Pool: 'ttw-pool',
  Bar: 'ttw-bar',
  'Air Conditioning': 'ttw-air-con',
  Gym: 'ttw-gym',
  'Ski-in Ski-out': 'ttw-ski-in-ski-out',
  'Mountain Views': 'ttw-mnt-view',
  Cinema: 'ttw-cinema',
  'Hot Tub': 'ttw-hot-tub',
  'Hot tub': 'ttw-hot-tub',
  Fireplace: 'ttw-fireplace',
  'BBQ Grill': 'ttw-bbq',
  'Dogs Allowed': 'ttw-pets',
  'Pet Friendly': 'ttw-pets',
  'Shuttle to Lifts': 'ttw-transfers',
  'Jacuzzi Tub': 'ttw-hot-tub',
  'Washer/Dryer': 'ttw-laundry',
  'Walk to Lifts': 'ttw-ski-lift',
  Sauna: 'ttw-sauna',
  'Steam Room': 'ttw-steam-room',
  'Tennis Court': 'ttw-tennis',
  'Day Spa': 'ttw-spa',
  'On-Site Dining': 'ttw-dining',
  'Valet Parking': 'ttw-valet-parking',
};
