<div>
  <mat-dialog-content
    [class.enquiry-dialog-container-classic]="!warningMessage || !isMobile"
    [class.enquiry-dialog-container-reduced]="warningMessage && isMobile"
    class="white-background"
  >
    <twbooking-inquiry-form
      #inquiryComponent
      [inquiryInput]="data"
      mode="Inquiry"
    >
    </twbooking-inquiry-form>
  </mat-dialog-content>
  <mat-dialog-actions
    align="end"
    [class.actions-classic]="!warningMessage || !isMobile"
    [class.actions-expanded]="warningMessage && isMobile"
  >
    <div
      *ngIf="warningMessage"
      class="alert-warning"
      [class.full-width-warning]="isMobile"
      [class.half-width-warning]="!isMobile"
    >
      {{ warningMessage }}
    </div>

    <button
      mat-button
      class="brand-button search-button"
      type="button"
      label="Submit Inquiry"
      (click)="handleSubmit()"
    >
      Submit inquiry
    </button>
    <button
      mat-button
      class="brand-button search-button"
      type="button"
      (click)="closeDialog()"
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
