export const svgHome1 = ` 
<svg id="Layer_5" data-name="Layer 5" width="36px"   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  <defs>
    <style>
      .cls-1 {
        fill: #103753;
        stroke: #f7f7f7;
        stroke-miterlimit: 10;
      }

      .cls-2 {
        fill: #f7f7f7;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <circle class="cls-1" cx="30" cy="30" r="20.39"/>
  <path class="cls-2" d="m24,37h3v-6h6v6h3v-9l-6-4.5-6,4.5v9Zm-2,2v-12l8-6,8,6v12h-7v-6h-2v6h-7Z"/>
  </svg>
`;
