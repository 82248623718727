import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'twbooking-booking-reservation-thankyou-fail',
  templateUrl: './booking-reservation-thankyou-fail.component.html',
  styleUrls: [
    '../unit-enquiry-thank-you/unit-enquiry-thank-you.component.scss',
  ],
})
export class BookingReservationThankyouFailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    let referenceId = this.route.snapshot.queryParams.Id;

    if (referenceId) {
      this.eventService.sendBookingPaymentFailGA({
        transaction_id: referenceId,
      });
      this.apiService
        .httpCall('/api/updateWebBookingPaymentStatus', {
          referenceId: referenceId,
          status: 'fail',
        })
        .subscribe((result: any) => {
          console.log('failed reservation');
        });
    }
  }
}
