<div class="search-listing-page">
  <twbooking-top-actions></twbooking-top-actions>

  <div class="action-buttons-container">
    <button
      *ngIf="columnNum > 1"
      class="ttw-transparent-button desktop-margin-right"
      (click)="scrollToElement(complexesAnchor)"
    >
      View Complexes
    </button>
    <button
      *ngIf="columnNum > 1"
      class="ttw-transparent-button desktop-margin-right"
      (click)="scrollToElement(unitsAnchor)"
    >
      View Individual Residences
    </button>
    <button
      *ngIf="mapsAvailable"
      class="ttw-transparent-button desktop-margin-right"
      (click)="navigateToMaps()"
    >
      View on Map
    </button>
  </div>

  <!-- <div class="loading-container" *ngIf="loadingFeaturedComplexes">
    <mat-spinner></mat-spinner>
  </div>-->

  <div #complexesAnchor></div>
  <div
    class="new-brand-background complex-container"
    *ngIf="complexTotal !== 0 && !mmOnly && includeComplexes"
  >
    <div class="grid-container">
      <div
        class="category-title white-color column-order"
        *ngIf="columnNum <= 2"
      >
        <h5 class="white-color">(Hotels, resorts, lodges, etc)</h5>
        <h2>Complexes</h2>
      </div>

      <div class="complex-title-bottom">
        <div class="left-column">
          <div
            class="category-title white-color row-order"
            *ngIf="columnNum > 2"
          >
            <h2>Complexes</h2>
            <h5 class="white-color">(Hotels, resorts, lodges, etc)</h5>
          </div>
        </div>
        <div
          class="right-column"
          *ngIf="complexTotal > columnNum && columnNum > 1"
        >
          <div class="view-all-container">
            <a
              href="/complexes"
              class="white-color"
              [routerLink]="['/complexes']"
              routerLinkActive="router-link-active"
              [queryParams]="searchQuery"
              >View all</a
            >
          </div>
          <div class="complex-navigation">
            <mat-paginator
              class="new-brand-background white-color invisible-item-number complexes-paginator"
              [length]="complexTotal"
              [pageSize]="columnNum"
              [pageSizeOptions]="[]"
              [showFirstLastButtons]="true"
              [pageIndex]="pageIndex"
              (page)="pageEvent.next($event)"
              aria-label="Select complex page"
            >
            </mat-paginator>
          </div>
        </div>
      </div>

      <div>
        <mat-grid-list
          class="ttw-grid complexes-list"
          [cols]="columnNum"
          rowHeight="38:48"
          gutterSize="4%"
        >
          @for (column of [].constructor(columnNum); track $index; let i =
          $index) {
          <mat-grid-tile [class.unit-summary-card-shadow]="i < complexTotal">
            <twbooking-complex-summary
              *ngIf="
                i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal &&
                featuredComplexList[
                  i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal
                    ? i + pageEvent.value.pageIndex * pageEvent.value.pageSize
                    : i +
                      pageEvent.value.pageIndex * pageEvent.value.pageSize -
                      complexTotal
                ]
              "
              [complex]="
                featuredComplexList[
                  i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal
                    ? i + pageEvent.value.pageIndex * pageEvent.value.pageSize
                    : i +
                      pageEvent.value.pageIndex * pageEvent.value.pageSize -
                      complexTotal
                ]
              "
              [searchQuery]="searchQuery"
              [columnNum]="columnNum"
              [@fadeIn]="
                featuredComplexList[
                  i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal
                    ? i + pageEvent.value.pageIndex * pageEvent.value.pageSize
                    : i +
                      pageEvent.value.pageIndex * pageEvent.value.pageSize -
                      complexTotal
                ]
              "
            >
            </twbooking-complex-summary>

            <twbooking-summary-ghost
              *ngIf="
                complexTotal < 0 ||
                (i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal &&
                  !featuredComplexList[
                    i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                    complexTotal
                      ? i + pageEvent.value.pageIndex * pageEvent.value.pageSize
                      : i +
                        pageEvent.value.pageIndex * pageEvent.value.pageSize -
                        complexTotal
                  ])
              "
              [@fadeOut]="
                featuredComplexList[
                  i + pageEvent.value.pageIndex * pageEvent.value.pageSize <
                  complexTotal
                    ? i + pageEvent.value.pageIndex * pageEvent.value.pageSize
                    : i +
                      pageEvent.value.pageIndex * pageEvent.value.pageSize -
                      complexTotal
                ]
              "
            ></twbooking-summary-ghost>
          </mat-grid-tile>
          }
        </mat-grid-list>

        <br />

        <div
          class="bottom-column"
          *ngIf="complexTotal > columnNum && columnNum == 1"
        >
          <div class="complex-navigation">
            <mat-paginator
              class="new-brand-background white-color invisible-item-number complexes-paginator"
              [length]="complexTotal"
              [pageSize]="columnNum"
              [pageSizeOptions]="[]"
              [showFirstLastButtons]="true"
              [pageIndex]="pageIndex"
              (page)="pageEvent.next($event)"
              aria-label="Select complex page"
            >
            </mat-paginator>
          </div>
          <div class="view-all-container">
            <a
              href="/complexes"
              class="white-color"
              [routerLink]="['/complexes']"
              routerLinkActive="router-link-active"
              [queryParams]="searchQuery"
              >View all</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #unitsAnchor></div>
  <div
    [class.complex-container]="complexTotal !== 0"
    [class.no-complex-container]="complexTotal == 0"
  >
    <div *ngIf="!noResult" class="grid-container">
      <div
        class="category-title new-brand-color"
        [class.row-order]="columnNum > 2"
        [class.column-order]="columnNum <= 2"
      >
        <h5 class="new-brand-color" *ngIf="columnNum <= 2">
          (Homes, apartments, condos, cabins, etc)
        </h5>
        <h2>Individual Residences</h2>
        <h5 class="new-brand-color" *ngIf="columnNum > 2">
          (Homes, apartments, condos, cabins, etc)
        </h5>
      </div>
      <div *ngIf="!unitDisplay" class="padding-top">
        <mat-grid-list
          class="ttw-grid"
          [cols]="columnNum"
          rowHeight="38:55"
          gutterSize="4%"
        >
          @for (column of [].constructor(columnNum); track $index; let i =
          $index) {
          <mat-grid-tile class="unit-summary-card-shadow">
            <twbooking-summary-ghost
              [@fadeOut]="unitDisplay"
            ></twbooking-summary-ghost>
          </mat-grid-tile>
          }
        </mat-grid-list>
      </div>
      <div *ngIf="unitDisplay | async as unitDisplayList" class="padding-top">
        <div
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="100"
          (scrolled)="onScroll($event)"
        >
          <mat-grid-list
            class="ttw-grid"
            [cols]="columnNum"
            rowHeight="38:55"
            gutterSize="4%"
          >
            @for (unit of unitDisplayList | slice : 0 : unitLimit; track
            identifyUnit($index,unit); let i = $index) {
            <mat-grid-tile class="unit-summary-card-shadow">
              <twbooking-unit-summary
                *ngIf="unit"
                [@fadeIn]="unitDisplay"
                [unit]="unit"
                [searchQuery]="searchQuery"
                [columnNum]="columnNum"
              >
              </twbooking-unit-summary>

              <!--<twbooking-summary-ghost *ngIf="!unit" [@fadeOut]="!unit"></twbooking-summary-ghost>-->
            </mat-grid-tile>
            }
          </mat-grid-list>
        </div>
        <div *ngIf="!done" class="pagination-spinner">
          <mat-spinner diameter="35"></mat-spinner>
        </div>
        <!--Infinite Scrolling-->
      </div>
    </div>

    <div
      class="grid-container"
      *ngIf="
        !loadingFeaturedComplexes &&
        !loadingHomes &&
        noResult &&
        complexTotal === 0
      "
    >
      <h1 class="mat-h1">
        No properties match the criteria that you have entered.
      </h1>
      <p>
        Please try
        <a class="show-more-link-serif" (click)="openSearchDialog()"
          >adjusting</a
        >
        your search filters or click
        <a
          [routerLink]="['/units']"
          routerLinkActive="router-link-active"
          class="show-more-link-serif"
          >here</a
        >
        to view our inventory.
      </p>
      <p *ngIf="myStay === 1 || myStay === 2">
        You are searching for a {{ myStay }} night trip, please note that much
        of our inventory requires longer (3-4 night) minimum stays and we may
        have discounted rates for longer stays. Our Personal Travel Designers
        are standing by to assist you with finding homes where short stays are
        available.
      </p>
    </div>
  </div>

  <twbooking-footer></twbooking-footer>
</div>
