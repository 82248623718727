import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'twbooking-booking-reservation-thankyou',
  templateUrl: './booking-reservation-thankyou.component.html',
  styleUrls: [
    '../unit-enquiry-thank-you/unit-enquiry-thank-you.component.scss',
  ],
})
export class BookingReservationThankYouComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    console.log('THANK YOU');
  }
}
