<div
  class="brand-light-background"
  [class.accomodations-containter-desktop]="!isSmall && !isMobile"
  [class.accomodations-containter-mobile]="isSmall || isMobile"
>
  <div
    [class.description-row]="!isSmall && !isMobile"
    [class.description-column]="isSmall || isMobile"
  >
    <div class="image-wrapper">
      <img
        *ngIf="!isMobile"
        width="225px"
        height="150px"
        [src]="
          unit.URL__c.includes('imgix')
            ? unit.URL__c + '?auto=enhance&w=225&h=150&fit=scale'
            : unit.URL__c
        "
        [alt]="unit.Caption__c"
      />

      <img
        *ngIf="isMobile"
        width="100%"
        [src]="
          unit.URL__c.includes('imgix')
            ? unit.URL__c + spaImagePrefix
            : unit.URL__c
        "
        [alt]="unit.Caption__c"
      />
      <a
        (click)="showPromotion()"
        (mouseenter)="showPromotion()"
        *ngIf="unit.promotioncount > 0"
        class="discount-sticker"
        ttw-tooltip
        [tooltipHtml]="AvailableDeals"
        tooltipHideDelay="1000"
      >
        Discounts
      </a>
    </div>

    <ng-template #AvailableDeals class="tw-tooltip" let-value>
      <div *ngIf="processedDeals.length === 0">
        <p class="white-color">Loading available deals...</p>
      </div>
      <div *ngIf="!this.unit.isInntopiaUnit__c">
        @for (promo of processedDeals; track promo.Name ) {
        <div style="text-align: left">
          <p class="white-color" style="font-weight: bold">{{ promo.Name }}</p>
          <p class="white-color" [innerHTML]="promo.Description__c"></p>
        </div>
        }
      </div>
      <div
        *ngIf="
          this.unit.isInntopiaUnit__c &&
          processedDeals &&
          processedDeals.length > 0
        "
      >
        @for (promo of processedDeals; track promo.Name ) {
        <div style="text-align: left">
          <p class="white-color" style="font-weight: bold">{{ promo.Name }}*</p>
          @for (details of promo.Details; track $index){
          <ul>
            <li class="white-color">
              {{ details.Discount__c }}% off {{ details.MinNights__c
              }}<span
                *ngIf="
                  details.MinNights__c !== details.MaxNights__c &&
                  details.MaxNights__c <= 10
                "
                >-{{ details.MaxNights__c }}</span
              >
              <span *ngIf="details.MaxNights__c > 10"> or more </span>
              nights
            </li>
          </ul>
          }
          <p class="white-color">
            *for stays
            {{ promo.ValidStart__c | date : "mediumDate" }} to
            {{ promo.ValidEnd__c | date : "mediumDate" }} booked until
            {{ promo.BookByDate__c | date : "mediumDate" }}
          </p>
        </div>
        }
      </div>
    </ng-template>

    <div class="description-column">
      <h4 class="new-brand-color">{{ unit.MarketingHeadline__c }}</h4>
      <p>
        {{ unit.Bedrooms__c }} Bedrooms · {{ unit.Bathrooms__c }} Bathrooms ·
        Sleeps
        {{ unit.Sleeps__c }}
      </p>
      <div *ngIf="!nb && this.unit.DisplayRates__c" class="rate-detail">
        <div
          class="rate-detail-row"
          *ngIf="
            !unit.showDetails &&
            !unit.errorMessage &&
            unit.Rent &&
            unit.Rent > 0
          "
        >
          <span>Rent:</span>
          <span *ngIf="unit.Rent === -1">
            Not available for selected dates</span
          >
          <span *ngIf="unit.Rent > 0">{{ unit.Rent | currency }}</span>
        </div>
        <div *ngIf="unit.errorMessage" [innerHTML]="this.errorMessage">
          <div *ngIf="unit.Rent > 0" class="rate-detail-row">
            <div>&nbsp;&nbsp;</div>
            <a
              *ngIf="!unit.showDetails && !unit.errorMessage"
              (click)="unit.showDetails = true; getQuote()"
              class="show-more-nav rate-details-show"
            >
              <span>Show details</span>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </a>
            <a
              *ngIf="unit.showDetails"
              (click)="unit.showDetails = false"
              class="show-more-nav rate-details-show"
            >
              <span>Hide</span>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </a>
          </div>
          <div
            *ngIf="
              unit.showDetails &&
              !unit.inntopiaRate &&
              !unit.rateDetails &&
              !unit.errorMessage
            "
          >
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>

          <div
            *ngIf="
              unit.Total > 0 && unit.showDetails && unit.rateDetails && !nb
            "
          >
            <div
              class="rate-detail-row"
              *ngIf="
                unit.rateDetails.TotalCost__c &&
                unit.rateDetails.TotalCost__c > 0
              "
            >
              <span>Total:</span>
              <span>{{ unit.rateDetails.TotalCost__c | currency }}</span>
            </div>
            <div class="rate-detail-row">
              <span
                [class.striked-out]="
                  unit.rateDetails.Discount__c &&
                  unit.rateDetails.Discount__c > 0 &&
                  unit.rateDetails.Discounted_Rent__c &&
                  unit.rateDetails.Discounted_Rent__c > 0
                "
              >
                Rent:
              </span>
              <span
                [class.striked-out]="
                  unit.rateDetails &&
                  unit.rateDetails.Discount__c &&
                  unit.rateDetails.Discount__c > 0 &&
                  unit.rateDetails.Discounted_Rent__c &&
                  unit.rateDetails.Discounted_Rent__c > 0
                "
              >
                {{ unit.rateDetails.TotalRent__c | currency }}
              </span>
            </div>
            <!-- MM Rent-->
            <div
              class="rate-detail-row"
              *ngIf="
                unit.rateDetails &&
                unit.rateDetails.Discount__c &&
                unit.rateDetails.Discount__c > 0 &&
                unit.rateDetails.Discounted_Rent__c &&
                unit.rateDetails.Discounted_Rent__c > 0
              "
            >
              <span>Discounted rent: </span>
              <span>
                {{ unit.rateDetails.Discounted_Rent__c | currency }}
              </span>
            </div>
            <!-- MM Discounted Rent-->
            <div
              class="rate-detail-row"
              *ngIf="
                unit.rateDetails.RefundableDeposit__c &&
                unit.rateDetails.RefundableDeposit__c > 0
              "
            >
              <span>Refundable Security Deposit:</span>
              <span>{{
                unit.rateDetails.RefundableDeposit__c | currency
              }}</span>
            </div>
            <!-- MM RefundableDeposit-->
            <div class="rate-detail-row">
              <span>Taxes and Fees:</span>
              <span>{{
                unit.rateDetails.DamageProtectionFee__c +
                  unit.rateDetails.CleaningService__c +
                  unit.rateDetails.GuestServicePackage__c +
                  unit.rateDetails.PetFee__c +
                  unit.rateDetails.TAX__c | currency
              }}</span>
            </div>
            <!--Taxes and Fees-->
          </div>

          <!-- MM quote-->
          <div
            *ngIf="
              unit.Total > 0 && unit.showDetails && unit.inntopiaRate && !nb
            "
          >
            <div class="rate-detail-row">
              <span> Total: </span>
              <span>
                {{
                  unit.inntopiaRate.DisplayRent +
                    unit.inntopiaRate.DisplayFees +
                    unit.inntopiaRate.DisplayTaxes +
                    unit.inntopiaRate.optionalFees | currency
                }}
              </span>
            </div>
            <div
              class="rate-detail-row"
              *ngIf="unit.inntopiaRate.savingsAmount > 0"
            >
              <span class="striked-out"> Rent: </span>
              <span class="striked-out">
                {{ unit.inntopiaRate.baseDisplayRent | currency }}
              </span>
            </div>
            <!-- Inntopia base rate-->
            <div class="rate-detail-row">
              <span *ngIf="unit.inntopiaRate.savingsAmount > 0"
                >Discounted Rent:
              </span>
              <span *ngIf="unit.inntopiaRate.savingsAmount === 0">Rent:</span>
              <span> {{ unit.inntopiaRate.DisplayRent | currency }}</span>
            </div>
            <!-- Inntopia discount rate-->
            <div class="rate-detail-row">
              <span>Taxes:</span>
              <span>{{ unit.inntopiaRate.DisplayTaxes | currency }}</span>
            </div>
            <!-- Taxes -->
            <div class="rate-detail-row">
              <span>Fees:</span>
              <span>{{ unit.inntopiaRate.DisplayFees | currency }}</span>
            </div>
            <div
              class="alert-warning"
              *ngIf="
                unit.inntopiaRate.DisplayTaxes <= 0 ||
                !unit.inntopiaRate.DisplayTaxes
              "
            >
              Please note that additional taxes and fees could be applied
            </div>
            <div
              *ngIf="unit.inntopiaRate && unit.inntopiaRate.savingsAmount > 0"
              style="font-weight: bold"
            >
              Book now to secure savings of
              {{ unit.inntopiaRate.savingsAmount | currency }} on this
              reservation
            </div>

            <!-- Fees -->
          </div>
          <!-- Inntopia quote-->
        </div>
      </div>
    </div>
    <div class="accomodations-third">
      <div
        [class.action-column]="!isSmall && !isMobile"
        [class.description-row]="isSmall || isMobile"
      >
        <a
          mat-button
          class="brand-button"
          [class.left-button]="!isMobile"
          [class.top-button]="isMobile"
          [routerLink]="['/unit-details', unit.UnitSlug__c]"
          routerLinkActive="router-link-active"
          [queryParams]="searchQuery"
          >View Details</a
        >
        <div *ngIf="unit.DisplayRates__c">
          <button
            *ngIf="!nb"
            class="brand-button"
            mat-button
            (click)="
              bookNow(
                unit,
                unit.rateDetails ? unit.rateDetails : null,
                unit.inntopiaRate ? unit.inntopiaRate : null
              )
            "
          >
            Book now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
