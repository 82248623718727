import { CheckoutService } from '../../services/checkout.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PaymentsService } from 'src/app/services/payments.service';
import { EventService } from '../../services/event.service';
import { BookingSubmitPaymentGA } from '../../store/models/events-ga.model';

@Component({
  selector: 'app-checkout-step1',
  templateUrl: './checkout-step1.component.html',
  styleUrls: ['./checkout-step1.component.scss'],
})
export class CheckoutStep1Component implements OnInit {
  @Input() booking = 0;
  @Input() ref = '';
  @Input() formValues: any;
  @Input() purchase: BookingSubmitPaymentGA;
  @Output() loadingEmitter = new EventEmitter<boolean>();
  public controls;
  public cyberSourceUrl = environment.cyberSourceUrl;

  constructor(
    private checkoutService: CheckoutService,
    private paymentsService: PaymentsService,
    private eventService: EventService
  ) {}

  encryptData(): void {
    this.loadingEmitter.emit(true);
    if (this.purchase){
        let bookingPayment: BookingSubmitPaymentGA = {
          value: this.purchase.value,
          currency: 'USD',
          transaction_id: this.ref,
        };

        if (this.purchase.coupon) {
          bookingPayment = {
            ...bookingPayment,
            coupon: this.purchase.coupon,
          };
        }

        this.eventService.sendBookingSubmitPaymentGA(bookingPayment);
    }

    if (this.formValues && this.formValues.payment) {
      this.paymentsService
        .changeStatus(this.ref, 'Initiated')
        .subscribe((result) => {
          console.log('Payment status is changed to Initiated');
        });
    }
    let payDetails: any = {
      amount: Math.ceil(this.booking).toString(),
      reference_number: this.ref,
      bill_to_forename: this.formValues.firstnameField,
      bill_to_surname: this.formValues.lastnameField,
      bill_to_address_line1: this.formValues.addressLine1,
      bill_to_address_city: this.formValues.city,
      bill_to_address_state: this.formValues.state,
      bill_to_address_country: this.formValues.country,
      bill_to_address_postal_code: this.formValues.zip,
      bill_to_email: this.formValues.emailField,
    };

    if (
      this.formValues &&
      this.formValues.country.toLowerCase() === 'other' &&
      this.formValues.country1
    ) {
      payDetails['bill_to_address_country'] = this.formValues.country1;
    }

    if (this.formValues && this.formValues.payment_method) {
      payDetails = {
        payment_method: this.formValues.payment_method,
        bill_to_phone: this.formValues.phoneField,
        ...payDetails,
      };
    }
    this.checkoutService.encrypt(payDetails).subscribe((signature) => {
      this.controls = signature;
      // Possibly convert to DOM listener to avoid full second wait
      setTimeout(() => {
        const myForm = <HTMLFormElement>document.forms['payment_confirmation'];
        myForm.submit();
      }, 1000);
    });
  }

  ngOnInit(): void {
    console.log('checkout for: ', this.formValues.firstnameField);
  }
}
