import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Campaign {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  mc_cid?: string;
  mc_eid?: string;
  landingPagePath?: string;
  
}

@Injectable({
  providedIn: 'root'
})
export class CampaignInfoService {
  campaignInfo: BehaviorSubject<Campaign>;

  constructor() {
    this.campaignInfo = new BehaviorSubject(null);
  }

  get(): Campaign {
    return this.campaignInfo.value;
  }

  update(utmInfo: Campaign) {
    this.campaignInfo = new BehaviorSubject(utmInfo);
  }
}
