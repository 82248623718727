import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitsService } from './units.service';
import * as moment from 'moment';
import { LodgingQuoteService } from './lodging-quote.service';

@Injectable({
  providedIn: 'root',
})
export class BookedDatesService {
  bookedDates = new Map();
  bookedDatesHold = new Map();

  constructor(
    private unitService: UnitsService,
    private lodgingQuoteService: LodgingQuoteService
  ) {}

  get(externalId: string): string[] | undefined {
    let hasException = this.lodgingQuoteService.getHold(externalId);
    let savedBooked = null;
    if (!hasException) {
      savedBooked = this.bookedDates.get(externalId);
    } else {
      savedBooked = this.bookedDatesHold.get(externalId);
    }

    if (!savedBooked) {
      return undefined;
    } else {
      return savedBooked;
    }
  }

  getBookedDates(
    externalId: string,
    isInntopiaUnit: boolean,
    supplierId?: string,
    productId?: string
  ): Observable<any> {
    let personalHold = null;
    if (!isInntopiaUnit) {
      personalHold = this.lodgingQuoteService.getHold(externalId);
    }
    const maxDate = moment(new Date()).add(550, 'days').format('YYYY-MM-DD');
    let bookedQuery = {
      unitId: externalId,
      supplierId: supplierId,
      productId: productId,
      personalHold: personalHold,
      maxDate: maxDate,
    };
    return this.unitService.getAvailablity(bookedQuery).pipe(
      map((response) => {
        if (response.bookeddates) {
          let bookedDays = response.bookeddates;
          this.bookedDates.set(externalId, bookedDays);
          return bookedDays;
        } else {
          console.error(
            `No booked dates for unit ${externalId} ${supplierId} ${productId}`
          );
          return [];
        }
      })
    );
  }
}
