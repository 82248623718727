import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Unit } from 'src/app/store/models/unit.model';
import { Complex } from 'src/app/store/models/complex.model';
import { Subscription, of, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CombinedStratery } from './combined-strategy';
import { ComplexRelated } from './complex-related-strategy';
import { UnitsService } from '../../services/units.service';
import { SearchParamsService } from '../../services/search-params.service';
import { Router } from '@angular/router';

export interface RelatedStrategy {
  getSearchQuery(): any;
}

@Component({
  selector: 'twbooking-unit-related',
  templateUrl: './unit-related.component.html',
  styleUrls: ['./unit-related.component.scss'],
})
export class UnitRelatedComponent implements OnInit, OnDestroy {
  @Input() unit: Unit;
  @Input() complex: Complex;
  uniComplex: any; // Unit | Complex
  unitDisplay: Observable<any>;
  columnNum = 1;
  mediaSub: Subscription;
  mmOnly = false;
  nest = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private searchService: SearchParamsService,
    private unitService: UnitsService,
    private router: Router
  ) {
    this.mediaSub = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.columnNum = 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.columnNum = 2;
        } else if (
          result.breakpoints[Breakpoints.Medium] ||
          result.breakpoints[Breakpoints.Large]
        ) {
          this.columnNum = 3;
        } else if (result.breakpoints[Breakpoints.XLarge]) {
          this.columnNum = 4;
        }
      });
    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
    }
  }

  myStrategy: RelatedStrategy;
  searchQuery: any = {};

  ngOnInit() {
    this.nest = 0;

    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
    }

    // this.searchService.searchParams.subscribe(changes => {
    const searchQueryInitial = this.searchService.get();
    if (this.unit) {
      this.uniComplex = this.unit;
      this.myStrategy = new CombinedStratery(this.unit, searchQueryInitial);
    } else {
      this.uniComplex = this.complex;
      this.myStrategy = new ComplexRelated(this.complex, searchQueryInitial);
    }
    this.searchQuery = this.myStrategy.getSearchQuery();
    this.unitDisplay = null;

    this.unitSearch();
    // });
  }

  unitSearch() {
    let rows = 3;
    const urlSearchParams = new URLSearchParams(this.searchQuery).toString();
    if (this.searchQuery === undefined) {
      this.searchQuery = {};
      throw new Error(
        'Undefined this.searchQuery in unit-related search for unit ' +
          this.uniComplex.UnitSlug__c
      );
    }
    if (this.nest < 5) {
      this.unitService
        .unitSearch(
          this.searchQuery,
          this.mmOnly,
          urlSearchParams,
          this.columnNum * rows + 1,
          0
        )
        .subscribe((resp: any) => {
          this.nest++;
          if (this.columnNum > 1) {
            rows = 1;
          }
          if (resp) {
            if (
              (this.nest < 2 && resp.result && resp.result === 'NO RESULTS') ||
              (resp.units && resp.units.length <= 1)
            ) {
              this.searchQuery = {
                City: this.uniComplex.Destination__Name
                  ? this.uniComplex.Destination__Name.split(', ')[0]
                  : this.uniComplex.Destination.split(', ')[0],
                State: this.uniComplex.Destination__Name
                  ? this.uniComplex.Destination__Name.split(', ')[1]
                  : this.uniComplex.Destination.split(', ')[1],
              };
              this.unitSearch();
            }
            if (resp.error && resp.error === 'TooManyRequests') {
              console.log('TooManyRequests!');
              throw new Error('TooManyRequests error in related listing');
            }
            if (
              (this.nest >= 2 && resp.result && resp.result === 'NO RESULTS') ||
              (resp.units && resp.units.length <= 1)
            ) {
              this.searchQuery = {};
              this.unitSearch();
            }

            if (resp.units && resp.units.length > 1) {
              this.unitDisplay = of(
                resp.units
                  .filter((relatedUnit) => {
                    return (
                      relatedUnit.UnitSlug__c !== this.uniComplex.UnitSlug__c
                    );
                  })
                  .slice(0, this.columnNum * rows)
              );
            }
            return;
          }
        });
    } else {
      return [];
    }
  }

  search() {
    this.searchService.update(this.searchQuery);
    const queryParams = this.searchQuery;
    this.router.navigate(['search-results'], { queryParams });
  }

  identifyUnit(index: number, unit: any) {
    return unit ? unit.externalId : null;
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
}
