<div class="unit-rates-container">
  <div class="modal-headline">
    <h1 mat-dialog-title>Booking Details</h1>
    <div class="modal-headline-close">
      <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <twbooking-booking-form
      [bookingInput]="data"
      mode="StandardBooking"
      editAllowed="true"
    ></twbooking-booking-form>
  </mat-dialog-content>
</div>
