import { Injectable } from '@angular/core';
import { DialogReplica, UnitBasics } from 'src/app/store/models/chat.models';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { collection, doc, docData, Firestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public checkExistingChatsDone = false;
  public userChat: BehaviorSubject<DialogReplica[]>;
  public chatSessionId: BehaviorSubject<string>;
  public proposedUnits: BehaviorSubject<UnitBasics[]>;
  public ptdInChat: BehaviorSubject<boolean>;
  public troubles: BehaviorSubject<number>;
  public botStatus$: BehaviorSubject<'On' | 'Off' | null>;
  public ptdImage$: BehaviorSubject<string> = new BehaviorSubject(null);
  public volume$: BehaviorSubject<'On' | 'Off' | null>;
  botStatusSub: Subscription;
  constructor(private readonly firestore: Firestore) {
    this.userChat = new BehaviorSubject([]);
    this.chatSessionId = new BehaviorSubject(null);
    this.proposedUnits = new BehaviorSubject([]);
    this.ptdInChat = new BehaviorSubject(false);
    this.troubles = new BehaviorSubject(0);
    this.botStatus$ = new BehaviorSubject('Off');
    this.volume$ = new BehaviorSubject('On');
    this.botStatusSub = this.fetchBotStatus().subscribe((status) => {
      if (status && status.value) {
        if (status.value !== this.botStatus$.value || status.photoURL !== this.ptdImage$.value) {
          this.ptdImage$.next(status.photoURL? status.photoURL : null);
          this.botStatus$.next(status.value);
        } 
      } else {
        this.ptdImage$.next(null);
        this.botStatus$.next('Off');
        
      }
    });
    
  }




  update(replicas: DialogReplica[]) {
    this.userChat.next(replicas);
  }
  get(): DialogReplica[] {
    return this.userChat.value;
  }

  updateSessionId(newSessionId: string) {
    this.chatSessionId.next(newSessionId);
  }
  getSessionId(): string {
    return this.chatSessionId.value;
  }
  getPtdStatus(): boolean {
    return this.ptdInChat.value;
  }

  getTroubles(): number {
    return this.troubles.value;
  }

  setTroubles(newTroubles: number) {
    this.troubles.next(newTroubles);
  }

 

  getVolume(): 'On' | 'Off' | null {
    return this.volume$.value;
  }

  setVolume(newVolume: 'On' | 'Off' | null) {
    this.volume$.next(newVolume);
  }

  ptdEnteredChat(value: boolean) {
    this.ptdInChat.next(value);
  }

  updateUnits(unitList: UnitBasics[]) {
    this.proposedUnits.next(unitList);
  }
  getUnits(): UnitBasics[] {
    return this.proposedUnits.value;
  }

  fetchBotStatus(): Observable<any> {
    const chatCollection = collection(this.firestore, 'chatStatus');
    const newMessagesRef = doc(chatCollection, 'status');
    return docData(newMessagesRef).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  


 
}
