import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationFilter',
})
export class LocationFilterPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (!args || !args[0] || !args[1]) {
      return value;
    }
    const filter = args[0].toLocaleLowerCase();
    const filterField = args[1];
    return filter && filterField
      ? value.filter(
          unit =>
            unit[filterField] &&
            unit[filterField].toLocaleLowerCase().indexOf(filter) !== -1
        )
      : value;
  }
}
