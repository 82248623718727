export interface TTWLink {
  text: string;
  url: string;
  items?: {
    text: string;
    url: string;
    sametab?: boolean;
  }[];
}

export const linksList = {
  Neutral: [],
  Firstteetravel: [
    {
      text: 'Tours',
      url: 'https://www.firstteetravel.com/tours.html'
    },
    {
      text: 'Cruises',
      url: 'https://www.firstteetravel.com/cruises.html'
    },
    {
      text: 'About Us',
      url: 'https://www.firstteetravel.com/about-us.html'
    }
  ],
  TTW: [
    {
      text: 'About Us',
      url: 'https://thetravelwhisperer.com/about-us',
      items: [
        {
          text: 'Meet the team',
          url: 'https://thetravelwhisperer.com/about-us/#meet-the-team',
        },
      ],
    },
    {
      text: 'Promotions',
      url: 'https://thetravelwhisperer.com/promotions',
      items: [
        {
          text: 'Ski Promotions',
          url: 'https://thetravelwhisperer.com/promotions',
        },
      ],
    },
    {
      text: 'Ski Resorts',
      url: 'https://thetravelwhisperer.com/ski-destinations',
      items: [
        {
          text: 'Aspen Snowmass',
          url: 'https://thetravelwhisperer.com/destinations/aspen-snowmass',
        },
        {
          text: 'Beaver Creek',
          url: 'https://thetravelwhisperer.com/destinations/beaver-creek',
        },
        {
          text: 'Big Sky Resort',
          url: 'https://thetravelwhisperer.com/destinations/big-sky',
        },
        {
          text: 'Breckenridge',
          url: 'https://thetravelwhisperer.com/destinations/breckenridge',
        },
        {
          text: 'Deer Valley',
          url: 'https://thetravelwhisperer.com/destinations/deer-valley',
        },
        {
          text: 'Jackson Hole',
          url: 'https://thetravelwhisperer.com/destinations/jackson-hole',
        },
        {
          text: 'Park City',
          url: 'https://thetravelwhisperer.com/destinations/park-city',
        },
        {
          text: 'Steamboat Resort',
          url: 'https://thetravelwhisperer.com/destinations/steamboat',
        },
        {
          text: 'Telluride',
          url: 'https://thetravelwhisperer.com/destinations/telluride',
        },
        {
          text: 'Vail',
          url: 'https://thetravelwhisperer.com/destinations/vail',
        },
        {
          text: 'Whistler Blackcomb',
          url: 'https://thetravelwhisperer.com/destinations/whistler-blackcomb',
        },
      ],
    },
    {
      text: 'Safari',
      url: 'https://thetravelwhisperer.com/safari',
      items: [
        {
          text: 'Botswana',
          url: 'https://thetravelwhisperer.com/safari/destinations/botswana',
        },
        {
          text: 'Kenya',
          url: 'https://thetravelwhisperer.com/safari/destinations/kenya',
        },
        {
          text: 'Rwanda',
          url: 'https://thetravelwhisperer.com/safari/destinations/rwanda',
        },
        {
          text: 'South Africa',
          url: 'https://thetravelwhisperer.com/safari/destinations/south-africa',
        },
        {
          text: 'Tanzania',
          url: 'https://thetravelwhisperer.com/safari/destinations/tanzania',
        },
        {
          text: 'Uganda',
          url: 'https://thetravelwhisperer.com/safari/destinations/uganda',
        },
      ],
    },
    {
      text: 'Contact Us',
      url: 'https://thetravelwhisperer.com/contact-us',
      items: [
        {
          text: 'Call: (970) 688-5193',
          url: 'tel:+19706885193',
          sametab: true,
        },
        {
          text: 'Ski Inquiries',
          url: '/contact-us/',
          sametab: true,
        },
        {
          text: 'Safari Inquiries',
          url: 'https://thetravelwhisperer.com/safari-contact-us',
        },
      ],
    },
  ],
  Laurie: [
    {
      text: 'Specials',
      url: 'https://www.vacationsbylaurie.com/packages-specials.asp',
    },
    {
      text: 'Local Area Guide',
      url: 'https://www.vacationsbylaurie.com/local-area-guide.asp',
    },
    {
      text: 'About Us',
      url: 'https://www.vacationsbylaurie.com/property-management-services.asp',
    },
    {
      text: 'Guest Services',
      url: 'https://www.vacationsbylaurie.com/concierge-services.asp',
    },
  ],
  smartflyer: [
    {
      text: 'Stories',
      url: 'https://smartflyer.com/blog',
    },
    {
      text: 'Reviews',
      url: 'https://smartflyer.com/reviews',
    },
    {
      text: 'About Us',
      url: 'https://smartflyer.com/agent/smartflyer-nussbaum',
    },
    {
      text: 'Contact Us',
      url: 'https://smartflyer.com/contact-us',
    },
  ],
  Amanda: [
    {
      text: "Amanda's Travel Podcasts",
      url: 'http://www.amandastravels.com/series/amandas-travels',
    },
    {
      text: 'About Me',
      url: 'http://www.amandastravels.com/about-us',
    },
    {
      text: 'Contact Us',
      url: 'http://www.amandastravels.com/contact-us',
    },
  ],
  jettsettravel: [
    {
      text: 'Helpful Travel Links',
      url: 'https://www.jettsettravel.us/Page/HelpfulTravelLinks',
    },
    {
      text: 'Book Now',
      url: 'https://www.jettsettravel.us/Page/BookNow',
    },
    {
      text: 'Contact Us',
      url: 'https://www.jettsettravel.us/Info/TripRequest',
    },
  ],
  packhappytravel: [
    {
      text: 'Services',
      url: 'https://packhappytravel.com/travel-services',
    },
    {
      text: 'FAQs',
      url: 'https://packhappytravel.com/travel-advice',
    },
    {
      text: 'Reviews',
      url: 'https://packhappytravel.com/travel-agent-reviews',
    },
    {
      text: 'About Us',
      url: 'https://packhappytravel.com/kansas-city-travel-agents',
    },
    {
      text: 'Contact Us',
      url: 'https://packhappytravel.com/contact',
    },
  ],
  MM: [
    {
      text: 'Home Management',
      url: 'https://www.mountainmanagement.com/home-management',
    },
    {
      text: 'Rental Management',
      url: 'https://www.mountainmanagement.com/rental-management',
    },
  ],
};
