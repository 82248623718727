import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentTransform'
})
export class MomentTransformPipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    return moment.utc(value, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }
}
