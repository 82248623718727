<mat-card
  appearance="outlined"
  class="unit-summary-card white-background new-brand-color flex-container-column"
>
  <div (swipe)="onSwipe($event)" class="img-default">
    <twbooking-summary-carousel
      complex="true"
      [images]="images"
      [show]="choosenAmenity"
      [slug]="complex.UnitSlug__c"
      [searchQuery]="searchQuery"
    >
    </twbooking-summary-carousel>

    <span *ngIf="complex.promounits > 0" class="discount-sticker">
      Discounts available
    </span>
  </div>

  <div
    class="summary-info-container new-brand-color container-default flex-even-column"
  >
    <h1 class="summary-info-title new-brand-color">
      <a
        class="summary-info-title new-brand-color"
        [routerLink]="['/complex-details', complex.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >{{ complex.MarketingHeadline__c }}</a
      >
    </h1>

    <div class="summary-info-data featured-amenities-container">
      <div class="inner-container">
        @for (amenity of featuredAmenities; track i; let i = $index) {
        <span class="featured-amenities">
          <ng-template #HtmlTooltip let-value>
            <div class="tooltip-container-small">
              <div class="tooltip-content">
                {{ amenity.Description__c }}
              </div>
            </div>
          </ng-template>
          <mat-icon
            fontSet="TTW"
            fontIcon="{{ iconByAmenity[amenity.AmenityType__c] }}"
            ttw-tooltip
            [tooltipHtml]="HtmlTooltip"
            smallTooltip="yes"
            (click)="showAmenity(amenity.sliderIndex)"
            class="tw-icon new-brand-color amenity-icon"
            [class.icon-clickable]="amenity.sliderIndex"
          ></mat-icon>
          <span *ngIf="i < featuredAmenities.length - 1 || restAmenities"
            >&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
        </span>
        }

        <span
          *ngIf="restAmenities"
          ttw-tooltip
          smallTooltip="yes"
          [tooltipHtml]="RestTooltip"
          >...
          <ng-template #RestTooltip let-value>
            <div class="tooltip-container-small">
              @for (amenity of restAmenities; track $index){
              <div class="tooltip-content-list">
                <mat-icon
                  fontSet="TTW"
                  fontIcon="{{ iconByAmenity[amenity.AmenityType__c] }}"
                ></mat-icon>
                <span style="vertical-align: center">{{
                  amenity.AmenityType__c
                }}</span>
              </div>
              }
            </div>
          </ng-template>
        </span>
      </div>

      <div class="reviews-container" *ngIf="complex.ReviewCount > 0">
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewCount > 3.8"
          >star</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewCount > 1 && complex.ReviewCount <= 3.8"
          >star_half</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewCount <= 1"
          >star_border</mat-icon
        >
        <span class="review-text">
          {{ complex.ReviewRating | number : "1.2-2" }} ({{
            complex.ReviewCount
          }})
        </span>
      </div>
    </div>

    <div class="summary-info-data ttw-placeholder-color">
      <span>{{ complex.unitsSummary }}</span>
    </div>

    <div class="summary-actions new-brand-color">
      <!--summary-info-actions-->
      <span class="hidden-button"></span>
      <a
        class="unit-summary-button new-brand-background white-color"
        mat-button
        [routerLink]="['/complex-details', complex.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >View Details</a
      >
      <a
        *ngIf="!nb"
        class="unit-summary-button new-brand-background white-color"
        mat-button
        (click)="inquireNow()"
        >Inquire now</a
      >
      <span *ngIf="nb" class="hidden-button"></span>
    </div>
  </div>
</mat-card>
