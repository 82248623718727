import { Component } from '@angular/core';

@Component({
  selector: 'twbooking-summary-ghost',
  templateUrl: './summary-ghost.component.html',
  styleUrls: ['./summary-ghost.component.scss']
})
export class SummaryGhostComponent {
  constructor() {}
}
