<div class="search-container">
  <!-------------------SMALL MOBILE WIDGET-------------------------->
  <div
    class="search-params-chip brand-border-16 white-background"
    *ngIf="isMobile && !showAllFields"
    (click)="showAllFields = true"
  >
    <div class="search-group">
      <div class="search-icon new-brand-color">
        <mat-icon>search</mat-icon>
      </div>

      <div class="search-summary">
        <div
          class="search-summary-location new-brand-color"
          *ngIf="locationMode !== 'mm'"
        >
          {{
            this.form.get("location").value
              ? this.form.get("location").value.City +
                ", " +
                this.form.get("location").value.State
              : "All Locations"
          }}
        </div>
        <div
          class="search-summary-location new-brand-color"
          *ngIf="locationMode == 'mm'"
        >
          {{
            this.form.get("village").value
              ? this.form.get("village").value
              : "Beaver Creek, Colorado"
          }}
        </div>

        <div class="dates-and-guests">
          <div>
            {{
              arrivalField && departureField
                ? arrivalField.format("DD MMM") +
                  " - " +
                  departureField.format("DD MMM YYYY")
                : "Any week"
            }}
          </div>
          <div>&nbsp;&middot;&nbsp;</div>
          <div>
            {{
              this.form.get("numAdults").value +
                (this.form.get("numAdults").value > 1 ? " Adults" : " Adult") +
                (this.form.get("numChildren").value
                  ? ", " +
                    this.form.get("numChildren").value +
                    (this.form.get("numChildren").value > 1
                      ? " Children"
                      : " Child")
                  : "")
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="search-icon new-brand-color">
      <mat-icon
        class="brand-light-badge"
        [matBadge]="filtersNumber > 0 ? filtersNumber : null"
        >tune</mat-icon
      >
    </div>
  </div>

  <!-------------------MOBILE VERSION-------------------------->
  <div class="search-bar figure-shadow" *ngIf="isMobile && showAllFields">
    <form class="search-form-column" [formGroup]="form" novalidate>
      <div class="action-full" [class.border-bottom-opacity-16]="isMobile">
        <h3 class="new-brand-color">LOCATION</h3>

        <mat-select
          *ngIf="locationMode !== 'mm'"
          panelClass="location-select"
          placeholder="Where are you going?  "
          formControlName="location"
          name="locationValue"
          class="location-placeholder brand-dark-color"
          [compareWith]="compareLocation"
        >
          @for (group of locationGroups; track group.name) {
          <mat-optgroup [label]="group.name" [disabled]="group.disabled">
            @for (location of group.locations; track location.value) {
            <mat-option [value]="location.value">
              {{ location.viewValue }}
            </mat-option>
            }
          </mat-optgroup>
          }
        </mat-select>
        <mat-select
          *ngIf="locationMode == 'mm'"
          panelClass="location-select"
          [placeholder]="locationsPlaceholder"
          formControlName="village"
          name="village"
          class="location-placeholder brand-dark-color"
        >
          @for (group of villagesBC; track group.name){
          <mat-optgroup>
            @for (village of group.villages; track village.value){
            <mat-option [value]="village.value">
              {{ village.viewValue }}
            </mat-option>
            }
          </mat-optgroup>
          }
        </mat-select>
      </div>

      <div class="action-full border-bottom-opacity-16">
        <h3 class="new-brand-color">DATES</h3>
        <div class="dates-in-row">
          <div class="check-in">
            <input
              matInput
              class="brand-dark-color date-input"
              readonly
              [matDatepicker]="arrivalPicker"
              placeholder="Arrival"
              formControlName="arrival"
              [matDatepickerFilter]="arrivalFilterFunction"
              (dateChange)="arrivalChanged($event)"
              (click)="arrivalPicker.open()"
            />
            <mat-datepicker
              #arrivalPicker
              [touchUi]="isMobile"
              color="primary"
            ></mat-datepicker>
          </div>

          <div class="right-divider brand-dark-opacity-16-background"></div>
          <ng-template #shortStayPopover>
            <div class="tooltip-container">
              <div class="tooltip-content">
                You are searching for a {{ myStay }} night trip, please note
                that much of our inventory requires longer (3-4 night) minimum
                stays and we may have discounted rates for longer stays.
                <a href="/contact-us" class="underlined-link"
                  >Our Personal Travel Designers</a
                >
                are standing by to assist you with finding homes where short
                stays are available.
              </div>
            </div>
          </ng-template>
          <div
            class="check-in"
            clickable="yes"
            [tooltipHtml]="shortStayPopover"
            [tooltipHideDelay]="10000"
            ttw-tooltip
            [tooltipOnHover]="false"
            #tooltip1Directive
            [target]="myDeparture"
          >
            <input
              matInput
              class="brand-dark-color date-input"
              readonly
              placeholder="Departure"
              formControlName="departure"
              [matDatepicker]="departurePicker"
              [matDatepickerFilter]="departureFilterFunction"
              (dateChange)="departureChanged($event)"
              (click)="departurePicker.open()"
              ttw-tooltip
              [tooltipHtml]="shortStayPopover"
              tooltipHideDelay="4000"
              tooltipOnHover="false"
              #tooltip1Directive
            />
            <mat-datepicker
              #departurePicker
              color="primary"
              [dateClass]="dateClass"
              [touchUi]="isMobile"
            ></mat-datepicker>
            <div #myDeparture></div>
          </div>
        </div>
      </div>

      <ng-template #lotOfGuestsPopover>
        <div class="tooltip-container">
          <div class="tooltip-content">
            Need to accommodate a large group?
            <a href="/contact-us" class="underlined-link"
              >Contact our Personal Travel Designers</a
            >, who are adept at finding the perfect homes for parties of any
            size, to assist you.
          </div>
        </div>
      </ng-template>
      <div
        class="action-full"
        [class.border-bottom-opacity-16]="isMobile"
        [tooltipHtml]="lotOfGuestsPopover"
        ttw-tooltip
        clickable="yes"
        [tooltipHideDelay]="10000"
        [tooltipOnHover]="false"
        #tooltip2Directive
        [target]="myGuests"
      >
        <h3 class="new-brand-color">GUESTS</h3>

        <div class="guests-value" (click)="showGuestsConfig = true">
          {{ guests }}
        </div>
        <div *ngIf="showGuestsConfig" class="guest-config">
          <div class="guest-option-section">
            <div class="guest-option-section-content">
              <div class="guest-column">
                <span class="guest-title new-brand-color">ADULTS</span>
                <span class="guest-description new-brand-color"
                  >Ages 13 or above</span
                >
              </div>
              <span class="guest-class">
                <button
                  class="plus-minus-button"
                  ttwNoDoubleTapZoom
                  (click)="changeGuests('-', 'numAdults')"
                >
                  <span>-</span></button
                >&nbsp;&nbsp;{{
                  form.get("numAdults").value
                }}&nbsp;&nbsp;<button
                  class="plus-minus-button"
                  ttwNoDoubleTapZoom
                  (click)="changeGuests('+', 'numAdults')"
                >
                  <span>+</span>
                </button>
              </span>
            </div>
          </div>

          <div class="guest-option-section">
            <div class="guest-option-section-content">
              <div class="guest-column">
                <span class="guest-title new-brand-color">CHILDREN</span>
                <span class="guest-description new-brand-color">Ages 2-12</span>
              </div>
              <span class="guest-class">
                <button
                  ttwNoDoubleTapZoom
                  class="plus-minus-button"
                  (click)="changeGuests('-', 'numChildren')"
                >
                  <span>-</span></button
                >&nbsp;&nbsp;{{
                  form.get("numChildren").value
                }}&nbsp;&nbsp;<button
                  ttwNoDoubleTapZoom
                  class="plus-minus-button"
                  (click)="changeGuests('+', 'numChildren')"
                >
                  <span>+</span>
                </button>
              </span>
            </div>
          </div>

          <div class="guests-button-container">
            <button
              class="ttw-transparent-button"
              mat-button
              (click)="showGuestsConfig = false"
            >
              Done
            </button>
          </div>
        </div>
        <div #myGuests></div>
      </div>

      <div [class.action-right]="!isMobile" [class.two-on-row]="isMobile">
        <button
          [class.button-mobile]="isMobile"
          class="brand-button"
          mat-button
          type="button"
          (click)="handleSubmit(form.value, form.valid)"
        >
          Search
        </button>
        <button
          mat-icon-button
          class="brand-button icon-button-mobile brand-light-badge"
          [matBadge]="filtersNumber > 0 ? filtersNumber : null"
          (click)="openSearchDialog()"
          *ngIf="isMobile"
        >
          <mat-icon>tune</mat-icon>
        </button>
        <button
          mat-icon-button
          class="brand-button icon-button-mobile"
          (click)="showAllFields = false"
          *ngIf="isMobile"
        >
          <mat-icon *ngIf="isMobile">close_fullscreen</mat-icon>
        </button>
      </div>
    </form>
  </div>

  <!-------------------DESKTOP VERSION-------------------------->

  <div class="search-bar figure-shadow" *ngIf="!isMobile">
    <form class="search-form-row" [formGroup]="form" novalidate>
      <div class="input-field-wrapper">
        <div class="action-full">
          <h3 class="new-brand-color">LOCATION</h3>

          <mat-select
            *ngIf="locationMode !== 'mm'"
            panelClass="location-select"
            [placeholder]="locationsPlaceholder"
            formControlName="location"
            name="locationValue"
            class="location-placeholder brand-dark-color"
            [compareWith]="compareLocation"
            (selectionChange)="onLocationChange($event)"
          >
            @for (group of locationGroups; track group.value) {
            <mat-optgroup [label]="group.name" [disabled]="group.disabled">
              @for (location of group.locations; track location.value) {
              <mat-option [value]="location.value">
                {{ location.viewValue }}
              </mat-option>
              }
            </mat-optgroup>
            }
          </mat-select>
          <mat-select
            *ngIf="locationMode == 'mm'"
            panelClass="location-select"
            [placeholder]="locationsPlaceholder"
            formControlName="village"
            name="village"
            class="location-placeholder brand-dark-color"
          >
            @for (group of villagesBC; track group.name) {
            <mat-optgroup [label]="group.name">
              @for (village of group.villages; track village.value) {
              <mat-option [value]="village.value">
                {{ village.viewValue }}
              </mat-option>
              }
            </mat-optgroup>
            }
          </mat-select>
        </div>
        <div class="right-divider brand-dark-opacity-16-background"></div>

        <div class="action-full">
          <h3 class="new-brand-color">CHECK IN</h3>
          <input
            matInput
            class="brand-dark-color date-input"
            readonly
            [matDatepicker]="arrivalPicker"
            placeholder="Add dates"
            formControlName="arrival"
            [matDatepickerFilter]="arrivalFilterFunction"
            (dateChange)="arrivalChanged($event)"
            (click)="arrivalPicker.open()"
          />
          <mat-datepicker
            #arrivalPicker
            [touchUi]="isMobile"
            color="primary"
          ></mat-datepicker>
        </div>
        <div class="right-divider brand-dark-opacity-16-background"></div>
        <ng-template #shortStayPopover>
          <div class="tooltip-container">
            <div class="tooltip-content">
              You are searching for a {{ myStay }} night trip, please note that
              much of our inventory requires longer (3-4 night) minimum stays
              and we may have discounted rates for longer stays.
              <a href="/contact-us" class="underlined-link"
                >Our Personal Travel Designers</a
              >
              are standing by to assist you with finding homes where short stays
              are available.
            </div>
          </div>
        </ng-template>
        <div
          class="action-full"
          [class.border-bottom-opacity-16]="isMobile"
          clickable="yes"
          [tooltipHtml]="shortStayPopover"
          [tooltipHideDelay]="10000"
          ttw-tooltip
          [tooltipOnHover]="false"
          #tooltip1Directive
          [target]="myDeparture"
        >
          <h3 class="new-brand-color">CHECK OUT</h3>
          <input
            matInput
            class="brand-dark-color date-input"
            readonly
            placeholder="Add dates"
            formControlName="departure"
            [matDatepicker]="departurePicker"
            [matDatepickerFilter]="departureFilterFunction"
            (dateChange)="departureChanged($event)"
            (click)="departurePicker.open()"
            ttw-tooltip
            [tooltipHtml]="shortStayPopover"
            tooltipHideDelay="4000"
            tooltipOnHover="false"
            #tooltip1Directive
          />
          <mat-datepicker
            #departurePicker
            color="primary"
            [dateClass]="dateClass"
            [touchUi]="isMobile"
          ></mat-datepicker>
          <div #myDeparture></div>
        </div>
        <div class="right-divider brand-dark-opacity-16-background"></div>

        <ng-template #lotOfGuestsPopover>
          <div class="tooltip-container">
            <div class="tooltip-content">
              Need to accommodate a large group?
              <a href="/contact-us" class="underlined-link"
                >Contact our Personal Travel Designers</a
              >, who are adept at finding the perfect homes for parties of any
              size, to assist you.
            </div>
          </div>
        </ng-template>
        <div
          class="action-full"
          [class.border-bottom-opacity-16]="isMobile"
          [tooltipHtml]="lotOfGuestsPopover"
          ttw-tooltip
          clickable="yes"
          [tooltipHideDelay]="10000"
          [tooltipOnHover]="false"
          #tooltip2Directive
          [target]="myGuests"
        >
          <h3 class="new-brand-color">GUESTS</h3>
          <twbooking-guests-select
            [numAdults]="form.get('numAdults').value"
            [numChildren]="form.get('numChildren').value"
            [numInfants]="0"
            [numPets]="0"
            [petFriendly]="false"
            (guestEmitter)="numberofGuestChanged($event)"
          ></twbooking-guests-select>
          <div #myGuests></div>
        </div>
      </div>

      <div class="action-wrapper">
        <button
          mat-button
          class="filters-transparent-button brand-light-badge"
          [matBadge]="filtersNumber > 0 ? filtersNumber : null"
          (click)="openSearchDialog()"
        >
          <mat-icon>tune</mat-icon>
          <span>Filters</span>
        </button>
      </div>

      <div class="action-wrapper">
        <div [class.action-right]="!isMobile">
          <button
            class="brand-small-button"
            mat-button
            type="button"
            (click)="handleSubmit(form.value, form.valid)"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </div>

  <!-------------------STICKY REDUCED DESKTOP VERSION-------------------------->

  <div
    class="sticky-panel"
    *ngIf="!isMobile && this.mode !== 'map'"
    [class.visible]="isStickyPanelVisible"
    (click)="openSearchDialog()"
  >
    <div class="sticky-search-bar">
      <div class="search-params-chip brand-border-16 white-background">
        <div class="search-group">
          <div class="search-icon new-brand-color">
            <mat-icon>search</mat-icon>
          </div>

          <div class="search-summary">
            <div
              class="search-summary-location new-brand-color"
              *ngIf="locationMode !== 'mm'"
            >
              {{
                this.form.get("location").value
                  ? this.form.get("location").value.City +
                    ", " +
                    this.form.get("location").value.State
                  : "All Locations"
              }}
            </div>
            <div
              class="search-summary-location new-brand-color"
              *ngIf="locationMode == 'mm'"
            >
              {{
                this.form.get("village").value
                  ? this.form.get("village").value
                  : "Beaver Creek, Colorado"
              }}
            </div>

            <div class="dates-and-guests">
              <div>
                {{
                  arrivalField && departureField
                    ? arrivalField.format("DD MMM") +
                      " - " +
                      departureField.format("DD MMM YYYY")
                    : "Any week"
                }}
              </div>
              <div>&nbsp;&middot;&nbsp;</div>
              <div>
                {{
                  this.form.get("numAdults").value +
                    (this.form.get("numAdults").value > 1
                      ? " Adults"
                      : " Adult") +
                    (this.form.get("numChildren").value
                      ? ", " +
                        this.form.get("numChildren").value +
                        (this.form.get("numChildren").value > 1
                          ? " Children"
                          : " Child")
                      : "")
                }}
              </div>
            </div>
          </div>
        </div>

        <button
          mat-button
          class="filters-transparent-button brand-light-badge"
          [matBadge]="filtersNumber > 0 ? filtersNumber : null"
        >
          <mat-icon>tune</mat-icon>
          <span>Filters</span>
        </button>
      </div>
    </div>
  </div>
</div>
