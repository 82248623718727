import {
  Component,
  Input,
  ElementRef,
  OnInit,
  HostListener,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { GoogleMapsLoaderService } from 'src/app/services/google-maps-loader.service';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';

@Component({
  selector: 'twbooking-unit-map',
  templateUrl: './unit-map.component.html',
  styleUrls: ['./unit-map.component.scss'],
})
export class UnitMapComponent implements OnInit {
  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @Input() lat: any;
  @Input() lng: any;
  @Input() title: string;
  @Input() image: string;
  @Input() caption: string;
  customPin: google.maps.marker.PinElement;

  showMap = false;

  _parentElement: ElementRef;
  parentWidth: number;
  resizeTimeout;
  width = 300;
  height = 150;

  markerInfo: {
    title: string;
    image: string;
    caption: string;
  } | null = null;

  public setParentElement(element: ElementRef) {
    this._parentElement = element;
    this.updateParentWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateParentWidth();
    }, 200); // 200ms debounce time
  }

  private updateParentWidth() {
    if (this._parentElement) {
      this.parentWidth = this._parentElement.nativeElement.offsetWidth;

      this.width = this.parentWidth;
      if (this.parentWidth < 450) {
        this.height = this.parentWidth;
      } else {
        this.height = this.parentWidth / 2;
      }
    }
  }

  latNumber: number = null;
  lngNumber: number = null;

  mapOptions: any;

  zoom: number = 15; // Adjust as needed

  constructor(private googleMapsService: GoogleMapsLoaderService) {
    this.googleMapsService.scriptLoaded$.subscribe((loaded) => {
      if (loaded) {
        console.log('google map script loaded');
        this.initializeMapProperties();
      }
    });
  }

  initializeMapProperties() {
    this.mapOptions = {
      mapId: '96e5c3c65d08bfec',
    };
    this.updateParentWidth();
    let glyphImg = document.createElement('img');

    glyphImg.src = '/assets/home_glyph.svg';

    this.customPin = new google.maps.marker.PinElement({
      background: '#90d7de',
      borderColor: '#103753',
      glyph: glyphImg,
      scale: 1.2,
    });
    this.showMap = true;
  }

  ngOnInit(): void {
    this.latNumber = +this.lat;
    this.lngNumber = +this.lng;

    this.markerInfo = {
      title: this.title,
      image: this.image,
      caption: this.caption,
    };
  }

  openInfoWindowHTML(mymarker: any) {
    console.log(
      'openInfoWindowHTML: mymarker.advancedMarker = ',
      mymarker.advancedMarker
    );
    let content = this.generateMapInfoWindowContent(this.markerInfo);
    this.infoWindow.openAdvancedMarkerElement(mymarker.advancedMarker, content);
  }

  generateMapInfoWindowContent(
    marker: {
      title: string;
      image: string;
      caption: string;
    } | null
  ) {
    const mapInfoWindowContent = `
      <style>
        .custom-content {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 200px;
          pointer-events: all !important;
          z-index: 40;
          margin-top: -50px;
        }

        .custom-content img {
          width: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
          
        .custom-content .unit-name {
            margin-top: 10px;
            color: #103753 !important;
            font-size: 16px;
            line-height: 26px;
            text-decoration: none;
            outline: none !important;
            padding-left: 12px !important;
            padding-right: 12px !important;
            padding-bottom: 12px !important;
        }
      </style>

      <div class="custom-content" id="content">
          <img
            src="${marker.image}"
            alt="${marker.caption}"
            width="200px"
          />
          <div class="unit-name" >
          ${marker.title}
          </div>
       <div>
    `;

    return mapInfoWindowContent;
  }
}
